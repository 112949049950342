import React from "react";
import { useNavigate } from "react-router-dom";
import Partypop1 from "../Images/Assets/Partypop1.png";
import Partypop2 from "../Images/Assets/Partypop2.png";

import Applicationcheck from "../Images/Assets/applicationcheck.gif";
import "./CNApplication.css";
import Header from "../User-Components/Front-Page-Components/Header/Header";
import StatusBar from "../Status-Bar/StatusBar";
import StepsFooter from "../User-Components/Front-Page-Components/Footer/StepsFooter";
import OfferImg from "../Images/Page-Img/Offers.png";

function ApplicationStatus() {
  const history = useNavigate();

  const handleNext = (e) => {
    e.preventDefault();
    history("/trackapplication");
  };

  return (
    <>
      <Header />

      <div className="CN2-Application">
        <div className="CN2-Application-Container">
          <div className="CN2-Application-Main-Div">
            <div className="CN2-Application-Main-Div-Left">
              <img
                src={OfferImg}
                alt="Profile with pan and aadhaar"
                className="CN2-Application-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-success)"
                KycColor="var(--color-success)"
                IncomeColor="var(--color-success)"
                OffersColor="var(--color-success)"
              />
            </div>
            <div className="CN2-Application-Main-Div-Right">
              <div>
                <img
                  src={Partypop1}
                  alt="Congratulation for approval"
                  className="CN2-Application-Main-Div-Right-Img"
                />
                <h1>Congratulations!</h1>
                <img
                  src={Partypop2}
                  alt="Congratulation for approval"
                  className="CN2-Application-Main-Div-Right-Img"
                />
              </div>
              Your application has been submitted
              <div>
                <img
                  src={Applicationcheck}
                  alt="Application check"
                  className="CN2-Application-Check-Img"
                />
              </div>
              Thank you for trusting us!
              <button className="CN2-Application-Button" onClick={handleNext}>
                What happens next?
              </button>
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default ApplicationStatus;
