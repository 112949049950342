import React from "react";
import "./CircleRates.css";
import ChannelSidebar from "../../Auth-Components/Channel-Auth/ChannelSidebar";
import ChannelHamburger from "../components/ChannelHamburger";

const CircleRates = () => {
  return (
    <>
      <div className="CN-Circle-Rate-Page">
        <ChannelSidebar />
        <div className="CN-Circle-Rate-MainContainer">
          <div
            style={{
              marginTop: "10px",
              marginBottom: "15px",
            }}
          >
            <ChannelHamburger />
          </div>
          <h1>Gurugram circle rates</h1>
          <iframe
            src="https://www.google.com/maps/d/u/2/embed?mid=13pqZ-F6004YcKx7k-y2oq8ncsZK82Hs&ehbc=2E312F&noprof=1"
            title="Gurugram circle rates"
          />
        </div>
      </div>
    </>
  );
};

export default CircleRates;
