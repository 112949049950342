import "./incomeindex.css";
import IncomeImg from "../Images/Page-Img/Income proof.png";
import { useNavigate } from "react-router-dom";
import Header from "../User-Components/Front-Page-Components/Header/Header";
import StatusBar from "../Status-Bar/StatusBar";
import StepsFooter from "../User-Components/Front-Page-Components/Footer/StepsFooter";

function IncomeIndex() {
  const history = useNavigate();

  const onIncomeButtonClick = () => {
    history("/banking");
  };
  return (
    <>
      <Header />

      <div className="CN2-IncomeProof">
        <div className="CN2-IncomeProof-Container">
          <div className="CN2-IncomeProof-Main-Div">
            <div className="CN2-IncomeProof-Main-Div-Left">
              <img
                src={IncomeImg}
                alt="Profile with pan and aadhaar"
                className="CN2-IncomeProof-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-success)"
                KycColor="var(--color-success)"
                IncomeColor="var(--color-yellow)"
                OffersColor="var(--color-success)"
              />
            </div>
            <div className="CN2-IncomeProof-Main-Div-Right">
              <h1>Start with your income proof</h1>
              <div>
                Banks need this to verify your income and <br />
                make you eligible for higher amount and <br />
                exiting offers
              </div>
              <button
                className="CN2-IncomeProof-Button"
                onClick={onIncomeButtonClick}
              >
                <div className="income-button-text">Proceed</div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default IncomeIndex;
