import React from "react";
import "./ChannelDasboardTest.css";
import ChannelSidebar from "./ChannelSidebar";
import ChannelSummary from "../../Channel-Components/ChannelSummary";

const ChannelDashboardTest = () => {
  return (
    <div className="CN-ChannelDashboardTest">
      <ChannelSidebar />
      <div className="CN-Channel-Main-Container">
        <ChannelSummary />
      </div>
    </div>
  );
};

export default ChannelDashboardTest;
