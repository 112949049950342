import React, { useEffect, useState } from "react";
import "./ChannelSidebar.css";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import WebAssetIcon from "@mui/icons-material/WebAsset";
// import CampaignIcon from "@mui/icons-material/Campaign";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Images/CNbw.png";

const ChannelSidebar = () => {
  // State to manage the selected item
  const [selected, setSelected] = useState("/channel/summary");
  const history = useNavigate();

  const SidebarData = [
    {
      title: "Summary",
      icon: <SummarizeIcon />,
      path: "/channel/summary",
    },
    {
      title: "Payouts",
      icon: <ReceiptIcon />,
      path: "/channel/payout",
    },
    {
      title: "Leads",
      icon: <PeopleOutlineIcon />,
      path: "/channel/leads",
    },
    // {
    //   title: "Tools",
    //   icon: <WebAssetIcon />,
    //   path: "/channel/tools",
    // },
    // {
    //   title: "Website",
    //   icon: <WebAssetIcon />,
    //   path: "/channel/website",
    // },
    // {
    //   title: "Campaigns",
    //   icon: <CampaignIcon />,
    //   path: "/channel/campaigns",
    // },
  ];

  // Function to handle click event on sidebar links
  const handleClick = (path) => {
    setSelected(path);
    localStorage.setItem("path", path);
  };

  useEffect(() => {
    const fetchPath = localStorage.getItem("path");
    setSelected(fetchPath);
    // console.log(fetchPath);
  }, []);

  return (
    <div className="CN-Channel-Sidebar">
      <div className="CN-Channel-Sidebar-Container">
        <img
          src={logo}
          alt="Creditnama"
          className="CN-Channel-CNLogo"
          onClick={() => history("/")}
        />
        {SidebarData.map((value) => {
          return (
            <Link
              to={value.path}
              className="CN-Channel-Sidebar-Link"
              style={
                selected === value.path
                  ? {
                      color: "var(--color-darkgreen)",
                      backgroundColor: "var(--color-lightyellow)",
                      borderRadius: "10px",
                      fontWeight: "700",
                    }
                  : null
              }
              key={value.path}
              onClick={() => handleClick(value.path)}
            >
              {value.icon}
              {value.title}
            </Link>
          );
        })}
        <a
          className="nav-link dropdown-toggle"
          href="#channelsidebar"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{
            marginLeft: "20px",
          }}
          onClick={() => localStorage.removeItem("path")}
        >
          <WebAssetIcon style={{ marginRight: "10px" }} />
          Tools
        </a>
        <ul
          className="dropdown-menu"
          style={{
            backgroundColor: "var(--color-lightgreen)",
            color: "var(--color-darkgreen) ",
          }}
        >
          <li>
            <a
              className="dropdown-item"
              href="/channel/calculators"
              style={{ color: "var(--color-darkgreen)" }}
            >
              Calculators
            </a>{" "}
            <a
              className="dropdown-item"
              href="/channel/summary"
              style={{ color: "var(--color-darkgreen)" }}
            >
              Check Property Valuation
            </a>{" "}
            <a
              className="dropdown-item"
              href="/channel/circle-rates"
              style={{ color: "var(--color-darkgreen)" }}
            >
              Circle Rates
            </a>
            <a
              className="dropdown-item"
              href="/channel/summary"
              style={{ color: "var(--color-darkgreen)" }}
            >
              List of RERA Approved Projects
            </a>
            <a
              className="dropdown-item"
              href="/channel/rate-of-interest"
              style={{ color: "var(--color-darkgreen)" }}
            >
              Rate of interest
            </a>
          </li>
        </ul>
      </div>
      <div className="CN-Channel-Sidebar-button-div">
        <Link
          className="CN-Channel-Sidebar-Link"
          to={"/channel/create-lead"}
          onClick={() => handleClick("/channel/create-lead")}
        >
          <DataSaverOnIcon sx={{ fontSize: 25 }} />
          Create Lead
        </Link>
      </div>
    </div>
  );
};

export default ChannelSidebar;
