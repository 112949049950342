import React from 'react';

const TextFormatter = ({ text }) => {
    const formatText = (input) => {
        const lines = input.split('\n'); // Split input into lines based on new lines
    
        return lines.map((line, index) => {
          // Format bold text wrapped with **
          const formattedLine = line.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    
          if (formattedLine.trim().startsWith('*')) {
            // Handle lines that start with '*'
            return <li key={index} dangerouslySetInnerHTML={{ __html: formattedLine.trim().slice(1).trim() }} />;
          } else if (formattedLine.trim().endsWith('.')) {
            // Handle sentences ending with '.'
            return <p key={index} dangerouslySetInnerHTML={{ __html: formattedLine.trim() }} />;
          } else {
            // Handle other text as a paragraph
            return <div key={index} dangerouslySetInnerHTML={{ __html: formattedLine.trim() }} />;
          }
        });
      };

  const formattedText = formatText(text);

  return (
    <div>
      <div>
        {formattedText.some(item => item.type === 'li') ? <ul>{formattedText}</ul> : formattedText}
      </div>
    </div>
  );
};

export default TextFormatter;
