import React from "react";
import "./ThankYou.css";

function ThankYou({ setShowForm }) {
  return (
    <>
      <div className="CN-ThankYou-content">
        <div className="CN-ThankYou-wrapper-1">
          <div className="CN-ThankYou-wrapper-2">
            <h1>Thank you !</h1>
            <p>
              We have recieved your request our team will get back to you soon.{" "}
            </p>
            <button
              onClick={() => setShowForm(true)}
              className="CN-ThankYou-go-home"
            >
              Back to Home
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ThankYou;
