"use client";
import React from "react";
import "./Tab.css";

const Tab = ({ tabName, setTabName }) => {
  return (
    <>
      <div className="tab-container BCN-Tab-Container-D">
        <input
          type="radio"
          name="tab"
          id="tab1"
          className="tab tab--1"
          value={"affordabilityCal"}
          checked={tabName === "affordabilityCal"}
          onChange={() => setTabName("affordabilityCal")}
        />
        <label className="tab_label" htmlFor="tab1">
          Affordability Calculator
        </label>

        <input
          type="radio"
          name="tab"
          id="tab2"
          className="tab tab--2"
          value={"btCal"}
          checked={tabName === "btCal"}
          onChange={() => setTabName("btCal")}
        />
        <label className="tab_label" htmlFor="tab2">
          Balance Transfer Calculator
        </label>

        <input
          type="radio"
          name="tab"
          id="tab3"
          className="tab tab--3"
          value={"emiCal"}
          checked={tabName === "emiCal"}
          onChange={() => setTabName("emiCal")}
        />
        <label className="tab_label" htmlFor="tab3">
          EMI Calculator
        </label>

        <input
          type="radio"
          name="tab"
          id="tab4"
          className="tab tab--4"
          value={"eligibilityCal"}
          checked={tabName === "eligibilityCal"}
          onChange={() => setTabName("eligibilityCal")}
        />
        <label className="tab_label" htmlFor="tab4">
          Eligibility Calculator
        </label>

        <div className="indicator"></div>
      </div>

      <div className="dropdown BCN-Tab-Container-M">
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {tabName === "" && "Affordability Calculator"}
          {tabName === "affordabilityCal" && "Affordability Calculator"}
          {tabName === "btCal" && "Balance Transfer Calculator"}
          {tabName === "emiCal" && "EMI Calculator"}
          {tabName === "eligibilityCal" && "Eligibility Calculator"}
        </button>
        <ul className="dropdown-menu">
          <li>
            <div onClick={() => setTabName("affordabilityCal")}>
              Affordability Calculator
            </div>
          </li>
          <li>
            <span onClick={() => setTabName("btCal")}>
              Balance Transfer Calculator
            </span>
          </li>
          <li>
            <div onClick={() => setTabName("emiCal")}>EMI Calculator</div>
          </li>
          <li>
            <div onClick={() => setTabName("eligibilityCal")}>
              Eligibility Calculator
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Tab;
