import React, { useState, useEffect, useCallback } from "react";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import "./EMIcalculator.css";

const EmiCalulatorForLandingPage = (Props) => {
  const [loanAmount, setLoanAmount] = useState(100000);
  const [interestRate, setInterestRate] = useState(8);
  const [tenure, setTenure] = useState(3);
  const [result, setResult] = useState(null);
  const [LoanTable, setLoanTable] = useState(false);
  const [amortizationTable, setAmortizationTable] = useState([]);

  const history = useNavigate();

  const calculateLoan = useCallback(() => {
    const monthlyInterestRate = interestRate / 12 / 100;
    const totalMonths = tenure * 12;

    const emi =
      (loanAmount *
        monthlyInterestRate *
        (1 + monthlyInterestRate) ** totalMonths) /
      ((1 + monthlyInterestRate) ** totalMonths - 1);

    let remainingBalance = loanAmount;
    const tableData = [];

    for (let month = 1; month <= totalMonths; month++) {
      const interestPayment = remainingBalance * monthlyInterestRate;
      const principalPayment = emi - interestPayment;
      remainingBalance -= principalPayment;

      tableData.push({
        month,
        monthlyPayment: emi.toFixed(0),
        principalPayment: principalPayment.toFixed(0),
        interestPayment: interestPayment.toFixed(0),
        remainingBalance: remainingBalance.toFixed(0),
      });
    }

    setAmortizationTable(tableData);

    setResult({
      emi: emi.toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 0,
      }),
      totalPrincipalPaid: (emi * totalMonths).toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 0,
      }),
      totalInterestPaid: (emi * totalMonths - loanAmount).toLocaleString(
        "en-IN",
        {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }
      ),
    });
  }, [loanAmount, interestRate, tenure]);

  useEffect(() => {
    calculateLoan();
  }, [loanAmount, interestRate, tenure, calculateLoan]);
  const handleShowTable = (e) => {
    e.preventDefault();
    if (LoanTable === true) {
      setLoanTable(false);
    } else if (LoanTable === false) {
      setLoanTable(true);
    }
  };
  const handleApply = () => {
    history("/signup");
  };

  return (
    <>
      <div className="CN-EMI-Cal-Div">
        <form onSubmit={handleShowTable}>
          <h3>{Props.Heading}</h3>
          <div className="CN-EMI-Inner-Content">
            <div className="PB-range-slider-div">
              <div className="CN-EMI-InnerLabel-Div">
                <div className="CN-EMICal-Container">
                  <label>Loan Amount:</label>
                  <div>
                    <input
                      value={Number(loanAmount).toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR",
                        maximumFractionDigits: 0,
                      })}
                      style={{
                        textDecoration: "none",

                        backgroundColor: "transparent",
                        width: "150px",
                        height: "40px",
                        textAlign: "end",
                        fontWeight: "700",
                        border: "1px solid lightgray",
                        borderRadius: "10px",
                        color: "var(--color-darkgreen)",
                        padding: "5px 10px",
                      }}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^\d.]/g, ""); // Remove non-numeric characters
                        setLoanAmount(value ? parseFloat(value) : 0); // Set to 0 if empty
                      }}
                      maxLength={15}
                    />
                  </div>
                </div>
                <input
                  type="range"
                  min="100000"
                  max="100000000"
                  step="50000"
                  value={loanAmount}
                  className="PB-range-slider"
                  id="loanAmountRange"
                  onChange={(e) =>
                    setLoanAmount(
                      parseFloat(e.target.value.replace(/[^\d."]/g, ""))
                    )
                  }
                />

                <div className="CN-EMICal-Container">
                  <label>Rate Of Interest (p.a)</label>
                  <div>
                    <input
                      value={interestRate}
                      style={{
                        textDecoration: "none",
                        backgroundColor: "transparent",
                        width: "80px",
                        height: "40px",
                        padding: "5px 10px",
                        border: "1px solid lightgray",
                        borderRadius: "10px",
                        textAlign: "end",
                        fontWeight: "700",
                        color: "var(--color-darkgreen)",
                      }}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^\d.]/g, ""); // Allow digits and a single period
                        setInterestRate(value); // Update state
                      }}
                      maxLength={5}
                      type="tel"
                    />
                    %
                  </div>
                </div>
                <input
                  type="range"
                  min="1"
                  max="30"
                  step={0.15}
                  value={interestRate}
                  onChange={(e) =>
                    setInterestRate(
                      parseFloat(e.target.value.replace(/[^\d.]/g, ""))
                    )
                  }
                  className="PB-range-slider"
                  id="interestRateRange"
                />
                <div className="CN-EMICal-Container">
                  <label> Loan Tenure </label>
                  <div>{tenure * 12} months</div>
                </div>
                <input
                  type="range"
                  min="1"
                  max="35"
                  value={tenure}
                  onChange={(e) => setTenure(e.target.value)}
                  className="PB-range-slider"
                  id="tenureRange"
                />
              </div>
              <div style={{ width: "100%", gap: "20px" }}>
                <div className="CN-Emi-Piechart-Div">
                  {result && amortizationTable.length > 0 && (
                    <>
                      <div>
                        <Doughnut
                          className="Cn-PieChart-Size"
                          data={{
                            datasets: [
                              {
                                data: [
                                  result.totalPrincipalPaid.replace(
                                    /[^0-9.-]+/g,
                                    ""
                                  ), // Remove non-numeric characters
                                  result.totalInterestPaid.replace(
                                    /[^0-9.-]+/g,
                                    ""
                                  ),
                                ],
                                backgroundColor: ["#217E3C", "#d4df33"],
                              },
                            ],
                          }}
                          options={{
                            tooltips: {
                              callbacks: {
                                label: function (tooltipItem, data) {
                                  const label =
                                    data.labels[tooltipItem.index] || "";
                                  const value = Number(
                                    data.datasets[0].data[tooltipItem.index]
                                  ).toLocaleString("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                    maximumFractionDigits: 0,
                                  });
                                  return `${label}: ${value}`;
                                },
                              },
                            },
                            legend: {
                              display: false,
                            },
                          }}
                        />
                      </div>
                    </>
                  )}

                  {result && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        fontSize: "15px",
                        width: "300px",
                      }}
                    >
                      <div className="CN-EMI-Details">
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            background: "#d4df33",
                            borderRadius: "1rem",
                          }}
                        />
                        <div>Interest Payment: {result.totalInterestPaid}</div>
                      </div>
                      <div className="CN-EMI-Details">
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            background: "#217E3C",
                            borderRadius: "1rem",
                          }}
                        />
                        Principal Payment: {result.totalPrincipalPaid}
                      </div>
                      <div className="my-2 mx-3">Monthly EMI: {result.emi}</div>
                    </div>
                  )}
                  <button
                    style={{
                      width: "120px",
                      height: "40px",
                      borderRadius: "1rem",
                      border: "none",
                      backgroundColor: "var(--color-darkgreen)",
                      color: "white",
                      fontFamily: "var(--font-poppins)",
                      fontSize: "15px",
                      fontWeight: "700",
                    }}
                    onClick={handleApply}
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EmiCalulatorForLandingPage;
