import React from "react";
import { Helmet } from "react-helmet-async";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import BlogBank from "../../../../Images/Bank-Logos/yesbank.png";

function YesLAP() {
  document.addEventListener("contextmenu", function (e) {
    e.preventDefault();
  });
  document.addEventListener("selectstart", function (e) {
    e.preventDefault();
  });
  document.addEventListener("touchstart", function (e) {
    e.preventDefault();
  });

  return (
    <>
      <Helmet>
        <title>Yes Bank - Loan Against Property</title>
        <meta
          name="description"
          content="Yes LAP interest rates starting from 9.75% p.a.
        for a maximum repayment tenure of 15 Years"
        />
        <link rel="canonical" href="/yes-lap" />
      </Helmet>
      <Header />

      <div className="Aboutus-page">
        <div className="Aboutus-content">
          <h1>Yes Bank - Loan Against Property</h1>
          <div className="CN-Bank-BlogsImgDiv">
            <img src={BlogBank} alt="Yes LAP" className="CN-Bank-BlogsImg" />
          </div>
          <div style={{ marginTop: "20px" }}>
            <p>
              Yes Bank loan against property product presents a versatile
              financing solution tailored to diverse property types and borrower
              profiles with competitive interest rates starting from 9.75% p.a.
              for a maximum repayment tenure of 15 years.
            </p>
            <p>
              The loan can be availed on both residential and commercial
              properties by salaried and self-employed applicants.
            </p>
            <p>
              The loan against property is like a personal loan which can be
              used for both personal and business purposes other than any
              speculative use.
            </p>
            <p>
              The bank charges a processing fee of up to 1% of the loan amount
              plus applicable taxes.
            </p>
          </div>
          <p>
            <b>Documents for Salaried Customers</b>
          </p>
          <ul className="CN-ul">
            <li>
              A copy of any of the following as proof of residence: Ration Card,
              Telephone Bill, Aadhar Card, Electricity Bill, Voter's ID Card
            </li>
            <li>
              A copy of any of the following as proof of identity: Voter's ID
              Card, PAN Card, Aadhar Card, Employer's Card
            </li>

            <li>
              Latest Bank Statement/Passbook from where you can show a
              salary/income being credited for the previous 6 months
            </li>
            <li>
              Salary slip for the previous 6 months showing all deductions
            </li>
            <li>Form 16 for the previous 2 years</li>
            <li>
              Copies of all the property documents of the concerned property to
              be pledged for the loan
            </li>
          </ul>
          <p>
            <b>Documents for Self-Employed Customers</b>
          </p>
          <ul className="CN-ul">
            <li>Certified Financial Statement for the previous 3 years</li>
            <li>
              A copy of any of the following as proof of residence: Ration Card,
              Telephone Bill, Electricity Bill, Voter's ID Card
            </li>
            <li>
              A copy of any of the following as proof of identity: Voter's ID
              Card, Employer's Card
            </li>
            <li>
              Latest Bank Statement/Passbook from where you can show a
              salary/income being credited for the previous 6 months
            </li>
            <li>
              Copies of all the property documents of the concerned property to
              be pledged for the loan
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default YesLAP;
