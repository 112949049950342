import React, { useEffect, useState } from "react";
import Header from "../../User-Components/Front-Page-Components/Header/Header";
import StatusBar from "../../Status-Bar/StatusBar";
import BankingStandbyImg from "../../Images/Page-Img/Bank Statement.png";
import LoadingGif2 from "../../Images/Page-Img/BankingStandby.gif";
import LoadingGif1 from "../../Images/Page-Img/BankingStandby2.gif";
import LoadingGif3 from "../../Images/Page-Img/BankingStandby3.gif";
import StepsFooter from "../../User-Components/Front-Page-Components/Footer/StepsFooter";
import "./CNBankingStandby.css";
import { useNavigate } from "react-router-dom";

const BankingStandby = () => {
  const [step, setStep] = useState(0);
  const [profession, setProfession] = useState("");
  const history = useNavigate();

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("AskFormData"));
    setProfession(storedData.prof);

    const timer = setInterval(() => {
      setStep((prevStep) => (prevStep + 1) % 3);
    }, 20000);

    const redirectTimer = setTimeout(() => {
      if (
        profession === "self employed" ||
        profession === "specialist professional"
      ) {
        clearInterval(timer);
        history("/finaloffer");
      } else if (profession === "salaried") {
        clearInterval(timer);
        history("/payslip");
      }
    }, 60000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, [profession, history]);

  const gifs = [LoadingGif1, LoadingGif2, LoadingGif3];
  const gifStyles = [{ width: "160px" }, { width: "150px" }, { width: "80px" }];

  return (
    <>
      <Header />

      <div className="CN2-BankingStandby">
        <div className="CN2-BankingStandby-Container">
          <div className="CN2-BankingStandby-Main-Div">
            <div className="CN2-BankingStandby-Main-Div-Left">
              <img
                src={BankingStandbyImg}
                alt="Upload BankingStandby"
                className="CN2-BankingStandby-Main-Div-Left-Img"
                loading="lazy"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-success)"
                KycColor="var(--color-success)"
                IncomeColor="var(--color-yellow)"
                OffersColor="var(--color-success)"
              />
            </div>
            <div className="CN2-BankingStandby-Main-Div-Right">
              <h1>
                Processing Your Banking Data.
                <br /> Thank You for Your Patience
              </h1>
              <img
                src={gifs[step]}
                alt="Banking Standby"
                className="CN2-BankingStandby-Gif"
                style={gifStyles[step]}
              />
              <p>Please do not press back button</p>
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
};

export default BankingStandby;
