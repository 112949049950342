import React, { useEffect } from "react";
import Header from "../Front-Page-Components/Header/Header";
import { useState } from "react";
import Checktest from "./Checktest";
// import Background from "../../Images/Assets/blob.svg";
import BusinessImg from "../../Images/Page-Img/Business detail.png";
import Propr from "../../Images/Assets/Prop.png";
import Partnersip from "../../Images/Assets/Partnership.png";
import PvtLtd from "../../Images/Assets/directors.png";
import Manuf from "../../Images/Assets/Manufacturing.png";
import Trader from "../../Images/Assets/Trader.png";
import Service from "../../Images/Assets/Service.png";
import axios from "axios";
import serverIP from "../../config/config";
import { useNavigate } from "react-router-dom";
import "./CNBusiness.css";
import Animation1 from "../../Animation/animation";
import StatusBar from "../../Status-Bar/StatusBar";
import StepsFooter from "../Front-Page-Components/Footer/StepsFooter";
import FAQ from "../FAQs/FAQ";

function Business() {
  const [businessCat, setBusinessCat] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [vintage, setVintage] = useState("");
  const [turnover1, setTurnover1] = useState("");
  const [turnover2, setTurnover2] = useState("");
  const [pat, setPat] = useState("");
  const [gst, setGst] = useState("");
  const [dep, setDep] = useState("");
  const [loan, setLoan] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();
  const currentDate = new Date();
  const oneYearAgo = new Date(currentDate);
  oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

  useEffect(() => {
    const storedData =
      JSON.parse(localStorage.getItem("businessFormData")) || {};
    setBusinessCat(storedData.businessCat || "");
    setBusinessName(storedData.businessName || "");

    setBusinessType(storedData.businessType || "");
    setVintage(storedData.vintage || "");
    setTurnover1(storedData.turnover1 || "");
    setTurnover2(storedData.turnover2 || "");
    setPat(storedData.pat || "");
    setGst(storedData.gst || "");
    setDep(storedData.dep || "");
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("AskFormData")) || {};
    setLoan(storedData.loan);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Save form data to localStorage on successful submission
    localStorage.setItem(
      "businessFormData",
      JSON.stringify({
        businessCat,
        businessName,
        businessType,
        vintage,
        gst,
        turnover1,
        turnover2,
        pat,
        dep,
      })
    );

    if (businessCat === "") {
      setError("Select Business Category");
      return;
    }
    if (businessName === "") {
      setError("Enter Business Name");
      return;
    }
    if (businessType === "") {
      setError("Select Business Type");
      return;
    }

    if (vintage === "") {
      setError("Select Date of Commencement");
      return;
    }

    if (new Date(vintage) > currentDate) {
      setError("Select Valid Date of Commencement");
      return;
    }

    if (gst === "" || gst.length < 15) {
      setError("Enter Correct GST Number");
      return;
    }
    if (turnover1 === "") {
      setError("Enter FY 22-23 Turnover");
      return;
    }
    if (turnover1 > "2000000000") {
      setError("Enter Valid Turnover in FY 22-23");
      return;
    }
    if (turnover1 < "500000") {
      setError("Minimum 5 Lakh Turnover Required");
      return;
    }
    if (turnover2 > "2000000000") {
      setError("Enter Valid Turnover in FY 21-22");
      return;
    }
    if (pat === "") {
      setError("Enter FY 22-23 Profit after tax");
      return;
    }
    if (pat > "200000000") {
      setError("Enter Valid Net Profit in FY 22-23");
      return;
    }
    if (dep > "200000000") {
      setError("Enter Valid Depreciation in FY 22-23");
      return;
    }

    try {
      // Get the access token from localStorage
      const token = localStorage.getItem("accessToken");
      const userId = localStorage.getItem("userId");

      // Make sure the token exists before proceeding
      if (!token) {
        console.error("Access token not found.");
        return;
      }

      // Set the Authorization header with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Create the data object in the desired format
      const data = {
        business_name: businessName,
        business_category: businessCat,
        business_type: businessType,
        industry: "",
        doi: vintage,
        gst: gst.toUpperCase(),
        revenue_yearminus1: parseInt(turnover1),
        revenue_yearminus2: parseInt(turnover2),
        pat_yearminus1: parseInt(pat),
        estimated_depreciation: parseInt(dep),
      };

      setIsLoading(true);
      // Send a POST request to the server with the selected data
      const response = await axios.post(
        `${serverIP}/user/selfemployed/${userId}`,
        data,
        config
      );
      console.log(response);
      if ((response.status = 200 || response.status < 300)) {
        console.log("Business Details Updated");
        if (loan === "property purchase" || loan === "loan against property") {
          history("/asset");
        } else {
          history("/offerstandby");
        }
      } else {
        console.log("Error in Post");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error inserting data:", error);
      setIsLoading(false);

      // Handle form submission error
    } finally {
      setIsLoading(false);
    }
  };

  const handleCatChange = (e) => {
    setBusinessCat(e.target.value);
    setError("");
  };

  const handleTypeChange = (e) => {
    setError("");

    if (businessCat === "") {
      setError("First Select Business Category");
    } else setBusinessType(e.target.value);
  };
  const handleBusinessNameChange = (e) => {
    setBusinessName(e.target.value);
    setError("");
  };
  const handleGstChange = (e) => {
    const gstInput = e.target.value.toUpperCase();
    const isValidInput = /^[a-zA-Z0-9]*$/.test(gstInput);
    if (gstInput.length > 15 || !isValidInput) {
      setError("Invalid GST Format");
    } else {
      setError("");
    }

    // Update state with formatted and validated GST
    setGst(isValidInput ? gstInput : gst);
  };
  const handlePatChange = (e) => {
    setError("");
    const value =
      e.target.type === "range"
        ? parseFloat(e.target.value)
        : parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
    setPat(isNaN(value) ? 0 : value);
  };

  const handleTo2Change = (e) => {
    setError("");
    const value =
      e.target.type === "range"
        ? parseFloat(e.target.value)
        : parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
    setTurnover2(isNaN(value) ? 0 : value);
  };

  const handleTo1Change = (e) => {
    setError("");
    const value =
      e.target.type === "range"
        ? parseFloat(e.target.value)
        : parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
    setTurnover1(isNaN(value) ? 0 : value);
  };

  const handleDepChange = (e) => {
    setError("");
    const value =
      e.target.type === "range"
        ? parseFloat(e.target.value)
        : parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
    setDep(isNaN(value) ? 0 : value);
  };
  return (
    <>
      <Header />

      <div className="CN2-Business">
        <div className="CN2-Business-Container">
          <div className="CN2-Business-Main-Div">
            <div className="CN2-Business-Main-Div-Left">
              <img
                src={BusinessImg}
                alt="Business Details"
                className="CN2-Business-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-yellow)"
                KycColor="white"
                IncomeColor="white"
                OffersColor="white"
              />
            </div>
            <div className="CN2-Business-Main-Div-Right">
              <h1>Business Details</h1>
              <div className="CN2-Business-Faq-Div">
                <FAQ PageName="business" />
              </div>
              {isLoading ? (
                <>
                  <Animation1 />
                </>
              ) : (
                <>
                  <form onSubmit={handleSubmit}>
                    {error && <div className="CN2-Business-Error">{error}</div>}
                    <div className="CN2-Business-Checkbox-Div">
                      Business Category
                      <div className="CN2-Business-Checkbox-Div-Select">
                        <Checktest
                          name="Proprietorship Firm"
                          src={Propr}
                          width="30px"
                          value="proprietorship firm"
                          onChange={handleCatChange}
                          checked={businessCat === "proprietorship firm"}
                          backgroundColor={
                            businessCat === "proprietorship firm" && "#dcf7e4"
                          }
                          fontWeight={
                            businessCat === "proprietorship firm" && "bolder"
                          }
                        />
                        <Checktest
                          name="Partnership Firm"
                          src={Partnersip}
                          width="35px"
                          value="partnership firm"
                          onChange={handleCatChange}
                          checked={businessCat === "partnership firm"}
                          backgroundColor={
                            businessCat === "partnership firm" && "#dcf7e4"
                          }
                          fontWeight={
                            businessCat === "partnership firm" && "bolder"
                          }
                        />
                        <Checktest
                          name="Private Limited Firm"
                          src={PvtLtd}
                          width="30px"
                          value="pvt ltd firm"
                          onChange={handleCatChange}
                          checked={businessCat === "pvt ltd firm"}
                          backgroundColor={
                            businessCat === "pvt ltd firm" && "#dcf7e4"
                          }
                          fontWeight={
                            businessCat === "pvt ltd firm" && "bolder"
                          }
                        />
                      </div>
                    </div>
                    {businessCat && (
                      <div className="CN2-Business-Checkbox-Div">
                        Business Name
                        <div className="CN2-Business-Checkbox-Div-Select">
                          <input
                            value={businessName}
                            type="text"
                            id="Business Vintage"
                            onChange={handleBusinessNameChange}
                            className="CN2-Business-Input"
                            maxLength={50}
                          />
                        </div>
                      </div>
                    )}
                    <div className="CN2-Business-Checkbox-Div">
                      Business Type
                      <div className="CN2-Business-Checkbox-Div-Select">
                        <Checktest
                          name="Manufacturer"
                          src={Manuf}
                          width="30px"
                          value="manufacturer"
                          onChange={handleTypeChange}
                          checked={businessType === "manufacturer"}
                          backgroundColor={
                            businessType === "manufacturer" && "#dcf7e4"
                          }
                          fontWeight={
                            businessType === "manufacturer" && "bolder"
                          }
                        />
                        <Checktest
                          name="Trader"
                          src={Trader}
                          width="30px"
                          value="trader"
                          onChange={handleTypeChange}
                          checked={businessType === "trader"}
                          backgroundColor={
                            businessType === "trader" && "#dcf7e4"
                          }
                          fontWeight={businessType === "trader" && "bolder"}
                        />
                        <Checktest
                          name="Service Provider"
                          src={Service}
                          width="35px"
                          value="service provider"
                          onChange={handleTypeChange}
                          checked={businessType === "service provider"}
                          backgroundColor={
                            businessType === "service provider" && "#dcf7e4"
                          }
                          fontWeight={
                            businessType === "service provider" && "bolder"
                          }
                        />
                      </div>
                    </div>
                    {businessType && (
                      <>
                        <div className="CN2-Business-Checkbox-Div">
                          {businessCat === "pvt ltd firm" ? (
                            <>Date of incorporation</>
                          ) : (
                            "Date of commencement"
                          )}

                          <div className="CN2-Business-Checkbox-Div-Select">
                            <input
                              value={vintage}
                              type="date"
                              id="Business Vintage"
                              onChange={(e) => {
                                setVintage(e.target.value);
                                setError("");
                              }}
                              className="CN2-Business-Input"
                              required
                            />
                          </div>
                        </div>

                        <div className="CN2-Business-Checkbox-Div">
                          GST Number
                          <div className="CN2-Business-Checkbox-Div-Select">
                            <input
                              value={gst}
                              type="text"
                              id="Business Vintage"
                              maxLength={15}
                              onChange={handleGstChange}
                              className="CN2-Business-Input"
                              required
                            />
                          </div>
                        </div>

                        <div
                          className="CN2-Business-Checkbox-Div-Select"
                          style={{ marginTop: "10px" }}
                        >
                          <div>
                            <label>Turnover FY 22-23</label>
                            <br />

                            <div>
                              <input
                                value={turnover1.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  maximumFractionDigits: "0",
                                })}
                                onChange={handleTo1Change}
                                type="tel"
                                className="CN2-Business-SmallInput"
                                required
                                maxLength={15}
                              />
                            </div>
                          </div>
                          <div>
                            <label>Turnover FY 21-22</label>
                            <br />

                            <div>
                              <input
                                value={turnover2.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  maximumFractionDigits: "0",
                                })}
                                onChange={handleTo2Change}
                                type="tel"
                                className="CN2-Business-SmallInput"
                                placeholder="(Optional)"
                                maxLength={15}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="CN2-Business-Checkbox-Div-Select"
                          style={{ marginTop: "10px" }}
                        >
                          <div>
                            <label>Net Profit FY 22-23</label>
                            <br />

                            <div>
                              <input
                                value={pat.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  maximumFractionDigits: "0",
                                })}
                                onChange={handlePatChange}
                                type="tel"
                                className="CN2-Business-SmallInput"
                                required
                                maxLength={15}
                              />
                            </div>
                          </div>

                          <div>
                            <label>Depreciation FY 22-23</label>
                            <br />

                            <div>
                              <input
                                value={dep.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  maximumFractionDigits: "0",
                                })}
                                onChange={handleDepChange}
                                type="tel"
                                className="CN2-Business-SmallInput"
                                placeholder="(Optional)"
                                maxLength={15}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button className="CN2-Business-Button">Submit</button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <StepsFooter />
    </>
  );
}

export default Business;
