import React from "react";
import Linkdin from "../../../Images/Assets/Linkdin.png";
import FB from "../../../Images/Assets/FB.png";
import Insta from "../../../Images/Assets/Insta.png";
import Mail from "../../../Images/Assets/Mail.png";
import Whatsapp from "../../../Images/Assets/WA.png";
import "./Footer.css";
import EmailIcon from "@mui/icons-material/Email";
import BusinessIcon from "@mui/icons-material/Business";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";

function Footer() {
  const redirectToSocialMedia = (socialMedia) => {
    // Define the URLs for each social media platform
    const socialMediaUrls = {
      Linkdin: "https://www.linkedin.com/company/creditnama/",
      FB: "https://www.facebook.com/creditnama",
      Insta: "https://www.instagram.com/creditnama/",
      Mail: "mailto:info@creditnama.com",
      Whatsapp: "https://wa.me/+918750072800?text=Hi",
    };

    // Redirect to the selected social media page
    window.open(socialMediaUrls[socialMedia]);
  };

  const handleTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="CN-Footer-Redirection">
        <div className="CN-Footer-InnerContent">
          <div style={{ width: "100%", padding: "0px 30px 0px 0px" }}>
            <p>
              <b>ABOUT</b>
            </p>
            <p style={{ fontFamily: "var(--font-abeezee)" }}>
              Creditnama is the brand owned by CREDITNAMA NVM FINSERV PRIVATE
              LIMITED (CIN: U66190DL2023PTC410899).
            </p>
            <p>
              Creditnama is a real-time & digital lending marketplace for
              individuals and MSMEs seeking asset-backed loans.
            </p>

            <p>
              With Creditnama’s proprietary underwriting, creditworthy customers
              are sure to find the best secured loan that address their unique
              needs. Instead of force-fitting customers into a loan product, we
              work with customers to compare through 40+ banks and NBFCs and
              find the exact loan that fits their credit needs in real-time.
            </p>
            <p style={{ marginTop: "20px" }}>
              <EmailIcon style={{ marginRight: "5px" }} /> hello@creditnama.com
              <br />
              <BusinessIcon style={{ marginRight: "5px" }} /> 406, Ground Floor,
              Kohat Enclave, Pitampura, New Delhi - 110034
              <br />
              <BusinessIcon style={{ marginRight: "5px" }} /> 4th Floor, Spring
              House, JMD Regent Arcade, Gurugram, Haryana - 122002
            </p>
          </div>
          <div className="CN-Footer-Resources">
            <p>
              <b>RESOURCES</b>
            </p>
            <br />
            <a href="https://creditnama.com/calculators">EMI Calculator</a>
            <br />
            <a href="https://creditnama.com/home-loan" className="my-2">
              Home Loan
            </a>

            <a
              className="mx-3"
              href="https://creditnama.com/hdfc-bank/home-loan"
            >
              Home Loan at HDFC Bank
            </a>
            <a
              className="mx-3"
              href="https://creditnama.com/lic-housing-finance/home-loan"
            >
              Home Loan at LIC Housing Finance
            </a>
            <a
              className="mx-3"
              href="https://creditnama.com/kotak-mahindra-bank/home-loan"
            >
              Home Loan at Kotak Bank
            </a>
            <a className="my-2" href="/lap-blogs">
              Loan Against Property
            </a>
            <a className="mx-3" href="/hdfc-lap">
              Loan Against Property at HDFC Bank
            </a>
            <a className="mx-3" href="/axis-lap">
              Loan Against Property at Axis Bank
            </a>
            <a className="mx-3" href="/kotak-lap">
              Loan Against Property at Kotak Bank
            </a>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "32%",
              gap: "5px",
            }}
          >
            <p>
              <b>QUICK LINKS</b>
            </p>
            <a href="/about">About</a>
            <a href="/contact-us">Contact</a>
            <a href="/careers">Careers</a>
            <a href="/glossary">Glossary</a>
            <a href="/partneroffers">Offers</a>
            <a href="/partnership">Partnership</a>
            <a href="https://creditnama.com/blogs">Blogs</a>
            <a href="/terms">Privacy policy</a>
            <a href="/terms">Terms of service</a>
            <a href="/team">Team</a>
            <a href="/faqs">FAQs</a>

            <div>
              <button onClick={handleTop} className="CN-GoToTop-btn">
                <VerticalAlignTopIcon />
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className="Social-acc-header">
            <div className="CN-SocialCard">
              <img
                src={Linkdin}
                alt="Linkdin"
                style={{
                  objectFit: "contain",
                  width: "30px",
                  borderRadius: "0.5rem",
                }}
                onClick={() => redirectToSocialMedia(`Linkdin`)}
              />

              <img
                src={FB}
                alt="FB"
                style={{
                  objectFit: "contain",
                  width: "30px",
                  borderRadius: "0.5rem",
                }}
                onClick={() => redirectToSocialMedia(`FB`)}
              />
              <img
                src={Insta}
                alt="Insta"
                style={{
                  objectFit: "contain",
                  width: "30px",
                  borderRadius: "0.5rem",
                }}
                onClick={() => redirectToSocialMedia(`Insta`)}
              />
              <img
                src={Mail}
                alt="Mail"
                style={{
                  objectFit: "contain",
                  width: "30px",
                  borderRadius: "0.5rem",
                }}
                onClick={() => redirectToSocialMedia(`Mail`)}
              />
              <img
                src={Whatsapp}
                alt="Whatsapp"
                style={{
                  objectFit: "contain",
                  width: "35px",
                  borderRadius: "0.5rem",
                }}
                onClick={() => redirectToSocialMedia(`Whatsapp`)}
              />
            </div>
          </div>
        </div>
        <div className="CN-Copyright-div">
          Copyright 2024 © creditnama.com. All rights reserved.
        </div>
      </div>
    </>
  );
}

export default Footer;
