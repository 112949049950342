import React from "react";
import "./aboutus.css";
import { Link } from "react-router-dom";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";

const PartnershipPage = () => {
  return (
    <>
      <Header />
      <div className="Aboutus-page">
        <div className="Aboutus-content">
          <h1>Partnership with us</h1>

          <div style={{ marginTop: "20px" }}>
            If you're a channel, an associate partner, a Direct Selling Agent
            (DSA), or a Digital Lending Partner (DLP), kindly create your
            channel profile here:{" "}
            <Link to={"/channel"}>Channel signup page </Link>
          </div>
          <div className="my-3">
            If you're a RBI registered Bank or NBFC and are interested in
            partnering with us, kindly fill this form and our team will connect
            with you:{" "}
            <Link
              to={
                "https://docs.google.com/forms/d/e/1FAIpQLScNfATpmCCfGm3Osew4CBx3QbtXdhIuI9Qe_oP4MHAtM3prOA/viewform?usp=sf_link"
              }
            >
              Lender signup page
            </Link>
          </div>
          <div>
            If you're interested in working at Creditnama, please apply at our{" "}
            <Link to={"/careers"}>careers page.</Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default PartnershipPage;
