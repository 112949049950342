import React from "react";

function Checktest(Props) {
  return (
    <div>
      <div className="checkbox-wrapper-16">
        <label className="checkbox-wrapper">
          <input
            className="checkbox-input"
            type="checkbox"
            value={Props.value}
            checked={Props.checked}
            onChange={Props.onChange}
            disabled={Props.disabled}
          />
          <span
            className="checkbox-tile"
            style={{ backgroundColor: Props.backgroundColor }}
          >
            <span className="checkbox-icon">
              <img
                src={Props.src}
                alt=""
                style={{
                  width: Props.width,
                  objectFit: "contain",
                }}
                onClick={Props.onClick}
              />
            </span>
            <span
              className="checkbox-label"
              style={{ fontWeight: Props.fontWeight, color: Props.fontColor }}
            >
              {Props.name}
            </span>
          </span>
        </label>
      </div>
    </div>
  );
}
export default Checktest;
