import React from "react";
import "./AiChatBox.css";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";

const AiChatBox = ({
  BtnDisable,
  userInput,
  setUserInput,
  handleSendMessage,
}) => {
  const handleKeyPress = (e) => {
    if (!BtnDisable) {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage();
      }
    }
  };

  // console.log(message)

  return (
    <div className="Ai-chatbox no-scrollbar">
      <div className="Ai-chatbox-input">
        <textarea
          className="form-control"
          rows="3"
          placeholder="Ask what you want!"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyDown={handleKeyPress}
        ></textarea>
        <button
          style={{ border: "none", backgroundColor: "transparent" }}
          disabled={BtnDisable}
          className="Ai-chatbox-input-icon"
          onClick={handleSendMessage}
        >
          <SendTwoToneIcon />
        </button>
      </div>
    </div>
  );
};

export default AiChatBox;
