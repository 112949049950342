import React, { useEffect, useState } from "react";
import ChannelSidebar from "../Auth-Components/Channel-Auth/ChannelSidebar";
import "./CreateStepOne.css";
import { useNavigate } from "react-router-dom";
import serverIP from "../config/config";

const CreateStepOne = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDOb] = useState("");
  const [profession, setProfession] = useState("");
  const [degree, setDegree] = useState("");
  const [salary, setSalary] = useState("");
  const [obligation, setObligation] = useState("");
  const [turnover, setTurnover] = useState("");
  const [netProfit, setNetProfit] = useState("");
  const [grossReceipts, setGrossReceipts] = useState("");
  const [loan, setLoan] = useState("");
  const [loanType, setLoanType] = useState("");
  const [loanAmount, setLoanAmount] = useState("");
  const [loanTenure, setLoanTenure] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [propertyStatus, setPropertyStatus] = useState("");
  const [pincode, setPincode] = useState("");
  const [estimatedValue, setEstimatedValue] = useState("");
  const [collapse1Open, setCollapse1Open] = useState(true);
  const [collapse2Open, setCollapse2Open] = useState(false);
  const [collapse3Open, setCollapse3Open] = useState(false);
  const [collapse4Open, setCollapse4Open] = useState(false);
  const [error, setError] = useState("");
  const channelId = localStorage.getItem("channelId");
  const token = localStorage.getItem("cToken");
  const cUserId = localStorage.getItem("cUserId");
  // const cUserContact = localStorage.getItem("cLeadContact");
  const cAskId = localStorage.getItem("cAskId");
  const history = useNavigate();

  useEffect(() => {
    const auth = localStorage.getItem("cToken");
    setLoanType(localStorage.getItem("cLeadLoanType"));
    if (!auth) {
      console.log("Login first");
      history("/channel");
    }
  }, [history]);

  const handlePersonalDetailsSubmit = async (e) => {
    e.preventDefault();

    if (firstName === "") {
      console.log("Enter first name first");
      setError("Enter first name");
      return;
    }
    if (lastName === "") {
      console.log("Enter last name");
      setError("Enter last name");
      return;
    }

    if (dob === "") {
      console.log("Enter date of birth first");
      setError("Enter date of birth");
      return;
    }

    if (profession === "") {
      console.log("Enter profession first");
      setError("Choose profession");
      return;
    }

    // Format the date of birth as "yyyy-mm-dd"
    const formattedDob = dob.split("-").reverse().join("-");

    const [yyyy, mm, dd] = formattedDob.split("-");
    const isValidDate =
      parseInt(dd, 10) <= 31 &&
      parseInt(mm, 10) <= 12 &&
      parseInt(yyyy, 10) <= 2002 &&
      parseInt(yyyy, 10) >= 1947;
    console.log(formattedDob);

    if (!isValidDate) {
      console.error("Invalid date entered");
      setError("Age should be between 21 - 75");

      return;
    }

    try {
      const response = await fetch(
        `${serverIP}/channel/p2_generateQuickOffers/${channelId}/${cUserId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fname: firstName,
            lname: lastName,
            dob: formattedDob,
            profession: profession,
          }),
        }
      );
      console.log(firstName, lastName, formattedDob, profession);

      if (response.status >= 200 && response.status < 300) {
        const data = await response.json();
        console.log(data);
        setError("");
        setCollapse1Open(false);
        setCollapse2Open(true);
      } else {
        console.log("Personal not posted");
        setError("Error in server");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleProfessionalDetailsSubmit = async (e) => {
    e.preventDefault();

    try {
      let requestBody;
      if (profession === "salaried") {
        requestBody = {
          profession: profession,
          monthlySalary: parseFloat(salary),
          monthlyDeductions: parseFloat(obligation),
        };
      } else if (profession === "self employed profession") {
        requestBody = {
          profession: profession,
          gross_receipt_yearminus1: grossReceipts,
          profession_type: degree,
        };
      } else {
        requestBody = {
          profession: profession,
          revenue_yearminus1: turnover,
          pat_yearminus1: netProfit,
        };
      }

      const response = await fetch(
        `${serverIP}/channel/p5_generateQuickOffers/${channelId}/${cUserId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.status >= 200 && response.status < 300) {
        const data = await response.json();
        console.log(data);
        setError("");
        setCollapse2Open(false);
        setCollapse3Open(true);
      } else {
        console.log("Professional details not posted");
        setError("Error in server");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAskDetailsSubmit = async (e) => {
    e.preventDefault();

    if (loanType === "") {
      console.log("Choose loan type first");
      setError("Choose loan type first");
      return;
    }

    if (loanAmount === "") {
      console.log("Enter loan amount first");
      setError("Enter loan amount first");
      return;
    }

    if (loanTenure === "") {
      console.log("Enter loan tenure first");
      setError("Enter loan tenure first");
      return;
    }

    try {
      const response = await fetch(
        `${serverIP}/channel/p3_generateQuickOffers/${channelId}/${cAskId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            initial_ask_type: loan,
            initial_ask_value: loanAmount,
            initial_ask_tenure: loanTenure,
          }),
        }
      );
      console.log(response.data);
      console.log(firstName, lastName, profession);
      if (response.status >= 200 && response.status < 300) {
        const data = await response.json();
        console.log(data);
        setError("");
        setCollapse3Open(false);
        setCollapse4Open(true);
      } else {
        console.log("Ask not posted");
        setError("Error in server");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePropertyDetailsSubmit = async (e) => {
    e.preventDefault();

    if (propertyType === "") {
      console.log("Choose property type first");
      setError("Choose property type first");
      return;
    }

    if (propertyStatus === "") {
      console.log("Choose property status first");
      setError("Choose property status first");
      return;
    }

    if (pincode === "") {
      console.log("Enter pincode first");
      setError("Enter pincode first");
      return;
    }

    if (estimatedValue === "") {
      console.log("Enter property value first");
      setError("Enter property value first");
      return;
    }
    try {
      const response = await fetch(
        `${serverIP}/channel/p4_generateQuickOffers/${channelId}/${cAskId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            asset_type: propertyType,
            pincode: pincode,
            assetValue: parseFloat(estimatedValue),
            curret_status: propertyStatus,
          }),
        }
      );
      console.log(response.data);
      console.log(firstName, lastName, profession);
      if (response.status >= 200 && response.status < 300) {
        const data = await response.json();
        console.log(data);
        history("/channel/leadoffers");
      } else {
        console.log("Profession not posted");
        setError("Error in server");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formatDob = (value) => {
    const numericValue = value.replace(/\D/g, "");

    if (!numericValue) {
      return "";
    }

    if (numericValue.length <= 2) {
      return numericValue;
    } else if (numericValue.length <= 4) {
      return `${numericValue.slice(0, 2)}-${numericValue.slice(2)}`;
    } else {
      return `${numericValue.slice(0, 2)}-${numericValue.slice(
        2,
        4
      )}-${numericValue.slice(4, 8)}`;
    }
  };

  const handleDobChange = (e) => {
    const formattedValue = formatDob(e.target.value);
    setDOb(formattedValue);
  };
  return (
    <>
      <div className="CN-Channel-CreateLead-Page">
        <ChannelSidebar />
        <div
          className="CN-Channel-CreateLeadStep-MainContainer"
          style={{ marginTop: "25px" }}
        >
          <h1>Create Lead</h1>
          <div className="CN-Channel-CreateLead-Status">
            <div>
              <div
                className="CN-Channel-Status-Circle"
                style={{
                  backgroundColor: "var(--color-yellow)",
                }}
              ></div>
              Details
            </div>
            <div className="CN-Channel-Status-Line"></div>
            <div>
              <div className="CN-Channel-Status-Circle"></div>
              Offer
            </div>
            <div className="CN-Channel-Status-Line"></div>

            <div>
              <div className="CN-Channel-Status-Circle"></div>
              Docs
            </div>
          </div>
          <div className="CN-Channel-CreateLeadStep-MainContainer">
            <h2>Fill Details</h2>
            <form>
              <div className="d-inline-flex gap-1">
                <button
                  className="btn btn-primary CN-Channel-CreateLead-Collapsebtn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  disabled={!collapse1Open}
                  style={{ border: "1px solid lightgrey" }}
                >
                  Personal Details
                </button>
              </div>
              <div
                className={`collapse ${collapse1Open ? "show" : ""}`}
                id="collapseExample"
              >
                <div className="card card-body CN-Channel-CreateLead-Collapse-Maindiv">
                  {error && (
                    <div className="CN-Channel-CreateStepOne-Error">
                      {error}
                    </div>
                  )}

                  <div>
                    <input
                      type="text"
                      placeholder="First Name"
                      maxLength={15}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />

                    <input
                      type="text"
                      placeholder="Last Name"
                      maxLength={15}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <input
                      type="tel"
                      placeholder="DOB"
                      value={dob}
                      onChange={handleDobChange}
                    />
                    <select
                      value={profession}
                      onChange={(e) => setProfession(e.target.value)}
                    >
                      <option value="">Choose profession</option>
                      <option value="salaried">Salaried</option>
                      <option value="self employed">Self employed</option>
                      <option value="self employed profession">
                        Self employed professional
                      </option>
                    </select>
                  </div>
                  <button
                    className="my-3"
                    onClick={handlePersonalDetailsSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div className="d-inline-flex gap-1 ">
                <button
                  className="btn btn-primary CN-Channel-CreateLead-Collapsebtn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample2"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  disabled={!collapse2Open}
                  style={{ border: "1px solid lightgrey" }}
                >
                  Profession Details
                </button>
              </div>
              <div
                className={`collapse ${collapse2Open ? "show" : ""}`}
                id="collapseExample2"
              >
                <div className="card card-body CN-Channel-CreateLead-Collapse-Maindiv">
                  {error && (
                    <div className="CN-Channel-CreateStepOne-Error">
                      {error}
                    </div>
                  )}
                  {profession && (
                    <>
                      <div>
                        {profession === "salaried" && (
                          <>
                            <input
                              type="tel"
                              placeholder="Monthly salary"
                              maxLength={10}
                              value={salary}
                              onChange={(e) => setSalary(e.target.value)}
                            />
                            <input
                              type="tel"
                              placeholder="Monthly obligation"
                              maxLength={10}
                              value={obligation}
                              onChange={(e) => setObligation(e.target.value)}
                            />
                          </>
                        )}
                        {profession === "self employed" && (
                          <>
                            <input
                              type="tel"
                              placeholder="Turnover (FY 22-23)"
                              maxLength={10}
                              value={turnover}
                              onChange={(e) => setTurnover(e.target.value)}
                            />
                            <input
                              type="tel"
                              placeholder="Net profit (FY 22-23)"
                              maxLength={10}
                              value={netProfit}
                              onChange={(e) => setNetProfit(e.target.value)}
                            />
                          </>
                        )}

                        {profession === "self employed profession" && (
                          <>
                            <select
                              value={degree}
                              onChange={(e) => setDegree(e.target.value)}
                            >
                              <option value="">Choose degree</option>
                              <option value="ca/cs">CA/CS</option>
                              <option value="doctor">Doctor</option>
                              <option value="other">Other</option>
                            </select>

                            <input
                              type="tel"
                              placeholder="Gross receipts (FY 22-23)"
                              maxLength={10}
                              value={grossReceipts}
                              onChange={(e) => setGrossReceipts(e.target.value)}
                            />
                          </>
                        )}
                      </div>
                      <button
                        className="my-3"
                        onClick={handleProfessionalDetailsSubmit}
                      >
                        Submit
                      </button>
                    </>
                  )}
                </div>
              </div>

              <div className="d-inline-flex gap-1">
                <button
                  className="btn btn-primary CN-Channel-CreateLead-Collapsebtn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample3"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  disabled={!collapse3Open}
                  style={{ border: "1px solid lightgrey" }}
                >
                  Ask Details
                </button>
              </div>
              <div
                className={`collapse ${collapse3Open ? "show" : ""}`}
                id="collapseExample3"
              >
                <div className="card card-body CN-Channel-CreateLead-Collapse-Maindiv">
                  {error && (
                    <div className="CN-Channel-CreateStepOne-Error">
                      {error}
                    </div>
                  )}
                  <div>
                    <select
                      value={loan}
                      onChange={(e) => setLoan(e.target.value)}
                    >
                      <option value="">Choose loan</option>
                      <option value="new loan">New loan</option>
                      <option value="balance transfer">Balance transfer</option>
                    </select>

                    <select
                      value={loanType}
                      onChange={(e) => setLoanType(e.target.value)}
                      disabled={
                        loanType === "property purchase" ||
                        loanType === "loan against property" ||
                        loanType === "personal loan"
                      }
                    >
                      <option value="">Choose loan type</option>
                      <option value="property purchase">
                        Property purchase
                      </option>
                      <option value="loan against property">
                        Loan against property
                      </option>
                      <option value="personal loan">Personal loan</option>
                    </select>

                    <input
                      type="tel"
                      placeholder="Loan amount"
                      maxLength={10}
                      value={loanAmount}
                      onChange={(e) => setLoanAmount(e.target.value)}
                    />

                    <label
                      style={{
                        fontWeight: "normal",
                        width: "300px",
                      }}
                    >
                      Loan tenure: {loanTenure * 12} Months
                      <input
                        type="range"
                        placeholder="Tenure"
                        maxLength={10}
                        value={loanTenure}
                        onChange={(e) => setLoanTenure(e.target.value)}
                        style={{ height: "2px" }}
                        min={1}
                        max={30}
                        step={1}
                      />
                    </label>
                  </div>
                  <button className="my-3" onClick={handleAskDetailsSubmit}>
                    Submit
                  </button>
                </div>
              </div>

              <div className="d-inline-flex gap-1">
                <button
                  className="btn btn-primary CN-Channel-CreateLead-Collapsebtn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample4"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  disabled={!collapse4Open}
                  style={{ border: "1px solid lightgrey" }}
                >
                  Property Details
                </button>
              </div>
              <div
                className={`collapse ${collapse4Open ? "show" : ""}`}
                id="collapseExample4"
              >
                <div className="card card-body CN-Channel-CreateLead-Collapse-Maindiv">
                  {error && (
                    <div className="CN-Channel-CreateStepOne-Error">
                      {error}
                    </div>
                  )}
                  <div>
                    <select
                      value={propertyType}
                      onChange={(e) => setPropertyType(e.target.value)}
                    >
                      <option value="">Property type</option>
                      <option value="flat/apartment">Flat/Apartment</option>
                      <option value="builder floor">Builder floor</option>
                      <option value="kothi/townhouse/villa">
                        Kothi/Townhouse/Villa
                      </option>
                      <option value="farmhouse">Farmhouse</option>
                      <option value="other">Other</option>
                    </select>

                    <select
                      value={propertyStatus}
                      onChange={(e) => setPropertyStatus(e.target.value)}
                    >
                      <option value="">Property status</option>
                      <option value="fully built">Fully built</option>
                      <option value="under development">
                        Under development
                      </option>
                      <option value="open plot">Open plot</option>
                    </select>

                    <input
                      type="tel"
                      placeholder="Pincode"
                      maxLength={10}
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                    />
                    <input
                      type="tel"
                      placeholder="Estimated value"
                      maxLength={10}
                      value={estimatedValue}
                      onChange={(e) => setEstimatedValue(e.target.value)}
                    />
                  </div>
                  <button
                    className="my-3"
                    onClick={handlePropertyDetailsSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateStepOne;
