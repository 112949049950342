import React from "react";
import "./aboutus.css";
import Footer from "./Footer/Footer";
import Header from "./header";

const AreaSalesManager = () => {
  return (
    <>
      <div className="Aboutus-page">
        <Header />
        <div className="Aboutus-content" style={{ top: "80px" }}>
          <div>
            <h1
              style={{
                color: "darkgreen",
                fontSize: "20px",
                marginTop: "30px",
              }}
            >
              <b>Area Sales Manager – Secured Lending</b>{" "}
            </h1>
            <p>Please read through thoroughly before applying. </p>
            <p>
              <strong>Location:</strong> Gurgaon
            </p>
            <p>
              This is a full-time, in-person role in Gurgaon. This role is not
              virtual, online, freelance, or part-time.
            </p>
            <p>
              Creditnama is an early-stage digital lending company dedicated to
              real-time distribution of financial products such as loans and
              credit cards to individuals and SMEs. Our team and mentors
              comprise of IITians and CA/CS/MBAs. We are seeking a highly
              motivated and versatile Operations Manager to join our team and
              spearhead the development of our secured loans processing
              function, ensuring tech-driven operational excellence.
            </p>

            <h6>
              <b>Responsibilities:</b>
            </h6>
            <ul>
              <li>
                <b style={{ color: "gray" }}>Lead Coordination: </b>Proactively
                manage our leads, ensuring swift follow-ups and successful loan
                disbursements for qualified clients.
              </li>
              <li>
                <b style={{ color: "gray" }}>Banking and NBFC Partnerships:</b>{" "}
                Foster strong relationships with banks and NBFCs, understanding
                their criteria, and recommending suitable leads.
              </li>
              <li>
                <b style={{ color: "gray" }}>Building team:</b> Take charge of
                assembling and nurturing a high-performance team to enhance our
                secured loan processing capabilities.
              </li>
              <li>
                <b style={{ color: "gray" }}>Data & Reporting:</b> Leverage
                analytics tools to glean insights and compile reports, aiding in
                informed decision-making.
              </li>
              <li>
                <b style={{ color: "gray" }}>Operational Excellence:</b>{" "}
                Implement and optimize operational processes to enhance
                efficiency and productivity within the organization.
              </li>
              <li>
                <b style={{ color: "gray" }}>Collaboration:</b> Work closely
                with cross-functional teams, including marketing, data, and
                technology, to ensure seamless coordination and alignment of
                efforts.
              </li>
            </ul>

            <h6>
              <b>Qualifications:</b>
            </h6>
            <p>
              To excel in this role, you should possess the following
              qualifications and skills:
            </p>
            <ul>
              <li>
                <b style={{ color: "gray" }}>Lending Expertise:</b>{" "}
                <u>5+ years of progressive experience</u> in working in,
                building, and scaling lending operations in banks or NBFCs.
                Illustrative titles: Senior Sales managers, Area sales managers
              </li>
              <li>
                <b style={{ color: "gray" }}>Funnel Management:</b> Track record
                of successful loan disbursements and collaboration with banks,
                and proficient handling of customer applications.
              </li>
              <li>
                <b style={{ color: "gray" }}>Tech Proficiency:</b> Proficient in
                tech and analysis using tools like Excel.
              </li>
              <li>
                <b style={{ color: "gray" }}>Communication:</b> Excellent
                communication and interpersonal skills, for effective
                collaboration.
              </li>
              {/* Add more qualifications */}
            </ul>
            <div style={{ display: "flex", width: "500px" }}>
              <div>
                <h6>
                  <b>Why join us?</b>
                </h6>
              </div>
              <div
                style={{
                  fontSize: "12px",
                  marginTop: "3px",
                  marginLeft: "3px",
                }}
              >
                {" "}
                <u>Your career’s best move awaits you.</u>
              </div>
            </div>
            <p>
              At Creditnama, We believe in empowering each one of us to do our
              career’s best work here.We believe that everything around us was
              built by people no smarter than us. And that together, by being
              candid and self-aware, we can solve customer’s pain and feel proud
              of what we build.
            </p>

            <h6>
              <b>How to Apply:</b>
            </h6>
            <p>
              If you are interested in this role, please send your resume to
              manik@creditnama.com with the subject line "Area Sales Manager:
              Your Full Name." Additionally, include the following details in
              your email:
            </p>
            <ul>
              <li>Current Total Compensation (Fixed + Variable):</li>
              <li>Expected Total Compensation:</li>
              <li>Years of Experience in processing Loans at banks/NBFCs:</li>
              <li>Notice Period:</li>
              <li>Reason for Seeking a Change:</li>
            </ul>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
export default AreaSalesManager;
