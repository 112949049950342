import React from "react";
import "./aboutus.css";
import { Link } from "react-router-dom";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import { Helmet } from "react-helmet-async";

const LoginOfferPage = () => {
  return (
    <>
      <Helmet>
        <title>Offers</title>
        <meta
          name="description"
          content=" Explore a world of credit opportunities with our carefully curated
          partner offers."
        />
        <link rel="canonical" href="/partneroffers" />
      </Helmet>
      <Header />

      <div className="Aboutus-page">
        <div className="Aboutus-content">
          <h1>Partners offers</h1>

          <h5 style={{ marginTop: "20px" }}>
            <b>Exclusive lenders offers:</b>
          </h5>
          <p>
            Explore a world of credit opportunities with our carefully curated
            partner offers. Our esteemed lending partners have crafted these
            exclusive promotions to enhance your financial well-being. Take
            advantage of these limited-time offers and elevate your financial
            journey.
          </p>

          <table className="mortgage-info-table">
            <thead>
              <tr>
                <th style={{ fontSize: "16px" }}>Lender</th>
                <th style={{ fontSize: "16px" }}>Details</th>
                <th style={{ fontSize: "16px" }}>Posted Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Pnb Housing Finance Ltd</td>
                <td>
                  <div>
                    <b>New Builds</b>
                  </div>
                  <div>
                    # Under Plot loan - Now avail Top-up loan on plot at 70% MV
                    -In plot purchase case.
                  </div>
                  <div># Upto 100% of ATS in plot loan </div>
                </td>
                <td>29-11-2023</td>
              </tr>
              <tr>
                <td>Aditya Birla Finance Ltd</td>
                <td>
                  <div>
                    <b>Loan against property (LAP)</b>
                  </div>
                  <div>Loan upto 5 Cr</div>
                  <div>
                    Minimum Cibil score should be 700 for Individual and upto 6
                    CMR for firm
                  </div>
                  <div>
                    Total no of enquiries should not be more than 5 in last 2
                    months.
                  </div>
                  <div>Login Fees- Rs. 5900</div>
                  <div>
                    LTV- SORP (up to 95%), SOCP (up to 85%), SOIP (up to 75%)
                    and Plot (up to 85%) for Selected Programs
                  </div>
                  <div>
                    Upto 75% Residencial, upto 65% Commercial, upto 60% on
                    Industrial, upto 65% on mix usage and upto 60% on plots in
                    Banking, LIP and Pure Rental program.
                  </div>
                  <div>
                    Up to 90 KM Major Locations of NCR and 75 km for School/
                    College property
                  </div>
                </td>
                <td>29-11-2023</td>
              </tr>
              <tr>
                <td>Godrej finance Ltd</td>
                <td>
                  <div>
                    <b>Loan against property (LAP)</b>
                  </div>
                  <div>1. Highest loan tenure of 25 years in SORP </div>
                  <div>
                    2. Design your EMI-Principle Moratorium upto 3 year{" "}
                  </div>
                  <div>
                    3. Banking Surrogate Upto Rs. 10 cr - BT Bank EMI added back
                    to ABB.{" "}
                  </div>
                  <div>4. Eligibility on Cash Profit – Upto 200% of EBIDTA</div>
                  <div>
                    {" "}
                    5. 100% of Depreciation considered as reported in B/L{" "}
                  </div>
                  <div>7. LRD loans upto Rs. 35cr</div>
                  <div> 8. Fast end to TAT of 4 days</div>
                  <div> 9. LAP on under construction properties.</div>
                </td>
                <td>29-11-2023</td>
              </tr>
            </tbody>
          </table>
          <div style={{ marginTop: "20px" }}>
            Kindly note that these exclusive offers are time-sensitive and
            subject to availability. Our lending partners may modify or withdraw
            these promotions without prior notice.
          </div>
          <div>
            {" "}
            Stay informed and seize the opportunity to optimize your financial
            portfolio. If you have any inquiries or wish to explore these offers
            further, please share your details to us by applying for your loan
            through <Link to="/">our website </Link>and our dedicated support
            team will follow up to assist you.
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default LoginOfferPage;
