import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Linkdin from "../../../Images/Assets/Linkdin.png";
import Mail from "../../../Images/Assets/Mail.png";
import hand from "../../../Images/Career-Icon/hand.png";
import idea from "../../../Images/Career-Icon/idea.png";
import light from "../../../Images/Career-Icon/light.png";
import run from "../../../Images/Career-Icon/run.png";
import "./Careers.css";
import { Helmet } from "react-helmet-async";

function Careers() {
  return (
    <>
      <Helmet>
        <title>Careers</title>
        <meta
          name="description"
          content="At Creditnama, we believe in empowering each one of us to do our
          career’s best work here."
        />
        <link rel="canonical" href="/careers" />
      </Helmet>
      <Header />

      <div className="CN-Careers-Page-Main">
        <div className="CN-Careers-Page-Container">
          <h1>Careers</h1>
          <h2>
            Why join us? <b>Your career’s best move awaits you.</b>
          </h2>
          <p>
            At Creditnama, we believe in empowering each one of us to do our
            career’s best work here. We believe that everything around us was
            built by people no smarter than us. And that together, by being
            candid and self-aware, we can solve customer’s pain and feel proud
            of what we build.
          </p>
          <div className="CN-Carrer-Specs-Main-Container">
            <h2>
              <b>Cultural ethos at Creditnama</b>
            </h2>
            <div className="CN-Carrer-Specs-Container">
              <div>
                <img
                  src={hand}
                  alt="Raise hand"
                  className="CN-Career-Icons"
                  loading="lazy"
                />
                <div>Raise your hand, speak up, take ownership</div>
              </div>

              <div>
                <img
                  src={run}
                  alt="smart folks"
                  className="CN-Career-Icons"
                  loading="lazy"
                />
                <div>
                  Do your career's best work here, alongside smart folks
                </div>
              </div>

              <div>
                <img
                  src={light}
                  alt="execution"
                  className="CN-Career-Icons"
                  loading="lazy"
                />
                <div>Disagree in good faith, commit in execution</div>
              </div>

              <div>
                <img
                  src={idea}
                  alt="self-aware"
                  className="CN-Career-Icons"
                  loading="lazy"
                />
                <div>Be candid, kind, & self-aware</div>
              </div>
            </div>
          </div>
          <h2 className="CN-Careers-Page-OpenP">
            <b>Open positions</b>
          </h2>
          <div className="CN-Careers-Open-Job-Section">
            <div className="CN-Careers-Div-style">
              <h2>
                Lead NestJs developer
                <br />
              </h2>
              {/* <br /> */}
              <div>
                <b>Location:</b> Gurgaon
                <br />
                <b>Type:</b> Full-time/hybrid, In-person
              </div>
              <div className="CN-Careers-Redirection-Container">
                <a href="https://www.linkedin.com/jobs/view/3680744743">
                  <img
                    src={Linkdin}
                    alt="Linkdin"
                    className="CN-Career-Page-Icons"
                  />
                </a>
                <a href="mailto: info@creditnama.com">
                  <img
                    src={Mail}
                    alt="Linkdin"
                    className="CN-Career-Page-Icons"
                  />
                </a>
              </div>
            </div>
            <div className="CN-Careers-Div-style">
              <h2>Marketing Content Specialist</h2>
              {/* <br /> */}
              <div>
                <b>Location:</b> Gurgaon
                <br />
                <b>Type:</b> Full-time, In-person
              </div>
              <div className="CN-Careers-Redirection-Container">
                <a href="https://www.linkedin.com/jobs/view/3498041299">
                  <img
                    src={Linkdin}
                    alt="Linkdin"
                    className="CN-Career-Page-Icons"
                  />
                </a>
                <a href="mailto: info@creditnama.com">
                  <img
                    src={Mail}
                    alt="Linkdin"
                    className="CN-Career-Page-Icons"
                  />
                </a>
              </div>
            </div>

            <div className="CN-Careers-Div-style">
              <h2>
                Marketing Internship <br />
                – Graphic design
                <br /> (2023-25 graduates, hybrid)
              </h2>
              {/* <br /> */}
              <div>
                <b>Location:</b> Gurgaon
                <br />
                <b>Type:</b> Full-time/hybrid, In-person
              </div>
              <div className="CN-Careers-Redirection-Container">
                <a href="https://www.linkedin.com/jobs/view/3814248498">
                  <img
                    src={Linkdin}
                    alt="Linkdin"
                    className="CN-Career-Page-Icons"
                  />
                </a>
                <a href="mailto: info@creditnama.com">
                  <img
                    src={Mail}
                    alt="Linkdin"
                    className="CN-Career-Page-Icons"
                  />
                </a>
              </div>
            </div>
            <div className="CN-Careers-Div-style">
              <h2>
                Founder's Office Intern :
                <br />
                Marketing
              </h2>
              {/* <br /> */}
              <div>
                <b>Location:</b> Gurgaon
                <br />
                <b>Type:</b> Full-time, In-person
              </div>
              <div className="CN-Careers-Redirection-Container">
                <a href="https://www.linkedin.com/jobs/view/3796390460">
                  <img
                    src={Linkdin}
                    alt="Linkdin"
                    className="CN-Career-Page-Icons"
                  />
                </a>
                <a href="mailto: info@creditnama.com">
                  <img
                    src={Mail}
                    alt="Linkdin"
                    className="CN-Career-Page-Icons"
                  />
                </a>
              </div>
            </div>
            <div className="CN-Careers-Div-style">
              <h2>
                Founder's Office:
                <br />
                Marketing
              </h2>
              {/* <br /> */}
              <div>
                <b>Location:</b> Gurgaon
                <br />
                <b>Type:</b> Full-time, In-person
              </div>
              <div className="CN-Careers-Redirection-Container">
                <a href="https://www.linkedin.com/jobs/view/3810604735">
                  <img
                    src={Linkdin}
                    alt="Linkdin"
                    className="CN-Career-Page-Icons"
                  />
                </a>
                <a href="mailto: info@creditnama.com">
                  <img
                    src={Mail}
                    alt="Linkdin"
                    className="CN-Career-Page-Icons"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Careers;
