import React from "react";
import { Helmet } from "react-helmet-async";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import BlogImg1 from "../../../../Images/Bank-Logos/hdfc.png";
import BlogImg2 from "../../../../Images/Bank-Logos/axis.png";
// import BlogImg3 from "../../../../Images/Bank-Logos/idfc.jpg";
import BlogImg4 from "../../../../Images/Bank-Logos/icici.png";
// import BlogImg5 from "../../../../Images/Bank-Logos/LicHousing.png";
import BlogImg6 from "../../../../Images/Bank-Logos/fedral.png";
import BlogImg7 from "../../../../Images/Bank-Logos/kotak.png";
import BlogImg8 from "../../../../Images/Bank-Logos/idfc.jpg";
import BlogImg9 from "../../../../Images/Bank-Logos/yesbank.png";
import { Link } from "react-router-dom";
import "../../aboutus.css";

function LAP() {
  document.addEventListener("contextmenu", function (e) {
    e.preventDefault();
  });
  document.addEventListener("selectstart", function (e) {
    e.preventDefault();
  });
  document.addEventListener("touchstart", function (e) {
    e.preventDefault();
  });

  const blogData = [
    {
      content: `HDFC Bank offers loan against property at interest rates starting from 9.50% p.a.
      for a maximum repayment tenure of 15 years.`,
      img: BlogImg1,
      link: "/hdfc-lap",
    },
    {
      content: `Axis Bank offers loan against property at interest rates starting from 10.50% p.a. for a maximum repayment tenure of 20 years.`,

      img: BlogImg2,
      link: "/axis-lap",
    },
    // {
    //   content: `IDFC First Bank offers loan against property at interest rates starting from 8.75% p.a.
    //   for a maximum repayment tenure of 30 years.`,

    //   img: BlogImg3,
    //   link: "/Idfc-lap",
    // },
    {
      content: `ICICI Bank offers loan against property at interest rates starting from 9.25% p.a.
      for a maximum repayment tenure of 15 years.`,

      img: BlogImg4,
      link: "/icici-lap",
    },
    // {
    //   content: `LIC Housing Finance offers loan against property at interest rates starting from 8.35%
    //   p.a. for a maximum repayment tenure of 30 years.`,

    //   img: BlogImg5,
    //   link: "/Lic-lap",
    // },
    {
      content: `Fedral Bank offers loan against property at interest rates starting from 9.15% p.a.
      for a maximum repayment tenure of 15 years,`,

      img: BlogImg6,
      link: "/federal-lap",
    },
    {
      content: `Kotak Mahindra Bank offers loan against property at interest rates starting from 9.50% p.a.
      for a maximum repayment tenure of 15 years.`,
      img: BlogImg7,
      link: "/kotak-lap",
    },
    {
      content: `Idfc First Bank offers loan against property at interest rates starting from 9% p.a.
      for a maximum repayment tenure of 25 years.`,
      img: BlogImg8,
      link: "/idfc-lap",
    },
    {
      content: `Yes Bank offers loan against property at interest rates starting from 9.75% p.a.
      for a maximum repayment tenure of 15 years.`,
      img: BlogImg9,
      link: "/yes-lap",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Loan Against Property</title>
        <meta
          name="description"
          content="Borrow money at half the interest rate of business loan by offering property as collateral.Flexible terms on funds use. Repayable through monthly installments."
        />
        <link rel="canonical" href="/lap-blogs" />
      </Helmet>
      <Header />

      <div className="Aboutus-page">
        <div className="Aboutus-content">
          <h1>Blogs on Loan Against Property</h1>
          <div className="Crow">
            {blogData.map((blog, index) => (
              <div className="Ccolumn" key={index}>
                <Link to={blog.link}>
                  <div
                    className="Ccard"
                    style={{
                      justifyContent: "space-around",
                      textAlign: "justify",
                      // wordSpacing: "-2px",
                    }}
                  >
                    <img
                      src={blog.img}
                      alt={blog.link}
                      className="Fit-blogImg"
                      loading="lazy"
                      style={{
                        objectFit: "contain",
                      }}
                    />
                    <h6>{blog.content}</h6>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LAP;
