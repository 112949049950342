import React, { useState, useEffect } from "react";
import "./ImageSlider.css";
// import Image1 from "../../../Images/Paras/Brimston.png";
import Image2 from "../../../Images/Paras/Brimston (1).png";
import Image3 from "../../../Images/Paras/dlf-privana.jpg";
import Image4 from "../../../Images/Paras/villaView.png";
import Image5 from "../../../Images/Paras/paras-interior.jpg";
import Image6 from "../../../Images/Paras/Paras-Irene.avif";

const images = [
  // "https://www.dlf.in/homes/luxury/privanasouth/img/banner/privana-web-banner-1.jpg",
  // "https://www.dlf.in/homes/luxury/privanasouth/img/banner/banner-1.jpg",
  // "https://www.dlf.in/homes/luxury/privanasouth/img/banner/banner-2.jpg",
  // "https://www.dlf.in/homes/luxury/privanasouth/img/banner/banner-3.jpg",
  // Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
];

const Mobileimages = [
  // "https://www.dlf.in/homes/luxury/privanasouth/img/banner/privana-web-mob-1.jpg",
  // "https://www.dlf.in/homes/luxury/privanasouth/img/banner/banner-3-mob.jpg",
  // "https://www.dlf.in/homes/luxury/privanasouth/img/banner/banner-2-mob.jpg",
  // "https://www.dlf.in/homes/luxury/privanasouth/img/banner/banner-1-mob.jpg",
  // Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
];

const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(true);

  const goToPrevious = () => {
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const goToNext = () => {
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  useEffect(() => {
    if (currentIndex === 0) {
      // Go to the last slide without animation
      setTimeout(() => {
        setIsTransitioning(false);
        setCurrentIndex(images.length);
      }, 500);
    } else if (currentIndex === images.length + 1) {
      // Go to the first slide without animation
      setTimeout(() => {
        setIsTransitioning(false);
        setCurrentIndex(1);
      }, 500);
    }
  }, [currentIndex]);

  return (
    <>
      <div className="paras-slider-container paras-desktop">
        <div
          className="paras-slider"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
            transition: isTransitioning ? "transform 0.5s ease-in-out" : "none",
          }}
        >
          <div className="paras-slide">
            <img
              className="paras-slide"
              src={images[images.length - 1]}
              alt=""
            />
          </div>
          {images.map((image, index) => (
            <div className="paras-slide" key={index}>
              <img className="paras-slide" src={image} alt="" />
            </div>
          ))}
          <div className="paras-slide">
            <img className="paras-slide" src={images[0]} alt="" />
          </div>
        </div>
        <button className="paras-prev-button" onClick={goToPrevious}>
          ❮
        </button>
        <button className="paras-next-button" onClick={goToNext}>
          ❯
        </button>
      </div>
      <div className="paras-slider-container paras-mobile">
        <div
          className="paras-slider"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
            transition: isTransitioning ? "transform 0.5s ease-in-out" : "none",
          }}
        >
          <div className="paras-slide">
            <img
              className="paras-slide"
              src={Mobileimages[Mobileimages.length - 1]}
              alt=""
            />
          </div>
          {Mobileimages.map((image, index) => (
            <div className="paras-slide" key={index}>
              <img className="paras-slide" src={image} alt="" />
            </div>
          ))}
          <div className="paras-slide">
            <img className="paras-slide" src={Mobileimages[0]} alt="" />
          </div>
        </div>
        <button className="paras-prev-button" onClick={goToPrevious}>
          ❮
        </button>
        <button className="paras-next-button" onClick={goToNext}>
          ❯
        </button>
      </div>
    </>
  );
};

export default ImageSlider;
