import React, { useEffect, useState } from "react";
import "./popup.css";
import Paras from "../../../Images/Paras/parassaving.jfif";
import CancelIcon from "@mui/icons-material/Cancel";

const PopUp = () => {
  const [showPop, setShowPop] = useState(true);
  const [close, setClose] = useState(false);

  // Handle WhatsApp Link click
  const handleWAClick = () => {
    window.open(
      "https://wa.me/+918750064900?text=Hey, I would like to know more about Paras sec 59"
    );
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setClose(true); // Show close button after 2 seconds
    }, 1000);

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(timeout);
  }, []);

  // Handle Close of the Popup
  const handleClose = (e) => {
    e.preventDefault();
    setShowPop(false); // Hide the popup when 'X' is clicked
  };

  return (
    <>
      {showPop && (
        <div className="Paras-popup-banner">
          {/* Close button */}
          {close && (
            <div
              className="Paras-popup-banner-close-container"
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            >
              <CancelIcon className="Paras-popup-banner-close" />
            </div>
          )}

          {/* Image with click event for WhatsApp */}
          <img
            src={Paras}
            alt="Paras Floret"
            onClick={handleWAClick}
            className="Paras-popup-banner-image" // Add a class for better control
          />
        </div>
      )}
    </>
  );
};

export default PopUp;
