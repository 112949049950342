import React from "react";
import "./Text.css";

function Text() {
  return (
    <>
      <div className="container">
        <section className="section overview">
          <h1 style={{ textAlign: "center" }}>
            Introducing the New Launch Project : Paras Sector 59, Gurgaon
          </h1>
          <div className="paras-cnn-content-popup-container"></div>
          <p>
            A new luxury low-rise project on Golf Course Extension Road, Sector
            59. It is a low-rise development by Paras. The project is named
            Paras Floret. Paras is a renowned real estate builder. Paras has
            already delivered multiple projects. This is a new low-rise
            community development in Gurgaon. In this location, Silverglades 59
            is also launching high-rise apartments.
          </p>
          <p>
            Paras sector 59 is spread over 6.25 acres. Paras Floret 59 is a
            gated community. It is located on golf course ext. road. The project
            has about 300 units. The development has a basement, stilt parking,
            and four well-appointed floors. Paras new launch has 2 bhk and 3 bhk
            configurations.
          </p>
          <h2>Paras Sector 59: Overview</h2>
          <table className="overview-table">
            <tbody>
              <tr>
                <td>
                  <strong>Property Type:</strong>
                  <br />
                  Residential
                </td>
                <td>
                  <strong>Developer</strong>
                  <br />
                  Paras
                </td>
                <td>
                  <strong>Type</strong>
                  <br />
                  Low Rise
                </td>
                <td>
                  <strong>Configuration</strong>
                  <br />
                  2/3 BHK
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Land Parcel</strong>
                  <br />
                  6.225 Acres
                </td>
                <td>
                  <strong>Price</strong>
                  <br />
                  2.20 Cr*
                </td>
                <td>
                  <strong>EOI</strong>
                  <br />
                  Starting from 10 Lacs
                </td>
                <td>
                  <strong>Location</strong>
                  <br />
                  Sec-59, Gurgaon
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Payment Plan</strong>
                  <br />
                  40:30:30
                </td>
                <td>
                  <strong>Units</strong>
                  <br />
                  300 No
                </td>
                <td>
                  <strong>Size</strong>
                  <br />
                  1150-1650 Sq.ft
                </td>
                <td>
                  <strong>RERA</strong>
                  <br />
                  Coming Soon
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section className="section highlights">
          <h2
            style={{
              color: "white",
              padding: "10px",
              backgroundColor: "black",
              textAlign: "center",
            }}
          >
            Highlights
          </h2>
          <table style={{ width: "100%" }} className="highlights-table">
            <tbody>
              <tr>
                <td style={{ padding: "30px", fontSize: "20px" }}>
                  Luxury Low Rise
                </td>
                <td style={{ padding: "30px", fontSize: "20px" }}>
                  Prime location: Golf Course Extension Road, Gurgaon
                </td>
              </tr>
              <tr>
                <td style={{ padding: "30px", fontSize: "20px" }}>
                  Gated Community
                </td>
                <td style={{ padding: "30px", fontSize: "20px" }}>
                  Club House
                </td>
              </tr>
              <tr>
                <td style={{ padding: "30px", fontSize: "20px" }}>
                  Modern Amenities
                </td>
                <td style={{ padding: "30px", fontSize: "20px" }}>
                  Top Floor With Terrace
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section className="section specifications">
          <h2>Specifications of Paras Low Rise New Launch</h2>
          <p>
            <strong>Location:</strong> The project is at Sector 59, Golf Course
            Extension Road. This road is active and connects to the region’s
            most prestigious areas.
          </p>
          <p>
            <strong>Total Area:</strong> It has 6.25 acres of area. It is a
            completely gated society.
          </p>
          <p>
            <strong>Total Units:</strong> It has approximately 300 planned
            apartments.
          </p>
          <p>
            <strong>Building Structure:</strong> The building includes a
            basement for parking, a stilt level, and four residential floors.
          </p>
          <p>
            <strong>Apartment Size:</strong> The project has 2 BHK, and 3 BHK
            units. The size varies from 1150 square feet to 1650 square feet.
          </p>
        </section>

        <section className="section amenities">
          <h2>Exclusive Amenities Designed for Modern Living</h2>
          <p>
            Paras Floret provides luxurious living spaces. The project includes
            amenities that enhance the quality of life of residents.
          </p>
          <ul>
            <li>
              <strong>Clubhouse:</strong> Paras Floret 59 offers a clubhouse of
              25000 sq ft. The clubhouse has all the features of a condo.
            </li>
            <li>
              <strong>Private Terrace:</strong> Each Apartment includes access
              to a private terrace on the 4th floor. The terrace can be utilized
              for a house party, sitting, and relaxing.
            </li>
          </ul>
        </section>

        <section className="section pricing">
          <h2>Paras Sector 59 Gurgaon Pricing Details</h2>
          <p>
            Paras Floret offers an attractive pricing structure with an
            introductory launch price of ₹20,000 per square foot. To make the
            purchase even more appealing, there is an inaugural discount of
            ₹1,000 per square foot.
          </p>

          <h3>Floor Plan</h3>
          <table>
            <thead>
              <tr>
                <th>TYPE</th>
                <th>Basic Sale Price (in ₹)</th>
              </tr>
            </thead>
            <tbody
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(
                  "https://wa.me/+918750064900?text=Hey, I would like to know more about Paras sec 59"
                );
              }}
            >
              <tr>
                <td>2 BHK</td>
                <td>Know Unit Price</td>
              </tr>

              <tr>
                <td>3 BHK</td>
                <td>Know Unit Price</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h3>Flexible Payment Plan for Paras Sector 59 Project</h3>
          <p>
            40% payment upon booking, 30% during construction, 20% at
            possession, and 10% upon final handover. To confirm your booking, a
            deposit of ₹25 lakhs as an Expression of Interest (EOI) is required.
          </p>
        </section>

        <section className="section location">
          <h2>Prime Location Benefits for Your Convenience</h2>
          <p>
            The Paras Floret location has several benefits. The location makes
            it an attractive choice:
          </p>
          <ul>
            <li>
              <strong>Prime Road Access:</strong> It is situated on Sector 59,
              Golf Course Extension Road, Gurgaon. The project has access to the
              private road to the project.
            </li>
            <li>
              <strong>Metro Accessibility:</strong> The project is close to the
              55-56 metro stations. A few minutes of drive to the metro makes
              the location more attractive.
            </li>
            <li>
              <strong>Nearby Developments:</strong> The location has many other
              prestigious projects like Conscient Elevate, Mahindra Luminare,
              Emaar Digihomes, and Ireo: The Grand Arch, etc.
            </li>
          </ul>
        </section>
      </div>
    </>
  );
}

export default Text;
