// import React, { useState } from "react";
// import "./CheckApplication.css";
// import Header from "../User-Components/Front-Page-Components/Header/Header";
// import Footer from "../User-Components/Front-Page-Components/Footer/Footer";

// function CheckApplication() {
//   const [preapplication, setPreApplication] = useState(true);
//   const [postapplication, setPostApplication] = useState(false);
//   const [postapproval, setPostApproval] = useState(false);

//   const preapplicationData = [
//     {
//       id: 1,
//       sub_stage: "Profile",
//       status: "Submited",
//       content: "Text Text Text Text Text",
//     },
//     {
//       id: 2,
//       sub_stage: "Loan",
//       status: "Submited",
//       content: "Text Text Text Text Text",
//     },
//     {
//       id: 3,
//       sub_stage: "KYC",
//       status: "Submited",
//       content: "Text Text Text Text Text",
//     },
//     {
//       id: 4,
//       sub_stage: "Asset",
//       status: "Submited",
//       content: "Text Text Text Text Text",
//     },
//     {
//       id: 5,
//       sub_stage: "Income",
//       status: "Submited",
//       content: "Text Text Text Text Text",
//     },
//     {
//       id: 6,
//       sub_stage: "Offers",
//       status: "Not Submited",
//       content: "Text Text Text Text Text",
//     },
//   ];

//   return (
//     <>
//       <Header />
//       <div className="CN2-Track-Application-Page">
//         <div className="CN2-Track-Container">
//           <h1>Welcome, Bhart Malhotra</h1>
//           <p>Here you will get updated status of loan application</p>
//         </div>
//         <div className="CN-Track-Application-Main-Container">
//           <div className="CN-Track-Application-Box-Containers">
//             <div>
//               Loan ask
//               <br />
//               <span>Loan against property</span>
//             </div>
//             <div>
//               Loan amount
//               <br />
//               <span>₹1,00,00,000</span>
//             </div>
//             <div>
//               Loan Tenure
//               <br />
//               <span>30 Years</span>
//             </div>
//             <div></div>
//           </div>
//           <div className="CN-Track-Application-Headings-Containers">
//             <div>
//               <span
//                 className="CN-Track-Application-Circle-Div"
//                 style={
//                   preapplication === true
//                     ? { backgroundColor: "var(--color-yellow)" }
//                     : { backgroundColor: "lightgrey" }
//                 }
//               ></span>
//               Pre application submission
//             </div>
//             <span
//               style={{
//                 position: "absolute",
//                 border: "1px solid grey",
//                 width: "145px",
//                 marginTop: "-50px",
//                 left: "calc(50% - 162px)",
//               }}
//             ></span>
//             <div>
//               <span
//                 className="CN-Track-Application-Circle-Div"
//                 style={
//                   postapplication === true
//                     ? { backgroundColor: "var(--color-yellow)" }
//                     : { backgroundColor: "lightgrey" }
//                 }
//               ></span>
//               Post application submission
//             </div>
//             <span
//               style={{
//                 position: "absolute",
//                 border: "1px solid grey",
//                 width: "145px",
//                 marginTop: "-50px",
//                 left: "calc(50% + 17px)",
//               }}
//             ></span>
//             <div>
//               <span
//                 className="CN-Track-Application-Circle-Div"
//                 style={
//                   postapproval === true
//                     ? { backgroundColor: "var(--color-yellow)" }
//                     : { backgroundColor: "lightgrey" }
//                 }
//               ></span>
//               Post approval stage
//             </div>
//           </div>
//           {preapplication === true && (
//             <>
//               {preapplicationData.map((item) => {
//                 const collapseId = `collapse-${item.id}`;

//                 return (
//                   <div
//                     className="accordion accordion-flush"
//                     id="accordionFlushExample"

//                     style={{ width: "300px", marginTop: "10px" }}
//                     key={item.id}
//                   >
//                     <div className="accordion-item">
//                       <h2 className="accordion-header ">
//                         <button
//                           className="accordion-button collapsed CN-Track-AccApplication-Button"
//                           type="button"
//                           data-bs-toggle="collapse"
//                           data-bs-target={`#${collapseId}`}
//                           aria-expanded="false"
//                           aria-controls={collapseId}
//                         >
//                           <span
//                             className="CN-Track-Application-Circle-Div2"
//                             style={
//                               item.status === "Submited"
//                                 ? { backgroundColor: "var(--color-darkgreen)" }
//                                 : { backgroundColor: "lightgrey" }
//                             }
//                           ></span>

//                           {item.sub_stage}
//                         </button>
//                       </h2>
//                       <div
//                         id={collapseId} // Use unique ID for the collapse element
//                         className="accordion-collapse collapse"
//                         data-bs-parent="#accordionFlushExample"
//                       >
//                         <div className="accordion-body">{item.content}</div>
//                       </div>
//                     </div>
//                   </div>
//                 );
//               })}
//             </>
//           )}
//           {postapplication === true && (
//             <>
//               <div className="CN-Track-Application-Subheadings-Containers">
//                 <div>
//                   <div className="CN-Track-Application-CircleSubhead">
//                     <span className="CN-Track-Application-Circle-Div"></span>
//                     Application submited
//                   </div>
//                   <div className="CN-Track-Application-Status-Content">Yes</div>
//                 </div>
//                 <div>
//                   <div className="CN-Track-Application-CircleSubhead">
//                     <span className="CN-Track-Application-Circle-Div"></span>
//                     Login with Bank
//                   </div>
//                   <div className="CN-Track-Application-Status-Content">Yes</div>
//                 </div>
//                 <div>
//                   <div className="CN-Track-Application-CircleSubhead">
//                     <span className="CN-Track-Application-Circle-Div"></span>
//                     Login fees
//                   </div>
//                   <div className="CN-Track-Application-Status-Content">
//                     Paid
//                   </div>
//                 </div>
//                 <div>
//                   <div className="CN-Track-Application-CircleSubhead">
//                     <span className="CN-Track-Application-Circle-Div"></span>
//                     Legal evalutaion
//                   </div>
//                   <div className="CN-Track-Application-Status-Content">
//                     Verified
//                   </div>
//                 </div>
//                 <div>
//                   <div className="CN-Track-Application-CircleSubhead">
//                     <span className="CN-Track-Application-Circle-Div"></span>
//                     Technical evalutaion
//                   </div>
//                   <div className="CN-Track-Application-Status-Content">
//                     Completed
//                   </div>
//                 </div>
//                 <div>
//                   <div className="CN-Track-Application-CircleSubhead">
//                     <span className="CN-Track-Application-Circle-Div"></span>
//                     Personal discussion with bank
//                   </div>
//                   <div className="CN-Track-Application-Status-Content">
//                     Completed
//                   </div>
//                 </div>
//                 <div>
//                   <div className="CN-Track-Application-CircleSubhead">
//                     <span className="CN-Track-Application-Circle-Div"></span>
//                     Pendecies from lender
//                   </div>
//                   <div className="CN-Track-Application-Status-Content">
//                     Completed
//                   </div>
//                 </div>
//                 <div>
//                   <div className="CN-Track-Application-CircleSubhead">
//                     <span className="CN-Track-Application-Circle-Div"></span>
//                     Pendecies clear by customer
//                   </div>
//                   <div className="CN-Track-Application-Status-Content">Yes</div>
//                 </div>
//               </div>
//             </>
//           )}
//           {postapproval === true && (
//             <>
//               <div className="CN-Track-Application-Subheadings-Containers">
//                 <div>
//                   <div className="CN-Track-Application-CircleSubhead">
//                     <span className="CN-Track-Application-Circle-Div"></span>
//                     In principal approval from lender
//                   </div>
//                   <div className="CN-Track-Application-Status-Content">
//                     Received
//                   </div>
//                 </div>
//                 <div>
//                   <div className="CN-Track-Application-CircleSubhead">
//                     <span className="CN-Track-Application-Circle-Div"></span>
//                     Concent with terms
//                   </div>
//                   <div className="CN-Track-Application-Status-Content">Yes</div>
//                 </div>
//                 <div>
//                   <div className="CN-Track-Application-CircleSubhead">
//                     <span className="CN-Track-Application-Circle-Div"></span>
//                     Saction letter
//                   </div>
//                   <div className="CN-Track-Application-Status-Content">
//                     Published
//                   </div>
//                 </div>
//                 <div>
//                   <div className="CN-Track-Application-CircleSubhead">
//                     <span className="CN-Track-Application-Circle-Div"></span>
//                     Sanction kit signed
//                   </div>
//                   <div className="CN-Track-Application-Status-Content">Yes</div>
//                 </div>
//                 <div>
//                   <div className="CN-Track-Application-CircleSubhead">
//                     <span className="CN-Track-Application-Circle-Div"></span>
//                     Loan disbursed
//                   </div>
//                   <div className="CN-Track-Application-Status-Content">Yes</div>
//                 </div>
//               </div>
//             </>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// }

// export default CheckApplication;

import React from "react";
import Header from "../User-Components/Front-Page-Components/Header/Header";
import StepsFooter from "../User-Components/Front-Page-Components/Footer/StepsFooter";
import "./CheckApplication.css";

const CheckApplication = () => {
  return (
    <>
      <Header />
      <div className="CN2-Track-Application-Page">
        <div className="CN2-Track-Container">
          <h1>Page under construction</h1>
        </div>
      </div>
      <StepsFooter />
    </>
  );
};

export default CheckApplication;
