import React, { useEffect, useState } from "react";
import "./CreateLead.css";
import ChannelSidebar from "../Auth-Components/Channel-Auth/ChannelSidebar";
import serverIP from "../config/config";
import axisLogo from "../Images/Bank-Logos/axis.png";
import hdfcLogo from "../Images/Bank-Logos/hdfc.png";
import kotakLogo from "../Images/Bank-Logos/kotak.png";
import abflLogo from "../Images/Bank-Logos/abfl.jpg";
import axisfinanceLogo from "../Images/Bank-Logos/axisfinance.png";
import bajajLogo from "../Images/Bank-Logos/bajaj.png";
import capriLogo from "../Images/Bank-Logos/capri.png";
import cholaLogo from "../Images/Bank-Logos/chola.png";
import citibankLogo from "../Images/Bank-Logos/citibank.png";
import dbsLogo from "../Images/Bank-Logos/dbs.png";
import deutcheLogo from "../Images/Bank-Logos/deutche.png";
import edelwisLogo from "../Images/Bank-Logos/edelwis.jpg";
import fullertonLogo from "../Images/Bank-Logos/fullerton.png";
import hdbLogo from "../Images/Bank-Logos/hdb.png";
import homecreditLogo from "../Images/Bank-Logos/homecredit.png";
import hsbcLogo from "../Images/Bank-Logos/hsbc.png";
import iciciLogo from "../Images/Bank-Logos/icici.png";
import idfcLogo from "../Images/Bank-Logos/idfc.jpg";
import iiflLogo from "../Images/Bank-Logos/iifl.png";
import incredLogo from "../Images/Bank-Logos/incred.png";
import indusindLogo from "../Images/Bank-Logos/indusind.png";
import karurLogo from "../Images/Bank-Logos/karur.jpg";
import myshubhlifeLogo from "../Images/Bank-Logos/myshubhlife.png";
import paysenseLogo from "../Images/Bank-Logos/paysense.png";
import protiumLogo from "../Images/Bank-Logos/protium.png";
import scbLogo from "../Images/Bank-Logos/scb.png";
import shriramLogo from "../Images/Bank-Logos/shriram.png";
import smcLogo from "../Images/Bank-Logos/smc.png";
import tataLogo from "../Images/Bank-Logos/tata.png";
import ujjivanLogo from "../Images/Bank-Logos/ujjivan.png";
import utkershLogo from "../Images/Bank-Logos/utkersh.png";
import yesbankLogo from "../Images/Bank-Logos/yesbank.png";
import indiabullshomeLogo from "../Images/Bank-Logos/Indiabulls.png";
import indiahomeLogo from "../Images/Bank-Logos/indiashelter.png";
import shubhamhomeLogo from "../Images/Bank-Logos/shubham.png";
import godrejLogo from "../Images/Bank-Logos/godrej.png";
import adityalimitedLogo from "../Images/Bank-Logos/abfl.jpg";
import easyhomefinanceLogo from "../Images/Bank-Logos/easyhome.png";
import piramalhousingLogo from "../Images/Bank-Logos/piramal.png";
import federalLogo from "../Images/Bank-Logos/fedral.png";
import shriramhousingLogo from "../Images/Bank-Logos/shriram.png";
import bajajhousingLogo from "../Images/Bank-Logos/bajaj.png";
import anandLogo from "../Images/Bank-Logos/Anandrathi.png";
import dmiLogo from "../Images/Bank-Logos/DmiHousing.png";
import rblLogo from "../Images/Bank-Logos/RblBank.png";
import aadharLogo from "../Images/Bank-Logos/AadharHousing.png";
import satinLogo from "../Images/Bank-Logos/SatinHousing.png";
import licLogo from "../Images/Bank-Logos/LicHousing.png";
import poonawallaLogo from "../Images/Bank-Logos/poonawalla.png";
import sbmLogo from "../Images/Bank-Logos/sbm.png";
import LockOpenRounded from "@mui/icons-material/LockOpenRounded";
import { useNavigate } from "react-router-dom";

const CreateLead = () => {
  const [loanType, setLoanType] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [contact, setContact] = useState("");
  const [offerData, setOfferData] = useState([]);
  const [displayedOffers, setDisplayedOffers] = useState(3);
  const [error, setError] = useState("");

  const history = useNavigate();

  useEffect(() => {
    const auth = localStorage.getItem("cToken");
    if (!auth) {
      console.log("Login first");
      history("/channel");
    }
  }, [history]);

  const bankLogos = {
    axisLogo: axisLogo,
    hdfcLogo: hdfcLogo,
    kotakLogo: kotakLogo,
    abflLogo: abflLogo,
    axisfinanceLogo: axisfinanceLogo,
    bajajLogo: bajajLogo,
    capriLogo: capriLogo,
    cholaLogo: cholaLogo,
    citibankLogo: citibankLogo,
    dbsLogo: dbsLogo,
    deutcheLogo: deutcheLogo,
    edelwisLogo: edelwisLogo,
    fullertonLogo: fullertonLogo,
    hdbLogo: hdbLogo,
    homecreditLogo: homecreditLogo,
    hsbcLogo: hsbcLogo,
    iciciLogo: iciciLogo,
    idfcLogo: idfcLogo,
    iiflLogo: iiflLogo,
    incredLogo: incredLogo,
    indusindLogo: indusindLogo,
    karurLogo: karurLogo,
    myshubhlifeLogo: myshubhlifeLogo,
    paysenseLogo: paysenseLogo,
    protiumLogo: protiumLogo,
    scbLogo: scbLogo,
    shriramLogo: shriramLogo,
    smcLogo: smcLogo,
    tataLogo: tataLogo,
    ujjivanLogo: ujjivanLogo,
    utkershLogo: utkershLogo,
    yesbankLogo: yesbankLogo,
    indiahomeLogo: indiahomeLogo,
    shubhamhomeLogo: shubhamhomeLogo,
    godrejLogo: godrejLogo,
    adityalimitedLogo: adityalimitedLogo,
    easyhomefinanceLogo: easyhomefinanceLogo,
    indiabullshomeLogo: indiabullshomeLogo,
    piramalhousingLogo: piramalhousingLogo,
    federalLogo: federalLogo,
    shriramhousingLogo: shriramhousingLogo,
    bajajhousingLogo: bajajhousingLogo,
    anandLogo: anandLogo,
    dmiLogo: dmiLogo,
    rblLogo: rblLogo,
    aadharLogo: aadharLogo,
    satinLogo: satinLogo,
    licLogo: licLogo,
    poonawallaLogo: poonawallaLogo,
    sbmLogo: sbmLogo,
  };

  const handleLoanTypeSelect = (e) => {
    setLoanType(e.target.value);
    setOfferData([]);
  };

  const handlePropertyTypeSelect = (e) => {
    setPropertyType(e.target.value);
    setOfferData([]);
  };

  const handleQuickOffer = async (e) => {
    e.preventDefault();
    const channelId = localStorage.getItem(`channelId`);
    const token = localStorage.getItem(`cToken`);

    try {
      const response = await fetch(
        `${serverIP}/channel/p1_generateQuickOffers/${channelId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone: contact,
            initial_ask_theme: propertyType,
            initial_ask_purpose: loanType,
          }),
        }
      );

      if (response.status >= 200 && response.status < 300) {
        const data = await response.json();
        console.log(data);
        localStorage.setItem("cUserId", data.userId);
        localStorage.setItem("cAskId", data.id);
        localStorage.setItem("cLeadLoanType", loanType);
        localStorage.setItem("cLeadContact", contact);

        handleOfferHook();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOfferHook = async () => {
    if (contact.length !== 10 || !/[6-9]/.test(contact[0])) {
      setError("Enter correct mobile number");
      return;
    }

    if (offerData[0]) {
      return;
    }
    try {
      const response = await fetch(
        `${serverIP}/product/get-bank-product-details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            product_category: loanType,
          }),
        }
      );
      const data = await response.json();

      if (response.status > 200 || response.status <= 300) {
        console.log(data);
        setOfferData(data);
      } else {
        console.log("data not found");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleViewMore = () => {
    setDisplayedOffers((prevCount) => prevCount + 3); // Increment displayed offers by 3
  };

  const handleProceed = () => {
    history("/channel/create-lead-s1");
  };

  const handleContact = (e) => {
    setContact(e.target.value);
    setError("");
  };
  return (
    <div className="CN-Channel-CreateLead-Page">
      <ChannelSidebar />
      <div className="CN-Channel-CreateLead-MainContainer">
        <h1>Create Lead</h1>
        <div className="CN-Channel-CreateLead-FormContainer">
          <h2>Generate quick offers</h2>

          <form onSubmit={handleQuickOffer}>
            {error && (
              <div className="CN-Channel-CreateLead-Error">{error}</div>
            )}
            <div>
              <select onChange={handleLoanTypeSelect} value={loanType} required>
                <option value="">Loan type...</option>
                <option value="property purchase">Property purchase</option>
                <option value="loan against property">
                  Loan against property
                </option>
                <option value="personal loan">Personal loan</option>
              </select>
              {(loanType === "property purchase" ||
                loanType === "loan against property") && (
                <>
                  <select
                    onChange={handlePropertyTypeSelect}
                    value={propertyType}
                    required
                  >
                    <option value="">Property type...</option>
                    <option value="residential">Residential</option>
                    <option value="commercial">Commercial</option>
                  </select>
                </>
              )}

              <input
                type="tel"
                placeholder="Mobile Number"
                maxLength={10}
                required
                onChange={handleContact}
              />
            </div>
            <button>Submit</button>
          </form>

          <div className="CN-Channel-CreateLead-OfferDiv">
            {offerData[0] && (
              <>
                <h2>Offers for client</h2>
              </>
            )}
            {offerData.slice(0, displayedOffers).map((data) => {
              return (
                <div
                  className="CN-Channel-OfferHook-Container"
                  key={data.bankId}
                >
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item CreateLead-Accordion-Item">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#panelsStayOpen-collapse-${data.bankId}`}
                        aria-expanded="false"
                      >
                        <div>
                          <img
                            src={bankLogos[data.bankLogo]}
                            alt={data.bankName}
                            style={{
                              objectFit: "contain",
                              width: "100px",
                            }}
                          />
                          <div>
                            <span>Upto</span>
                            {data.maxLoanMax <= "99" ? (
                              <>{data.maxLoanMax / 1} L</>
                            ) : (
                              <>{data.maxLoanMax / 100} Cr</>
                            )}
                          </div>
                          <div>
                            <span>Starting</span>
                            {(data.minRate * 1).toFixed(2)}%
                          </div>
                        </div>
                      </button>
                      <div
                        id={`panelsStayOpen-collapse-${data.bankId}`}
                        className="accordion-collapse collapse"
                      >
                        <div className="accordion-body CN-Channel-OfferHook-InnerItems">
                          <div>
                            <div>
                              Tenure:{" "}
                              <LockOpenRounded sx={{ fontSize: "16px" }} />
                            </div>
                            <div>
                              Insurance:{" "}
                              <LockOpenRounded sx={{ fontSize: "16px" }} />
                            </div>
                            <div>
                              Application charges:{" "}
                              <LockOpenRounded sx={{ fontSize: "16px" }} />
                            </div>
                          </div>
                          <div>
                            <div>
                              {" "}
                              Prepayment:{" "}
                              <LockOpenRounded sx={{ fontSize: "16px" }} />
                            </div>
                            <div>
                              Balance-Transfer:{" "}
                              <LockOpenRounded sx={{ fontSize: "16px" }} />
                            </div>
                            <div>
                              Top-up:{" "}
                              <LockOpenRounded sx={{ fontSize: "16px" }} />
                            </div>
                          </div>
                        </div>
                        <div className="CN-Channel-OfferHook-Proceed">
                          Click to proceed to unlock offers
                          <button onClick={handleProceed}>Proceed</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              {offerData.length > displayedOffers && (
                <div>
                  <button
                    onClick={handleViewMore}
                    style={{
                      backgroundColor: "var(--color-lightyellow)",
                      color: "var(--color-darkgreen)",
                    }}
                  >
                    View More
                  </button>
                </div>
              )}
              {offerData[0] && (
                <div>
                  <button onClick={handleProceed}>Proceed</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateLead;
