import React from "react";
import "./StatusBar.css";

function StatusBar(Props) {
  return (
    <>
      <div className="CN2-Profile1-StatusBar">
        <div className="CN2-Profile1-StatusBar-Div">
          <div className="CN2-Circle1-Container">
            <div
              className="CN2-Circle"
              style={{ backgroundColor: Props.ProfileColor }}
            ></div>
            Profile
          </div>
          <div className="CN2-Status-Line"></div>
        </div>
        <div className="CN2-Profile1-StatusBar-Div">
          <div className="CN2-Circle1-Container">
            <div
              className="CN2-Circle"
              style={{ backgroundColor: Props.LoanColor }}
            ></div>
            Loan
          </div>
          <div className="CN2-Status-Line "></div>
        </div>
        <div className="CN2-Profile1-StatusBar-Div">
          <div className="CN2-Circle1-Container">
            <div
              className="CN2-Circle"
              style={{ backgroundColor: Props.KycColor }}
            ></div>
            KYC
          </div>
          <div className="CN2-Status-Line"></div>
        </div>
        <div className="CN2-Profile1-StatusBar-Div">
          <div className="CN2-Circle1-Container">
            <div
              className="CN2-Circle"
              style={{ backgroundColor: Props.OffersColor }}
            ></div>
            Offers
          </div>
          <div className="CN2-Status-Line"></div>
        </div>
        <div className="CN2-Profile1-StatusBar-Div">
          <div className="CN2-Circle1-Container">
            <div
              className="CN2-Circle"
              style={{ backgroundColor: Props.IncomeColor }}
            ></div>
            Income
          </div>
        </div>
      </div>
    </>
  );
}

export default StatusBar;
