import React, { useEffect, useState } from "react";
import Header from "../../User-Components/Front-Page-Components/Header/Header";
import StepsFooter from "../../User-Components/Front-Page-Components/Footer/StepsFooter";
import "./ChannelTest.css";
import Sample1 from "../../Images/Channel-Img/livetracking.png";
import Sample2 from "../../Images/Channel-Img/approvaltime.png";
import Sample3 from "../../Images/Channel-Img/fastpayment.png";
import partners from "../../Images/BanksLogo.jpg";
import serverIP from "../../config/config";
import { useNavigate } from "react-router-dom";
import Animation1 from "../../Animation/animation";

const ChannelTest = () => {
  const images = [Sample1, Sample2, Sample3];
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpDiv, setOtpDiv] = useState(false);
  const [error, setError] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImageSubheadIndex, setCurrentImageSubheadIndex] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [timer, setTimer] = useState(45);
  const [isWhatsappOptedIn, setIsWhatsappOptedIn] = useState(true);

  const history = useNavigate();

  useEffect(() => {
    localStorage.removeItem("cToken");
  }, []);

  const startTimer = () => {
    setIsResendDisabled(true);
    let countdown = 45;

    const interval = setInterval(() => {
      countdown -= 1;
      setTimer(countdown);

      if (countdown === 0) {
        clearInterval(interval);
        setIsResendDisabled(false);
      }
    }, 1000);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 45);
    const remainingSeconds = seconds % 45;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    if (otpDiv) {
      startTimer();
    }
  }, [otpDiv]);

  const handlePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
    setError("");
  };

  const handleOtp = (e) => {
    setOtp(e.target.value);
    setError("");
  };

  const handlePhoneSubmit = async () => {
    if (phoneNumber.length !== 10 || !/[6-9]/.test(phoneNumber[0])) {
      setError("Enter Correct Mobile Number");
      return;
    }

    setShowAnimation(true);
    try {
      const response = await fetch(`${serverIP}/channel/generate-channel-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber: phoneNumber,
          whatsappOptIn: isWhatsappOptedIn,
        }),
      });

      if (response.status >= 200 && response.status < 300) {
        setTimer();
        // const data = await response.json();
        // console.log(data);
        setOtpDiv(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowAnimation(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setShowAnimation(true);
    try {
      const response = await fetch(
        `${serverIP}/auth/channel/verify-channel-text-otp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phoneNumber: phoneNumber,
            enteredOTP: otp,
          }),
        }
      );

      if (response.status >= 200 && response.status < 300) {
        const data = await response.json();
        // console.log(data);
        if (data.isOTPValid === true) {
          localStorage.setItem("channelId", data.channelId);
          localStorage.setItem("cToken", data.token);
          localStorage.setItem("cNumber", phoneNumber);
          handleCheckChannelAuth();
          // history("/channel/summary");
        } else {
          setError("Invalid OTP");
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowAnimation(false);
    }
  };
  const ImageSubhead = [
    {
      head: "Live Payout Tracker",
      subhead: "You would never need to make another call to inquire",
    },
    {
      head: "Industry's Best Approval Rate",
      subhead:
        "We partner with a broad range of 40+ lenders to ensure your customer gets credit",
    },
    {
      head: "Same-day Payment",
      subhead: "We pay you the same-day we get paid",
    },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Change the current image index every 3 seconds
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
      setCurrentImageSubheadIndex((prevIndex) =>
        prevIndex === ImageSubhead.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    // Clear the interval on component unmount to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [images.length, ImageSubhead.length]);

  const handleCheckboxChange = (e) => {
    setIsWhatsappOptedIn(e.target.checked);
    // console.log(!e.target.checked);
  };

  const handleCheckChannelAuth = async () => {
    setShowAnimation(true);
    const channelId = localStorage.getItem("channelId");
    try {
      const response = await fetch(
        `${serverIP}/channel/getApprovedStatus/${channelId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("cToken")}`,
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        const data = await response.json();
        console.log(data);
        if (data.approved === true || data.approved === "t") {
          history("/channel/summary");
          console.log(response);
        } else {
          history("/channel/auth-status");
          console.log(response);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowAnimation(false);
    }
  };

  return (
    <div className="CN-ChannelTest-Page">
      <Header />
      <div className="CN-ChannelTest">
        <div>
          <h1>Welcome to Creditnama</h1>
        </div>
        <div className="CN-ChannelTest-Main">
          <div className="CN-ChannelTest-Part1">
            <h1>{ImageSubhead[currentImageSubheadIndex].head}</h1>
            <div>
              <img
                src={images[currentImageIndex]}
                alt={`Slide ${currentImageIndex + 1}`}
                className="CN2-ChannelTest--Img"
                loading="lazy"
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
            <p>{ImageSubhead[currentImageSubheadIndex].subhead}</p>
          </div>

          <div className="CN-ChannelTest-Part2">
            <h2>Enter Details</h2>
            {showAnimation ? (
              <>
                <Animation1 />
              </>
            ) : (
              <>
                {error && <div className="CN2-ChannelTest-Error">{error}</div>}

                {otpDiv ? (
                  <>
                    <div
                      style={{
                        width: "280px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOtpDiv(false);
                      }}
                    >
                      Edit
                    </div>
                    <input value={phoneNumber} disabled={true} />
                    <input
                      type="tel"
                      placeholder="Enter OTP"
                      value={otp}
                      maxLength={6}
                      onChange={handleOtp}
                    />
                    <button onClick={handleOtpSubmit}>Submit OTP</button>
                    {isResendDisabled ? (
                      <p className="CN2-ResendOtp">
                        Resend OTP {formatTime(timer)}
                      </p>
                    ) : (
                      <p
                        className="CN2-ResendOtp"
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={handlePhoneSubmit}
                      >
                        Resend OTP
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <input
                      type="tel"
                      placeholder="Enter phone number"
                      value={phoneNumber}
                      maxLength={10}
                      onChange={handlePhoneNumber}
                    />

                    <button onClick={handlePhoneSubmit}>Send OTP</button>
                    <div>
                      <input
                        type="checkbox"
                        style={{ width: "15px", height: "15px" }}
                        className="CN2-Channel-Checkbox-Style mx-2"
                        checked={isWhatsappOptedIn}
                        onChange={handleCheckboxChange}
                      />
                      Opt in to receive important updates on WhatsApp
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <h1 style={{ fontFamily: "var(--font-Josefin" }}>Partner Banks</h1>
        <div className="CN2-ChannelTest-PartnerBanks-Container ">
          <img
            src={partners}
            alt="Partner Banks"
            className="CN-ChannelTest-PartnerBanks"
          />
          <img
            src={partners}
            alt="Partner Banks"
            className="CN-ChannelTest-PartnerBanks"
          />{" "}
          <img
            src={partners}
            alt="Partner Banks"
            className="CN-ChannelTest-PartnerBanks"
          />
        </div>
      </div>
      <StepsFooter />
    </div>
  );
};

export default ChannelTest;
