import React, { useEffect, useState } from "react";
import "./CNEmployer.css";
import Header from "../Front-Page-Components/Header/Header";
import StatusBar from "../../Status-Bar/StatusBar";
import EmployerImg from "../../Images/Page-Img/Employement.png";
import Animation1 from "../../Animation/animation";
import serverIP from "../../config/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import StepsFooter from "../Front-Page-Components/Footer/StepsFooter";
import FAQ from "../FAQs/FAQ";

function Employer() {
  const [employer, setEmployer] = useState("");
  const [salary, setSalary] = useState("");
  const [joining, setJoining] = useState("");
  const [officialEmail, setOfficialEmail] = useState("");
  const [Error, setError] = useState("");
  const [monthlyObligation, setMonthlyObligation] = useState("");
  const [loan, setLoan] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();

  // Load data from localStorage on component mount
  useEffect(() => {
    const storedData =
      JSON.parse(localStorage.getItem("employerFormData")) || {};
    setEmployer(storedData.employer || "");
    setSalary(storedData.salary || "");
    setMonthlyObligation(storedData.monthlyObligation || "");
    setJoining(storedData.joining || "");
    setOfficialEmail(storedData.officialEmail || "");
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("AskFormData")) || {};
    setLoan(storedData.loan);
  }, []);

  const formatDoj = (value) => {
    // Remove all non-numeric characters
    const numericValue = value.replace(/\D/g, "");

    // Check if the numericValue is empty or not
    if (!numericValue) {
      return "";
    }

    // Use regular expressions to format as DD/MM/YYYY
    if (numericValue.length <= 2) {
      return numericValue;
    } else if (numericValue.length <= 4) {
      return `${numericValue.slice(0, 2)}-${numericValue.slice(2)}`;
    } else {
      return `${numericValue.slice(0, 2)}-${numericValue.slice(
        2,
        4
      )}-${numericValue.slice(4, 8)}`;
    }
  };

  const handleChange = (e) => {
    setError("");
    const formattedValue = formatDoj(e.target.value);
    setJoining(formattedValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Save form data to localStorage on successful submission
    localStorage.setItem(
      "employerFormData",
      JSON.stringify({
        employer,
        salary,
        monthlyObligation,
        joining,
        officialEmail,
      })
    );
    try {
      // Get the access token from localStorage
      const token = localStorage.getItem("accessToken");
      const userId = localStorage.getItem("userId");

      if (salary < 15000) {
        console.error("Minimum 15k salary required ");
        setError("Minimum 15k salary required");
        return;
      }

      // Format the joining date as "yyyy-mm-dd"
      const formattedJoining = joining.split("-").reverse().join("-");

      const [yyyy, mm, dd] = formattedJoining.split("-");
      const isValidDate =
        parseInt(dd, 10) <= 31 &&
        parseInt(mm, 10) <= 12 &&
        parseInt(yyyy, 10) >= 1947;

      if (!isValidDate) {
        console.error("Invalid date entered");
        setError("Enter correct joining date");
        return;
      }

      const enteredDate = new Date(`${yyyy}-${mm}-${dd}`);
      const currentDate = new Date();

      // Check if the entered date is not greater than today's date
      if (enteredDate > currentDate) {
        setError("Enter correct joining date");
        return;
      }

      // Make sure the token exists before proceeding
      if (!token) {
        console.error("Access token not found.");
        return;
      }

      // Set the Authorization header with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Create the data object in the desired format
      const data = {
        employerName: employer,
        workEmail: officialEmail,
        monthlySalary: parseInt(salary, 10),
        monthlyDeductions: parseInt(monthlyObligation),
        doj: formattedJoining,
      };
      setIsLoading(true);
      // Send a POST request to the server with the selected data
      const response = await axios.post(
        `${serverIP}/user/employer/${userId}`,
        data,
        config
      );

      if (response.status === 200 || response.status <= 300) {
        // Redirect to Waiting page after successful form submission
        console.log("Employer Details Updated");
        console.log(response);
        if (loan === "property purchase" || loan === "loan against property") {
          history("/asset");
        } else {
          history("/offerstandby");
        }
      } else {
        console.log("Error in Post");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error inserting data:", error);
      setIsLoading(false);
      // Handle form submission error
    } finally {
      setIsLoading(false);
    }
  };

  const handleObligations = (e) => {
    setError("");
    const value =
      e.target.type === "range"
        ? parseFloat(e.target.value)
        : parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
    setMonthlyObligation(isNaN(value) ? 0 : value);
  };

  const handleSalary = (e) => {
    setError("");
    const value =
      e.target.type === "range"
        ? parseFloat(e.target.value)
        : parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
    setSalary(isNaN(value) ? 0 : value);
  };

  return (
    <>
      <Header />

      <div className="CN2-Employer">
        <div className="CN2-Employer-Container">
          <div className="CN2-Employer-Main-Div">
            <div className="CN2-Employer-Main-Div-Left">
              <img
                src={EmployerImg}
                alt="Employer Details"
                className="CN2-Employer-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-yellow)"
                KycColor="white"
                IncomeColor="white"
                OffersColor="white"
              />
            </div>
            <div className="CN2-Employer-Main-Div-Right">
              <h1>Employment</h1>
              <div className="CN2-Employer-Faq-Div">
                <FAQ PageName="employer" />
              </div>
              {isLoading ? (
                <>
                  <Animation1 />
                </>
              ) : (
                <>
                  <form onSubmit={handleSubmit}>
                    {Error && <div className="CN2-Employer-Error">{Error}</div>}
                    <div>
                      <input
                        className="CN2-Employer-Input"
                        placeholder="Employer name"
                        type="text"
                        value={employer}
                        onChange={(e) => setEmployer(e.target.value)}
                        required
                      />
                    </div>
                    <div>
                      <input
                        className="CN2-Employer-Input"
                        placeholder="Gross Monthly Salary"
                        type="tel"
                        value={salary.toLocaleString("en-IN", {
                          style: "currency",
                          currency: "INR",
                          maximumFractionDigits: "0",
                        })}
                        onChange={handleSalary}
                        required
                        maxLength={9}
                      />
                    </div>
                    <div>
                      <input
                        className="CN2-Employer-Input"
                        placeholder="Existing Monthly EMIs"
                        type="tel"
                        value={monthlyObligation.toLocaleString("en-IN", {
                          style: "currency",
                          currency: "INR",
                          maximumFractionDigits: "0",
                        })}
                        onChange={handleObligations}
                        // required
                        maxLength={9}
                      />
                    </div>
                    <div>
                      <input
                        className="CN2-Employer-Input"
                        placeholder="Joining date"
                        type="tel"
                        value={joining}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <input
                        className="CN2-Employer-Input"
                        name="PhoneInput"
                        placeholder="Official email"
                        type="email"
                        value={officialEmail}
                        onChange={(e) => setOfficialEmail(e.target.value)}
                        required
                      />
                    </div>
                    <button className="CN2-Employer-Button">Submit</button>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default Employer;
