import React, { useEffect, useState } from "react";
import "./CNSelection.css";
import BarChart from "./Charts-Components/BarChart";
import PieChart from "./Charts-Components/PieChart";
import { useNavigate } from "react-router-dom";
import serverIP from "../config/config";
import axisLogo from "../Images/Bank-Logos/axis.png";
import hdfcLogo from "../Images/Bank-Logos/hdfc.png";
import kotakLogo from "../Images/Bank-Logos/kotak.png";
import abflLogo from "../Images/Bank-Logos/abfl.jpg";
import axisfinanceLogo from "../Images/Bank-Logos/axisfinance.png";
import bajajLogo from "../Images/Bank-Logos/bajaj.png";
import capriLogo from "../Images/Bank-Logos/capri.png";
import cholaLogo from "../Images/Bank-Logos/chola.png";
import citibankLogo from "../Images/Bank-Logos/citibank.png";
import dbsLogo from "../Images/Bank-Logos/dbs.png";
import deutcheLogo from "../Images/Bank-Logos/deutche.png";
import edelwisLogo from "../Images/Bank-Logos/edelwis.jpg";
import fullertonLogo from "../Images/Bank-Logos/fullerton.png";
import hdbLogo from "../Images/Bank-Logos/hdb.png";
import homecreditLogo from "../Images/Bank-Logos/homecredit.png";
import hsbcLogo from "../Images/Bank-Logos/hsbc.png";
import iciciLogo from "../Images/Bank-Logos/icici.png";
import idfcLogo from "../Images/Bank-Logos/idfc.jpg";
import iiflLogo from "../Images/Bank-Logos/iifl.png";
import incredLogo from "../Images/Bank-Logos/incred.png";
import indusindLogo from "../Images/Bank-Logos/indusind.png";
import karurLogo from "../Images/Bank-Logos/karur.jpg";
import myshubhlifeLogo from "../Images/Bank-Logos/myshubhlife.png";
import paysenseLogo from "../Images/Bank-Logos/paysense.png";
import protiumLogo from "../Images/Bank-Logos/protium.png";
import scbLogo from "../Images/Bank-Logos/scb.png";
import shriramLogo from "../Images/Bank-Logos/shriram.png";
import smcLogo from "../Images/Bank-Logos/smc.png";
import tataLogo from "../Images/Bank-Logos/tata.png";
import ujjivanLogo from "../Images/Bank-Logos/ujjivan.png";
import utkershLogo from "../Images/Bank-Logos/utkersh.png";
import yesbankLogo from "../Images/Bank-Logos/yesbank.png";
import indiabullshomeLogo from "../Images/Bank-Logos/Indiabulls.png";
import indiahomeLogo from "../Images/Bank-Logos/indiashelter.png";
import shubhamhomeLogo from "../Images/Bank-Logos/shubham.png";
import godrejLogo from "../Images/Bank-Logos/godrej.png";
import adityalimitedLogo from "../Images/Bank-Logos/abfl.jpg";
import easyhomefinanceLogo from "../Images/Bank-Logos/easyhome.png";
import piramalhousingLogo from "../Images/Bank-Logos/piramal.png";
import federalLogo from "../Images/Bank-Logos/fedral.png";
import shriramhousingLogo from "../Images/Bank-Logos/shriram.png";
import bajajhousingLogo from "../Images/Bank-Logos/bajaj.png";
import anandLogo from "../Images/Bank-Logos/Anandrathi.png";
import dmiLogo from "../Images/Bank-Logos/DmiHousing.png";
import rblLogo from "../Images/Bank-Logos/RblBank.png";
import aadharLogo from "../Images/Bank-Logos/AadharHousing.png";
import satinLogo from "../Images/Bank-Logos/SatinHousing.png";
import licLogo from "../Images/Bank-Logos/LicHousing.png";
import poonawallaLogo from "../Images/Bank-Logos/poonawalla.png";
import sbmLogo from "../Images/Bank-Logos/sbm.png";
import Header from "../User-Components/Front-Page-Components/Header/Header";
import StepsFooter from "../User-Components/Front-Page-Components/Footer/StepsFooter";
import StatusBar from "../Status-Bar/StatusBar";
import OfferImg from "../Images/Page-Img/Finaloffer.png";

function CNSelectionDetails() {
  const [principal, setPrincipal] = useState(0);
  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [tenure, setTenure] = useState(0);
  const [minTenure, setMinTenure] = useState(0);
  const [maxTenure, setMaxTenure] = useState(0);
  const [appCharge, setAppCharge] = useState(0);
  const [rate, setRate] = useState(0);
  const [bankLogo, setBankLogo] = useState("");
  const [tat, setTat] = useState("");
  const [error, setError] = useState("");

  const history = useNavigate();

  const bankLogos = {
    axisLogo: axisLogo,
    hdfcLogo: hdfcLogo,
    kotakLogo: kotakLogo,
    abflLogo: abflLogo,
    axisfinanceLogo: axisfinanceLogo,
    bajajLogo: bajajLogo,
    capriLogo: capriLogo,
    cholaLogo: cholaLogo,
    citibankLogo: citibankLogo,
    dbsLogo: dbsLogo,
    deutcheLogo: deutcheLogo,
    edelwisLogo: edelwisLogo,
    fullertonLogo: fullertonLogo,
    hdbLogo: hdbLogo,
    homecreditLogo: homecreditLogo,
    hsbcLogo: hsbcLogo,
    iciciLogo: iciciLogo,
    idfcLogo: idfcLogo,
    iiflLogo: iiflLogo,
    incredLogo: incredLogo,
    indusindLogo: indusindLogo,
    karurLogo: karurLogo,
    myshubhlifeLogo: myshubhlifeLogo,
    paysenseLogo: paysenseLogo,
    protiumLogo: protiumLogo,
    scbLogo: scbLogo,
    shriramLogo: shriramLogo,
    smcLogo: smcLogo,
    tataLogo: tataLogo,
    ujjivanLogo: ujjivanLogo,
    utkershLogo: utkershLogo,
    yesbankLogo: yesbankLogo,
    indiahomeLogo: indiahomeLogo,
    shubhamhomeLogo: shubhamhomeLogo,
    godrejLogo: godrejLogo,
    adityalimitedLogo: adityalimitedLogo,
    easyhomefinanceLogo: easyhomefinanceLogo,
    indiabullshomeLogo: indiabullshomeLogo,
    piramalhousingLogo: piramalhousingLogo,
    federalLogo: federalLogo,
    shriramhousingLogo: shriramhousingLogo,
    bajajhousingLogo: bajajhousingLogo,
    anandLogo: anandLogo,
    dmiLogo: dmiLogo,
    rblLogo: rblLogo,
    aadharLogo: aadharLogo,
    satinLogo: satinLogo,
    licLogo: licLogo,
    poonawallaLogo: poonawallaLogo,
    sbmLogo: sbmLogo,
  };
  const ask = localStorage.getItem("initial_ask_purpose");

  useEffect(() => {
    // Retrieve data from local storage
    const storedOfferSelection = JSON.parse(
      localStorage.getItem("finalOfferSelection")
    );

    if (
      storedOfferSelection &&
      Array.isArray(storedOfferSelection) &&
      storedOfferSelection.length > 0
    ) {
      const offerSelectionData = storedOfferSelection[0];
      setBankLogo(offerSelectionData.bankName);
      setPrincipal(offerSelectionData.maxLoan * 100000);
      setMinAmount(offerSelectionData.minLoan * 100000);
      setTenure(offerSelectionData.maxTenure);
      setMinTenure(offerSelectionData.minTenure);
      setRate(offerSelectionData.interestRate);
      setTat(offerSelectionData.tat);
      setAppCharge(offerSelectionData.appCharge);
      setMaxAmount(offerSelectionData.maxLoan * 100000);
      setMaxTenure(offerSelectionData.maxTenure);
    }
  }, []); // Run this effect only once on component mount
  const handleNoClick = () => {
    history("/finaloffer");
  };
  const handleYesClick = async () => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      console.log("Token not found");
      return;
    }

    if (principal < minAmount) {
      console.log(`Minimum ${minAmount} amount required`);
      setError(`Minimum ${minAmount} amount required`);
      return;
    }

    if (principal > maxAmount) {
      console.log(`Maximum ${maxAmount} amount exceed`);
      setError(`Maximum ${maxAmount.toFixed(0)} amount exceed`);

      return;
    }

    if (tenure < minTenure) {
      console.log(`Minimum ${minTenure} years tenure required`);
      setError(`Minimum ${minTenure} years tenure required`);
      return;
    }

    if (tenure > maxTenure) {
      console.log(`Maximum ${maxTenure} years tenure exceed`);
      setError(`Maximum ${maxTenure} years tenure exceed`);
      return;
    }

    const askId = localStorage.getItem("askId");

    try {
      const response = await fetch(
        `${serverIP}/eligibility/save-selection/${askId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            selected_value: parseInt(principal),
            selected_rate: parseInt(rate).toFixed(2),
            selected_tenure: parseInt(tenure),
            selected_consent: true,
          }),
        }
      );

      const data = await response.json();
      // console.log(data);

      if (response.status > 200 || response.status <= 300) {
        console.log("Selection saved successfully!");
        console.log(data);
        history("/income");
      } else {
        console.error("Failed to save selection");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePrincipalChange = (e) => {
    const value = parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
    setPrincipal(value);
  };

  const handleTenureChange = (e) => {
    const value = parseInt(e.target.value);
    setTenure(value);
  };

  const monthlyInterestRate = rate / 12 / 100; // Using a constant interest rate
  const numberOfMonths = tenure * 12;
  const monthlyPayment =
    (principal * monthlyInterestRate) /
    (1 - Math.pow(1 + monthlyInterestRate, -numberOfMonths));

  const totalInterestPaid = monthlyPayment * numberOfMonths - principal;
  // Calculate yearly data for principal and interest
  const yearlyData = [];
  let remainingPrincipal = principal;
  for (let year = 1; year <= tenure; year++) {
    let yearPrincipalPaid = 0;
    let yearInterestPaid = 0;

    for (let month = 1; month <= 12; month++) {
      const monthlyPrincipalPaid =
        (remainingPrincipal * monthlyInterestRate) /
        (1 - Math.pow(1 + monthlyInterestRate, -numberOfMonths));
      const monthlyInterestPaid = monthlyPayment - monthlyPrincipalPaid;
      remainingPrincipal -= monthlyPrincipalPaid;
      yearPrincipalPaid += monthlyPrincipalPaid;
      yearInterestPaid += monthlyInterestPaid;
    }

    yearlyData.push({
      year: year,
      principalPaid: yearPrincipalPaid,
      interestPaid: yearInterestPaid,
    });
  }

  // Reverse the yearlyData array
  const reversedYearlyData = yearlyData.slice().reverse();

  // Calculate data for the bar chart
  const userData = {
    labels: reversedYearlyData.map((data) => data.year), // Reversed order
    datasets: [
      {
        label: "Principal",
        data: reversedYearlyData.map((data) => data.principalPaid), // Reversed order
        backgroundColor: "#1d6b11",
        borderColor: "black",
        borderWidth: 1,
      },
      {
        label: "Interest",
        data: reversedYearlyData.map((data) => data.interestPaid), // Reversed order
        backgroundColor: "rgb(237, 140, 43)",
        borderColor: "black",
        borderWidth: 1,
      },
    ],
  };

  // Pie chart data
  const pieChartData = {
    labels: ["Principal", "Interest"],
    datasets: [
      {
        data: [principal, totalInterestPaid.toFixed(0)],
        backgroundColor: ["#1d6b11", "rgb(237, 140, 43)"],
        borderColor: "black",
        borderWidth: 1,
      },
    ],
  };

  const rangeStyles = {
    WebkitAppearance: "none",
  };

  return (
    <>
      <Header />

      <div className="CN2-Selection">
        <div className="CN2-Selection-Container">
          <div className="CN2-Selection-Main-Div">
            <div className="CN2-Selection-Main-Div-Left">
              <img
                src={OfferImg}
                alt="Profile with pan and aadhaar"
                className="CN2-Selection-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-success)"
                KycColor="var(--color-success)"
                IncomeColor="var(--color-yellow)"
                OffersColor="var(--color-success)"
              />
            </div>
            <div className="CN2-Selection-Main-Div-Right">
              <h1>Your Selection</h1>
              {error && (
                <div className="CN2-Selection-Details-Error">{error}</div>
              )}
              <div className="CN2-Selection-Details-Div">
                <div className="CN2-Selection-Details-Div-head">
                  <div>
                    <b>{ask.toUpperCase()}</b>
                  </div>
                  <div>
                    <img
                      src={bankLogos[bankLogo]}
                      alt="Selected Bank Logo"
                      className="CN2-Selection-Details-Div-Bank-Logo"
                    />
                  </div>
                </div>
                <div className="CN2-Selection-Fees-Div">
                  <div>
                    <div>Tenure: {tenure} Years</div>
                    <div>
                      Application charges: ₹
                      {appCharge.toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                      })}
                    </div>
                  </div>
                  <div>
                    <div>
                      EMI: ₹
                      {monthlyPayment.toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                      })}
                    </div>
                    <div>Time: {tat} Days</div>
                  </div>
                </div>
              </div>
              <div className="CN2-Selection-EMI-Div">
                <h2>Customize your EMI</h2>
                <div className="CN2-Selection-EMI-Div-Left">
                  <div className="CN2-Selection-Amt-Div">
                    Amount
                    <input
                      value={
                        isNaN(principal)
                          ? "0"
                          : principal.toLocaleString("en-IN", {
                              style: "currency",
                              currency: "INR",
                              maximumFractionDigits: "0",
                            })
                      }
                      onChange={handlePrincipalChange}
                      id="principal"
                      type="tel"
                      step={(principal - minAmount) / 20}
                      className="CN2-Selection-Input"
                      maxLength={13}
                    />
                    <input
                      value={principal}
                      type="range"
                      className="CN2-Selection-Slider"
                      min={parseFloat(minAmount)}
                      max={parseFloat(maxAmount)}
                      step={parseFloat(maxAmount - minAmount) / 20}
                      id="customRange1"
                      onChange={handlePrincipalChange}
                      style={rangeStyles}
                    />
                  </div>
                  <div className="CN2-Selection-Tenure-Div">
                    Tenure
                    <div className="CN2-Selection-Tenure">{tenure} Years</div>
                    <input
                      value={tenure}
                      type="range"
                      className="CN2-Selection-Slider"
                      min={parseFloat(minTenure)}
                      max={parseFloat(maxTenure)}
                      id="customRange2"
                      onChange={handleTenureChange}
                      style={rangeStyles}
                    />
                  </div>
                </div>
                <div style={{ marginTop: "10px", fontWeight: "700" }}>
                  EMI: ₹
                  {monthlyPayment.toLocaleString("en-IN", {
                    maximumFractionDigits: 0,
                  })}
                </div>
              </div>
              <div className="CN2-Selection-PaymentSchedule">
                <h2>Payment Schedule</h2>
                <div className="CN2-Selection-Charts">
                  <div className="CN2-Selection-PaymentSchedule-PieChart">
                    <PieChart chartData={pieChartData} />
                  </div>
                  <div className="CN2-Selection-PaymentSchedule-BarChart">
                    <BarChart chartData={userData} />
                  </div>
                </div>
              </div>
              <b>Would you like to proceed with this loan ?</b>

              <div className="CN2-Selection-Button-Container">
                <button
                  className="CN2-Selection-Button mx-2"
                  style={{
                    backgroundColor: "var(--color-lightyellow)",
                    color: "var(--color-darkgreen)",
                  }}
                  onClick={handleNoClick}
                >
                  <b>NO!</b> <br />
                  Take me back to eligible options
                </button>
                <button
                  className="CN2-Selection-Button"
                  onClick={handleYesClick}
                >
                  <b>Yes!</b>
                  <br />
                  Submit my application & generate sanction draft
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default CNSelectionDetails;
