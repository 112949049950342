import React from "react";
import "./page.css";
import ErrorPng from "../Images/Assets/404Animation.png";
import { useNavigate } from "react-router-dom";
import Header from "../User-Components/Front-Page-Components/Header/Header";
import Footer from "../User-Components/Front-Page-Components/Footer/Footer";

function PageNotFound() {
  const history = useNavigate();

  const handleHome = () => {
    history("/");
  };
  return (
    <>
      <Header />

      <div className="error404">
        <div className="CN-Error404-Container">
          <h1>Page Not Found</h1>
          <img className="CN-Error-icon" alt="" src={ErrorPng} loading="lazy" />

          <button className="CN-Error404-button" onClick={handleHome}>
            Go to Home
          </button>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default PageNotFound;
