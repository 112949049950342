import React, { useEffect, useState } from "react";
import "./CNProfile1.css";
import Header from "../Front-Page-Components/Header/Header";
import Profile from "../../Images/Page-Img/Create Profile.png";
import StatusBar from "../../Status-Bar/StatusBar";
import AadhaarIcon from "../../Images/Assets/aadhaaricon@2x.png";
import PanIcon from "../../Images/Assets/panicon@2x.png";
import { Link } from "react-router-dom";
import Animation1 from "../../Animation/animation";
import serverIP from "../../config/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import StepsFooter from "../Front-Page-Components/Footer/StepsFooter";
import FAQ from "../FAQs/FAQ";

function Profile1() {
  const [pan, setPan] = useState("");
  const [aadhaar, setAadhaar] = useState("");
  const [Error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();

  //   ###################### Check if User Done This Process ######################

  useEffect(() => {
    const timer = setTimeout(() => {
      const token = localStorage.getItem("accessToken");
      const userId = localStorage.getItem("userId");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Fetch profile status from the server
      fetch(`${serverIP}/user/profileStatus/${userId}`, config)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          // Redirect based on the retrieved PAN and Aadhaar status
          if (data.retrievedPan && data.retrievedAadhaar) {
            history("/ask1");
          } else {
            history("/profile1");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, 10);

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, [history]);

  //   ###################### Check if PAN exists ######################
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        };

        const response = await axios.get(
          `${serverIP}/user/fetchPanifExists/${userId}`,
          config
        );

        if (response.status >= 200 && response.status < 300) {
          console.log(response);
          setPan(response.data.pan || "");
        } else {
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the function inside useEffect
    fetchData();
  }, []);

  //   ###################### Check Aadhaar Status ######################

  const handleAadhaarChange = (event) => {
    const enteredAadhaar = event.target.value;
    const numericAadhaar = enteredAadhaar.replace(/\D/g, "");
    setAadhaar(numericAadhaar);
    if (numericAadhaar.length === 12) {
      setError("");
    }
  };
  //   ###################### Action on Submit ######################

  const handleSubmit = (e) => {
    e.preventDefault();

    const token = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");

    const panPattern = /^[A-Z]{5}\d{4}[A-Z]$/;
    const upperCasePan = pan.toUpperCase();
    if (!panPattern.test(upperCasePan)) {
      setError("Enter valid pan number");
      return;
    }
    if (aadhaar.length !== 12) {
      setError("Enter valid aadhaar number");
      return;
    }

    if (!token || !userId) {
      console.error("Access token or UserId not found.");
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // First PATCH request to update user's PAN and Aadhaar data
      axios
        .patch(
          `${serverIP}/user/profile1/${userId}`,
          {
            pan: upperCasePan,
            aadhaar: aadhaar,
          },
          config
        )
        .then(() => {
          console.log("Profile Updated Successfully");

          // Second PATCH request to update PAN Data in Perfios
          axios
            .patch(
              `${serverIP}/user/scoreme/pan/${userId}`,
              {
                pan: upperCasePan,
              },
              config
            )
            .then(() => {
              console.log("PAN Send Successfully");
              setIsLoading(true);

              // Trigger Aadhaar OTP Request
              axios
                .post(
                  `${serverIP}/kyc/scoreme/eaadhaar/otp/${userId}`,
                  {
                    aadhaar: aadhaar,
                  },
                  config
                )
                .then((response) => {
                  if (response.data.responseCode === "SOS174") {
                    console.log("OTP Sent Successfully");
                    history("/profile2", { state: { pan, aadhaar } });
                    setIsLoading(false);
                  } else if (response.data.responseCode === "EAE168") {
                    setError("Aadhaar does not exist");
                    setIsLoading(false);
                  } else if (response.data.responseCode === "EIS042") {
                    setError("Aadhaar server didn't respond. Regenerate OTP");
                    setIsLoading(false);
                  }
                })
                .catch((aadhaarError) => {
                  console.error("Failed To Send OTP:", aadhaarError);
                  setIsLoading(false);
                });
            })
            .catch((panPatchError) => {
              console.error("Error updating PAN:", panPatchError);
              setIsLoading(false);
            });
        })
        .catch((profilePatchError) => {
          console.error("Error updating user profile data:", profilePatchError);
          // Handle user profile data update error
        });
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle form submission error
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header />

      <div className="CN2-Profile1">
        <div className="CN2-Profile1-Container">
          <div className="CN2-Profile1-Main-Div">
            <div className="CN2-Profile1-Main-Div-Left">
              <img
                src={Profile}
                alt="Profile with pan and aadhaar"
                className="CN2-Profile1-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-yellow)"
                LoanColor="white"
                KycColor="white"
                IncomeColor="white"
                OffersColor="white"
              />
            </div>
            <div className="CN2-Profile1-Main-Div-Right">
              <h1>Create Profile</h1>

              <div className="CN2-Profile1-Faq-Div">
                <FAQ PageName="profile1" />
              </div>

              {isLoading ? (
                <>
                  <Animation1 />
                </>
              ) : (
                <>
                  <form onSubmit={handleSubmit}>
                    {Error && <div className="CN2-Profile1-Error">{Error}</div>}
                    <div>
                      <img
                        src={PanIcon}
                        alt="Pan"
                        className="CN2-Profile1-Pan-Icon"
                      />
                      <input
                        type="text"
                        placeholder="Pan Number"
                        className="CN2-Profile1-Input"
                        value={pan}
                        onChange={(e) => {
                          const inputPan = e.target.value
                            .toUpperCase()
                            .slice(0, 10);
                          setPan(inputPan);
                          if (/^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/.test(inputPan)) {
                            setError("");
                          }
                        }}
                        required
                        maxLength={10}
                      />
                    </div>

                    <div>
                      <img
                        src={AadhaarIcon}
                        alt="Aadhaar"
                        className="CN2-Profile1-Aadhaar-Icon"
                      />
                      <input
                        placeholder="Aadhaar Number"
                        className="CN2-Profile1-Input"
                        type="tel"
                        value={aadhaar}
                        onChange={handleAadhaarChange}
                        maxLength={12}
                        required
                      />
                    </div>
                    <button className="CN2-Profile1-Button">Fetch Info</button>
                    <p>
                      Alternatively, enter details{" "}
                      <Link to={"/personal1"}>manually</Link>
                    </p>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}
export default Profile1;
