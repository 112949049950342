import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./CNBanking.css";
import BankingImg from "../../Images/Page-Img/Bank Statement.png";
import StatementUpload from "../../Images/Assets/statementuploadicon@2x.png";
import SalaryBankImg from "../../Images/Assets/salarybankimg@2x.png";
import Animation1 from "../../Animation/animation";
import FolderIcon from "@mui/icons-material/Folder";
import serverIP from "../../config/config";
import { useEffect } from "react";
import Header from "../../User-Components/Front-Page-Components/Header/Header";
import SuccessImg from "../../Images/Assets/applicationcheck.gif";
import StatusBar from "../../Status-Bar/StatusBar";
import StepsFooter from "../../User-Components/Front-Page-Components/Footer/StepsFooter";
import DeleteIcon from "@mui/icons-material/Delete";

function Banking() {
  const history = useNavigate();
  const location = useLocation();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [successDiv, setSuccessDiv] = useState(false);
  const [referenceId, setReferenceId] = useState("");
  const [password, setPassword] = useState("");
  // Handle selected files from the previous page
  useEffect(() => {
    const selectedFilesFromPreviousPage =
      location.state && location.state.selectedFiles;
    const selectedPasswordFromPreviousPage =
      location.state && location.state.password;
    if (selectedFilesFromPreviousPage) {
      setSelectedFiles(selectedFilesFromPreviousPage);
      setPassword(selectedPasswordFromPreviousPage);
    }
  }, [location.state]);

  useEffect(() => {
    // Store the selectedFiles state in local storage whenever it changes
    localStorage.setItem("selectedFiles", JSON.stringify(selectedFiles));
  }, [selectedFiles]);

  const handleFileChange = () => {
    history("/uploadbanking");
    // setSelectedFile(true);
  };

  const handleBankSelectChange = (event) => {
    // const bank = event.target.value;
    // setSelectedBank(bank);
  };

  const handleUpload = async (e) => {
    // e.preventDefault();
    setIsLoading(true);
    handleUploadBanking();
    // const password = localStorage.getItem("password");
    try {
      const token = localStorage.getItem("accessToken");
      const userId = localStorage.getItem("userId");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      if (selectedFiles.length > 0) {
        const url = `${serverIP}/banking/scoreme/bsa/${userId}`;
        const formData = new FormData();

        // Append each file to the FormData
        selectedFiles.forEach((file) => {
          formData.append("file", file);
        });

        // Append password to FormData
        formData.append("password", password);

        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: formData,
        });

        const responseData = await response.json();

        if (responseData.responseCode === "SRS016") {
          const referenceId = responseData.referenceId;
          // localStorage.setItem("referenceid", referenceId);
          setReferenceId(referenceId);
          setIsLoading(false);
          setSuccessDiv(true);
        } else if (
          responseData.responseCode === "EAT070" ||
          responseData.responseCode === "EFP070"
        ) {
          console.log("File password protected");
          setError(`Password protected file. Provide password`);
          setSelectedFiles([]);
        } else if (responseData.responseCode === "EIP069") {
          console.log("File password Incorrect");
          setError(`File password incorrect. Enter correct password`);
          setSelectedFiles([]);
        } else if (responseData.responseCode === "ETI058") {
          console.log("3rd Party Issue");
          history("/bankingstandby");
        } else if (responseData.responseCode === "EPI022") {
          console.log("Payload Issue");
          history("/bankingstandby");
        } else {
          setError("Error in Uploading");
          console.error("Upload Failed:", response.statusText);
          history("/bankingstandby");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Error in Uploading");

      setIsLoading(false);
      history("/session-break");
    } finally {
      setIsLoading(false);
    }
  };
  const handleUploadBanking = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("accessToken");
      const userId = localStorage.getItem("userId");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      if (selectedFiles.length > 0) {
        let success = false;

        for (const file of selectedFiles) {
          const formData = new FormData();

          // Append each file to the FormData
          formData.append("banking", file);

          // Append password to FormData
          formData.append("password", password);

          const url = `${serverIP}/banking/uploadbanking/${userId}`;

          const response = await fetch(url, {
            method: "POST",
            headers: headers,
            body: formData,
          });

          console.log(response);

          if (response.status === 200 || response.status <= 300) {
            success = true;
          }
        }

        if (success) {
          console.log("files uploaded successfully");
          setIsLoading(false);
        } else {
          console.error("files not uploaded");
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Error in Uploading");
      setIsLoading(false);
      history("/session-break");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleGet = () => {
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const fetchReport = async () => {
      const fetchUrl = `${serverIP}/banking/scoreme/bsaReportFetch/${referenceId}`;
      try {
        const fetchResponse = await fetch(fetchUrl, {
          method: "GET",
          headers: headers,
        });

        const responseData = await fetchResponse.json();

        if (responseData.responseCode === "SRC001") {
          console.log("GET Request Response:", responseData);
          console.log("Banking Send to Server Successfully");
          // Stop the loop when responseCode is "SRC001"
          return;
        } else {
          console.error("Error in GET Request:", responseData);
          // Retry after 3 minutes
          setTimeout(fetchReport, 3 * 60 * 1000);
        }
      } catch (error) {
        console.error("Error in GET Request:", error);
        // Retry after 3 minutes
        setTimeout(fetchReport, 3 * 60 * 1000);
      }
    };

    // Initial call to start the retry loop
    fetchReport();
  };

  const isButtonEnabled = selectedFiles.length > 0;

  return (
    <>
      <Header />
      <div className="CN2-Banking">
        <div className="CN2-Banking-Container">
          <div className="CN2-Banking-Main-Div">
            <div className="CN2-Banking-Main-Div-Left">
              <img
                src={BankingImg}
                alt="Upload Banking"
                className="CN2-Banking-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-success)"
                KycColor="var(--color-success)"
                IncomeColor="var(--color-yellow)"
                OffersColor="var(--color-success)"
              />
            </div>
            <div className="CN2-Banking-Main-Div-Right">
              <h1>Upload bank statement</h1>
              {error && <div className="CN2-Banking-Error">{error}</div>}
              {successDiv ? (
                <>
                  <img
                    src={SuccessImg}
                    alt="SuccessFully Uploaded"
                    style={{ width: "100px" }}
                  />
                  Uploaded successfully
                  <div>
                    <button
                      className="CN2-Banking-Button"
                      onClick={() => {
                        setTimeout(handleGet, 2 * 60 * 1000);
                        history("/bankingstandby");
                      }}
                    >
                      Proceed
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {isLoading ? (
                    <div>
                      <Animation1 />
                    </div>
                  ) : (
                    <>
                      Net banking
                      <select
                        className="CN2-Banking-Input"
                        name="BankSelect"
                        required
                        onChange={handleBankSelectChange}
                      >
                        <option value="">Choose Bank</option>
                        <option value="Other" disabled>
                          Coming Soon
                        </option>
                      </select>
                      or
                      <div
                        className="CN2-Banking-Bank-Div"
                        onClick={handleFileChange}
                      >
                        <div>
                          <img
                            src={SalaryBankImg}
                            alt="Verify Pan"
                            className="CN2-Banking-Bank-Icon"
                          />
                        </div>
                        <div>Click to upload</div>
                        <div>
                          <img
                            src={StatementUpload}
                            alt="Verify Pan"
                            className="CN2-Banking-Bank-Icon"
                          />
                        </div>
                      </div>
                      {selectedFiles.length > 0 && (
                        <>
                          {selectedFiles.map((file, index) => (
                            <li key={index} className="CN2-Banking-List-Div">
                              <FolderIcon
                                sx={{ color: "orange", marginRight: "5px" }}
                              />
                              {file.name}

                              <DeleteIcon
                                sx={{
                                  color: "red",
                                  marginLeft: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleRemoveFile(index)}
                              />
                            </li>
                          ))}
                        </>
                      )}
                      <button
                        type="button"
                        className="CN2-Banking-Button"
                        disabled={!isButtonEnabled}
                        onClick={handleUpload}
                        style={
                          !isButtonEnabled
                            ? { backgroundColor: "lightgray", color: "gray" }
                            : {
                                backgroundColor: "var(--color-darkgreen)",
                                color: "white",
                              }
                        }
                      >
                        Submit
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default Banking;
