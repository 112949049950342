import React, { useEffect, useState } from "react";
import Header from "../Front-Page-Components/Header/Header";
import Checktest from "./Checktest";
import CACS from "../../Images/Assets/ca.png";
import Doctor from "../../Images/Assets/Professional.png";
import Other from "../../Images/Assets/otherdegree.png";
import Own from "../../Images/Assets/ownsetup.png";
import Consultancy from "../../Images/Assets/consultancy.png";
import Both from "../../Images/Assets/both.png";
// import Background from "../../Images/Assets/blob.svg";
import ProfessionImg from "../../Images/Page-Img/Profession details.png";
import { useNavigate } from "react-router-dom";
import serverIP from "../../config/config";
import axios from "axios";
import Animation1 from "../../Animation/animation";
import "./CNProfession.css";
import StepsFooter from "../Front-Page-Components/Footer/StepsFooter";
import StatusBar from "../../Status-Bar/StatusBar";
import FAQ from "../FAQs/FAQ";

function Profession() {
  const [registration, setregistration] = useState("");
  const [prof, setProf] = useState("");
  const [workin, setWorkIn] = useState("");
  const [gr1, setGr1] = useState("");
  const [gr2, setGr2] = useState("");
  const [error, setError] = useState("");
  const [state, setState] = useState("");
  const [loan, setLoan] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const allStates = [
    "New Delhi",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  useEffect(() => {
    const storedData =
      JSON.parse(localStorage.getItem("professionFormData")) || {};
    setregistration(storedData.registration || "");
    setProf(storedData.prof || "");
    setWorkIn(storedData.workin || "");
    setGr1(storedData.gr1 || "");
    setGr2(storedData.gr2 || "");
    setState(storedData.state || "");
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("AskFormData")) || {};
    setLoan(storedData.loan);
  }, []);
  const history = useNavigate();

  const handleProfChange = (e) => {
    setProf(e.target.value);
    setError("");
    // console.log(e.target.value);
  };
  const handleWorkChange = (e) => {
    setWorkIn(e.target.value);
    setError("");
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
    setError("");
  };
  const handleGr2Change = (e) => {
    setError("");
    const value =
      e.target.type === "range"
        ? parseFloat(e.target.value)
        : parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
    setGr2(isNaN(value) ? 0 : value);
  };
  const handleGr1Change = (e) => {
    setError("");
    const value =
      e.target.type === "range"
        ? parseFloat(e.target.value)
        : parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
    setGr1(isNaN(value) ? 0 : value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    localStorage.setItem(
      "professionFormData",
      JSON.stringify({
        registration,
        prof,
        workin,
        gr1,
        gr2,
        state,
      })
    );

    if (prof === "") {
      setError("Choose Profession");
      return;
    }
    if (prof === "doctor") {
      if (state === "") {
        setError("Select Your Registration State");
        return;
      }
    }
    if (registration === "") {
      setError("Enter Registration Number");
      return;
    }
    if (workin === "") {
      setError("Choose Serve Type");
      return;
    }
    if (gr1 === "") {
      setError("Enter Gross Receipt in FY 22-23");
      return;
    }
    if (gr1 > "500000000") {
      setError("Enter Valid Gross Receipt in FY 22-23");
      return;
    }
    if (gr1 < "200000") {
      setError("Minimum 2 Lakhs gross receipt required");
      return;
    }
    try {
      // Get the access token from localStorage
      const token = localStorage.getItem("accessToken");
      const userId = localStorage.getItem("userId");

      // Make sure the token exists before proceeding
      if (!token) {
        console.error("Access token not found.");
        return;
      }

      // Set the Authorization header with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Create the data object in the desired format
      const data = {
        profession_type: prof,
        state: state,
        registration: registration,
        serve_type: workin,
        gross_receipt_yearminus2: parseInt(gr2),
        gross_receipt_yearminus1: parseInt(gr1),
      };
      setIsLoading(true);
      // Send a POST request to the server with the selected data
      const response = await axios.post(
        `${serverIP}/user/seprofessional/${userId}`,
        data,
        config
      );
      console.log(response);
      if ((response.status = 200 || response.status < 300)) {
        console.log("Profession Details Updated");
        if (loan === "property purchase" || loan === "loan against property") {
          history("/asset");
        } else {
          history("/offerstandby");
        }
      } else {
        console.log("Error in Post");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error inserting data:", error);
      setIsLoading(false);

      // Handle form submission error
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header />

      <div className="CN2-Profession">
        <div className="CN2-Profession-Container">
          <div className="CN2-Profession-Main-Div">
            <div className="CN2-Profession-Main-Div-Left">
              <img
                src={ProfessionImg}
                alt="Profile with pan and aadhaar"
                className="CN2-Profession-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-yellow)"
                KycColor="white"
                IncomeColor="white"
                OffersColor="white"
              />
            </div>
            <div className="CN2-Profession-Main-Div-Right">
              <h1>Profession Details</h1>
              <div className="CN2-Profession-Faq-Div">
                <FAQ PageName="profession" />
              </div>
              {isLoading ? (
                <>
                  <Animation1 />
                </>
              ) : (
                <>
                  <form onSubmit={handleSubmit}>
                    {error && (
                      <div className="CN2-Profession-Error">{error}</div>
                    )}
                    <div className="CN2-Profession-Checkbox-Div">
                      Choose Profession
                    </div>
                    <div className="CN2-Profession-Checkbox-Div-Select">
                      <Checktest
                        name="CA / CS"
                        src={CACS}
                        width="35px"
                        value="ca"
                        onChange={handleProfChange}
                        checked={prof === "ca"}
                        backgroundColor={prof === "ca" && "#dcf7e4"}
                        fontWeight={prof === "ca" && "bolder"}
                      />
                      <Checktest
                        name="Doctor"
                        src={Doctor}
                        width="35px"
                        value="doctor"
                        onChange={handleProfChange}
                        checked={prof === "doctor"}
                        backgroundColor={prof === "doctor" && "#dcf7e4"}
                        fontWeight={prof === "doctor" && "bolder"}
                      />
                      <Checktest
                        name="Other"
                        src={Other}
                        width="35px"
                        value="other"
                        onChange={handleProfChange}
                        checked={prof === "other"}
                        backgroundColor={prof === "other" && "#dcf7e4"}
                        fontWeight={prof === "other" && "bolder"}
                      />
                    </div>
                    {prof === "doctor" ? (
                      <div className="CN2-Profession-Checkbox-Div">
                        <label>Select State</label>
                        <select
                          value={state}
                          onChange={handleStateChange}
                          className="CN2-Profession-Input"
                        >
                          <option value="">Select a state</option>
                          {allStates.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : (
                      ""
                    )}
                    {prof && (
                      <div className="CN2-Profession-Checkbox-Div">
                        <label>
                          Enter{" "}
                          {prof === "ca"
                            ? "Membership No."
                            : "Registration No."}
                        </label>

                        <div>
                          <input
                            type="text"
                            id="Registration number"
                            value={registration}
                            onChange={(e) => setregistration(e.target.value)}
                            maxLength={25}
                            className="CN2-Profession-Input"
                          />
                        </div>
                      </div>
                    )}
                    <div className="CN2-Profession-Checkbox-Div">
                      Serve in :
                    </div>
                    <div className="CN2-Profession-Checkbox-Div-Select">
                      <Checktest
                        name="Own Setup"
                        src={Own}
                        width="35px"
                        value="own setup"
                        onChange={handleWorkChange}
                        checked={workin === "own setup"}
                        backgroundColor={workin === "own setup" && "#dcf7e4"}
                        fontWeight={workin === "own setup" && "bolder"}
                      />
                      <Checktest
                        name="Consultancy"
                        src={Consultancy}
                        width="35px"
                        value="consultancy"
                        onChange={handleWorkChange}
                        checked={workin === "consultancy"}
                        backgroundColor={workin === "consultancy" && "#dcf7e4"}
                        fontWeight={workin === "consultancy" && "bolder"}
                      />
                      <Checktest
                        name={
                          <>
                            <br />
                            Both
                          </>
                        }
                        src={Both}
                        width="30px"
                        value="both"
                        onChange={handleWorkChange}
                        checked={workin === "both"}
                        backgroundColor={workin === "both" && "#dcf7e4"}
                        fontWeight={workin === "both" && "bolder"}
                      />
                    </div>
                    {workin && (
                      <div className="CN2-Profession-Checkbox-Div">
                        <label>Gross Receipts</label>
                        <div className="CN2-Profession-Checkbox-Div-Select">
                          <div>
                            <div>FY 22-23</div>
                            <input
                              id="Gross Receipt FY 22-23"
                              value={gr1.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                                maximumFractionDigits: "0",
                              })}
                              onChange={handleGr1Change}
                              type="tel"
                              className="CN2-Profession-SmallInput"
                              maxLength={13}
                              required
                            />
                          </div>
                          <div>
                            <div>FY 21-22</div>
                            <input
                              id="Gross Receipt FY 21-22"
                              value={gr2.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                                maximumFractionDigits: "0",
                              })}
                              onChange={handleGr2Change}
                              type="tel"
                              maxLength={13}
                              className="CN2-Profession-SmallInput"
                              placeholder="(Optional)"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button className="CN2-Profession-Button">Submit</button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default Profession;
