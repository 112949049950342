import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Aboutus.css";
import Imgforaboutus from "../../../Images/Assets/About-Page-Img.png";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

function Aboutus() {
  const history = useNavigate();

  const handleProducts = () => {
    history("/");
    setTimeout(() => {
      const partnersSection = document.getElementById("Partners");
      if (partnersSection) {
        partnersSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000);
  };
  return (
    <>
      <Helmet>
        <title>About Creditnama</title>
        <meta
          name="description"
          content="At Creditnama, we believe a society prospers when it rewards its good actors and gives them the means to grow. We focus on efforts on empowering such change makers, individuals as well as businesses, by getting them access to credit, effectively, efficiently, and in as real-time as possible."
        />
        <link rel="canonical" href="/about" />
      </Helmet>
      <Header />

      <div className="CN2-Aboutus-Page">
        <div className="CN2-Aboutus-Page-Div1">
          <h1 className="CN2-Aboutus-Page-Heading">About Creditnama</h1>
          <div className="CN2-Aboutus-Page-Div1-Container">
            <h2 className="CN2-Aboutus-Page-Div1-Container-Subhead">
              Our Goal
            </h2>
            <h1>
              A credit-empowered India, <br />
              swift in execution,
              <br /> grounded in Realty
            </h1>
            <img
              src={Imgforaboutus}
              alt="A Developed India"
              className="CN2-Aboutus-Page-Div1-Image"
              loading="lazy"
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>
        </div>

        <div className="CN2-Aboutus-Page-Div2 ">
          <div className="CN2-Aboutus-Page-Div2-Container">
            <h2 className="CN2-Aboutus-Page-Div2-Container-Subhead">
              Why we do?
            </h2>
            <div className="CN2-Aboutus-Page-Div2-Container-Subhead-Text">
              At Creditnama, we believe a society prospers when it rewards its
              good actors and gives them the means to grow. We focus on efforts
              on empowering such change makers, individuals as well as
              businesses, by getting them access to credit, effectively,
              efficiently, and in as real-time as possible.
              <br />
              <br />
              <b>
                “I would rather wait until tomorrow to get credit”, said no
                customer ever.
              </b>
            </div>
            <div className="CN2-Aboutus-Page-Div2-Container-Focus-Div">
              <h1>10%</h1>of Indian population that has access to formal credit
            </div>
            <div className="CN2-Aboutus-Page-Div2-Container-Subhead-Text">
              India is a credit-constrained society, where the credit gap in the
              MSME sector alone amounts to a staggering ₹25 trillion. Credit is
              limited, yet there are always more seekers. <br />
              <br />
              Creditnama addresses this gap by partnering with 40+ financial
              institutions to deliver credit judiciously to those who can
              leverage it effectively to grow themselves and prioritize repaying
              their lenders.
            </div>
          </div>
        </div>
        <div className="CN2-Aboutus-Page-Div3 ">
          <div className="CN2-Aboutus-Page-Div2-Container">
            <h2 className="CN2-Aboutus-Page-Div2-Container-Subhead">
              What we do?
            </h2>
            <div className="CN2-Aboutus-Page-Div2-Container-Subhead-Text">
              At Creditnama, we believe that no country in this world can
              develop without establishing a vibrant and transparent real estate
              ecosystem. India took a bold step in this direction with the Real
              Estate (Regulation and Development) Act, of 2016. This act paved
              the way for increased transparency, accountability, and consumer
              protection in the real estate sector.
              <br />
              <br />
              The next step in adding to the vibrancy of this ecosystem is to
              address the credit need in real estate. At Creditnama, we fulfill
              this need by partnering with creditworthy actors throughout their
              property lifecycle.
            </div>
            <div className="CN2-Aboutus-Page-Div2-Container-Focus-Div">
              <h1>40+</h1>Parameters accounted in Creditnama’s proprietary
              Artificial Intelligence engine to optimally rank lenders.
            </div>
            <div className="CN2-Aboutus-Page-Div2-Container-Subhead-Text">
              Our proprietary Artificial Intelligence-driven engine simplifies
              the process, matching borrowers with lenders tailored to their
              individual needs. From home loans to unlocking real estate
              appreciation through loans against property, Creditnama
              facilitates access to affordable financing for personal and
              business endeavors, ensuring individuals make sound financial
              choices without the hassle of visiting multiple lenders.
            </div>
          </div>
        </div>
        <div className="CN2-Aboutus-Page-Div4">
          <div
            className="CN2-Aboutus-Page-Redirection-div"
            style={{ backgroundColor: "var(--color-white)" }}
          >
            <div>Click here to learn more about the team behind it</div>
            <div>
              <button>
                <a href="/team">Team</a>
              </button>
            </div>
          </div>
          <div
            className="CN2-Aboutus-Page-Redirection-div"
            style={{ backgroundColor: "var(--color-lightgreen)" }}
          >
            <div>
              Does the thought of collaborating with smart folks to shape the
              future of digital lending excite you? Share your profile with us
              and join our growing team, your career’s best move awaits you!
            </div>
            <div>
              <button>
                <a href="/careers">Join us</a>
              </button>
            </div>
          </div>
          <div
            className="CN2-Aboutus-Page-Redirection-div"
            style={{ backgroundColor: "var(--color-white)" }}
          >
            <div>
              We appreciate your feedback and would love to hear from you.
              Please don’t hesitate to leave a comment, question or thought or
              just to say hi.
            </div>
            <div>
              <button>
                <a href="/contact-us">Let's Talk</a>
              </button>
            </div>
          </div>
          <div
            className="CN2-Aboutus-Page-Redirection-div"
            style={{ backgroundColor: "var(--color-lightgreen)" }}
          >
            <div>Learn more about Creditnama product offerings.</div>
            <div>
              <button onClick={handleProducts}>Products</button>
            </div>
          </div>
          <div
            className="CN2-Aboutus-Page-Redirection-div"
            style={{
              borderBottom: "1px solid #00000040",
              backgroundColor: "var(--color-white)",
            }}
          >
            <div>
              Check our latest thinking as we follow macro trends and micro
              markets.
            </div>
            <div>
              <button>
                <a href="/blogs">Blogs</a>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Aboutus;
