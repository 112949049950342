import React from "react";
import { Pie } from "react-chartjs-2";

function PieChart({ chartData }) {
  const options = {
    plugins: {
      legend: {
        display: true, // Set to true or false to show/hide the legend
        position: "top", // Position of the legend (top, bottom, left, right, etc.)
      },
      tooltip: {
        enabled: true, // Set to true or false to enable/disable tooltips
      },
    },
  };

  return <Pie data={chartData} options={options} />;
}

export default PieChart;
