import React from "react";
import "./details.css";

function Details() {
  return (
    <>
      <div
        style={{ backgroundColor: "var(--color-darkgreen)" }}
        className="paras-cnn-row paras-cnn-align-items-center"
      >
        <div className="paras-cnn-col-lg-5">
          <div className="paras-cnn-content-box  paras-cnn-content-box1">
            <h1 style={{ color: "white" }}>
              EOI started for Low rise luxury floors
            </h1>
            <p>
              With prices starting from 2.2 crores and an attractive payment
              plan of 40:30:20:10, this project stands out in Sector 59 for its
              luxurious yet affordable living options. Spanning 6.225 acres, the
              development offers ample space for a modern lifestyle, complete
              with a clubhouse covering 25,000 to 30,000 sqft. The project
              caters to the diverse needs of its residents, with flexible
              payment options and state-of-the-art amenities.
            </p>
            <p>
              Whether you seek space or luxury, these apartments provide both,
              ensuring that homeowners enjoy a premium lifestyle in a prime
              location close to essential services and the Grand Hyatt Hotel.
            </p>
            <div className="paras-details-download-brochure">
              <div
                onClick={() => {
                  window.open(
                    "https://wa.me/+918750064900?text=Hey, I would like to know more about Paras sec 59"
                  );
                }}
                className="paras-details-download-brochure-btn"
              >
                Download Brochure
              </div>
              <div
                onClick={() => {
                  window.open(
                    "https://wa.me/+918750064900?text=Hey, I would like to know more about Paras sec 59"
                  );
                }}
                className="paras-details-download-brochure-btn"
              >
                Schedule Meeting
              </div>
            </div>
          </div>
        </div>
        <div className="paras-cnn-col-lg-7">
          <div>
            <figure>
              <div className="paras-cnn-holder1">
                <img
                  src="https://www.dlf.in/homes/luxury/privanasouth/img/fifth-banner.jpg"
                  alt="Paras sector 59"
                />
              </div>
            </figure>
          </div>
        </div>
      </div>
    </>
  );
}

export default Details;
