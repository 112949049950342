import React, { useEffect, useState } from "react";
import Header from "../User-Components/Front-Page-Components/Header/Header";
import "./CNAsset.css";
import AssetImg from "../Images/Page-Img/Property details.png";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import serverIP from "../config/config";
import Animation1 from "../Animation/animation";
import StatusBar from "../Status-Bar/StatusBar";
import StepsFooter from "../User-Components/Front-Page-Components/Footer/StepsFooter";
import FAQ from "../User-Components/FAQs/FAQ";

function Asset() {
  const [assettype, setAssetType] = useState("");
  const [assetname, setAssetName] = useState("");
  const [assetAddress, setAssetAddress] = useState("");
  const [assetPincode, setAssetPincode] = useState("");
  const [assetAuthorized, setAssetAuthorized] = useState("");
  const [assetArea, setAssetArea] = useState("");
  const [areaType, setAreaType] = useState("");
  const [assetValue, setAssetValue] = useState();
  const [assetStatus, setAssetStatus] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const history = useNavigate();

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("AssetFormData")) || {};
    setAssetType(storedData.assettype || "");
    setAssetName(storedData.assetname || "");
    setAssetAddress(storedData.assetAddress || "");
    setAssetPincode(storedData.assetPincode || "");
    setAssetAuthorized(storedData.assetAuthorized || "");
    setAssetArea(storedData.assetArea || "");
    setAreaType(storedData.areaType || "");
    setAssetValue(storedData.assetValue || "");
    setAssetStatus(storedData.assetStatus || "");
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("AskFormData")) || {};
    setPropertyType(storedData.propertyType);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Save form data to localStorage on successful submission
    localStorage.setItem(
      "AssetFormData",
      JSON.stringify({
        assettype,
        assetname,
        assetAddress,
        assetPincode,
        assetAuthorized,
        assetArea,
        areaType,
        assetValue,
        assetStatus,
      })
    );
    if (assettype === "") {
      setError("Choose Property Type");
      return;
    }
    if (assetname === "") {
      setError("Enter Property Name/Address");
      return;
    }

    if (assetPincode === "") {
      setError("Enter Pincode");
      return;
    }
    if (assetAuthorized === "") {
      setError("Select Authorized Tag");
      return;
    }
    if (assetArea === "") {
      setError("Enter Property Area");
      return;
    }
    if (areaType === "") {
      setError("Select Area Tag");
      return;
    }
    if (assetValue === "") {
      setError("Enter Estimated Value");
      return;
    }

    if (assetStatus === "") {
      setError("Choose Property Status");
      return;
    }
    if (assetValue < "2000000") {
      setError("Minimum 20 Lakh Property Value Required");
      return;
    }
    try {
      // Get the access token from localStorage
      const token = localStorage.getItem("accessToken");
      const askId = localStorage.getItem("askId");

      // Make sure the token exists before proceeding
      if (!token) {
        console.error("Access token not found.");
        return;
      }

      // Set the Authorization header with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Create the data object in the desired format
      const data = {
        asset_type: assettype,
        asset_address1: assetname,
        asset_address2: assetAddress,
        pincode: parseInt(assetPincode),
        asset_area: assetArea,
        asset_area_units: areaType,
        assetValue: assetValue,
        authorized_flag: Boolean(assetAuthorized),
        current_status: assetStatus,
      };
      setIsLoading(assetValue);

      console.log(data);

      // Send a POST request to the server with the selected data
      const response = await Axios.post(
        `${serverIP}/asset/createAsset/${askId}`,
        data,
        config
      );

      console.log(response);
      if (response.status === 200 || response.status <= 300) {
        console.log("Asset Details Updated");
        history("/offerstandby");
      } else {
        console.log("Error in Post");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error inserting data:", error);
      setIsLoading(false);

      // Handle form submission error
    } finally {
      setIsLoading(false);
    }
  };

  const handlePropertyAuth = (e) => {
    setError("");
    setAssetAuthorized(e.target.value);
  };

  const handleAssetValue = (e) => {
    setError("");
    const value = parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
    setAssetValue(isNaN(value) ? 0 : value);
  };

  const handleAssetArea = (e) => {
    setError("");
    const value =
      e.target.type === "range"
        ? parseFloat(e.target.value)
        : parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
    setAssetArea(isNaN(value) ? 0 : value);
  };
  const handleAreaType = (e) => {
    setError("");
    setAreaType(e.target.value);
  };
  const handleAssetName = (e) => {
    setError("");
    setAssetName(e.target.value);
  };

  const handleAssetPincode = (e) => {
    const input = e.target.value;
    // Regular expression to match only numbers
    const numericRegex = /^[0-9]*$/;

    if (numericRegex.test(input)) {
      setError("");
      setAssetPincode(input);
    } else {
      setError("Please enter only numbers.");
    }
  };

  const handleAssetStatus = (e) => {
    setError("");
    setAssetStatus(e.target.value);
  };

  return (
    <>
      <Header />

      <div className="CN2-Asset">
        <div className="CN2-Asset-Container">
          <div className="CN2-Asset-Main-Div">
            <div className="CN2-Asset-Main-Div-Left">
              <img
                src={AssetImg}
                alt="Property Details"
                className="CN2-Asset-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-yellow)"
                KycColor="white"
                IncomeColor="white"
                OffersColor="white"
              />
            </div>
            <div className="CN2-Asset-Main-Div-Right">
              <h1>Property Details</h1>
              <div className="CN2-Asset-Faq-Div">
                <FAQ PageName="asset" />
              </div>
              {isLoading ? (
                <>
                  <Animation1 />
                </>
              ) : (
                <>
                  <form onSubmit={handleSubmit}>
                    {error && <div className="CN2-Asset-Error">{error}</div>}
                    <div>
                      <label>Property Type</label>
                      <br />

                      <select
                        value={assettype}
                        onChange={(e) => setAssetType(e.target.value)}
                        className="CN2-Asset-Input"
                      >
                        {propertyType === "residential" ? (
                          <>
                            <option value="">Choose...</option>

                            <option value="flat/apartment">
                              Flat/Apartment
                            </option>
                            <option value="builder floor">Builder floor</option>
                            <option value="kothi/townhouse/villa">
                              Kothi/Townhouse/Villa
                            </option>
                            <option value="farmhouse">Farmhouse</option>
                            <option value="other">Other</option>
                          </>
                        ) : (
                          <>
                            <option value="">Choose...</option>
                            <option value="office space">Office Space</option>
                            <option value="shop/showroom">Shop/Showroom</option>
                            <option value="commercial land">
                              Commercial Land
                            </option>
                            <option value="warehouse/godown">
                              Warehouse/Godown
                            </option>
                            <option value="industrial building">
                              Industrial Building
                            </option>
                            <option value="industrial shed">
                              Industrial Shed
                            </option>
                            <option value="agriculture land">
                              Agriculture Land
                            </option>
                            <option value="other">Other</option>
                          </>
                        )}
                      </select>
                    </div>
                    <div>
                      <label>Property Name/Address</label>
                      <br />
                      <input
                        value={assetname}
                        onChange={handleAssetName}
                        className="CN2-Asset-Input"
                      />
                    </div>
                    <div>
                      <label>Property Address 2</label>
                      <br />

                      <input
                        value={assetAddress}
                        type="text"
                        id="Asset Address"
                        onChange={(e) => setAssetAddress(e.target.value)}
                        className="CN2-Asset-Input"
                        placeholder="(Optional)"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        gap: "20px",
                      }}
                    >
                      <div>
                        <label>Property Pincode</label>
                        <br />

                        <input
                          value={assetPincode}
                          type="tel"
                          id="Asset Name"
                          onChange={handleAssetPincode}
                          className="CN2-Asset-SmallInput"
                          maxLength={6}
                        />
                      </div>
                      <div>
                        <label>Authorized ?</label>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          <label className="AuthPropertyTypeContainer">
                            Yes
                            <input
                              type="checkbox"
                              value="true"
                              onChange={handlePropertyAuth}
                              checked={assetAuthorized === "true"}
                            />
                            <div className="Authcheckmark"></div>
                          </label>
                          <label className="AuthPropertyTypeContainer">
                            No
                            <input
                              value="false"
                              type="checkbox"
                              onChange={handlePropertyAuth}
                              checked={assetAuthorized === "false"}
                            />
                            <div className="Authcheckmark"></div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label>Area</label>
                      <br />
                      <input
                        value={
                          isNaN(assetArea)
                            ? ""
                            : assetArea.toLocaleString("en-IN", {
                                maximumFractionDigits: 0,
                              })
                        }
                        type="tel"
                        id="Asset Area"
                        onChange={handleAssetArea}
                        className="CN2-Asset-SmallInput"
                        maxLength={7}
                      />
                      <select
                        className="CN2-Asset-SmallInput mx-2"
                        value={areaType}
                        onChange={handleAreaType}
                      >
                        <option value="">Choose</option>
                        <option value="sqft">sqft</option>
                        <option value="sqm">sqm</option>
                        <option value="sqyrd">sqyrd</option>
                        <option value="acre">acre</option>
                        <option value="gaj">gaj</option>
                      </select>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div>
                        <label>Estimated Value</label>
                        <br />
                        <input
                          type="tel"
                          id="Asset Value"
                          value={
                            isNaN(assetValue)
                              ? ""
                              : "₹ " +
                                assetValue.toLocaleString("en-IN", {
                                  maximumFractionDigits: 0,
                                })
                          }
                          onChange={handleAssetValue}
                          className="CN2-Asset-SmallInput"
                          maxLength={16}
                        />
                      </div>
                      <div
                        style={{
                          marginTop: "30px",
                          marginLeft: "15px",
                          fontSize: "15px",
                        }}
                      >
                        {assetValue < 10000000 && (
                          <>
                            {assetValue >= 100000 ? (
                              <>₹{(assetValue / 100000).toFixed(1)} lacs</>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                        {assetValue >= 10000000 ? (
                          <>₹{(assetValue / 10000000).toFixed(1)} cr</>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div>
                      <label>Current Status</label>
                      <br />

                      <select
                        value={assetStatus}
                        onChange={handleAssetStatus}
                        className="CN2-Asset-Input"
                      >
                        <option value="">Choose...</option>
                        <option value="fully built">Fully built</option>
                        <option value="under development">
                          Under development
                        </option>
                        <option value="vacant">Open Plot</option>
                      </select>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button className="CN2-Asset-Button">Submit</button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default Asset;
