import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CNUploadBanking.css";
import UploadBankingImg from "../../Images/Page-Img/Bank Statement.png";
import StatementUpload from "../../Images/Assets/statementuploadicon@2x.png";
import SalaryBankImg from "../../Images/Assets/salarybankimg@2x.png";
import Animation1 from "../../Animation/animation";
import FolderIcon from "@mui/icons-material/Folder";
import { useEffect } from "react";
import Header from "../../User-Components/Front-Page-Components/Header/Header";
import StatusBar from "../../Status-Bar/StatusBar";
import StepsFooter from "../../User-Components/Front-Page-Components/Footer/StepsFooter";
import DeleteIcon from "@mui/icons-material/Delete";

function UploadBanking() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");

  const history = useNavigate();

  useEffect(() => {
    // Update the current date every second
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);
  // Calculate the current month and the previous two months
  const currentMonth = currentDate.getMonth(); // Get the current month (0-11)
  const currentYear = currentDate.getFullYear(); // Get the current year

  const currentMonthDate = new Date(currentYear, currentMonth - 7, 1);

  const formattedCurrentMonth = currentMonthDate.toLocaleDateString("en-US", {
    month: "short",
    year: "2-digit",
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if file is not null
      if (selectedFiles.length < 2) {
        setSelectedFiles([...selectedFiles, file]);
        setError("");
      } else {
        setError("You can only upload up to 2 files.");
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    history("/banking", { state: { selectedFiles, password } });
    setIsLoading(false);
  };
  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const isButtonEnabled = selectedFiles.length > 0;

  return (
    <>
      <Header />

      <div className="CN2-UploadBanking">
        <div className="CN2-UploadBanking-Container">
          <div className="CN2-UploadBanking-Main-Div">
            <div className="CN2-UploadBanking-Main-Div-Left">
              <img
                src={UploadBankingImg}
                alt="Upload UploadBanking"
                className="CN2-UploadBanking-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-success)"
                KycColor="var(--color-success)"
                IncomeColor="var(--color-yellow)"
                OffersColor="var(--color-success)"
              />
            </div>
            <div className="CN2-UploadBanking-Main-Div-Right">
              <h1>Upload bank statement</h1>
              {error && <div className="CN2-UploadBanking-Error">{error}</div>}

              {isLoading ? (
                <>
                  <Animation1 />
                </>
              ) : (
                <>
                  <form onSubmit={handleSubmit}>
                    Bank name
                    <select
                      className="CN2-UploadBanking-Input"
                      style={{ fontSize: "14px" }}
                      name="BankSelect"
                      required
                    >
                      <option value="">Choose Bank</option>
                      <option value="HDFC">HDFC Bank</option>
                      <option value="ICICI">ICICI Bank</option>
                      <option value="Axis">Axis Bank</option>
                      <option value="SBI">State Bank of India</option>
                      <option value="IDFC First">IDFC First Bank</option>
                      <option value="Yes">Yes Bank</option>
                      <option value="IndusInd">IndusInd Bank</option>
                      <option value="Federal">Federal Bank</option>
                      <option value="Bandhan">Bandhan Bank</option>
                      <option value="RBL">RBL Bank</option>
                      <option value="IDBI">IDBI Bank</option>
                      <option value="PNB">Punjab National Bank</option>
                      <option value="BOB">Bank of Baroda</option>
                      <option value="Canara">Canara Bank</option>
                      <option value="Union">Union Bank of India</option>
                      <option value="BOI">Bank of India</option>
                      <option value="Central">Central Bank of India</option>
                      <option value="Indian">Indian Bank</option>
                      <option value="Maharashtra">Bank of Maharashtra</option>
                      <option value="PunjabSind">Punjab & Sind Bank</option>
                      <option value="Other">Other</option>
                    </select>
                    <div style={{ marginTop: "20px" }}>
                      Period 1st {formattedCurrentMonth} to current date
                    </div>
                    <label
                      htmlFor="fileInput"
                      className="CN2-UploadBanking-Bank-Div"
                    >
                      <input
                        type="file"
                        accept=".pdf, .xlsx, .xls"
                        style={{ display: "none" }}
                        id="fileInput"
                        name="Uploadedbanking"
                        onChange={handleFileChange}
                      />
                      <div>
                        <img
                          src={SalaryBankImg}
                          alt="Verify Pan"
                          className="CN2-Banking-Bank-Icon"
                        />
                      </div>
                      <div>Click to upload</div>
                      <div>
                        <img
                          src={StatementUpload}
                          alt="Verify Pan"
                          className="CN2-UploadBanking-Bank-Icon"
                        />
                      </div>
                    </label>
                    {selectedFiles.map((file, index) => (
                      <div key={index} className="CN2-UploadBanking-List-Div">
                        <FolderIcon
                          style={{
                            color: "orange",
                            marginRight: "5px",
                            cursor: "pointer",
                          }}
                        />
                        {file?.name} {/* Add optional chaining here */}
                        <DeleteIcon
                          onClick={() => handleRemoveFile(index)}
                          style={{
                            color: "red",
                            marginLeft: "5px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ))}
                    <div style={{ marginTop: "10px" }}>
                      File password (optional)
                    </div>
                    <input
                      className="CN2-UploadBanking-Input"
                      name="PasswordInput"
                      placeholder="Enter password"
                      type="text"
                      onChange={(e) => setPassword(e.target.value)}
                      maxLength={25}
                    />
                    <button
                      className="CN2-UploadBanking-Button"
                      disabled={!isButtonEnabled}
                      style={
                        !isButtonEnabled ? { backgroundColor: "lightgray" } : {}
                      }
                    >
                      Proceed
                    </button>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default UploadBanking;
