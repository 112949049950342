import React from "react";
import "./Loading.css";

function Loading() {
  return (
    <section
      style={{ display: "flex", marginTop: "10px", marginLeft: "10px" }}
      className="Ai-Ai-dots-container"
    >
      <div className="Ai-dot"></div>
      <div className="Ai-dot"></div>
      <div className="Ai-dot"></div>
      <div className="Ai-dot"></div>
      <div className="Ai-dot"></div>
    </section>
  );
}

export default Loading;
