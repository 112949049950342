import React, { useEffect, useState } from "react";
import "./TrackerSummary.css";
import Creditnama from "../Images/CNLogo.png";
import serverIP from "../config/config";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useNavigate } from "react-router-dom";

const TrackerSummary = () => {
  const [data, setData] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("iToken");

    if (!token) {
      history("/thekedaar/login");
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${serverIP}/ask/thekedaar/summary`,
          config
        );

        if (response.status >= 200 && response.status < 300) {
          const data = await response.json();
          console.log(data);
          setData(data);
        } else {
          console.log("Data not found");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [history]);

  const handleGo = (askId) => {
    localStorage.setItem("askId", askId);
    history("/thekedaar/details");
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  };
  return (
    <div className="CN-TrackerSummary-Page">
      <div className="CN-TrackerSummary-Logo-Div">
        <img
          src={Creditnama}
          alt="Creditnama"
          className="CN-TrackerSummary-Img"
        />
      </div>
      <h1>Current Pipeline</h1>
      <table>
        <thead>
          <tr>
            <th>Ask ID</th>
            <th>Product</th>
            <th>Last stage</th>
            <th>Last substage</th>
            <th>Last step</th>
            <th>Last updated</th>
            <th>Owner</th>
            <th>Add an update</th>
          </tr>
        </thead>
        <tbody>
          {data.map((value) => (
            <tr key={value.askId}>
              <td>{value.askId}</td>
              <td>{value.product}</td>
              <td>{value.lastStatus}</td>
              <td>{value.lastSubStatus}</td>
              <td>{value.lastStep}</td>
              <td>{formatDate(value.lastUpdated)}</td>
              <td>{value.owner}</td>
              <td>
                <PlayArrowIcon
                  onClick={() => handleGo(value.askId)}
                  fontSize="large"
                  style={{ cursor: "pointer" }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TrackerSummary;
