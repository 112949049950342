import React, { useState } from "react";
import Header from "../../User-Components/Front-Page-Components/Header/Header";
import "./Contact.css";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import BusinessIcon from "@mui/icons-material/Business";
import Linkdin from "../../Images/Assets/Linkdin.png";
import FB from "../../Images/Assets/FB.png";
import Insta from "../../Images/Assets/Insta.png";
import Mail from "../../Images/Assets/Mail.png";
import Whatsapp from "../../Images/Assets/WA.png";
import serverIP from "../../config/config";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import StepsFooter from "./Footer/StepsFooter";
import { Helmet } from "react-helmet-async";

const ContactPage = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [response, setResponse] = useState(false);
  const history = useNavigate();

  const handlePhoneInput = (e) => {
    e.preventDefault();
    setPhone(e.target.value);
    setError("");
  };

  const handleGotit = () => {
    history("/");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (phone.length !== 10 || !/[6-9]/.test(phone[0])) {
      setError("Enter Correct Mobile Number");
      return;
    }

    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const latitude = position.coords.latitude.toFixed(15);
          const longitude = position.coords.longitude.toFixed(15);

          const data = {
            name: firstname + " " + lastname,
            phone: phone,
            email: email,
            subject: subject,
            message: message,
            latitude: latitude,
            longitude: longitude,
          };

          const response = await axios.post(
            `${serverIP}/product/contact-us/`,
            data
          );
          if (response.status >= 200 && response.status < 300) {
            setResponse(true);
            console.log("Asset Details Updated");
            console.log(response);
          } else {
            console.log("Error in Post");
            console.log(response);
          }
        });
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    } catch (error) {
      console.error("Error inserting data:", error);
    }
  };

  const redirectToSocialMedia = (socialMedia) => {
    // Define the URLs for each social media platform
    const socialMediaUrls = {
      Linkdin: "https://www.linkedin.com/company/creditnama/",
      FB: "https://www.facebook.com/creditnama",
      Insta: "https://www.instagram.com/creditnama/",
      Mail: "mailto:info@creditnama.com",
      Whatsapp: "https://wa.me/+918750072800?text=Hi",
    };

    // Redirect to the selected social media page
    window.location.href = socialMediaUrls[socialMedia];
  };

  return (
    <>
      <Helmet>
        <title>Contact Creditnama</title>
        <meta
          name="description"
          content="Any questions or remarks? Just write us a message!"
        />
        <link rel="canonical" href="/contact-us" />
      </Helmet>
      <Header />

      <div className="CN-Contact-Us-Page">
        <div className="CN-Contact-Us-Container">
          <div className="CN-Contact-Us-Content">
            <h1>Let's talk</h1>
            <p>Any questions or remarks? Just write us a message!</p>
          </div>
          <div className="CN-Contact-Us-Div">
            <div className="CN-Contact-us-Style">
              <h1>Mailing address</h1>
              <div className="CN-Contact-us-Method">
                <div className="CN-Contact-us-Method-Div">
                  <PhoneIcon />
                  <div>+91 8750072800</div>
                </div>
                <div className="CN-Contact-us-Method-Div">
                  <EmailIcon />
                  <div>hello@creditnama.com</div>
                </div>
                <div className="CN-Contact-us-Method-Div">
                  <BusinessIcon />
                  <div>
                    House No. 406, Ground Floor, Kohat Enclave, Pitampura, New
                    Delhi - 110034
                  </div>
                </div>
              </div>
              <div>
                <div className="Social-acc-header">
                  <div className="CN2-SocialCard">
                    <img
                      src={Linkdin}
                      alt="Linkdin"
                      className="CN2-SocialCard-Img"
                      onClick={() => redirectToSocialMedia(`Linkdin`)}
                    />

                    <img
                      src={FB}
                      alt="FB"
                      className="CN2-SocialCard-Img"
                      onClick={() => redirectToSocialMedia(`FB`)}
                    />
                    <img
                      src={Insta}
                      alt="Insta"
                      className="CN2-SocialCard-Img"
                      onClick={() => redirectToSocialMedia(`Insta`)}
                    />
                    <img
                      src={Mail}
                      alt="Mail"
                      className="CN2-SocialCard-Img"
                      onClick={() => redirectToSocialMedia(`Mail`)}
                    />
                    <img
                      src={Whatsapp}
                      alt="Whatsapp"
                      className="CN2-SocialCard-Img"
                      onClick={() => redirectToSocialMedia(`Whatsapp`)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <form className="CN-Contact-us-Form" onSubmit={handleSubmit}>
              {response ? (
                <>
                  <div className="CN-Contact-us-Form-Success">
                    <h1>Thankyou your response has been submited</h1>
                    <button
                      className="CN-Contact-us-Form-Success-Btn"
                      onClick={handleGotit}
                    >
                      Got it
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <h1>Contact us</h1>

                  {error && (
                    <div className="CN-Contact-us-Form-Error">{error}</div>
                  )}
                  <div className="CN-Contact-us-Form-Div">
                    <input
                      className="CN-Contact-us-Form-Input"
                      placeholder="First name"
                      type="text"
                      value={firstname}
                      required
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <input
                      className="CN-Contact-us-Form-Input"
                      placeholder="Last name"
                      type="text"
                      value={lastname}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <div className="CN-Contact-us-Form-Div">
                    <input
                      className="CN-Contact-us-Form-Input"
                      type="tel"
                      maxLength={10}
                      placeholder="Contact number"
                      value={phone}
                      onChange={handlePhoneInput}
                      required
                    />
                    <input
                      className="CN-Contact-us-Form-Input"
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="CN-Contact-us-Form-Subject">
                    <h2>Select Subject</h2>
                    <div className="CN-Contact-us-Form-Subject-Div">
                      <div>
                        <input
                          type="radio"
                          className="CN-Contact-us-Form-Radio"
                          id="general"
                          name="subject"
                          value="General inquiry"
                          onChange={(e) => setSubject(e.target.value)}
                        />
                        <label htmlFor="general">General inquiry</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          className="CN-Contact-us-Form-Radio"
                          id="feedback"
                          name="subject"
                          value="Feedback"
                          onChange={(e) => setSubject(e.target.value)}
                        />
                        <label htmlFor="feedback">Feedback</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          className="CN-Contact-us-Form-Radio"
                          id="questions"
                          name="subject"
                          value="Questions"
                          onChange={(e) => setSubject(e.target.value)}
                        />
                        <label htmlFor="questions">Questions</label>
                      </div>
                    </div>
                  </div>
                  <textarea
                    id="message"
                    name="message"
                    className="CN-Contact-us-Form-Textarea"
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <div>
                    <button className="CN-Contact-us-Submit-Button">
                      Submit
                    </button>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
};
export default ContactPage;
