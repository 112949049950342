import React from "react";
import paras from "../Paraslogo.svg";
import "./Navbar.css";
function Navbar() {
  const handleWAClick = () => {
    window.open(
      "https://wa.me/+918750064900?text=Hey, I would like to know more about Paras sec 59"
    );
  };

  return (
    <div className="paras-navbar-parent">
      <img draggable={false} src={paras} alt="Paras Logo" />
      <div
        className="paras-navbar-getadvise"
        onClick={handleWAClick}
        style={{
          color: "white",
          cursor: "pointer",
        }}
      >
        Get Advise
      </div>
    </div>
  );
}

export default Navbar;
