// Import the necessary libraries
import React, { useState } from "react";
import "./FAQ.css";
import SearchIcon from "@mui/icons-material/Search";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import serverIP from "../../config/config";
// import InfoIcon from "@mui/icons-material/Info";

const FAQ = (props) => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const handleGetFaq = async () => {
    try {
      const response = await fetch(
        `${serverIP}/product/get-faqs-page/${props.PageName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const fetchedData = await response.json();

      if (response.ok) {
        setData(fetchedData);
        setOriginalData(fetchedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   const formattedDate = new Date(data.updated_at).toLocaleDateString("en-GB");

  const handleFilter = (e) => {
    const searchText = e.target.value;
    setSearch(searchText);

    if (searchText === "") {
      setData(originalData);
    } else {
      const newFilter = originalData.filter((value) => {
        return value.question.toLowerCase().includes(searchText.toLowerCase());
      });
      setData(newFilter);
    }
  };

  return (
    <>
      <a
        data-bs-toggle="offcanvas"
        href="#offcanvasExample"
        role="button"
        aria-controls="offcanvasExample"
        onClick={handleGetFaq}
        className="CN-Faq-learnmore"
      >
        <span className="CN-Faq-Learn-See">
          Your right to know why we need this
        </span>{" "}
        Learn more
        {/* <span className="CN-Faq-Icon-See">
          <InfoIcon />
        </span> */}
      </a>

      <div
        className="offcanvas offcanvas-end CN-Faq-OffcanvasDiv"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
        // style={{ backgroundColor: "var(--color-lightyellow)" }}
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            {/* Offcanvas */}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body CN-Faq-Offcanvas-Body">
          <div>
            <input
              type="text"
              placeholder="Search questions or topics"
              value={search}
              className="CN-Faq-Offcanvas-Input"
              onChange={handleFilter}
            />
            <SearchIcon className="CN-Faq-Offcanvas-SearchIcon" />
          </div>
          <div className="CN-Faq-Offcanvas-TextContainer">
            <h1>Creditnama FAQ</h1>
            <div className="CN-Faq-Offcanvas-Line"></div>

            {data.map((value) => {
              return (
                <div
                  key={value.id}
                  style={{ width: "100%", textAlign: "left" }}
                  className="my-2"
                >
                  <div className="CN-Faq-Question">
                    <b>{value.question}</b>
                  </div>
                  <div>{value.response}</div>
                </div>
              );
            })}
            <div style={{ marginTop: "50px" }}>
              <ArrowCircleDownIcon
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                fontSize="large"
                style={{ rotate: "90deg", cursor: "pointer" }}
              />
              Back to journey
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
