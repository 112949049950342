import "./Banners.css";
import DesktopBanner1 from "../../../Images/Banners/DesktopHomeLoan.png";
import DesktopBanner2 from "../../../Images/Banners/DesktopMonsoonFiesta.png";
import MobileBanner1 from "../../../Images/Banners/MobileHomeLoan.png";
import MobileBanner2 from "../../../Images/Banners/MobileMonsoonFiesta.png";
import { useState, useEffect } from "react";

function Banners() {
  const Desktop = [DesktopBanner1, DesktopBanner2];
  const Mobile = [MobileBanner1, MobileBanner2];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % Desktop.length);
    }, 5000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <>
      <a className="CN-Banners-Parent" href="https://creditnama.com/home-loan">
        {Desktop.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`Banner ${index + 1}`}
            className="CN-Banners-Images"
            style={{
              opacity: index === currentIndex ? 1 : 0,
              borderRadius: index === currentIndex ? "10px" : "10px",
              transition: "opacity 1s ease-in-out",
              position: index === currentIndex ? "relative" : "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
        ))}
      </a>

      <a
        className="CN-Banners-Mobile-Parent"
        href="https://creditnama.com/home-loan"
      >
        <br />
        {Mobile.map((img, index) => (
          <img
            key={index + 1}
            src={img}
            alt={`Home Loan`}
            className="CN-Banners-Images"
            style={{
              opacity: index === currentIndex ? 1 : 0,
              borderRadius: "10px",
              transition: "opacity 1s ease-in-out",
              position: index === currentIndex ? "relative" : "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
        ))}
      </a>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        {Desktop.map((_, index) => (
          <div
            key={index}
            className={`dot ${
              index === currentIndex ? "active" : ""
            } CN-Banners-Images-dots`}
            onClick={() => handleDotClick(index)}
          ></div>
        ))}
      </div>
    </>
  );
}

export default Banners;
