import React, { useState } from "react";
import TextFormatter from "./Formatter";

function Message({ message, index }) {
  const [up, setUp] = useState("");
  const [down, setDown] = useState("");


  const ThumbsUp = (message, index) => {
    setUp("up");
    setDown("");
    console.log(`User Thumbs Up the Response = ${message.text}`, index);
  };

  const ThumbsDown = (message, index) => {
    setUp("");
    setDown("down");
    console.log(`User Thumbs Down the Response = ${message.text}`, index);
  };

  const upstyle = {
    color: up === "up" ? "green" : "black",
    fontSize: "20px",
  };

  const Downstyle = {
    color: down === "down" ? "red" : "black",
    fontSize: "20px",
  };


  // Check if the message text starts with $extracted$Data$
  if (message.text && message.text.startsWith("$extracted$Data$")) {
    return null; // Don't render this message
  }

  return (
    <>
      <div
        key={index}
        style={!message.text ? { backgroundColor: "transparent" }:{}}
        className={`Ai-chatbox-message ${
          message.role === "user" ? "user-message" : "ai-message"
        }`}
      >
        <div>
          {!message.text ? (
            <img
              draggable={false}
              style={{ width: "200px" }}
              src={message.image}
              alt="Uploaded"
            />
          ) : (
            <TextFormatter text={message.text} />
          )}
        </div>
      </div>
      {message.role === "bot" && (
        <div style={{ display: "flex", gap: "10px" }}>
          <div
            onClick={() => ThumbsUp(message, index)}
            className="Ai-chatbox-thumbs Ai-chatbox-thumbs-green"
          >
            <span style={upstyle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
                className="icon-md-heavy"
                aria-label="Thumbs Up"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M12.132 2.504a1 1 0 0 1 .992-.496l.454.056a4 4 0 0 1 3.327 5.146L16.354 9h.718c2.638 0 4.553 2.508 3.86 5.053l-1.364 5A4 4 0 0 1 15.708 22H6a3 3 0 0 1-3-3v-7a3 3 0 0 1 3-3h2c.26 0 .5-.14.628-.364zM10 20h5.709a2 2 0 0 0 1.93-1.474l1.363-5A2 2 0 0 0 17.072 11H15a1 1 0 0 1-.956-1.294l.95-3.084a2 2 0 0 0-1.462-2.537l-3.168 5.543A2.72 2.72 0 0 1 9 10.81V19a1 1 0 0 0 1 1m-3-9v8c0 .35.06.687.17 1H6a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
          </div>
          <div
            onClick={() => ThumbsDown(message, index)}
            className="Ai-chatbox-thumbs Ai-chatbox-thumbs-red"
          >
            <span style={Downstyle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
                className="icon-md-heavy"
                aria-label="Thumbs Down"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M11.873 21.496a1 1 0 0 1-.992.496l-.454-.056A4 4 0 0 1 7.1 16.79L7.65 15h-.718c-2.637 0-4.553-2.508-3.859-5.052l1.364-5A4 4 0 0 1 8.296 2h9.709a3 3 0 0 1 3 3v7a3 3 0 0 1-3 3h-2c-.26 0-.5.14-.628.364zM14.005 4h-5.71a2 2 0 0 0-1.929 1.474l-1.363 5A2 2 0 0 0 6.933 13h2.072a1 1 0 0 1 .955 1.294l-.949 3.084a2 2 0 0 0 1.462 2.537l3.167-5.543a2.72 2.72 0 0 1 1.364-1.182V5a1 1 0 0 0-1-1m3 9V5c0-.35-.06-.687-.171-1h1.17a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default Message;
