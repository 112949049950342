import React from "react";
import "./aboutus.css";
import Footer from "./Footer/Footer";
import Header from "./header";

const Intern = () => {
  return (
    <>
      <div className="Aboutus-page">
        <Header />
        <div className="Aboutus-content" style={{ top: "80px" }}>
          {" "}
          <div className="job-opening-card">
            <h1
              style={{
                color: "darkgreen",
                fontSize: "20px",
                marginTop: "30px",
              }}
            >
              <b>Founder’s Office Associate (2022/2023 graduates only)</b>{" "}
            </h1>
            <p>
              Please read through thoroughly & only apply if you are a recent
              graduate. This is a full-time, in-person role in Delhi. This role
              is not virtual, online, freelance, or part-time.
            </p>
            <p>
              <strong>Role Type:</strong> In-person, Full-time
            </p>
            <p>
              <strong>Location:</strong> Rajendra Place, Delhi
            </p>
            <p>
              Creditnama is an early-state digital lending company focusing on
              real-time, digital distribution of financial products such as
              loans and credit cards to individuals and institutions. Our core
              team comprises of IITians and CA/CS/MBAs with a combined
              experience of 80+ years in finance and 20+ years in technology.
            </p>
            <p>
              The Founder's Office Associate is a key role at Creditnama,
              directly supporting the founder(s) and playing a crucial part in
              driving the vision, strategy, and overall success.
            </p>

            <h6>
              <b>Role:</b>
            </h6>
            <ol>
              <li>
                Conduct research and prepare briefings on key topics, industry
                trends, and potential opportunities to keep the founder(s)
                well-informed.
              </li>
              <li>
                Cultivate and maintain relationships with key stakeholders,
                including investors, board members, partners, and customers, on
                behalf of the founder(s).
              </li>
              <li>
                Provide comprehensive support, including managing emails,
                drafting correspondence, preparing reports, and organizing
                meetings and events.
              </li>
              <li>
                Collaborate with cross-functional teams to manage and execute
                strategic projects, ensuring timely delivery and high-quality
                outcomes.
              </li>
              <li>
                Assist in the formulation and implementation of business
                strategies, helping to translate high-level goals into
                actionable plans.
              </li>
              <li>
                Undertake special assignments and ad-hoc projects as required,
                ranging from conducting due diligence on potential partnerships
                to assisting with external communications.
              </li>
            </ol>

            <h6>
              <b>Requirements:</b>
            </h6>
            <ul>
              <li>
                Founder mindset – ownership to assigned tasks and zeal to learn
              </li>
              <li>
                Ability to communicate effectively in written and oral format
              </li>
              <li>Any bachelor’s degree</li>
              <li>Ability to understand and tell authentic success stories</li>
              <li>
                Familiarity with project management, collaboration tools, and
                social media
              </li>
            </ul>

            <h6>
              <b>How to Apply:</b>
            </h6>
            <p>
              If you are interested, kindly share your resume at
              info@creditnama.com with the subject line “Founder’s Office: Your
              Full Name” and provide the following details:
            </p>
            <ol>
              <li>Present CTC (Fixed + VP):</li>
              <li>Expected CTC:</li>
              <li>No. of years’ experience:</li>
              <li>Notice Period:</li>
              <li>Reason for Change:</li>
            </ol>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
export default Intern;
