import React, { useEffect, useState } from "react";
import "./Faq.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import serverIP from "../../../config/config";
import { Helmet } from "react-helmet-async";

const Faq = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const res = async () => {
      try {
        const response = await fetch(
          `${serverIP}/product/get-faqs-page/all_faqs`
        );
        const data = await response.json();

        if (response.status === 200 || response.status >= 300) {
          setData(data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    res();
  }, []);

  return (
    <>
      <Helmet>
        <title>FAQs</title>
        <meta name="description" content="Frequently Asked Questions" />
        <link rel="canonical" href="/faqs" />
      </Helmet>
      <Header />

      <div className="CN-Faq-MainPage">
        <div className="CN-Faq-MainPage-Container">
          <h1>Frequently Asked Questions (FAQs)</h1>

          {data.map((value, index) => {
            return (
              <div
                className="accordion CN-Faq-Buttondiv"
                id={`accordionExample${index}`}
                key={value.id}
              >
                <div
                  className="accordion-item"
                  style={{ marginBottom: "5px", width: "100%" }}
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="false"
                      aria-controls={`collapse${index}`}
                    >
                      {value.question}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse"
                    data-bs-parent={`#accordionExample${index}`}
                  >
                    <div
                      className="accordion-body"
                      style={{ color: "var(--color-darkgreen)" }}
                    >
                      {value.response}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
