import React, { useEffect, useState } from "react";
import "./CNPersonal2.css";
import Header from "../Front-Page-Components/Header/Header";
import StatusBar from "../../Status-Bar/StatusBar";
import Personal from "../../Images/Page-Img/Personal Info.png";
import Animation1 from "../../Animation/animation";
import serverIP from "../../config/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import StepsFooter from "../Front-Page-Components/Footer/StepsFooter";

function Personal2() {
  const [pan, setPan] = useState("");
  const [email, setEmail] = useState("");
  const [Error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const history = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        };

        const response = await axios.get(
          `${serverIP}/user/fetchPanifExists/${userId}`,
          config
        );

        if (response.status >= 200 && response.status < 300) {
          console.log(response);
          setPan(response.data.pan || "");
        } else {
          console.error(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the function inside useEffect
    fetchData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Convert the PAN number to uppercase
    const upperCasePan = pan.toUpperCase();

    // Validate PAN number
    if (upperCasePan.length !== 10 || !/^[A-Z0-9]+$/.test(upperCasePan)) {
      setError("Enter Valid PAN");
      return; // Prevent form submission if there's an error
    } else {
      setError(""); // Clear the error if PAN number is valid
    }

    try {
      const token = localStorage.getItem("accessToken");
      const userId = localStorage.getItem("userId");

      // Make sure the token exists before proceeding
      if (!token) {
        console.error("Access token not found.");
        return;
      }
      if (!userId) {
        console.error("UserId not found.");
        return;
      }

      // Set the Authorization header with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      setIsLoading(true);
      // Send a PATCH request to the server with the user's PAN number and email

      axios
        .patch(
          `${serverIP}/user/personal2/${userId}`,
          {
            pan: upperCasePan, // Use the uppercase PAN number
            email: email,
          },
          config // Pass the config object with the headers
        )
        .then(() => {
          console.log("Personal 2 Values Updated");
          // Redirect to Ask1 page after successful form submission
          history("/ask1");
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsLoading(false);
      // Handle form submission error
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header />

      <div className="CN2-Personal2">
        <div className="CN2-Personal2-Container">
          <div className="CN2-Personal2-Main-Div">
            <div className="CN2-Personal2-Main-Div-Left">
              <img
                src={Personal}
                alt="Profile with pan and aadhaar"
                className="CN2-Personal2-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-yellow)"
                LoanColor="white"
                KycColor="white"
                IncomeColor="white"
                OffersColor="white"
              />
            </div>
            <div className="CN2-Personal2-Main-Div-Right">
              <h1>Personal Info</h1>
              {isLoading ? (
                <>
                  <Animation1 />
                </>
              ) : (
                <>
                  <form onSubmit={handleSubmit}>
                    {Error && (
                      <div className="CN2-Personal2-Error">{Error}</div>
                    )}
                    <div>
                      <input
                        type="text"
                        placeholder="Pan Number"
                        className="CN2-Personal2-Input"
                        value={pan}
                        pattern="^[A-Za-z]{5}\d{4}[A-Za-z]{1}$"
                        onChange={(e) => {
                          const inputPan = e.target.value
                            .toUpperCase()
                            .slice(0, 10);
                          setPan(inputPan);
                          // Clear the error message when the user starts typing again
                          if (/^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/.test(inputPan)) {
                            setError("");
                          } else {
                            setError("Enter valid pan");
                          }
                        }}
                        required
                        maxLength={10}
                      />
                    </div>
                    <div>
                      <input
                        placeholder="Email (Optional)"
                        className="CN2-Personal2-Input"
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </div>

                    <button className="CN2-Personal2-Button">Submit</button>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default Personal2;
