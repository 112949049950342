import React from "react";
import "./terms.css";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import { Helmet } from "react-helmet-async";

const PrivacyPolicyPage = () => {
  return (
    <>
      <Helmet>
        <title>Terms of service and privacy policy</title>
        <meta
          name="description"
          content="Terms of service and privacy policy."
        />
        <link rel="canonical" href="/terms" />
      </Helmet>
      <Header />

      <div className="privacy-policy-page">
        <h1 className="privacy-header">Terms of service and privacy policy</h1>
        <div className="privacy-content">
          <p className="introduction">
            <b style={{ margin: "-15px" }}>1. INTRODUCTION</b>
          </p>
          <p className="introduction">
            Creditnama NVM Finserv Private Limited, a company registered under
            the Companies Act 2013 having its registered office at House No.
            406, Ground Floor, Kohat Enclave, Pitampura, New Delhi - 110034 and{" "}
            <br /> 4th Floor, Spring House, JMD Regent Arcade, Gurugram, Haryana
            - 122002 and 4th Floor, Spring House, JMD Regent Arcade, Gurugram,
            Haryana - 122002 owns, manages, and operates this platform under the
            brand names ‘Creditnama’, “Creditnama.com” (hereinafter referred as
            “Company”, “We”, “Us” or “Our” which term includes our affiliates,
            subsidiaries, group companies, associates, and assigns). For the
            purposes of this privacy policy the term “User(s)” and wherever the
            context so requires “You”, “Your”, “Yourself” shall mean any natural
            or legal person who accesses or Uses the Portal (defined below) and
            in the event that a natural person is representing a business entity
            or a body corporate, reference to such terms shall include a
            reference to such business entity and/or body corporate as well and
            other promoters of such business entity and/or body corporate. All
            references to “You” shall include Yourself, Your company in the case
            of proprietorship, and any other persons You are authorized to and
            required to provide consent for.
          </p>
          <p className="introduction">
            Creditnama, including its affiliates and subsidiary companies, has
            created this Privacy Policy (“Policy”) to demonstrate its commitment
            towards compliance with the Information Technology (Reasonable
            Security Practices and Procedures and Sensitive Personal Data or
            Information), Rules, 2011 and other applicable law.
          </p>
          <p className="introduction">
            The purpose of this Policy is to give you information on how the
            Company collects, stores, Uses, discloses, transfers and processes
            Your Personal Information (defined below) including Sensitive
            Personal Information (defined below) when: (a) You Use Our websites
            including www.creditnama.com (“Website” and “Web application”,
            “app”, “Platform”), software applications or mobile applications
            including the Creditnama application (“mobile application”, “mobile
            app”, “app”, “mobile platform”) or platform(s) of any of Our
            partners (“Platforms”) (Websites, Apps and Platforms shall
            collectively be the “Portal”), or (b) You avail any products or
            services which We may operate or offer to You either directly
            through the Portal or through a merchant or partner platform. This
            Policy also governs Our practices for collecting, Using,
            maintaining, protecting, and disclosing Your User Information
            (defined below).
          </p>
          <p className="introduction">
            The term “Websites” includes all pages that are sub-domains or are
            associated with or exist within each Website which are hosted by the
            Company.
          </p>
          <p className="introduction">
            It is strongly recommended for You to return to this page
            periodically to review the most current version of this Policy which
            is amended by Us from time to time.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>2. SCOPE</b>
          </p>
          <p className="introduction">
            This Privacy Policy explains how we collect information from you,
            whether directly, through third parties, or automatically when you
            interact with our Website. It also covers how we handle this
            information once collected.
          </p>
          <p className="introduction">
            Please note that this Privacy Policy, presented as an electronic
            record under the Information Technology Act, 2000, and its rules,
            does not require any physical, electronic, or digital signature. It
            forms a legally binding agreement between you and Creditnama.
          </p>
          <p className="introduction">
            Upon accepting this Privacy Policy by checking the "I Accept"
            checkbox on our Website and mobile application, it governs your
            relationship with Creditnama for using our Website and mobile
            applications.
          </p>
          <p className="introduction">
            It's essential to recognize that this Policy does not apply to
            information you provide to or that's collected by third parties.
            This includes lenders/financing partners (Financiers), merchants
            offering services, and social networks used in connection with our
            services. We recommend direct consultation with these third parties
            regarding their privacy practices.
          </p>
          <p className="introduction">
            By using our platform, you acknowledge and accept the terms outlined
            in this Privacy Policy. We appreciate your trust in Creditnama, and
            we're committed to ensuring the protection of your information.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>3. CONSENT</b>
          </p>
          <p className="introduction">
            When you access the Portal or use products/services provided by us,
            whether directly or indirectly, you confirm that you've read,
            understood, and agreed to be bound by this Policy and our Terms and
            Conditions. By using the Portal, and/or by having used our products
            or services in the past, present, or future, you explicitly consent
            to the use and disclosure of your User Information as outlined in
            this Policy. If you disagree with these terms, we kindly request
            that you refrain from using or accessing our Portal. Please note
            that this consent also extends to any User Information (defined
            below) you've submitted to us, either directly or indirectly, in the
            past.
          </p>
          <p className="introduction">
            Through the access and/or use of the Platform, you expressly consent
            to the Company's use and disclosure of your Information (as defined
            below) as per this Policy. If you disagree with the terms stated in
            this Policy, please do not use the Platform. Additionally, by
            clicking the "I Accept" checkbox, you authorize us to contact you
            even if you are registered with the DND/NDNC registry.
          </p>
          <p className="introduction">
            Your consent to these terms plays a crucial role in the services we
            provide. It ensures that you're informed about how your information
            is utilized and that you have the freedom to choose whether to
            proceed based on this understanding.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>4. INFORMATION COLLECTED</b>
          </p>
          <p className="introduction">
            We gather various types of information from users of our Portal,
            including:
          </p>
          <p className="introduction">
            1. Personal Identifiable Information: This encompasses details
            capable of uniquely identifying individuals or the business entities
            they represent, such as names, addresses, bank details, and social
            media information.
          </p>
          <p className="introduction">
            2. Sensitive Personal Information: This category includes
            information relating to passwords, financial details like bank
            accounts and payment instruments, sexual orientation, physical and
            mental health conditions, and medical history. It's important to
            note that information already available in the public domain is not
            considered Sensitive Personal Information.
          </p>
          <p className="introduction">
            Depending on your role (e.g., merchant, customer, cardholder,
            consumer, supplier, or business partner) and how you engage with us
            (e.g., through Website, Platform, App, telephone, IVR, API, online,
            or offline), we may collect, use, store, analyze, combine, transfer,
            or process different categories of your Personal Information,
            including Sensitive Personal Information.
          </p>
          <p className="introduction">
            Please be aware that information freely accessible in the public
            domain or furnished under specific laws (e.g., the Right to
            Information Act, 2005) is not treated as Sensitive Personal
            Information according to this Policy.
          </p>
          <p className="introduction">
            This revised version provides a succinct overview of the information
            collection process, highlighting both Personal Identifiable
            Information and Sensitive Personal Information, along with the
            relevant exemptions.
          </p>
          <p className="introduction">
            You provide Your explicit consent for collection of information, on
            Our Portal, which includes but is not limited to (“User
            Information”):
          </p>
          <p className="introduction">
            1. Personal Details: Your full name, address, email address,
            telephone number, date of birth and bank or payment details and any
            proof of Your identity and/or address, postal code, profile picture,
            password, and other information. You may provide with Your account
            (including but not limited to, account holder, account name, account
            number, online banking PIN, available balance and transaction
            history as may be required by Us to facilitate Your use of the
            Portal), ITR, Tax Certificates or any other income documents as
            necessary by Us, such as Your gender, mobile phone number and
            Website and all other information, You may provide Us through
            third-party sign-in services such as Facebook and Google. In such
            events/ cases, We fetch and store whatever information is made
            available to Us by You through these sign-in services or otherwise;
          </p>
          <p className="introduction">
            2. Preferences: Your preferences including settings such as time
            zone and language;
          </p>
          <p className="introduction">
            3. Technical information: IP addresses, application, device or
            browser type, versions and configurations, operating systems, device
            brand and model, time zone setting, content, unique identifiers
            associated with Your device(s) and pages that You access on the
            Portal, and the dates and times that You visit the Portal, and paths
            taken;
          </p>
          <p className="introduction">
            4. Credit related information: Credit assessments, credit
            information (and income information (e.g., employment contract,
            salary details, employer name, tax returns, bank account statements,
            income statements, balance sheet, cashflow statement), credit or
            score or reliability scores provided by third parties such as Our
            Financiers, financial institutions (including TransUnion CIBIL
            Limited and other credit bureaus) or account aggregators or similar
            third parties as permitted under applicable laws of India including
            Our business partners, independent service providers and Our group
            entities;
          </p>
          <p className="introduction">
            5. Device data: Information obtained through mobile devices to
            determine creditworthiness, etc. as per applicable law;
          </p>
          <p className="introduction">
            6. Financial information: Such as personal bank account numbers and
            bank account data including ACH, NEFT, IMPS and UPI ID details,
            salary and income details;
          </p>
          <p className="introduction">
            7. Know Your Customer (KYC) information: Including all proofs of
            identity and address, photograph, Permanent Account Number (PAN),
            etc.
          </p>
          <p className="introduction">{`8. Search & browsing: Your search details such as search terms You have looked up and results You selected. Your browsing details including time, frequency, features Used, etc. by You;`}</p>
          <p className="introduction">
            9. Content provided: Information provided, uploaded, shared by You
            through Our Portal, including inter alia Your PAN details, TAN
            details, details of incorporation, government and business-related
            approvals/ consents/ licenses, tax certificates, invoices, reviews,
            photographs, comments, lists, followers, the Users You follow,
            current and prior purchase or browsing details, contact or
            notification information, and other information in Your account
            profile;
          </p>
          <p className="introduction">
            10. Portal activity: Details of Your visits to the Portal, including
            but not limited to, Web logs and other communication data
          </p>
          <p className="introduction">
            11. Communication: Communications between You and other Financiers,
            merchants, and other third parties through Our Portal; participation
            by You in a survey, poll, sweepstakes, contest or promotion scheme;
            Your request for certain features (e.g., newsletters, updates or
            other products/ services). Your communication with Us about
            employment opportunities posted;
          </p>
          <p className="introduction">
            12. Participation: The information provided by You such as ratings,
            reviews, tips, photos, comments, likes, bookmarks, friends, lists,
            etc. to be published or displayed on publicly accessible areas of
            Our Portal or transmitted to other Users of Our Portals or third-
            parties (collectively, "User Inputs"); and
          </p>
          <p className="introduction">
            13. Cookies: Information collected through cookies.
          </p>
          <p className="introduction">
            The User Information encompasses both Personal Information and
            Sensitive Personal Information. You acknowledge that third-party
            service providers (e.g., partners, merchants, financial
            organizations) may collect and use Your User Information through the
            Portal based on this Policy and their privacy policies.
          </p>
          <p className="introduction">
            We indicate which fields on the Portal are mandatory or optional.
            You have the choice not to provide certain information, but this may
            restrict your access to our products and services.
          </p>
          <p className="introduction">
            Please be aware that sharing User Inputs is at Your own risk. While
            we take security measures, no system is completely invulnerable. We
            cannot control unauthorized views by other Portal users. We may
            display, share, or distribute this information through third-party
            services.
          </p>
          <p className="introduction">
            By Using the Portal, You consent to the collection, storage, and Use
            of User Information for the services We offer. You also allow Us to
            collect updates or changes to User Information. We collect only
            relevant information for providing requested services.
          </p>
          <p className="introduction">
            We don't take liability for the authenticity of User Information
            provided by You. You affirm that the User Information provided is
            valid, true, and accurate.
          </p>
          <p className="introduction">
            This section clarifies how User Information is collected, used, and
            protected, while emphasizing the importance of accurate data and
            user discretion.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>
              5. INFORMATION COLLECTION APPROACH
            </b>
          </p>
          <p className="introduction">
            We value your privacy, and we want to be transparent about how we
            collect information from you. Our collection methods encompass both
            direct interactions with you and indirect data collection while you
            use our web and mobile applications. We adhere to applicable laws
            and ensure that your data is handled responsibly.
          </p>
          <p className="introduction">Here's how we gather information:</p>
          <p className="introduction">
            1. Direct Interaction: We collect information that you explicitly
            provide to us. This includes details such as your full name,
            address, email address, telephone number, date of birth,
            bank/payment details, and any identity/address proofs you submit.
            Additionally, you may provide information through third-party
            sign-in services like Facebook or Google. We securely store the data
            you share with us to enhance your experience on our platform.
          </p>
          <p className="introduction">
            2. Indirect Interaction: As you navigate through our web and mobile
            applications, we may automatically gather certain data. This
            includes your server's IP address, browser type, operating system,
            the website you visited before ours, device information, mobile
            network details, and unique device identifiers. We also collect
            information on how you use our platform, such as the resources you
            access, your browsing habits, and the duration of your visits.
          </p>
          <p className="introduction">
            3. Third-Party Sources: We collaborate with trusted third parties to
            ensure the accuracy and relevance of the information we collect.
            These sources may include our Financiers, merchants, credit bureaus,
            and other reputable entities. We only obtain data from these sources
            in compliance with applicable laws and with your explicit consent.
          </p>
          <p className="introduction">
            4. Consent-Based Data: We obtain your consent for specific data
            collection, as required by law. For instance, when you use our
            mobile application, we may request one-time consent to access
            certain device resources, such as device information, transactional
            SMS, and communication details, all of which enable us to provide
            personalized offerings.
          </p>
          <p className="introduction">
            5. Publicly Available Information: We may collect information that
            is publicly available or accessible in the public domain, as
            permitted by law. Please note that data provided under the Right to
            Information Act, 2005, or any other applicable law will not be
            considered Sensitive Personal Information under our policy.
          </p>
          <p className="introduction">
            6. Centralized Databases: If you're registered with a cKYC ID linked
            to your PAN, or if your records are available on your consent with
            credit bureaus, such as Experian, CIBIL, CRIF, Equifax, and other
            relevant agencies.
          </p>
          <p className="introduction">
            7. Security and Law Compliance: We adhere to rigorous security
            standards and comply with applicable laws while accessing phone
            resources or metadata, ensuring your data is handled with the utmost
            care.
          </p>
          <p className="introduction">
            By collecting information through these methods, we strive to
            provide you with relevant and valuable services. We want you to have
            control over your data, and we give you the option to decide what
            information to provide, as some fields on our portal may be marked
            as mandatory, while others are optional. Your trust and confidence
            in us are essential, and we work diligently to safeguard your data
            while delivering an exceptional experience on our platform.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>6. COLLECTED INFORMATION USE</b>
          </p>
          <p className="introduction">
            We highly value the trust you place in us by sharing your
            information, and we want to ensure that you understand how we use
            the data you provide. By using our Portal, you explicitly provide
            your consent for us to use your User Information in the following
            ways:
          </p>
          <p className="introduction">
            1. Identity Verification: We use your information to verify and
            authenticate your identity, ensuring the security and integrity of
            our platform.
          </p>
          <p className="introduction">
            2. Product/Service Usage: Your data helps us authenticate and
            authorize your use of our products or services, which includes risk
            and portfolio management for a seamless experience.
          </p>
          <p className="introduction">
            3. Facilitating Portal Usage: We use your information to enhance
            your usage of the Portal, making it more tailored to your
            preferences.
          </p>
          <p className="introduction">
            4. Risk Assessment: We conduct internal risk assessments and
            analysis to protect our business and ensure compliance with legal
            requirements.
          </p>
          <p className="introduction">
            5. Access to Products/Services: Your data facilitates your access to
            our products and services, connecting you with our Financiers,
            merchants, and other third parties as needed.
          </p>
          <p className="introduction">
            6. Surveys and Marketing Communications: We may send you surveys and
            marketing communications, including offers from third parties, that
            align with your interests.
          </p>
          <p className="introduction">
            7. Creditworthiness Checks: We use your data for creditworthiness
            checks when necessary.
          </p>
          <p className="introduction">
            8. Content Enhancement: Your information is essential for us to
            analyze, review, and conduct internal assessments, leading to
            improvements in the content and features of our Portal.
          </p>
          <p className="introduction">
            9. Transaction Processing: Your data is used to process and complete
            your transactions efficiently.
          </p>
          <p className="introduction">
            10. Technical Support: We use your information to diagnose technical
            problems, provide support, and troubleshoot issues you may
            encounter.
          </p>
          <p className="introduction">
            11. Communications: We send and receive communications from you,
            show you relevant advertisements and notifications, and keep you
            updated.
          </p>
          <p className="introduction">
            12. Reports and Reviews: We prepare reports, reviews, and analyses
            using your data to improve our services continually.
          </p>
          <p className="introduction">
            13. Third-Party Services and Offers: We may contact you regarding
            third-party services and offers to understand your preferences and
            requirements.
          </p>
          <p className="introduction">
            14. Interactive Features: Your information permits your
            participation in interactive features offered through our Portal.
          </p>
          <p className="introduction">
            15. Content Integrity: We use your data to improve the content and
            protect the integrity of the Portal.
          </p>
          <p className="introduction">
            16. Relationship Management: Your data helps us effectively manage
            our relationship with you.
          </p>
          <p className="introduction">
            You acknowledge that the collection of your User Information serves
            the purposes mentioned above and aligns with lawful intentions. Your
            trust is paramount, and we handle your data responsibly to ensure
            that you have a safe and beneficial experience on our platform. If
            you have any concerns about the use of your data, please review our
            Privacy Policy or contact us directly for clarification.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>
              7. DISCLOSURE OF INFORMATION COLLECTED
            </b>
          </p>
          <p className="introduction">
            We want to be transparent about how we handle the information you
            provide and ensure that you're aware of the instances where we may
            disclose your User Information. By using our Portal, you explicitly
            provide your consent for us to disclose your User Information as
            follows:
          </p>
          <p className="introduction">
            1. Compliance with Laws: We may disclose your information when
            required by law enforcement, government officials, fraud detection
            agencies, financial institutions, or other third parties, as
            compelled by applicable laws, regulations, subpoenas, court orders,
            or similar legal procedures.
          </p>
          <p className="introduction">
            2. Affiliates and Service Providers: Your information may be shared
            with our affiliates, subsidiaries, group companies, and related
            parties, as well as other entities that provide support services or
            technical assistance to us, or from whom we receive such services.
          </p>
          <p className="introduction">
            3. Partners and Third-Parties: We may disclose your information to
            our partners, Financiers, merchants, contractors, independent
            service providers, and other third parties as necessary to jointly
            create and offer products and services, or to assist with our
            business operations.
          </p>
          <p className="introduction">
            4. KYC Information Handling: We may share your User Information with
            associated entities, service providers, and assigns for the purposes
            of using or otherwise handling the KYC information received from the
            Central KYC Registry and information received from account
            aggregators.
          </p>
          <p className="introduction">
            5. Service Agreements: We disclose information to third parties and
            financial institutions in accordance with our service agreements
            (terms and conditions or contracts), whether directly or through
            third-party service providers like payment aggregators or referral
            partners.
          </p>
          <p className="introduction">
            6. Mergers and Acquisitions: In case of mergers, acquisitions, or
            similar corporate transactions, we may disclose information to the
            new combined entity or the acquiring/ acquired entity, subject to
            their adherence to this Policy.
          </p>
          <p className="introduction">
            7. Security and Fraud Protection: Disclosure may be made to maintain
            security, operate platforms, prevent fraud, hacking, or protect the
            rights, property, or safety of customers and others.
          </p>
          <p className="introduction">
            8. Marketing and Offers: We may share your information with third
            parties and financial institutions to enable them to market and
            offer their products and services to you, based on your interests
            and Usage patterns.
          </p>
          <p className="introduction">
            9. Other Legitimate Purposes: We may disclose your information for
            any other purpose directly connected to the ones mentioned above.
          </p>
          <p className="introduction">
            10. Enforcement and Agreements: Disclosure may be made for enforcing
            our Terms and Conditions, agreements, billing, and collection
            purposes.
          </p>
          <p className="introduction">
            11. International Entities: We may disclose information to
            international entities we partner with for offering and/or
            developing products and services, ensuring compliance with this
            Policy and applicable Indian laws.
          </p>
          <p className="introduction">
            12. Safety and Expansion: Your data may be disclosed to ensure the
            safety of our business, enhance the Portal, or help us serve you and
            other users better.
          </p>
          <p className="introduction">
            13. Prescribed Disclosures: Other than as prescribed under this
            Privacy Policy, we will not disclose User Information unless (i) we
            have your consent; or (ii) we are required by law.
          </p>
          <p className="introduction">
            Your User Information collected for a specific purpose will be used
            solely for that purpose, with no other usage without your consent.
          </p>
          <p className="introduction">
            We retain your User Information as long as required for the purposes
            for which it was collected, considering legal, accounting, and
            compliance reporting requirements. We ensure that your User
            Information is stored only on servers located in India, and we do
            not store any biometric data.
          </p>
          <p className="introduction">
            The information you provide, both Personal and Non-Personal, is used
            for various purposes to enhance your experience, provide you with
            personalized content, and improve our services. By using our
            Platform, you specifically agree and consent to us collecting,
            storing, processing, transferring, and sharing your information for
            the use-cases mentioned above.
          </p>
          <p className="introduction">
            Please note that we may send text messages to your registered mobile
            number, always adhering to guidelines prescribed by the Telecom
            Regulatory Authority of India (TRAI) and utilizing authorized
            third-party service providers, where applicable.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>8. INFORMATION RETENTION</b>
          </p>
          <p className="introduction">
            We want to be clear about how we handle the retention of your
            information, ensuring it aligns with our services, legal
            obligations, and business practices. Here's how we retain your
            Personal Information and Non-Personal Information:
          </p>
          <p className="introduction">
            1. Service Delivery: We retain your Personal Information, Sensitive
            Personal Information, and Non-Personal Information as long as your
            account remains active or as needed to provide our Services,
            ensuring a seamless experience for you on our Platform.
          </p>
          <p className="introduction">
            2. Legal Compliance: We may retain and use the information collected
            to fulfill our legal obligations, resolve disputes, or for other
            legitimate business purposes, ensuring compliance with relevant laws
            and regulations.
          </p>
          <p className="introduction">
            3. Retention After Account Closure: After you cancel, deactivate, or
            unsubscribe your account with us, we have a business practice of
            retaining your Personal Information and Non-Personal Information for
            a period of 5 years. This retention helps us maintain a historical
            record and assists in case you decide to reactivate your account.
          </p>
          <p className="introduction">
            4. Data Deletion and Anonymization: Where we no longer have a
            legitimate business need to process your information, we take steps
            to delete or anonymize it. If complete deletion is not immediately
            possible, such as when personal data is stored in backup archives,
            we securely store the data and isolate it from any further
            processing until deletion becomes feasible.
          </p>
          <p className="introduction">
            5. Credit Information Purging: In alignment with applicable laws and
            the policies of our credit bureau partners, we are obligated to
            purge the credit information collected through them in six months
            from the consent provided by you.
          </p>
          <p className="introduction">
            We believe that this approach to retaining information balances the
            need for seamless service delivery, legal compliance, and data
            protection. If you have any concerns about the retention of your
            information or wish to exercise any rights related to it, please
            refer to our Privacy Policy for further details on how to get in
            touch with us.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>9. INFORMATION CORRECTION</b>
          </p>
          <p className="introduction">
            We respect your right to ensure the accuracy and completeness of
            your Personal Information and Sensitive Personal Data or Information
            (SPDI) that you provide on our Platform. Here's how you can exercise
            this right:
          </p>
          <p className="introduction">
            Review and Modify: You have the right to review the Personal
            Information and/or SPDI you've submitted on our Platform. If you
            find any inaccuracies, incompleteness, or changes needed, you can
            modify the information directly on the Platform.
          </p>
          <p className="introduction">
            Deletion: Additionally, you have the right to request the deletion
            of any Personal Information and/or SPDI you've provided directly on
            the Platform, should you decide that it's no longer necessary or
            relevant.
          </p>
          <p className="introduction">
            We want to make sure that the information we hold about you is
            up-to-date and accurate, as we value your privacy and aim to provide
            you with the best experience possible. If you have any questions or
            need assistance with rectifying or deleting your Personal
            Information and/or SPDI, please reach us at the following address:
          </p>
          <p className="introduction">Creditnama NVM Finserv Private Limited</p>
          <p className="introduction">
            406, Ground Floor, Kohat Enclave, Pitampura, New Delhi - 110034 and{" "}
            <br /> 4th Floor, Spring House, JMD Regent Arcade, Gurugram, Haryana
            - 122002 and 4th Floor, Spring House, JMD Regent Arcade, Gurugram,
            Haryana - 122002
          </p>
          <p className="introduction">
            We're here to help and ensure that your information is in line with
            your preferences.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>10. INFORMATION UPDATION</b>
          </p>
          <p className="introduction">
            We understand that your Information may change over time, and it's
            important for us to keep it accurate. Here's how you can update your
            User Information:
          </p>
          <p className="introduction">
            Keeping Your Information Current: If there are changes or amendments
            to your Information, please ensure that you correct, update, or
            amend the relevant details stored with us. You can do this by making
            the necessary changes in your account settings.
          </p>
          <p className="introduction">
            Contact Us for Assistance: If you need further assistance in
            updating your Information or encounter any issues, you can reach out
            to us at the following address:
          </p>
          <p className="introduction">Creditnama NVM Finserv Private Limited</p>
          <p className="introduction">
            406, Ground floor, Kohat enclave, Pitampura, New Delhi - 110034 and{" "}
            <br /> 4th Floor, Spring House, JMD Regent Arcade, Gurugram, Haryana
            - 122002 and 4th Floor, Spring House, JMD Regent Arcade, Gurugram,
            Haryana - 122002
          </p>
          <p className="introduction">
            Impact on Platform Usage: Please be aware that any modifications to
            your Information may impact your ability to use the Platform. It's
            important to keep your details current to ensure a seamless
            experience.
          </p>
          <p className="introduction">
            Legal Constraints: In some cases, the Company may be unable to grant
            access or make updates to your Personal Information. This may be the
            case if doing so would infringe upon another person's rights or if
            it's not permitted by the applicable laws.
          </p>
          <p className="introduction">
            We value your active involvement in maintaining the accuracy of your
            Information and appreciate your understanding of the potential
            impact of these updates on our ability to deliver services that
            cater to your needs while adhering to legal requirements and
            considerations.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>11. MARKETING</b>
          </p>
          <p className="introduction">
            At Creditnama, we value transparency in our marketing
            communications. Here's what you need to know about the marketing
            activities related to our products and services:
          </p>
          <p className="introduction">
            Receiving Marketing Communications: You may receive marketing
            communications from us or other third parties, in line with this
            Policy, related to the products and services provided by us or
            through third parties we collaborate with. These communications may
            be delivered via messages, email, or other digital channels. You may
            encounter them on the Portal or on various platforms, including
            social media platforms, websites, and apps.
          </p>
          <p className="introduction">
            Scope of Marketing: These marketing communications may cover a range
            of offerings, including our own products and services, those offered
            by our group companies, affiliates, subsidiaries, and third parties.
            These activities are conducted within the bounds of applicable laws
            and regulations.
          </p>
          <p className="introduction">
            Opting Out: We respect your preferences. If you wish to opt out of
            receiving such marketing communications, you can do so by contacting
            us at the following address:
          </p>
          <p className="introduction">Creditnama NVM Finserv Private Limited</p>
          <p className="introduction">
            406, Ground Floor, Kohat Enclave, Pitampura, New Delhi - 110034 and{" "}
            <br /> 4th Floor, Spring House, JMD Regent Arcade, Gurugram, Haryana
            - 122002 and 4th Floor, Spring House, JMD Regent Arcade, Gurugram,
            Haryana - 122002
          </p>
          <p className="introduction">
            Improving Your Experience: Our marketing and communications efforts
            are also aimed at enhancing and customizing the content of our
            advertisements, promotions, and other forms of advertising. This
            ensures that the content we present to you aligns with your
            interests.
          </p>
          <p className="introduction">
            Your choice to receive or opt out of marketing communications is
            important to us. We believe in keeping you informed and empowered to
            make decisions that best suit your preferences, while also enhancing
            your experience with the products and services we offer.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>12. ADVERTISEMENT</b>
          </p>
          <p className="introduction">
            At Creditnama, we may utilize the services of third-party
            advertising companies/entities to enhance your experience on our
            Portal. Here's how it works:
          </p>
          <p className="introduction">
            Third-Party Advertising: When you visit our Portal, these
            third-party advertising entities may collect certain information
            about your browsing behavior, but they do not have access to
            personal identifiers such as your name, address, email address, or
            telephone number. The information they collect is focused on
            understanding your interaction with our Portal and other websites.
          </p>
          <p className="introduction">
            Advertisement Personalization: The primary purpose of this data
            collection is to enable these third-party entities to provide
            advertisements that align with your interests and preferences. This
            means that the advertisements you encounter may be tailored to goods
            and services that could be of interest to you.
          </p>
          <p className="introduction">
            This practice allows us to deliver more relevant content and ads,
            enhancing your overall experience on our Portal. If you have any
            concerns about this process, please feel free to reach out to us.
            We're committed to ensuring that your experience with Creditnama is
            both enjoyable and respectful of your privacy.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>13. ACCOUNT CLOSURE</b>
          </p>
          <p className="introduction">
            You can close Your account through a written request addressed to
            the Company’s representatives at the following address:
          </p>
          <p className="introduction">Creditnama NVM Finserv Private Limited</p>
          <p className="introduction">
            406, Ground Floor, Kohat Enclave, Pitampura, New Delhi - 110034 and{" "}
            <br /> 4th Floor, Spring House, JMD Regent Arcade, Gurugram, Haryana
            - 122002 and 4th Floor, Spring House, JMD Regent Arcade, Gurugram,
            Haryana - 122002
          </p>
          <p className="introduction">
            In some cases, we may need to retain certain information about you
            for purposes authorized under this Policy, as long as it is not
            prohibited by law. This retention helps us comply with legal
            obligations, resolve disputes, or fulfill business requirements.
          </p>
          <p className="introduction">
            We will remove Your public posts from view and/or dissociate them
            from Your account profile. We may retain information about You for
            the purposes authorized under this Policy unless prohibited by law.
            If you have any concerns or questions about account closure or data
            retention, please feel free to reach out to us. We're here to ensure
            that the process aligns with regulatory requirements.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>14. MINORS</b>
          </p>
          <p className="introduction">
            At Creditnama, we prioritize the protection of minors and adhere to
            strict policies in this regard:
          </p>
          <p className="introduction">
            Age Limit: The Portal is designed for users who are 18 (eighteen)
            years of age or older. It is not intended for individuals under the
            age of 18 (eighteen) years.
          </p>
          <p className="introduction">
            No Collection of Minor's Information: We do not knowingly collect
            any personal information from individuals under the age of 18
            (eighteen) years. Additionally, we do not engage in marketing or
            solicitation of information from anyone under the age of 18
            (eighteen) years.
          </p>
          <p className="introduction">
            Verifiable Parental Consent: In the event that we inadvertently
            collect user information from a minor without first receiving
            verifiable parental consent, we are committed to taking appropriate
            steps to delete such information in a manner consistent with
            applicable laws.
          </p>
          <p className="introduction">
            Prioritizing Protection: Safeguarding the privacy and security of
            minors is of utmost importance to us. We are dedicated to complying
            with legal requirements and maintaining a safe environment for all
            users.
          </p>
          <p className="introduction">
            If you have any concerns about the age-appropriateness of our Portal
            or suspect that we may have collected information from a minor
            without proper consent, please contact us. We are committed to
            addressing such issues promptly and in accordance with applicable
            laws and best practices.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>15. THIRD PARTY LINKS</b>
          </p>
          <p className="introduction">
            We want you to have a clear understanding of how third-party links
            are treated on our Portal:
          </p>
          <p className="introduction">
            Links to Third-Party Sites: The Portal may feature links to other
            websites or platforms that are not under our direct control. These
            third-party sites operate independently, and their practices may
            differ from ours.
          </p>
          <p className="introduction">
            User Information Handling: Please note that when you access these
            third-party sites through our links, these third parties may
            collect, store, or process your user information. It's essential to
            be aware that we do not control or endorse the actions or policies
            of these third-party websites.
          </p>
          <p className="introduction">
            No Endorsement or Responsibility: We neither endorse nor make any
            representations about third-party websites or services. We take no
            responsibility for any form of transmission you receive from these
            third-party platforms. Any interaction you have with these sites,
            including the provision of your information, is governed by their
            own privacy policies, which may differ from ours.
          </p>
          <p className="introduction">
            Quality and Accuracy: We do not control or guarantee the accuracy,
            integrity, or quality of information, data, text, software, sound,
            photographs, graphics, videos, cookies, messages, or other materials
            available on third-party platforms.
          </p>
          <p className="introduction">
            Read Privacy Policies: We highly recommend that you carefully read
            the privacy policies of these third-party sites before interacting
            with them. This will help you understand how your information may be
            used or shared by these sites.
          </p>
          <p className="introduction">
            Your Responsibility: If you choose to access any third-party sites
            linked to our Portal, you do so at your own risk. The responsibility
            for any interactions, transactions, or sharing of information on
            these third-party sites falls on the linking party, and we will not
            be held responsible for notifications regarding changes in the name
            or location of information on those sites.
          </p>
          <p className="introduction">
            It's essential to exercise caution and make informed decisions when
            interacting with third-party sites. Your privacy and security matter
            to us, and we want you to have a safe online experience.
          </p>
          <p className="introduction">&nbsp;</p>

          <p className="introduction">
            <b style={{ margin: "-15px" }}>
              {" "}
              16. INFORMATION SHARING WITH THIRD PARTIES
            </b>
          </p>
          <p className="introduction">
            We hold your Personal Information in high regard and believe in
            maintaining its confidentiality. Here's how we handle the sharing of
            Personal Information with third parties:
          </p>
          <p className="introduction">
            Our Commitment to Privacy: We are committed to protecting your
            privacy, and we will not publish, sell, rent, trade, or otherwise
            transfer your Personal Information to third parties unless specific
            conditions are met.
          </p>
          <p className="introduction">
            Legal Requirements: There may be instances where we are legally
            obligated to share your Personal Information. This includes
            situations when required by applicable laws, regulations, or
            pursuant to a court order or a directive from a regulatory
            authority.
          </p>
          <p className="introduction">
            Protection of Rights: We may share Personal Information to protect
            our rights or to enforce the terms and conditions outlined in this
            Policy. This may include taking actions to prevent or address fraud,
            security breaches, or other violations.
          </p>
          <p className="introduction">
            Stated Purposes: We will share your Personal Information with third
            parties only when it's expressly stated in this Policy. These
            instances will be for specific purposes and within the boundaries of
            your consent or as outlined in our terms.
          </p>
          <p className="introduction">
            Non-Disclosure Agreements: If the need arises for us to share
            Personal Information with third parties, we will ensure that proper
            safeguards are in place. We will enter into non-disclosure
            agreements or equivalent covenants with these third parties to
            maintain the confidentiality and security of your information.
          </p>
          <p className="introduction">
            Our Commitment to Your Privacy: We value your trust in us, and we
            take privacy seriously. Rest assured that any sharing of Personal
            Information with third parties will be done with care and in
            accordance with the highest standards of privacy and data
            protection.
          </p>
          <p className="introduction">
            We are dedicated to maintaining the integrity of your Personal
            Information, and we strive to handle it responsibly in compliance
            with the law and this Policy. Your privacy matters to us, and we are
            committed to keeping your trust intact.
          </p>
          <p className="introduction">&nbsp;</p>

          <p className="introduction">
            <b style={{ margin: "-15px" }}>17. DATA PROTECTION RIGHTS</b>
          </p>
          <p className="introduction">
            You have certain rights regarding your personal data, and we want to
            ensure you're fully aware of those rights. These rights include:
          </p>
          <p className="introduction">
            Access: You have the right to request access to the personal data we
            hold about you.
          </p>
          <p className="introduction">
            Rectification: You have the right to request that we correct any
            inaccurate or incomplete personal data.
          </p>
          <p className="introduction">
            Erasure: You can request the deletion of your personal data under
            certain conditions.
          </p>
          <p className="introduction">
            Restrict Processing: You can request that we restrict the processing
            of your personal data under certain conditions.
          </p>
          <p className="introduction">
            Data Portability: You have the right to receive your personal data
            in a structured, machine-readable format and transmit it to another
            controller, where technically feasible.
          </p>
          <p className="introduction">
            Objection: You can object to the processing of your personal data
            under certain conditions.
          </p>
          <p className="introduction">
            If you wish to exercise any of these rights, please contact us using
            the information provided in the "Contact" section below. We'll
            respond to your request within a reasonable timeframe and in
            accordance with applicable data protection laws.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>18. LIABILITY AND INDEMNIFICATION</b>
          </p>
          <p className="introduction">
            In the event of Your breach of any terms or conditions outlined in
            this Policy, We shall not be held liable, and We will not be
            obligated to indemnify You for any direct or indirect losses You may
            incur. By accepting this Policy, You agree to indemnify, defend, and
            hold harmless Us, Our affiliates, group companies, and their
            directors, officers, employees, agents, third-party service
            providers, and any other third party providing services to Us in
            relation to the services, whether directly or indirectly, from and
            against any and all losses, liabilities, claims, damages, costs, and
            expenses (including legal fees and disbursements in connection
            therewith and interest chargeable thereon) asserted against or
            incurred by Us that arise out of, result from, or may be payable due
            to any breach or non-performance of any terms of this Policy,
            including any representation, warranty, covenant, or agreement made
            or obligation to be performed by You pursuant to this Policy.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>
              {" "}
              19. DISCLOSURE TO ACQUIRERS AND/OR INVESTORS
            </b>
          </p>
          <p className="introduction">
            In the event of significant changes in our corporate structure, we
            may need to disclose and/or transfer Information as part of business
            transitions. Here's how we handle such scenarios:
          </p>
          <p className="introduction">
            Business Transitions: As part of our commitment to transparency, we
            may need to disclose or transfer Information to an investor,
            acquirer, assignee, or other successor entity in connection with a
            sale, merger, or reorganization. This disclosure or transfer may
            involve all or substantially all of the Company’s equity, business,
            or assets.
          </p>
          <p className="introduction">
            Protection of Your Information: In such cases, we will take
            necessary measures to ensure that your Information is treated with
            the same level of protection and security as outlined in this
            Policy. Any new entity or successor will be required to adhere to
            this Privacy Policy concerning your Information.
          </p>
          <p className="introduction">
            Maintaining Privacy: While business transitions are a part of the
            corporate landscape, your privacy remains a priority. We will ensure
            that your Information is handled in accordance with applicable
            privacy laws and that the new entity understands the importance of
            safeguarding your data.
          </p>
          <p className="introduction">
            Notice of Changes: In the event of such a transition, we will strive
            to provide notice on our Portal or through other means to keep you
            informed about the changes and the impact, if any, on your
            Information.
          </p>
          <p className="introduction">
            Your Control: Remember that you have the ability to manage your
            Information as described in this Policy, and your consent remains an
            essential part of how we handle your data.
          </p>
          <p className="introduction">
            We value the trust you place in us, and our commitment to your
            privacy extends even in times of significant corporate transitions.
            Rest assured that we will continue to protect your Information in
            accordance with the highest standards of privacy and data
            protection.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>20. COOKIES</b>
          </p>
          <p className="introduction">
            We use cookies and other tracking technologies to enhance your
            experience on our Platform and improve the services we offer. This
            section explains how cookies work and how you can manage them:
          </p>
          <p className="introduction">
            Understanding Cookies: Cookies are small text files that the Company
            places on your mobile phone, computer, browser, tablet, or other
            electronic devices. These files allow us to remember your
            preferences, distinguish you from other users, and provide a
            personalized experience when you use our services on the Platform.
          </p>
          <p className="introduction">
            Anonymous Data: Cookies, on their own, do not reveal your email
            address or other personally identifiable information unless you
            choose to provide it to us. They are designed to store a small
            amount of specific data related to your interaction with our
            Platform.
          </p>
          <p className="introduction">
            Enhancing User Experience: We use cookies to analyze Platform usage
            patterns and improve the content and offerings on the Platform. By
            understanding how users like you use the Platform, we can create a
            better and more customized experience.
          </p>
          <p className="introduction">
            Third-Party Cookies: Some cookies on the Platform may be placed by
            third parties. It's important to note that we don't control the use
            of cookies by these third parties. Their use may serve various
            purposes, such as analytics or advertising.
          </p>
          <p className="introduction">
            Session Cookies: Most cookies we use are session cookies, which
            means they're automatically deleted from your hard drive once your
            session ends. These cookies help us provide smooth navigation and
            functionality during your visit to the Platform.
          </p>
          <p className="introduction">
            Cookie Preferences: You have the option to decline cookies if you
            prefer. However, it's important to note that by doing so, you may be
            limited in using certain features on the Platform. We recommend
            leaving cookies turned on to fully enjoy our services.
          </p>
          <p className="introduction">
            Personalization: The data stored in cookies can help us personalize
            your experience, making it more convenient and tailored to your
            preferences.
          </p>
          <p className="introduction">
            Managing Cookies: If you want to manage your cookie preferences, you
            can usually do so through your browser settings. These settings
            allow you to accept or decline cookies and review the cookies stored
            on your device.
          </p>
          <p className="introduction">
            We respect your privacy, and our use of cookies is aimed at
            enhancing your experience while maintaining the security of your
            data. By continuing to use our Platform, you consent to our use of
            cookies as described in this Policy.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>21. CYBER SECURITY</b>
          </p>
          <p className="introduction">
            We take the security of your information seriously and have
            implemented measures to safeguard the data collected from you.
            However, it's important to understand the limitations of online
            security, and your role in maintaining the confidentiality of your
            credentials. Here's how we approach cyber security:
          </p>
          <p className="introduction">
            Security Measures: Creditnama employs appropriate organizational and
            technical security measures to protect the information you provide.
            These measures include electronic, physical, and procedural
            safeguards aimed at preventing unlawful or unauthorized access,
            alteration, use, destruction, damage, or accidental loss of
            information.
          </p>
          <p className="introduction">
            No Absolute Guarantee: While we strive to provide a secure
            environment, it's essential to recognize that no method of
            electronic storage or transmission over the internet is completely
            foolproof. Therefore, we cannot guarantee absolute security.
          </p>
          <p className="introduction">
            Your Responsibility: You have a crucial role in maintaining the
            security of your account. Please keep your password and login ID
            confidential, and do not share them with unrelated third parties. If
            we receive instructions using your credentials, we'll consider those
            instructions as authorized by you.
          </p>
          <p className="introduction">
            ISO 27001 Certification: Creditnama is certified under ISO/IEC
            27001:2013, an international standard for information security
            management. This certification reflects our commitment to
            maintaining a systematic approach to keeping sensitive company
            information secure.
          </p>
          <p className="introduction">
            Security Incident Response: In case of security breaches, we have a
            security incident response procedure aligned with ISO standards. A
            copy of this procedure can be obtained by making a request to us at
            the provided address.
          </p>
          <p className="introduction">
            Consent to Security Measures: By using our services, you expressly
            consent to the storage, handling, and use of your user information
            in line with the security measures we have implemented.
          </p>
          <p className="introduction">
            Unauthorized Access: Engaging in data scraping or crawling
            activities on the Portal without authorization is strictly
            prohibited. Any use of web crawling or web scraping software to
            access the Portal is considered unauthorized access.
          </p>
          <p className="introduction">
            We're dedicated to maintaining the highest security standards to
            protect your information. By using our services, you agree to comply
            with our security measures and protect the confidentiality of your
            account credentials.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>22. COPYRIGHT POLICY</b>
          </p>
          <p className="introduction">
            We take copyright seriously and expect users of our portal to do the
            same. Our portal's content, including graphics, text, icons,
            interfaces, audio clips, logos, images, reviews, comments, and
            software, is the property of the Company and/or its content
            suppliers. These materials are protected by Indian and international
            copyright laws, as well as other applicable intellectual property
            laws.
          </p>
          <p className="introduction">
            Ownership and Use of Content: Any use of the content on our portal,
            such as reproduction, modification, distribution, transmission,
            republication, display, or performance, requires the express written
            permission of the Company. Trademarks, brands, and copyrights not
            owned by the Company belong to their respective owners and are their
            property.
          </p>
          <p className="introduction">
            Limitations on Use: You are not allowed to modify paper or digital
            copies of materials you've printed or downloaded from the portal.
            Additionally, you must not use illustrations, photographs, videos,
            audio sequences, or graphics separately from their accompanying
            text. Using any part of our portal's content for commercial purposes
            without obtaining written permission from us or our licensors is
            prohibited.
          </p>
          <p className="introduction">
            Consequences of Unauthorized Use: If you engage in actions that
            breach these copyright terms, your right to use our portal will be
            terminated immediately. At our discretion, you may be required to
            return or destroy any copies of the materials you've made.
          </p>
          <p className="introduction">
            Respecting copyright is essential, and we expect all users of our
            portal to adhere to these terms. Unauthorized use of copyrighted
            materials not only violates our policy but may also have legal
            implications.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>
              23. DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY
            </b>
          </p>
          <p className="introduction">
            We provide no warranties or representations regarding the accuracy
            or completeness of this Portal. You understand and expressly agree
            that to the extent permitted under applicable laws, in no event will
            the Company or any of its affiliates, group companies, or any of
            their respective officers, employees, directors, shareholders,
            agents, or licensors be liable to You or anyone else under any
            theory of liability (whether in contract, tort, statutory, or
            otherwise) for any direct, indirect, incidental, special,
            consequential, or exemplary damages, including but not limited to,
            damages for loss of revenues, profits, goodwill, Use, data, or other
            intangible losses (even if such parties were advised of, knew of, or
            should have known of the possibility of such damages), resulting
            from:
          </p>
          <p className="introduction">
            1. Your Use of the Portal or any parts thereof;
          </p>
          <p className="introduction">
            2. Unauthorized access to or alteration of Your transmissions or
            data;
          </p>
          <p className="introduction">
            3. Any other matter relating to the services, including, without
            limitation, damages for loss of Use, data, or profits, arising out
            of or in any way connected with availing of the services.
          </p>
          <p className="introduction">
            We have made efforts to ensure that all the information provided by
            Us and/or by third-party service providers on the Portal is correct.
            However, We neither warrant nor make any representations regarding
            the quality, accuracy, or completeness of any data or information
            displayed on the Portal. We make no warranty, express or implied,
            concerning the Portal and/or its contents and disclaim all
            warranties of fitness for a particular purpose and warranties of
            merchantability in respect of information displayed and communicated
            through or on the Portal, including any liability, responsibility,
            or any other claim, whatsoever, in respect of any loss, whether
            direct or consequential, to You or any other person, arising out of
            or from the Use of any such information as is displayed or
            communicated through or on the Portal.
          </p>
          <p className="introduction">
            Notwithstanding anything to the contrary herein, You hereby
            irrevocably waive any right or remedy to seek and/or obtain
            injunctive or other equitable relief or any order with respect to,
            and/or to enjoin or restrain or otherwise impair in any manner, the
            production, distribution, exhibition, or other exploitation of the
            Company or any of its affiliate or group company-related project, or
            the Use, publication, or dissemination of any advertising in
            connection with such a project.
          </p>
          <p className="introduction">
            Your sole and exclusive remedy for any dispute with Us shall be to
            discontinue the Use of the Portal and the services.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>
              24. SEVERABILITY, EXCLUSION, AND LIMITATIONS
            </b>
          </p>
          <p className="introduction">
            We have made diligent efforts to ensure that this Policy complies
            with all applicable laws. In the event that any part of this Policy
            is found to be invalid or unenforceable, it shall not impact the
            validity or enforceability of the remaining provisions of this
            Policy.
          </p>
          <p className="introduction">
            It's important to note that this Policy specifically pertains to the
            information collected by Us exclusively through the Portal. This
            Policy does not extend to cover any unsolicited information provided
            by You through the Portal or any other means. This includes, but is
            not limited to, information posted in public areas of the website or
            app.
          </p>
          <p className="introduction">
            All unsolicited information shared with Us through any means shall
            be considered non-confidential. As a result, We shall have the
            freedom to use and/or disclose such unsolicited information without
            any limitations or obligations.
          </p>
          <p className="introduction">
            By providing unsolicited information, You acknowledge that it is not
            covered by the confidentiality protections outlined in this Policy.
            Therefore, We recommend exercising caution and refraining from
            sharing sensitive or confidential details through unsolicited
            communications.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>25. TERMINATION</b>
          </p>
          <p className="introduction">
            Subject to Our Terms and Conditions, We may suspend or terminate
            Your Account or Your Use of this Portal at any time, for any reason
            or for no reason, provided that there is no outstanding credit
            provided by any of Our Financiers. You are personally liable for any
            charges incurred through Your account prior to termination. We
            reserve the right to change, suspend, or discontinue all or any
            aspects of this Portal at any time without notice.
          </p>
          <p className="introduction">
            Please note that if You revoke any mandatory permissions or revoke
            the consent to process and store information such as Your Account
            data, financial and KYC information and/or any other information
            needed to facilitate Your access to the loan facilities offered by
            Our Financiers, then We may have to cease the provision of services
            to You. You cannot withdraw Your consent once You have availed a
            credit facility Using the Portal till You have repaid the entire
            outstanding amount and all related charges in its entirety to such
            Financier.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>
              26. AMENDMENTS TO THIS PRIVACY POLICY
            </b>
          </p>
          <p className="introduction">
            This Privacy Policy is subject to periodic updates and amendments to
            reflect changes in the law, Our data collection and usage practices,
            advancements in technology, or modifications to the features of Our
            services. The most current version of this Policy will be made
            available on our website. Please be aware that this Policy is
            subject to change at any time without prior notice to You.
          </p>
          <p className="introduction">
            We reserve the right to revise, update, modify, and amend any terms
            of this Policy as needed. We encourage You to periodically review
            this page to stay informed about any changes. If there are any
            material changes to this Policy, We will notify You by publishing
            the updated version on Our Portal.
          </p>
          <p className="introduction">
            While We aim to ensure that You are informed of any significant
            changes to this Policy, it is ultimately Your responsibility to
            review the updated Policy before continuing to access or use Our
            services. By continuing to use Our services following the posting of
            changes to this Policy, You indicate Your consent and acceptance of
            those changes.
          </p>
          <p className="introduction">
            Should You disagree with the changes made to this Policy, We request
            that You cease using Our services and promptly notify Us of your
            decision. Your ongoing use of Our services following any updates to
            this Policy implies Your acknowledgment and agreement with the
            revised terms.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>27. EMPLOYMENT</b>
          </p>
          <p className="introduction">
            If you submit your information through our Portal while applying for
            a position with the Company, please be aware that the information
            you provide will be used for the purpose of considering your
            application for the position you've applied for. We may retain this
            information for a period of time as necessary for evaluation and
            consideration.
          </p>
          <p className="introduction">
            It's important to note that we may share this information with other
            companies, but only for the specific purpose of assessing your
            qualifications for the particular position you've applied for, as
            well as for evaluating your suitability for other available
            positions within the Company. Additionally, we may engage
            third-party service providers to assist in the collection,
            maintenance, and analysis of candidate submissions for job postings.
          </p>
          <p className="introduction">
            We are committed to treating your application information with the
            utmost respect and confidentiality. The information you provide will
            be handled in accordance with our privacy practices and applicable
            laws. We appreciate your interest in potential employment
            opportunities with us and strive to ensure a fair and thorough
            evaluation process.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>28. CONTACT</b>
          </p>
          <p className="introduction">If You have any inquiries regarding:</p>
          <p className="introduction">1. This Policy,</p>
          <p className="introduction">2. The practices of this Portal,</p>
          <p className="introduction">3. Your interactions with this Portal,</p>
          <p className="introduction">
            Please feel free to write to us at our following address:
          </p>
          <p className="introduction">CREDITNAMA NVM FINSERV PRIVATE LIMITED</p>
          <p className="introduction">
            406, Ground floor, Kohat enclave, Pitampura, New Delhi - 110034 and{" "}
            <br /> 4th Floor, Spring House, JMD Regent Arcade, Gurugram, Haryana
            - 122002 and 4th Floor, Spring House, JMD Regent Arcade, Gurugram,
            Haryana - 122002
          </p>
          <p className="introduction">hello@creditnama.com.</p>
          <p className="introduction">
            It's essential to understand that this Policy should be read in
            conjunction with any other agreements You may enter into with Us, as
            well as the Terms and Conditions published by Us on Our Portal.
            These documents collectively outline the terms and conditions that
            govern Your use of Our services and interactions on the Portal.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b style={{ margin: "-15px" }}>29. GRIEVANCE REDRESSAL</b>
          </p>
          <p className="introduction">
            If You have any questions or concerns regarding this Policy, or if
            You need to address any grievance related to Your User Information
            or privacy, please contact:
          </p>
          <p className="introduction">For Creditnama:</p>
          <p className="introduction">The Grievance Redressal Officer</p>
          <p className="introduction">CREDITNAMA NVM FINSERV PRIVATE LIMITED</p>
          <p className="introduction">
            406, Ground floor, Kohat enclave, Pitampura, New Delhi - 110034 and{" "}
            <br /> 4th Floor, Spring House, JMD Regent Arcade, Gurugram, Haryana
            - 122002 and 4th Floor, Spring House, JMD Regent Arcade, Gurugram,
            Haryana - 122002
          </p>
          <p className="introduction">hello@creditnama.com.</p>
          <p className="introduction">
            We take Your privacy seriously, and Your concerns are important to
            us. The designated Grievance Redressal Officer will strive to
            address Your queries and grievances promptly and efficiently.
          </p>
          <p className="introduction">&nbsp;</p>
          <p className="introduction">
            <b
              style={{ margin: "-15px" }}
            >{`30. GOVERNING LAW & DISPUTE RESOLUTION`}</b>
          </p>
          <p className="introduction">
            This Privacy Policy is subject to the laws of India and shall be
            interpreted and construed accordingly. Any disputes arising out of
            or in connection with this Policy shall be exclusively subject to
            the jurisdiction of the courts located in New Delhi.
          </p>
          <p className="introduction">
            Arbitration: If a dispute arises between you and us regarding the
            validity, interpretation, implementation, or alleged breach of any
            provision of this Policy, such dispute shall be referred to and
            resolved through arbitration in accordance with the Indian
            Arbitration and Conciliation Act, 1996, as currently in force. The
            rules of the said Act are considered to be incorporated by reference
            in this clause.
          </p>
          <p className="introduction">
            Arbitrator and Seat: The arbitration shall be conducted by a single
            arbitrator, and the seat of the arbitration shall be New Delhi,
            India.
          </p>
          <p className="introduction">
            Language of Arbitration: The language used in the arbitration
            proceedings, as well as in all written decisions and correspondence
            related to the arbitration, shall be English.
          </p>
          <p className="introduction">
            By accepting this Policy, you acknowledge and agree to be bound by
            the governing law and the dispute resolution mechanism detailed
            above. This provision aims to provide a fair and efficient
            resolution process for any disputes that may arise in connection
            with this Policy.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
