import React, { useEffect, useState } from "react";
import Header from "../Front-Page-Components/Header/Header";
import StatusBar from "../../Status-Bar/StatusBar";
import Ask from "../../Images/Page-Img/Loan Requirements.png";
import StepsFooter from "../Front-Page-Components/Footer/StepsFooter";
import serverIP from "../../config/config";
import "./Previousasks.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useNavigate } from "react-router-dom";

const Previousasks = () => {
  const [data, setData] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("accessToken");

      if (!token) {
        console.log("Token not found");
        return;
      }

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await fetch(
          `${serverIP}/ask/previousasks/${userId}`,
          config
        );
        if (response.status === 200 || response.status <= 300) {
          const data = await response.json();
          setData(data);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  const handleGo = (askId, purpose) => {
    localStorage.setItem("askId", askId);
    localStorage.setItem("initial_ask_purpose", purpose);
    history("/confirmation");
  };

  // Function to format date
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-IN", options);
  };
  return (
    <>
      <Header />
      <div className="CN2-PreviousAsk">
        <div className="CN2-PreviousAsk-Container">
          <div className="CN2-PreviousAsk-Main-Div">
            <div className="CN2-PreviousAsk-Main-Div-Left">
              <img
                src={Ask}
                alt="Profile with pan and aadhaar"
                className="CN2-PreviousAsk-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-yellow)"
                KycColor="white"
                IncomeColor="white"
                OffersColor="white"
              />
            </div>
            <div className="CN2-PreviousAsk-Main-Div-Right">
              {data[0] ? (
                <>
                  <div className="CN2-PreviousAsk-Main-Div-Right-Inner">
                    <h1>Your previous asks:</h1>
                    <button
                      className="CN2-PreviousAsk-Button"
                      onClick={() => history("/ask1")}
                    >
                      Create new ask
                    </button>
                  </div>
                  <div className="CN2-table-wrapper">
                    <table className="CN2-Previous-table">
                      <thead>
                        <tr
                          style={{
                            backgroundColor: "var(--color-greenbg)",
                            color: "var(--color-darkgreen)",
                          }}
                        >
                          <th>Ask</th>
                          <th>Amount & Tenure</th>
                          <th>Date</th>
                          <th>Revisit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => (
                          <tr key={index}>
                            <td>{item.initial_ask_purpose.toUpperCase()}</td>
                            <td>
                              Amount:{" "}
                              {new Intl.NumberFormat("en-IN", {
                                style: "currency",
                                currency: "INR",
                                maximumFractionDigits: 0,
                              }).format(item.initial_ask_value)}{" "}
                              <br />
                              Tenure: {item.initial_ask_tenure} Years
                            </td>
                            <td>{formatDate(item.created_at)}</td>
                            <td>
                              <PlayArrowIcon
                                onClick={() =>
                                  handleGo(item.askId, item.initial_ask_purpose)
                                }
                                fontSize="large"
                                style={{ cursor: "pointer" }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <div className="CN2-PreviousAsk-NotFound-Div">
                    <h1>No previous asks</h1>
                    <button
                      className="CN2-PreviousAsk-Button my-2"
                      onClick={() => history("/ask1")}
                    >
                      Create ask
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
};

export default Previousasks;
