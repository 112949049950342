import React, { useEffect, useRef, useState } from "react";
import "../Auth-Components/User-Auth/LoginPage.css";
import "../Test/Test.css";
import Header from "../User-Components/Front-Page-Components/Header/Header";
import LoginImg from "../Images/Page-Img/PlAd.png";
import LoginImg2 from "../Images/Page-Img/PlAd.png";
import LoginImg3 from "../Images/Page-Img/PlAd.png";
import axios from "axios";
import serverIP from "../config/config";
import { Link, useNavigate } from "react-router-dom";
import ImgFlag from "../Images/Assets/indiaflagcn@2x.png";
import Animation1 from "../Animation/animation";
// import StepsFooter from "../User-Components/Front-Page-Components/Footer/StepsFooter";
import axisLogo from "../Images/Bank-Logos/axis.png";
import hdfcLogo from "../Images/Bank-Logos/hdfc.png";
import kotakLogo from "../Images/Bank-Logos/kotak.png";
import abflLogo from "../Images/Bank-Logos/abfl.jpg";
import axisfinanceLogo from "../Images/Bank-Logos/axisfinance.png";
import bajajLogo from "../Images/Bank-Logos/bajaj.png";
import capriLogo from "../Images/Bank-Logos/capri.png";
import cholaLogo from "../Images/Bank-Logos/chola.png";
import citibankLogo from "../Images/Bank-Logos/citibank.png";
import dbsLogo from "../Images/Bank-Logos/dbs.png";
import deutcheLogo from "../Images/Bank-Logos/deutche.png";
import edelwisLogo from "../Images/Bank-Logos/edelwis.jpg";
import fullertonLogo from "../Images/Bank-Logos/fullerton.png";
import hdbLogo from "../Images/Bank-Logos/hdb.png";
import homecreditLogo from "../Images/Bank-Logos/homecredit.png";
import hsbcLogo from "../Images/Bank-Logos/hsbc.png";
import iciciLogo from "../Images/Bank-Logos/icici.png";
import idfcLogo from "../Images/Bank-Logos/idfc.jpg";
import iiflLogo from "../Images/Bank-Logos/iifl.png";
import incredLogo from "../Images/Bank-Logos/incred.png";
import indusindLogo from "../Images/Bank-Logos/indusind.png";
import karurLogo from "../Images/Bank-Logos/karur.jpg";
import myshubhlifeLogo from "../Images/Bank-Logos/myshubhlife.png";
import paysenseLogo from "../Images/Bank-Logos/paysense.png";
import protiumLogo from "../Images/Bank-Logos/protium.png";
import scbLogo from "../Images/Bank-Logos/scb.png";
import shriramLogo from "../Images/Bank-Logos/shriram.png";
import smcLogo from "../Images/Bank-Logos/smc.png";
import tataLogo from "../Images/Bank-Logos/tata.png";
import ujjivanLogo from "../Images/Bank-Logos/ujjivan.png";
import utkershLogo from "../Images/Bank-Logos/utkersh.png";
import yesbankLogo from "../Images/Bank-Logos/yesbank.png";
import indiabullshomeLogo from "../Images/Bank-Logos/Indiabulls.png";
import indiahomeLogo from "../Images/Bank-Logos/indiashelter.png";
import shubhamhomeLogo from "../Images/Bank-Logos/shubham.png";
import godrejLogo from "../Images/Bank-Logos/godrej.png";
import adityalimitedLogo from "../Images/Bank-Logos/abfl.jpg";
import easyhomefinanceLogo from "../Images/Bank-Logos/easyhome.png";
import piramalhousingLogo from "../Images/Bank-Logos/piramal.png";
import federalLogo from "../Images/Bank-Logos/fedral.png";
import shriramhousingLogo from "../Images/Bank-Logos/shriram.png";
import bajajhousingLogo from "../Images/Bank-Logos/bajaj.png";
import anandLogo from "../Images/Bank-Logos/Anandrathi.png";
import dmiLogo from "../Images/Bank-Logos/DmiHousing.png";
import rblLogo from "../Images/Bank-Logos/RblBank.png";
import aadharLogo from "../Images/Bank-Logos/AadharHousing.png";
import satinLogo from "../Images/Bank-Logos/SatinHousing.png";
import licLogo from "../Images/Bank-Logos/LicHousing.png";
import poonawallaLogo from "../Images/Bank-Logos/poonawalla.png";
import sbmLogo from "../Images/Bank-Logos/sbm.png";

// import EmiCalulatorForLandingPage from "../User-Components/Front-Page-Components/EMI-Calculator/EmiCalulatorForLandingPage";
// import EMICalcForCamp from "../User-Components/Front-Page-Components/EMI-Calculator/EMICalcForCamp";
import WA from "../Images/Assets/WA.png";
import Startupindia from "../Images/Assets/startup@2x.png";
import Digilocker from "../Images/Assets/digicn@2x.png";
import ISO from "../Images/Assets/isocn@2x.png";
// import TLS from "../../Images/Assets/TLS.png";
import AWS from "../Images/Assets/AWS.png";
import BlogPart from "../Test/BlogPart";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Footer from "../User-Components/Front-Page-Components/Footer/Footer";
import PlEmiCalc from "../User-Components/Front-Page-Components/EMI-Calculator/PlEMICalc";
import { Helmet } from "react-helmet-async";
// import Sample from "../Images/Assets/Sample.gif";

function PlTest() {
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [error, setError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOTP] = useState("");
  const [timer, setTimer] = useState(45);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [isWhatsappOptedIn, setIsWhatsappOptedIn] = useState(true);
  const [BankData, setBankData] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [displayedRows, setDisplayedRows] = useState(5);
  const [ipAddress, setIpAddress] = useState("");
  const [operatingSystem, setOperatingSystem] = useState("");
  const [browser, setBrowser] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [screenResolution, setScreenResolution] = useState("");
  const [referringURL, setReferringURL] = useState("");
  const [userAgent, setUserAgent] = useState("");
  const [visitorIpType, setVisitorIpType] = useState("");
  const [utm, setUtm] = useState({
    utmSource: null,
    utmMedium: null,
    utmCampaign: null,
    utmContent: null,
    utmTerm: null,
  });

  const history = useNavigate();

  useEffect(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    const getVisitorInfo = () => {
      setOperatingSystem(navigator.platform);
      setBrowser(navigator.userAgent);
      setDeviceType(getDeviceType());
      setScreenResolution(`${window.screen.width}x${window.screen.height}`);
      setReferringURL(document.referrer);
      setUserAgent(navigator.userAgent);

      const { utmSource, utmMedium, utmCampaign, utmTerm, utmContent } =
        getUTMParameters();
      setUtm({ utmSource, utmMedium, utmCampaign, utmTerm, utmContent });
    };

    getVisitorInfo();
    getUserIp();
  }, []);

  const getDeviceType = () => {
    const userAgent = navigator.userAgent;
    if (
      userAgent.match(/Android/i) ||
      userAgent.match(/webOS/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPod/i) ||
      userAgent.match(/BlackBerry/i) ||
      userAgent.match(/Windows Phone/i)
    ) {
      return "Mobile";
    } else if (userAgent.match(/iPad/i) || userAgent.match(/Tablet/i)) {
      return "Tablet";
    } else {
      return "Desktop";
    }
  };

  const getUTMParameters = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    return {
      utmSource: urlParams.get("utm_source"),
      utmMedium: urlParams.get("utm_medium"),
      utmCampaign: urlParams.get("utm_campaign"),
      utmTerm: urlParams.get("utm_term"),
      utmContent: urlParams.get("utm_content"),
    };
  };

  const getUserIp = async () => {
    try {
      const response = await fetch(`${serverIP}/visitor/getIP`);
      const data = await response.json();
      setIpAddress(data.ipAddress);
      setVisitorIpType(data.ipType);
    } catch (error) {
      console.error("Error fetching user IP:", error);
    }
  };

  useEffect(() => {
    const visitorId = localStorage.getItem("visitorId");
    if (visitorId) {
      return;
    }
    const handleVisitors = async () => {
      const visitorData = {
        visitorIp: ipAddress,
        visitorIpType: visitorIpType,
        userAgent: userAgent,
        referringURL: referringURL,
        screenResolution: screenResolution,
        deviceType: deviceType,
        operatingSystem: operatingSystem,
        browser: browser,
        utm_source: utm.utmSource,
        utm_medium: utm.utmMedium,
        utm_campaign: utm.utmCampaign,
        utm_term: utm.utmTerm,
        utm_content: utm.utmContent,
      };

      const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(visitorData),
      };

      try {
        const response = await fetch(`${serverIP}/visitor/newvisitor`, config);
        const data = await response.json();
        console.log("Data:", data);
        localStorage.setItem("visitorId", data.visitorId);
      } catch (error) {
        console.error("Error submitting data:", error);
        // Handle error here, maybe show a message to the user
      }
    };

    const timeoutId = setTimeout(() => {
      handleVisitors();
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [
    browser,
    deviceType,
    ipAddress,
    operatingSystem,
    referringURL,
    screenResolution,
    userAgent,
    utm,
    visitorIpType,
  ]);

  const handleSendSessionID = async () => {
    try {
      const response = await axios.post(
        `${serverIP}/visitor/${localStorage.getItem("visitorId")}`,
        {
          sessionId: localStorage.getItem("sessionId"),
        }
      );

      if (response.status === 200 || response.status < 300) {
        console.log("Session ID sent");
      } else {
        console.log("Error sending session ID");
      }
    } catch (error) {
      console.log("Error sending sessionId:", error);
    }
  };
  //  ####################### For TeleCRM ############################################

  const handleTeleCRM = async () => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer 2c438757-dc99-4297-b188-b06bba3341651714828905449:e51d6e0b-9961-45b9-bcf0-bae006ffa758",
      },
      body: JSON.stringify({
        fields: {
          phone: `${phoneNumber}`,
        },
        actions: [
          {
            type: "SYSTEM_NOTE",
            text: "Lead Source: Website Personal-Loan",
          },
          {
            type: "SYSTEM_NOTE",
            text: "Session ID created",
          },
        ],
      }),
    };

    try {
      const response = await fetch(
        `https://api.telecrm.in/enterprise/66361419545ce40e83b1154b/autoupdatelead`,
        config
      );
      if (response.status >= 200 && response.status < 300) {
        // const data = await response.json();
        console.log("Lead updated successfully");
        // console.log(response);
      } else {
        console.error("Lead update failed. Error", response.status);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //   ########################################################################
  const handlePhoneNumberSubmit = async (e) => {
    e.preventDefault();

    if (phoneNumber.length !== 10 || !/[6-9]/.test(phoneNumber[0])) {
      setError("Enter Correct Mobile Number");
      return;
    }
    setShowAnimation(true);
    try {
      const response = await axios.post(`${serverIP}/user/generate-otp`, {
        phoneNumber: phoneNumber,
        whatsappOptIn: isWhatsappOptedIn,
      });
      if (response.status >= 200 && response.status < 300) {
        startTimer();
        console.log("OTP Send");
        setShowOTPInput(true);
        setShowAnimation(false);
        localStorage.setItem("sessionId", response.data.sessionId);
        handleSendSessionID();
        handleTeleCRM();
      } else {
        setError("Error to Send OTP");
        setShowAnimation(false);
      }
    } catch (error) {
      console.log("Error sending mobile number:", error);
    } finally {
      setShowAnimation(false);
    }
  };
  //   ########################################################################
  const startTimer = () => {
    setIsResendDisabled(true);
    let countdown = 45;

    const interval = setInterval(() => {
      countdown -= 1;
      setTimer(countdown);

      if (countdown === 0) {
        clearInterval(interval);
        setIsResendDisabled(false);
      }
    }, 1000);
  };
  useEffect(() => {
    if (showOTPInput) {
      startTimer();
    }
  }, [showOTPInput]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 45);
    const remainingSeconds = seconds % 45;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };
  // ############################################################################
  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    setShowAnimation(true);
    try {
      const response = await axios.post(`${serverIP}/auth/verify-otp`, {
        phoneNumber: phoneNumber,
        enteredOTP: otp,
      });
      if (response.data.isOTPValid === true) {
        localStorage.setItem("userId", response.data.userId);
        localStorage.setItem("accessToken", response.data.token);
        handleTeleCRMUpadte();
        history("/profile1");
      } else {
        setError("Incorrect OTP");
        setShowAnimation(false);
      }
    } catch (error) {
      console.log("Error while sending OTP:", error);
    } finally {
      setShowAnimation(false);
    }
  };

  //   #######################################################################
  const handlePhoneInput = (e) => {
    e.preventDefault();
    setPhoneNumber(e.target.value);
    setError("");
  };

  const handleOtpInput = (e) => {
    e.preventDefault();
    setOTP(e.target.value);
    setError("");
  };

  const handleCheckboxChange = (e) => {
    setIsWhatsappOptedIn(e.target.checked);
    // console.log(!e.target.checked);
  };

  const bankLogos = {
    axisLogo: axisLogo,
    hdfcLogo: hdfcLogo,
    kotakLogo: kotakLogo,
    abflLogo: abflLogo,
    axisfinanceLogo: axisfinanceLogo,
    bajajLogo: bajajLogo,
    capriLogo: capriLogo,
    cholaLogo: cholaLogo,
    citibankLogo: citibankLogo,
    dbsLogo: dbsLogo,
    deutcheLogo: deutcheLogo,
    edelwisLogo: edelwisLogo,
    fullertonLogo: fullertonLogo,
    hdbLogo: hdbLogo,
    homecreditLogo: homecreditLogo,
    hsbcLogo: hsbcLogo,
    iciciLogo: iciciLogo,
    idfcLogo: idfcLogo,
    iiflLogo: iiflLogo,
    incredLogo: incredLogo,
    indusindLogo: indusindLogo,
    karurLogo: karurLogo,
    myshubhlifeLogo: myshubhlifeLogo,
    paysenseLogo: paysenseLogo,
    protiumLogo: protiumLogo,
    scbLogo: scbLogo,
    shriramLogo: shriramLogo,
    smcLogo: smcLogo,
    tataLogo: tataLogo,
    ujjivanLogo: ujjivanLogo,
    utkershLogo: utkershLogo,
    yesbankLogo: yesbankLogo,
    indiahomeLogo: indiahomeLogo,
    shubhamhomeLogo: shubhamhomeLogo,
    godrejLogo: godrejLogo,
    adityalimitedLogo: adityalimitedLogo,
    easyhomefinanceLogo: easyhomefinanceLogo,
    indiabullshomeLogo: indiabullshomeLogo,
    piramalhousingLogo: piramalhousingLogo,
    federalLogo: federalLogo,
    shriramhousingLogo: shriramhousingLogo,
    bajajhousingLogo: bajajhousingLogo,
    anandLogo: anandLogo,
    dmiLogo: dmiLogo,
    rblLogo: rblLogo,
    aadharLogo: aadharLogo,
    satinLogo: satinLogo,
    licLogo: licLogo,
    poonawallaLogo: poonawallaLogo,
    sbmLogo: sbmLogo,
  };

  const handleLoadMore = () => {
    setDisplayedRows(displayedRows + 5);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${serverIP}/product/get-bank-product-details`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              product_category: "personal loan",
            }),
          }
        );
        const data = await response.json();
        if (response.ok) {
          const sortedData = data.sort((a, b) => a.minRate - b.minRate);

          console.log(sortedData);

          setBankData(sortedData);
        } else {
          console.log(response);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const images = [LoginImg2, LoginImg3, LoginImg];

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Change the current image index every 3 seconds
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
      // setCurrentImageSubheadIndex((prevIndex) =>
      //   prevIndex === ImageSubhead.length - 1 ? 0 : prevIndex + 1
      // );
    }, 3000);

    // Clear the interval on component unmount to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [images.length]);

  const handleWAClick = () => {
    window.open(
      "https://wa.me/+918750072800?text=Hey, help me choose the best personal loan"
    );
  };
  const containerRef = useRef(null);
  const scrollNext = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 500,
        behavior: "smooth",
      });
    }
  };
  const scrollPrev = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -500,
        behavior: "smooth",
      });
    }
  };

  const handleTeleCRMUpadte = async () => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer 2c438757-dc99-4297-b188-b06bba3341651714828905449:e51d6e0b-9961-45b9-bcf0-bae006ffa758",
      },
      body: JSON.stringify({
        fields: {
          phone: `${phoneNumber}`,
        },
        actions: [
          {
            type: "SYSTEM_NOTE",
            text: "User ID created",
          },
        ],
      }),
    };

    try {
      const response = await fetch(
        `https://api.telecrm.in/enterprise/66361419545ce40e83b1154b/autoupdatelead`,
        config
      );
      if (response.status >= 200 && response.status < 300) {
        console.log("Lead updated successfully");
      } else {
        console.error("Lead update failed. Error", response.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Personal Loan</title>
        <meta
          name="description"
          content="Find Your Personal Loan Eligibility"
        />
        <link rel="canonical" href="/personal-loan" />
      </Helmet>
      <Header />
      <div className="CN2-LoginCampaign-Page">
        <div className="CN2-LoginCampaign-Page-Container">
          <div className="CN2-LoginCampaign-Page-Container-div1">
            <div>
              <h1>Find Your Personal Loan Eligibility</h1>
              <img
                src={images[currentImageIndex]}
                alt={`Slide ${currentImageIndex + 1}`}
                className="CN2-LoginCampaign-Page-Img"
                loading="lazy"
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
          </div>
          <div className="CN2-LoginCampaign-Page-Container-div2">
            <h1>Find Your Personal Loan Eligibility</h1>

            {showAnimation ? (
              <>
                <Animation1 />
              </>
            ) : (
              <>
                {showOTPInput ? (
                  <div className="CN2-LoginCampaign-Page-Form">
                    <form onSubmit={handleOTPSubmit}>
                      {error && (
                        <div className="CN2-LoginCampaign-Error-Handling">
                          {error}
                        </div>
                      )}

                      <input
                        placeholder="Enter OTP"
                        type="tel"
                        className="CN2-LoginCampaign-Signin-Input"
                        id="otp-input"
                        value={otp}
                        maxLength={6}
                        onChange={handleOtpInput}
                        required
                      />
                      <button className="CN2-LoginCampaign-Signin-Button">
                        Submit OTP
                      </button>
                      {isResendDisabled ? (
                        <p className="CN2-LoginCampaign-ResendOtp">
                          Resend OTP {formatTime(timer)}
                        </p>
                      ) : (
                        <p
                          className="CN2-LoginCampaign-ResendOtp"
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={handlePhoneNumberSubmit}
                        >
                          Resend OTP
                        </p>
                      )}
                    </form>
                  </div>
                ) : (
                  <div className="CN2-LoginCampaign-Page-Form">
                    <form onSubmit={handlePhoneNumberSubmit}>
                      {error && (
                        <div className="CN2-LoginCampaign-Error-Handling">
                          {error}
                        </div>
                      )}

                      <div className="CN2-LoginCampaign-Signin-Container">
                        <img
                          src={ImgFlag}
                          alt="Head Img"
                          style={{ width: "50px" }}
                          loading="lazy"
                        />
                        +91{" "}
                        <input
                          placeholder="Enter Phone Number"
                          type="tel"
                          className="CN2-LoginCampaign-Signin-Input"
                          required
                          id="phone-input"
                          value={phoneNumber}
                          maxLength={10}
                          onChange={handlePhoneInput}
                        />
                      </div>
                      <div className="CN2-LoginCampaign-Signin-checkbox">
                        <div>
                          <input
                            type="checkbox"
                            className="CN2-LoginCampaign-Checkbox-Style mx-2"
                            required
                          />
                          I accept{" "}
                          <Link className="mx-1" to="/terms">
                            terms of service & applicable policies
                          </Link>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            className="CN2-LoginCampaign-Checkbox-Style mx-2"
                            checked={isWhatsappOptedIn}
                            onChange={handleCheckboxChange}
                          />
                          Opt in to receive important updates on WhatsApp
                        </div>
                      </div>

                      <button className="CN2-LoginCampaign-Signin-Button">
                        Send OTP
                      </button>
                    </form>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="CN-Login-Page-BanksOffer-Table">
          <table>
            <thead>
              <tr>
                <th>Lender</th>
                <th>Loan upto</th>
                <th>Min rate</th>
              </tr>
            </thead>
            <tbody>
              {BankData.slice(0, displayedRows).map((item, index) => (
                <tr key={index}>
                  <td>
                    <img src={bankLogos[item.bankLogo]} alt={item.bankName} />
                  </td>
                  <td>
                    ₹
                    {new Intl.NumberFormat("en-IN").format(
                      item.maxLoanMax * 100000
                    )}
                  </td>
                  <td>{(item.minRate / 1).toFixed(2)}%</td>
                </tr>
              ))}
            </tbody>
          </table>
          {BankData.length > displayedRows && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "ceneter",
                marginTop: "20px",
              }}
            >
              <button
                onClick={handleLoadMore}
                style={{
                  width: "120px",
                  height: "40px",
                  borderRadius: "1rem",
                  fontWeight: "700",
                  border: "1px solid lightgrey",
                  backgroundColor: "var(--color-darkgreen)",
                  color: "white",
                  fontSize: "15px",
                }}
              >
                Load More
              </button>
            </div>
          )}
        </div>

        <PlEmiCalc />
        <div className="CN-LoginCampaign-Page-CTA">
          <h2> Want to connect with personal loan experts?</h2>
          <div className="CN-LoginCampaign-Page-CTA-SubDiv">
            <div>
              Our banking experts and advanced technology ensure transparent
              loan terms and secured processing with your preferred bank.
            </div>
            <button onClick={handleWAClick}>
              <img
                src={WA}
                alt="connect now"
                style={{ width: "25px", marginRight: "5px" }}
              />
              Connect now!
            </button>
          </div>
        </div>
        <div className="CN-Test-Section5" style={{ marginTop: "25px" }}>
          <div>
            <h2>
              Comfort of Digital Offers Without Hassle of Scams or Telecalls
            </h2>
            <div className="CN2-Test-Ceriticates-Container">
              <div className="CN2-Test-Trust-Div">
                <img
                  src={Startupindia}
                  alt="Startupindia"
                  className="CN2-Test-Ceriticates-Img"
                  onContextMenu={(e) => e.preventDefault()}
                  loading="lazy"
                />
              </div>
              <div className="CN2-Test-Trust-Div">
                <img
                  src={Digilocker}
                  alt="Digilocker"
                  className="CN2-Test-Ceriticates-Img"
                  onContextMenu={(e) => e.preventDefault()}
                  loading="lazy"
                  style={{ padding: "10px" }}
                />
              </div>
              <div className="CN2-Test-Trust-Div">
                <img
                  src={AWS}
                  alt="AWS"
                  className="CN2-Test-Ceriticates1-Img"
                  onContextMenu={(e) => e.preventDefault()}
                  loading="lazy"
                />
              </div>
              <div className="CN2-Test-Trust-Div">
                <img
                  src={ISO}
                  alt="ISO"
                  className="CN2-Test-Ceriticates1-Img"
                  onContextMenu={(e) => e.preventDefault()}
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          <div
            className="CN2-Test-LatestThinking"
            style={{ padding: "0px 10px" }}
          >
            <h2>Latest Thinking</h2>
            <div className="CN2-Test-Blog-Container" ref={containerRef}>
              <BlogPart />
            </div>
            <div className="CN2-Test-Blog-Pagination">
              <button onClick={scrollPrev}>
                <NavigateNextIcon style={{ rotate: "180deg" }} />
              </button>
              <button onClick={scrollNext}>
                <NavigateNextIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PlTest;
