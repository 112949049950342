import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import CalculateIcon from "@mui/icons-material/Calculate";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import "./ChannelHamburger.css";

const ChannelHamburger = () => {
  const [selected, setSelected] = useState("/channel/summary");
  //   const history = useNavigate();

  const SidebarData = [
    {
      title: "Summary",
      icon: <SummarizeIcon />,
      path: "/channel/summary",
    },
    {
      title: "Payouts",
      icon: <ReceiptIcon />,
      path: "/channel/payout",
    },
    {
      title: "Leads",
      icon: <PeopleOutlineIcon />,
      path: "/channel/leads",
    },
    {
      title: "Rate of interest",
      icon: <ReceiptIcon />,
      path: "/channel/rate-of-interest",
    },
    {
      title: "Circle rates",
      icon: <CorporateFareIcon />,
      path: "/channel/circle-rates",
    },
    {
      title: "Calculators",
      icon: <CalculateIcon />,
      path: "/channel/calculators",
    },
  ];

  // Function to handle click event on sidebar links
  const handleClick = (path) => {
    setSelected(path);
    localStorage.setItem("path", path);
  };

  useEffect(() => {
    const fetchPath = localStorage.getItem("path");
    setSelected(fetchPath);
    // console.log(fetchPath);
  }, []);

  return (
    <div className="CN-Channel-Hamburger-Container">
      <button
        // class="btn btn-primary"
        style={{
          border: "none",
        }}
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasWithBothOptions"
        aria-controls="offcanvasWithBothOptions"
      >
        <MenuIcon />
      </button>

      <div
        class="offcanvas offcanvas-start"
        data-bs-scroll="true"
        tabindex="-1"
        id="offcanvasWithBothOptions"
        aria-labelledby="offcanvasWithBothOptionsLabel"
        style={{
          width: "80%",
        }}
      >
        <div class="offcanvas-header">
          <h5
            class="offcanvas-title"
            id="offcanvasWithBothOptionsLabel"
            style={{ fontSize: "18px" }}
          >
            Menu
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body" className="CN-Channel-Hamburger-Phoneview">
          {SidebarData.map((value) => {
            return (
              <Link
                to={value.path}
                // className="CN-Channel-Sidebar-Link"
                className="CN-Channel-Hamburger-Phoneview"
                style={
                  selected === value.path
                    ? {
                        color: "var(--color-darkgreen)",
                        backgroundColor: "var(--color-lightyellow)",
                        borderRadius: "10px",
                        fontWeight: "700",
                      }
                    : null
                }
                key={value.path}
                onClick={() => handleClick(value.path)}
              >
                {value.icon}
                {value.title}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ChannelHamburger;
