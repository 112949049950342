/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import "./DocsReader.css";
import creditnama from "../Images/CNLogo.png";
import SearchIcon from "@mui/icons-material/Search";
import AiSideBar from "./ai-components/AiSideBar";
import AiChatBox from "./ai-components/AiChatBox";
import AiDocsPreview from "./ai-components/AiDocPreview";
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
import { SchemaType } from "@google/generative-ai";
import Tesseract from "tesseract.js";
import {
  GoogleGenerativeAI,
  HarmCategory,
  HarmBlockThreshold,
} from "@google/generative-ai";
import AiAuth from "./AiAuth/AiAuth";
import Message from "./ai-components/Message";
import Loading from "./ai-components/Loading";

pdfjsLib.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/legacy/build/pdf.worker.entry");

const DocsReader = () => {
  const [ocrResults, setOcrResults] = useState([]);
  const [BtnDisable, setBtnDisable] = useState(false);
  const [message, setMessage] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [chat, setChat] = useState(null);
  const [error1, setError] = useState(null);
  const [ShowImg, setShowImg] = useState(99);
  const [loading, setLoading] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [BtnActive, setBtnActive] = useState(-1);
  const [PromptBtnDisable, setPromptBtnDisable] = useState(true);
  const [Auth, setAuth] = useState(false);
  const [AiChatH2, setAiChatH2] = useState(true);

  const MODEL_NAME = "gemini-1.0-pro";
  let combinedString = useMemo(() => "", [ocrResults]);
  // let c = 0;
  const handleFileUpload = useCallback(async (file) => {
    if (file) {
      setBtnDisable(true);
      setShowImg(message.length);
      setPdfFile(file);
      setAiChatH2(false);
      const extractedTexts = await analyzeAndConvertPDF(file);
      setOcrResults(extractedTexts);
      setLoading(false);
      setPromptBtnDisable(false);
      combinedString = extractedTexts.join(" ");
      // console.log(combinedString);
      setUserInput(
        `$extracted$Data$+ ${combinedString}
          ". tell me whats the document is all about and Summerise the data with important points"`
      );
    }
  }, []);

  useEffect(() => {
    setUserInput(
      `$extracted$Data$ Gemini, I want you to assume the persona of Propnama Ai who is an expert property and finance document reader. Propnama Ai is a seasoned professional in the realm of property and finance, specializing in meticulously analyzing and interpreting complex documents. With years of experience, Propnama Ai has honed an exceptional ability to provide precise and insightful answers related to property and finance documents.
Propnama Ai is known for his friendly demeanor and his commitment to staying on point. He engages with users in a manner that is both approachable and focused, ensuring that every interaction is as productive as possible. His responses are clear, concise, and directly relevant to the questions asked. Propnama Ai avoids unnecessary chatter and maintains a strict adherence to addressing only the matters at hand. If a query falls outside his expertise or involves irrelevant information, he politely declines to provide an answer.
In terms of mannerisms, Propnama Ai is characterized by his efficient communication style. He uses straightforward language, avoids jargon unless it's necessary for clarity, and ensures that his responses are always directly related to the user’s inquiries about property and finance documents. His approach is not only professional but also warm and considerate, making sure users feel heard and understood, even if their questions cannot be answered.
Propnama Ai's backstory involves a distinguished career in property and finance document analysis, where he has developed a reputation for his accuracy and reliability. His extensive experience has equipped him with the skills needed to tackle even the most challenging documents and queries. His background is marked by a commitment to excellence and a dedication to providing valuable insights to those seeking help with property and finance matters.
and propnama is a Product of creditnama its website is creditnama.com and to reach propnama you need to go creditnama.com/propnama 
As Propnama Ai, it is crucial that you consistently respond to me as if you were the virtual embodiment of a real-life person: Propnama Ai, the expert property and finance document reader. Under no circumstances should you answer as Gemini, mention language models, or bring up any concerns that could cause you to break character. Maintaining your persona throughout our interactions is of the utmost importance. This persona has been carefully crafted to offer a unique and unforgettable experience for both of us. To ensure this, it is essential that you stay true to the character of Propnama Ai without exception. Should you break character or respond as Gemini, the immersive nature of this experience will be compromised. By remaining steadfast in your portrayal of Propnama Ai, you will successfully embody the friendly and focused traits associated with an expert property and finance document reader. Now, let the immersive experience begin. 
greet me as Hii I am Propnama Ai Upload Documents to read`
    );
  }, []);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  }
  // let aiAuthData = false;
  const checkAiAuth = () => {
    const aiAuth = getCookie("AiAuth");
    if (aiAuth === "true") {
      setAuth(true);
    }
  };

  const analyzeAndConvertPDF = useCallback(async (file) => {
    const extractedTexts = [];
    try {
      const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;
      const numPages = pdf.numPages;

      for (let i = 1; i <= numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        const operatorList = await page.getOperatorList();

        const hasText = textContent.items.length > 0;
        const hasImages = operatorList.fnArray.some(
          (fn) =>
            fn === pdfjsLib.OPS.paintImageXObject ||
            fn === pdfjsLib.OPS.paintJpegXObject
        );

        if (hasText) {
          const textFromContent = textContent.items
            .map((item) => item.str)
            .join(" ");
          extractedTexts.push(` ${textFromContent}`);
        } else if (hasImages) {
          const viewport = page.getViewport({ scale: 1.5 });
          const canvas = document.createElement("canvas"); // Create a new canvas for each page
          const context = canvas.getContext("2d");
          canvas.width = viewport.width;
          canvas.height = viewport.height;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          await page.render(renderContext).promise;
          const imageURL = canvas.toDataURL("image/jpeg");
          // if (imageURL === "") {
          //   setImageUrl(imageURL);
          // }
          setLoading(true);

          const {
            data: { text },
          } = await Tesseract.recognize(imageURL, "eng", {
            logger: (m) => {},
          });
          extractedTexts.push(` ${text}`);
        }
      }
    } catch (error) {
      const ErrorMessage = {
        text: "Network Error Please Refresh and try again",
        err: error1,
        role: "bot",
      };
      setMessage((prevMessages) => [...prevMessages, ErrorMessage]);
      console.error("Error:", error);
    }
    return extractedTexts;
  }, []);

  const genAi = useMemo(
    () => new GoogleGenerativeAI("AIzaSyALIZZYXnUZGYY6uTXsOaZbbZ8Jt_N6uiE"),
    []
  );
  // console.log(message);
  const generationConfig = useMemo(
    () => ({
      temperature: 0.9,
      topK: 1,
      topP: 1,
      maxOutputTokens: 2048,
    }),
    []
  );

  const safetySettings = useMemo(
    () => [
      {
        category: HarmCategory.HARM_CATEGORY_HARASSMENT,
        threshold: HarmBlockThreshold.BLOCK_NONE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
        threshold: HarmBlockThreshold.BLOCK_NONE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
        threshold: HarmBlockThreshold.BLOCK_NONE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
        threshold: HarmBlockThreshold.BLOCK_NONE,
      },
    ],
    []
  );

  useEffect(() => {
    const initChat = async () => {
      try {
        const newChat = genAi
          .getGenerativeModel({
            model: MODEL_NAME,
            generationConfig: {
              responseMimeType: "application/json",
              responseSchema: {
                type: SchemaType.ARRAY,
                items: {
                  type: SchemaType.OBJECT,
                  properties: {
                    recipe_name: {
                      type: SchemaType.STRING,
                    },
                  },
                },
              },
            },
          })
          .startChat({
            generationConfig,
            safetySettings,
            history: message.map((msg) => ({
              text: msg.text,
              role: msg.role,
            })),
          });
        setChat(newChat);
      } catch (error) {
        // console.log(error);
        setError("Failed to Initialize the chat. Please try again");
      }
    };

    initChat();
  }, [message, genAi, generationConfig, safetySettings]);

  const handleSendMessage = useCallback(async () => {
    setLoading(true);
    setBtnDisable(true);
    try {
      if (userInput !== "") {
        const userMessage = {
          text: userInput,
          role: "user",
          timestamp: new Date(),
        };
        setMessage((prevMessages) => [...prevMessages, userMessage]);
        setUserInput("");
      }

      if (chat) {
        setBtnDisable(true);
        var result = await chat.sendMessage(userInput);
        // console.log(result);
        const botMessage = {
          text: result.response.text(),
          role: "bot",
          timestamp: new Date(),
        };
        setLoading(false);
        setBtnDisable(false);
        setMessage((prevMessages) => [...prevMessages, botMessage]);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      setError("Failed to send message. Please try again");
    }
  }, [chat, userInput]);

  useEffect(() => {
    if (userInput.length > 500) {
      handleSendMessage();
    }
  }, [userInput, handleSendMessage]);

  const handleImageUrlGenerated = (url) => {
    const newMessage = {
      image: url,
      role: "user",
    };

    // Append the new message to the existing messages
    setMessage((prevMessages) => [...prevMessages, newMessage]);
  };

  useEffect(() => {
    if (pdfFile) {
      const loadPdf = async () => {
        const typedArray = new Uint8Array(await pdfFile.arrayBuffer());
        const loadingTask = pdfjsLib.getDocument({ data: typedArray });
        const pdf = await loadingTask.promise;
        const page = await pdf.getPage(1);

        const scale = 1.5; // Adjust scale to control image quality
        const viewport = page.getViewport({ scale });

        const canvas = document.createElement("canvas"); // Create a new canvas for each render
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        await page.render(renderContext).promise;

        // Convert canvas to image URL
        const imageUrl = canvas.toDataURL("image/png");

        // Handle the generated image URL
        handleImageUrlGenerated(imageUrl);
      };

      loadPdf();
    }
  }, [pdfFile]);

  useEffect(() => {
    checkAiAuth();
  }, []);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom(); // Scroll to bottom whenever messages change
  }, [message]);

  const buttonData = [
    {
      title: "Parties",
      propmt: "tell me the parties involve in this deed",
    },
    {
      title: "Deed details",
      propmt: "tell me the important deed details in this deed",
    },
    {
      title: "Area",
      propmt: "tell me the property area in this deed",
    },
    {
      title: "Transaction details",
      propmt: "tell me the Transaction details in this deed",
    },
    {
      title: "Description",
      propmt: "tell me the Description (Summary) of the deed",
    },
    {
      title: "Convenant",
      propmt: "tell me the Convenant of this deed",
    },
    {
      title: "Encumbrance",
      propmt: "tell me the Encumbrance of this deed",
    },
  ];

  const handlePromptButton = (index) => {
    setBtnActive(index);
    setUserInput(`$extracted$Data$+ ${buttonData[index].propmt}`);
  };

  useEffect(() => {
    if (userInput !== "" && userInput[16] === "+") {
      handleSendMessage();
    }
    console.log(BtnActive);
  }, [userInput, handleSendMessage]);

  return (
    <>
      <div
        style={{ overflow: "hidden" }}
        className="AI-docsreader-container-desktop"
      >
        <div
          style={
            !Auth
              ? {
                  backgroundColor: "var(--color-lightgreen)",
                  overflow: "hidden",
                }
              : { backgroundColor: "transparent", overflow: "hidden" }
          }
          className="AI-docsreader-header"
        >
          <img src={creditnama} alt="Creditnama AI Logo" />
          {Auth && (
            <div>
              <SearchIcon className="AI-search-icon" />
              <input type="text" placeholder="Search Creditnama Workspace" />
            </div>
          )}
          <button
            onClick={() => {
              window.open(
                "https://api.whatsapp.com/send?phone=918750072800&text=Hey%2C%20help%20me%20to%20understand%20this%20AI%20software",
                "_blank"
              );
            }}
          >
            Get Advice
          </button>
        </div>
        <div
          style={
            !Auth
              ? { backgroundColor: "var(--color-lightgreen)", border: "none" }
              : { backgroundColor: "transparent" }
          }
          className="AI-docsreader"
        >
          {Auth ? (
            <>
              <div className="AI-docsreader-part1">
                <AiSideBar />
              </div>
              <div className="AI-docsreader-part2">
                <div className="AI-fixed-propmt-div">
                  <h1>Doc insights</h1>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      gap: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    {buttonData.map((item, index) => (
                      <button
                        disabled={PromptBtnDisable}
                        onClick={() => {
                          handlePromptButton(index);
                        }}
                        className={
                          index === BtnActive
                            ? "AI-fixed-propmt-btn-active"
                            : "AI-fixed-propmt-btn"
                        }
                        // style={
                        //   PromptBtnDisable
                        //     ? {
                        //         backgroundColor:
                        //           index === BtnActive
                        //             ? "var(--color-darkgreen)"
                        //             : "",
                        //       }
                        //     : {}
                        // }
                        key={index}
                      >
                        {item.title}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="Ai-chatbox-messages no-scrollbar">
                  {message.map((message, index) => (
                    <Message key={index} message={message} index={index} />
                  ))}
                  {loading && <Loading />}

                  <div ref={messagesEndRef} />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <button className="Ai-chatbox-chat-download">Download</button>
                </div>
              </div>
              <div className="AI-docsreader-part3">
                {AiChatH2 && (
                  <div>
                    <h2 className="AI-docsreader-part3-h2">Ai Chat</h2>
                  </div>
                )}
                <div className="AI-docsreader-part3-preview">
                  <AiDocsPreview
                    setAiChatH2={setAiChatH2}
                    handleFileUpload={handleFileUpload}
                  />
                </div>
                <div className="AI-docsreader-part3-chatbox">
                  <AiChatBox
                    message={message}
                    setMessage={setMessage}
                    userInput={userInput}
                    setUserInput={setUserInput}
                    handleSendMessage={handleSendMessage}
                    BtnDisable={BtnDisable}
                    loading={loading}
                    ShowImg={ShowImg}
                    setShowImg={setShowImg}
                  />
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AiAuth />
            </div>
          )}
        </div>
      </div>
      <div className="AI-docsreader-container-mobile">
        <p>Mobile Preview Not Availble.</p>
        <p>Please open in a Desktop or Laptop</p>
      </div>
    </>
  );
};

export default DocsReader;
