import React, { useEffect, useRef, useState } from "react";
import Header from "../../User-Components/Front-Page-Components/Header/Header";
import Footer from "../../User-Components/Front-Page-Components/Footer/Footer";
import ImgFlag from "../../Images/Assets/indiaflagcn@2x.png";
import Animation1 from "../../Animation/animation";
import HeadImg from "../../Images/Page-Img/Property details.png";
import { Link, useNavigate } from "react-router-dom";
import Sample1 from "../../Images/Page-Img/Landing-Img-1.png";
import Sample2 from "../../Images/Page-Img/Landing-Img-2.png";
import Sample3 from "../../Images/Page-Img/Landing-Img-3.png";
import PartnerBanks from "../../Images/BanksLogo.jpg";
import Home from "../../Images/LandingPage-Vectors/HLIcon.png";
import LAP from "../../Images/LandingPage-Vectors/LAPIcon.png";
import CPP from "../../Images/LandingPage-Vectors/CPIcon.png";
import Startupindia from "../../Images/Assets/startup@2x.png";
import Digilocker from "../../Images/Assets/digicn@2x.png";
import ISO from "../../Images/Assets/isocn@2x.png";
// import TLS from "../../Images/Assets/TLS.png";
import AWS from "../../Images/Assets/AWS.png";
import "./userPage.css";
// import EmiCalulatorForLandingPage from "../../User-Components/Front-Page-Components/EMI-Calculator/EmiCalulatorForLandingPage";
import BlogPart from "../../Test/BlogPart";
import axios from "axios";
import serverIP from "../../config/config";
import Vedio from "../../Images/LandingPage-Vectors/LandingV.mp4";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Helmet } from "react-helmet-async";
import Calculators from "../../User-Components/Calculators/Calculator";
import Banners from "../../User-Components/Front-Page-Components/Banners/Banners";

function SigninPage() {
  const images = [Sample1, Sample2, Sample3];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [isTyping, setIsTyping] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImageSubheadIndex, setCurrentImageSubheadIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(null);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [error, setError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOTP] = useState("");
  const [timer, setTimer] = useState(45);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [isWhatsappOptedIn, setIsWhatsappOptedIn] = useState(true);
  const [ipAddress, setIpAddress] = useState("");
  const [operatingSystem, setOperatingSystem] = useState("");
  const [browser, setBrowser] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [screenResolution, setScreenResolution] = useState("");
  const [referringURL, setReferringURL] = useState("");
  const [userAgent, setUserAgent] = useState("");
  const [visitorIpType, setVisitorIpType] = useState("");
  const [utm, setUtm] = useState({
    utmSource: null,
    utmMedium: null,
    utmCampaign: null,
    utmContent: null,
    utmTerm: null,
  });
  useEffect(() => {
    const textToType = [
      "Offers",
      "Results",
      "Financing",
      "Eligibility",
      "Decisions",
      "Sanctions",
    ];

    const timeoutId = setTimeout(() => {
      if (isTyping) {
        setCurrentText((prevText) =>
          textToType[currentWordIndex].slice(0, prevText.length + 1)
        );

        if (currentText === textToType[currentWordIndex]) {
          setTimeout(() => {
            setIsTyping(false);
          }, 2000);
        }
      } else {
        setCurrentText((prevText) => prevText.slice(0, -1));

        if (currentText === "") {
          setIsTyping(true);
          setCurrentWordIndex(
            (prevIndex) => (prevIndex + 1) % textToType.length
          );
        }
      }
    }, 30);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTyping, currentWordIndex]);

  useEffect(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    const getVisitorInfo = () => {
      setOperatingSystem(navigator.platform);
      setBrowser(navigator.userAgent);
      setDeviceType(getDeviceType());
      setScreenResolution(`${window.screen.width}x${window.screen.height}`);
      setReferringURL(document.referrer);
      setUserAgent(navigator.userAgent);

      const { utmSource, utmMedium, utmCampaign, utmTerm, utmContent } =
        getUTMParameters();
      setUtm({ utmSource, utmMedium, utmCampaign, utmTerm, utmContent });
    };

    getVisitorInfo();
    getUserIp();
  }, []);

  const getDeviceType = () => {
    const userAgent = navigator.userAgent;
    if (
      userAgent.match(/Android/i) ||
      userAgent.match(/webOS/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPod/i) ||
      userAgent.match(/BlackBerry/i) ||
      userAgent.match(/Windows Phone/i)
    ) {
      return "Mobile";
    } else if (userAgent.match(/iPad/i) || userAgent.match(/Tablet/i)) {
      return "Tablet";
    } else {
      return "Desktop";
    }
  };

  const getUTMParameters = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    return {
      utmSource: urlParams.get("utm_source"),
      utmMedium: urlParams.get("utm_medium"),
      utmCampaign: urlParams.get("utm_campaign"),
      utmTerm: urlParams.get("utm_term"),
      utmContent: urlParams.get("utm_content"),
    };
  };

  const getUserIp = async () => {
    try {
      const response = await fetch(`${serverIP}/visitor/getIP`);
      const data = await response.json();
      setIpAddress(data.ipAddress);
      setVisitorIpType(data.ipType);
    } catch (error) {
      console.error("Error fetching user IP:", error);
    }
  };

  useEffect(() => {
    // Check if the data has already been submitted for this user
    const visitorId = localStorage.getItem("visitorId");
    if (visitorId) {
      // Data has already been submitted, no need to submit again
      // console.log("Data has already been submitted for this visitor.");
      return;
    }
    const handleVisitors = async () => {
      const visitorData = {
        visitorIp: ipAddress,
        visitorIpType: visitorIpType,
        userAgent: userAgent,
        referringURL: referringURL,
        screenResolution: screenResolution,
        deviceType: deviceType,
        operatingSystem: operatingSystem,
        browser: browser,
        utm_source: utm.utmSource,
        utm_medium: utm.utmMedium,
        utm_campaign: utm.utmCampaign,
        utm_term: utm.utmTerm,
        utm_content: utm.utmContent,
      };

      const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(visitorData),
      };

      try {
        const response = await fetch(`${serverIP}/visitor/newvisitor`, config);
        const data = await response.json();
        console.log("Data:", data);
        localStorage.setItem("visitorId", data.visitorId);
      } catch (error) {
        console.error("Error submitting data:", error);
        // Handle error here, maybe show a message to the user
      }
    };

    const timeoutId = setTimeout(() => {
      handleVisitors();
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [
    browser,
    deviceType,
    ipAddress,
    operatingSystem,
    referringURL,
    screenResolution,
    userAgent,
    utm,
    visitorIpType,
  ]);

  const history = useNavigate();

  const ImageSubhead = [
    "Create your profile & check your eligibility.",
    "Get personalized offers based on bank assessments.",
    <>
      Sit back & relax!
      <br />
      Stay updated as Creditnama navigates processing for you.
    </>,
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Change the current image index every 3 seconds
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
      setCurrentImageSubheadIndex((prevIndex) =>
        prevIndex === ImageSubhead.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    // Clear the interval on component unmount to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [images.length, ImageSubhead.length]);

  useEffect(() => {
    // Move the initialization of 'textToType' inside the useEffect callback
    const textToType = [
      "Offers",
      "Eligibility",
      "Results",
      "Financing",
      "Decisions",
      "Sanctions",
    ];

    const timeoutId = setTimeout(() => {
      if (isTyping) {
        // Typing simulation
        setCurrentText((prevText) =>
          textToType[currentWordIndex].slice(0, prevText.length + 1)
        );

        // If fully typed, start backspacing after a delay
        if (currentText === textToType[currentWordIndex]) {
          setTimeout(() => {
            setIsTyping(false);
          }, 2000); // Wait for 2 seconds before starting backspacing
        }
      } else {
        // Backspacing simulation
        setCurrentText((prevText) => prevText.slice(0, -1));

        // If fully backspaced, switch to typing the next word
        if (currentText === "") {
          setIsTyping(true);
          setCurrentWordIndex(
            (prevIndex) => (prevIndex + 1) % textToType.length
          );
        }
      }
    }, 30);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [currentText, isTyping, currentWordIndex]); // Include only necessary dependencies

  const handleDotClick = (index) => {
    // Handle click on a dot to change the current image
    setCurrentImageIndex(index);
    setCurrentImageSubheadIndex(index);
  };
  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (touchStartX !== null) {
      const touchEndX = e.touches[0].clientX;
      const deltaX = touchEndX - touchStartX;

      if (deltaX > 50) {
        // Swipe right
        setCurrentImageIndex(
          (prevSlide) => (prevSlide - 1 + images.length) % images.length
        );
        setCurrentImageSubheadIndex(
          (prevSlide) =>
            (prevSlide - 1 + ImageSubhead.length) % ImageSubhead.length
        );
        setTouchStartX(null);
      } else if (deltaX < -50) {
        // Swipe left
        setCurrentImageIndex((prevSlide) => (prevSlide + 1) % images.length);
        setCurrentImageSubheadIndex(
          (prevSlide) => (prevSlide + 1) % ImageSubhead.length
        );

        setTouchStartX(null);
      }
    }
  };

  //  ####################### For TeleCRM ############################################

  const handleTeleCRM = async () => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer 2c438757-dc99-4297-b188-b06bba3341651714828905449:e51d6e0b-9961-45b9-bcf0-bae006ffa758",
      },
      body: JSON.stringify({
        fields: {
          phone: `${phoneNumber}`,
        },
        actions: [
          {
            type: "SYSTEM_NOTE",
            text: "Lead Source: Website Landing-Page",
          },
          {
            type: "SYSTEM_NOTE",
            text: "Session ID created",
          },
        ],
      }),
    };

    try {
      const response = await fetch(
        `https://api.telecrm.in/enterprise/66361419545ce40e83b1154b/autoupdatelead`,
        config
      );
      if (response.status >= 200 && response.status < 300) {
        // const data = await response.json();
        console.log("Lead updated successfully");
        // console.log(response);
      } else {
        console.error("Lead update failed. Error", response.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   ########################################################################
  const handlePhoneNumberSubmit = async (e) => {
    e.preventDefault();

    if (phoneNumber.length !== 10 || !/[6-9]/.test(phoneNumber[0])) {
      setError("Enter Correct Mobile Number");
      return;
    }
    setShowAnimation(true);
    try {
      const response = await axios.post(`${serverIP}/user/generate-otp`, {
        phoneNumber: phoneNumber,
        whatsappOptIn: isWhatsappOptedIn,
      });
      if (response.status === 200 || response.status < 300) {
        startTimer();
        console.log("OTP Send");
        setShowOTPInput(true);
        setShowAnimation(false);
        localStorage.setItem("sessionId", response.data.sessionId);
        handleSendSessionID();
        handleTeleCRM();
      } else {
        setError("Error to Send OTP");
        setShowAnimation(false);
      }
    } catch (error) {
      console.log("Error sending mobile number:", error);
    } finally {
      setShowAnimation(false);
    }
  };
  //   ########################################################################
  const startTimer = () => {
    setIsResendDisabled(true);
    let countdown = 45;

    const interval = setInterval(() => {
      countdown -= 1;
      setTimer(countdown);

      if (countdown === 0) {
        clearInterval(interval);
        setIsResendDisabled(false);
      }
    }, 1000);
  };

  useEffect(() => {
    if (showOTPInput) {
      startTimer();
    }
  }, [showOTPInput]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 45);
    const remainingSeconds = seconds % 45;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };
  // ############################################################################
  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    setShowAnimation(true);
    try {
      const response = await axios.post(`${serverIP}/auth/verify-otp`, {
        phoneNumber: phoneNumber,
        enteredOTP: otp,
      });
      if (response.data.isOTPValid === true) {
        localStorage.setItem("userId", response.data.userId);
        localStorage.setItem("accessToken", response.data.token);
        handleTeleCRMUpadte();
        history("/profile1");
      } else {
        setError("Incorrect OTP");
        setShowAnimation(false);
      }
    } catch (error) {
      console.log("Error while sending OTP:", error);
    } finally {
      setShowAnimation(false);
    }
  };

  //   #######################################################################
  const handlePhoneInput = (e) => {
    e.preventDefault();
    setPhoneNumber(e.target.value);
    setError("");
  };

  const handleOtpInput = (e) => {
    e.preventDefault();
    setOTP(e.target.value);
    setError("");
  };

  const handleCheckboxChange = (e) => {
    setIsWhatsappOptedIn(e.target.checked);
    // console.log(!e.target.checked);
  };

  const handleSendSessionID = async () => {
    try {
      const response = await axios.post(
        `${serverIP}/visitor/${localStorage.getItem("visitorId")}`,
        {
          sessionId: localStorage.getItem("sessionId"),
        }
      );

      if (response.status === 200 || response.status < 300) {
        console.log("Session ID sent");
      } else {
        console.log("Error sending session ID");
      }
    } catch (error) {
      console.log("Error sending sessionId:", error);
    }
  };

  const containerRef = useRef(null);

  const scrollNext = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 500,
        behavior: "smooth",
      });
    }
  };
  const scrollPrev = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -500,
        behavior: "smooth",
      });
    }
  };

  const handleTeleCRMUpadte = async () => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer 2c438757-dc99-4297-b188-b06bba3341651714828905449:e51d6e0b-9961-45b9-bcf0-bae006ffa758",
      },
      body: JSON.stringify({
        fields: {
          phone: `${phoneNumber}`,
        },
        actions: [
          {
            type: "SYSTEM_NOTE",
            text: "User ID created",
          },
        ],
      }),
    };

    try {
      const response = await fetch(
        `https://api.telecrm.in/enterprise/66361419545ce40e83b1154b/autoupdatelead`,
        config
      );
      if (response.status >= 200 && response.status < 300) {
        console.log("Lead updated successfully");
      } else {
        console.error("Lead update failed. Error", response.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Creditnama</title>
        <meta name="description" content="Seamless Property LOans" />
        <link rel="canonical" href="/" />
      </Helmet>
      <div className="CN-Test-HeaderDiv">
        <Header />
      </div>
      <div className="CN-Test-LandingPage">
        <div className="CN-Test-Section1">
          <h1>
            Real Estate <br />
            <span className="CN-Test-SectionHead">
              {" "}
              Real-Time{" "}
              <span className="CN-Test-ChangeWord"> {currentText}</span>
            </span>
          </h1>
          <p>
            With Creditnama, compare through 40+ banks and NBFCs to find the
            exact property loan that fits your credit needs in real-time.
          </p>
          <p className="CN-Test-Para2">
            Our financing experts and advanced technology ensure transparent
            loan terms and secured processing with your preferred bank.
          </p>

          <div className="CN2-Test-Signin-Div">
            {showAnimation ? (
              <>
                <Animation1 />
              </>
            ) : (
              <>
                {showOTPInput ? (
                  <>
                    <form onSubmit={handleOTPSubmit}>
                      <h2>Find Your Best Eligibility Today</h2>

                      <img
                        src={HeadImg}
                        alt="Head Img"
                        className="CN2-Test-Head-Img"
                        onContextMenu={(e) => e.preventDefault()}
                        loading="lazy"
                      />
                      {error && (
                        <div className="CN2-Error-Handling">{error}</div>
                      )}

                      <input
                        placeholder="Enter OTP"
                        type="tel"
                        className="CN2-Test-Signin-Input "
                        id="otp-input"
                        value={otp}
                        maxLength={6}
                        onChange={handleOtpInput}
                        required
                      />
                      <button className="CN2-Signin-Button">Submit OTP</button>
                      {isResendDisabled ? (
                        <p className="CN2-ResendOtp">
                          Resend OTP {formatTime(timer)}
                        </p>
                      ) : (
                        <p
                          className="CN2-ResendOtp"
                          style={{ color: "blue" }}
                          onClick={handlePhoneNumberSubmit}
                        >
                          Resend OTP
                        </p>
                      )}
                    </form>
                  </>
                ) : (
                  <>
                    <form onSubmit={handlePhoneNumberSubmit}>
                      <h2>Find Your Best Eligibility Today</h2>

                      <img
                        src={HeadImg}
                        alt="Head Img"
                        className="CN2-Test-Head-Img"
                        onContextMenu={(e) => e.preventDefault()}
                        loading="lazy"
                      />
                      {error && (
                        <div className="CN2-Error-Handling">{error}</div>
                      )}

                      <div className="CN2-Signin-Container">
                        <img
                          src={ImgFlag}
                          alt="Head Img"
                          style={{ width: "40px" }}
                          onContextMenu={(e) => e.preventDefault()}
                          loading="lazy"
                        />
                        +91{" "}
                        <input
                          placeholder="Enter Phone Number"
                          type="tel"
                          className="CN2-Test-Signin-Input "
                          required
                          id="phone-input"
                          value={phoneNumber}
                          maxLength={10}
                          onChange={handlePhoneInput}
                        />
                      </div>
                      <div className="CN2-Test-Signin-checkbox">
                        <div>
                          <input
                            type="checkbox"
                            className="CN2-Test-Checkbox-Style mx-2"
                            required
                          />
                          I accept{" "}
                          <Link className="mx-1" to="/terms">
                            terms of service & applicable policies
                          </Link>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            className="CN2-Test-Checkbox-Style mx-2"
                            checked={isWhatsappOptedIn}
                            onChange={handleCheckboxChange}
                          />
                          Opt in to receive important updates on WhatsApp
                        </div>
                      </div>

                      <button className="CN2-Signin-Button">Send OTP</button>
                    </form>
                  </>
                )}
              </>
            )}
          </div>
          <div
            className="CN2-Test-Vedio-Div"
            onContextMenu={(e) => e.preventDefault()}
          >
            <video
              autoPlay
              muted
              loop
              id="video-bg"
              className="CN2-Test-Vedio1"
              controlsList="nodownload"
              loading="lazy"
            >
              <source src={Vedio} type="video/mp4" />
            </video>
          </div>
        </div>
        <Banners />
        <div className="CN-Test-Section2">
          <h2>Creditnama Offers Real-Time Results</h2>
          <div
            className="CN2-Test-Main2-Container"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            <div className="CN2-Test-Main2-TextImg-Container">
              <div className="CN2-Test-Main2-Container-Left">
                {ImageSubhead[currentImageSubheadIndex]}
              </div>
              <div>
                <img
                  src={images[currentImageIndex]}
                  alt={`Slide ${currentImageIndex + 1}`}
                  className="CN2-Test-Phone-Img"
                  loading="lazy"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              {images.map((_, index) => (
                <div
                  key={index}
                  className={`dot ${
                    index === currentImageIndex ? "active" : ""
                  }`}
                  onClick={() => handleDotClick(index)}
                ></div>
              ))}
            </div>
          </div>
        </div>
        <div className="CN-Test-Section3" id="Partners">
          <h2>Across 40+ Financial Institutions To Meet Your Credit Needs</h2>
          <div className="CN2-Test-Main3-Container">
            <img
              src={PartnerBanks}
              alt="Partner-Banks"
              className="CN2-Test-Partner-Banks"
              onContextMenu={(e) => e.preventDefault()}
              loading="lazy"
            />
            <img
              src={PartnerBanks}
              alt="Partner-Banks"
              className="CN2-Test-Partner-Banks"
              onContextMenu={(e) => e.preventDefault()}
              loading="lazy"
            />
            <img
              src={PartnerBanks}
              alt="Partner-Banks"
              className="CN2-Test-Partner-Banks"
              onContextMenu={(e) => e.preventDefault()}
              loading="lazy"
            />
          </div>

          <h2>Products Offered</h2>
          <div className="CN2-Test-Offered-Product-Container" id="Products">
            <a
              href="https://www.creditnama.com/home-loan"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="CN2-Test-Products-Div">
                <h2>
                  <b>Home Loan</b>
                </h2>
                <img
                  src={Home}
                  alt="HL"
                  className="CN2-Test-Offered-Product-Icon"
                  onContextMenu={(e) => e.preventDefault()}
                  loading="lazy"
                />
                <p className="CN2-Test-About-Offers">
                  Finance your dream home with a loan that can be repaid in
                  monthly installments over a period of 5-30 years.
                </p>
              </div>
            </a>
            <a
              href="https://www.creditnama.com/loan-against-property"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="CN2-Test-Products-Div">
                <h2>
                  <b>Loan Against Property</b>
                </h2>
                <img
                  src={LAP}
                  alt="LAP"
                  className="CN2-Test-Offered-Product-Icon"
                  onContextMenu={(e) => e.preventDefault()}
                  loading="lazy"
                />
                <p className="CN2-Test-About-Offers">
                  Secure a low-interest loan using your property as collateral,
                  with flexible fund usage and easy monthly repayments.
                </p>
              </div>
            </a>
            <a
              href="https://www.creditnama.com/commercial-property-loan"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="CN2-Test-Products-Div">
                <h2>
                  <b>Commercial Property</b>
                </h2>
                <img
                  src={CPP}
                  alt="CPP"
                  className="CN2-Test-Offered-Product-Icon"
                  onContextMenu={(e) => e.preventDefault()}
                  loading="lazy"
                />
                <p className="CN2-Test-About-Offers">
                  Finance your perfect commercial space - office, shop, or
                  storage - to grow your business and repay over 5-15 years.
                </p>
              </div>
            </a>
          </div>
        </div>
        <div className="CN-Test-Section4">
          {/* <EmiCalulatorForLandingPage Heading="EMI Calculator" /> */}
          <Calculators />
        </div>
        <div className="CN-Test-Section5">
          <div>
            <h2>
              Comfort of Digital Offers Without Hassle of Scams or Telecalls
            </h2>
            <div className="CN2-Test-Ceriticates-Container">
              <div className="CN2-Test-Trust-Div">
                <img
                  src={Startupindia}
                  alt="Startupindia"
                  className="CN2-Test-Ceriticates-Img"
                  onContextMenu={(e) => e.preventDefault()}
                  loading="lazy"
                />
              </div>
              <div className="CN2-Test-Trust-Div">
                <img
                  src={Digilocker}
                  alt="Digilocker"
                  className="CN2-Test-Ceriticates-Img"
                  onContextMenu={(e) => e.preventDefault()}
                  loading="lazy"
                  style={{ padding: "10px" }}
                />
              </div>
              <div className="CN2-Test-Trust-Div">
                <img
                  src={AWS}
                  alt="AWS"
                  className="CN2-Test-Ceriticates1-Img"
                  onContextMenu={(e) => e.preventDefault()}
                  loading="lazy"
                />
              </div>
              <div className="CN2-Test-Trust-Div">
                <img
                  src={ISO}
                  alt="ISO"
                  className="CN2-Test-Ceriticates1-Img"
                  onContextMenu={(e) => e.preventDefault()}
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          <div className="CN2-Test-LatestThinking">
            <h2>Latest Thinking</h2>
            <div className="CN2-Test-Blog-Container" ref={containerRef}>
              <BlogPart />
            </div>
            <div className="CN2-Test-Blog-Pagination">
              <button onClick={scrollPrev}>
                <NavigateNextIcon style={{ rotate: "180deg" }} />
              </button>
              <button onClick={scrollNext}>
                <NavigateNextIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SigninPage;

// import React, { useEffect, useState } from "react";
// import "./userPage.css";
// import HeadImg from "../../Images/Page-Img/Property details.png";
// import PartnerBanks from "../../Images/BanksLogo.jpg";
// import { Link } from "react-router-dom";
// import Home from "../../Images/Assets/HL.png";
// import LAP from "../../Images/Assets/LAP.png";
// import CPP from "../../Images/Assets/ownsetup.png";
// import Startupindia from "../../Images/Assets/startup@2x.png";
// import Digilocker from "../../Images/Assets/digicn@2x.png";
// import ISO from "../../Images/Assets/isocn@2x.png";
// import TLS from "../../Images/Assets/TLS.png";
// import AWS from "../../Images/Assets/AWS.png";
// import Sample1 from "../../Images/Page-Img/Landing-Img-1.png";
// import Sample2 from "../../Images/Page-Img/Landing-Img-2.png";
// import Sample3 from "../../Images/Page-Img/Landing-Img-3.png";
// import Footer from "../../User-Components/Front-Page-Components/Footer/Footer";
// import Blog1 from "../../Images/BlogsImages/Blog1Img.png";
// import Blog2 from "../../Images/BlogsImages/Blog2-1Img.png";
// import Blog3 from "../../Images/BlogsImages/Blog3-ThumImg.png";
// import Blog4 from "../../Images/BlogsImages/Blog4-1Img.jpg";
// import Blog5 from "../../Images/BlogsImages/Blog5-1Img.jpg";
// import Blog6 from "../../Images/BlogsImages/Blog6-1Img.png";
// import Blog7 from "../../Images/BlogsImages/Blog7-1Img.png";
// import ImgFlag from "../../Images/Assets/indiaflagcn@2x.png";
// import { useNavigate } from "react-router-dom";
// import Header from "../../User-Components/Front-Page-Components/Header/Header";
// import axios from "axios";
// import serverIP from "../../config/config";
// import Animation1 from "../../Animation/animation";

// function SigninPage() {
//   const images = [Sample1, Sample2, Sample3];
//   const [currentWordIndex, setCurrentWordIndex] = useState(0);
//   const [currentText, setCurrentText] = useState("");
//   const [isTyping, setIsTyping] = useState(true);
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);
//   const [currentImageSubheadIndex, setCurrentImageSubheadIndex] = useState(0);
//   const [touchStartX, setTouchStartX] = useState(null);
//   const [currentBlogIndex, setCurrentBlogIndex] = useState(0);
//   const [showOTPInput, setShowOTPInput] = useState(false);
//   const [error, setError] = useState("");
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [otp, setOTP] = useState("");
//   const [timer, setTimer] = useState(45);
//   const [isResendDisabled, setIsResendDisabled] = useState(false);
//   const [showAnimation, setShowAnimation] = useState(false);
//   const [isWhatsappOptedIn, setIsWhatsappOptedIn] = useState(true);
//   const [ipAddress, setIpAddress] = useState("");
//   const [operatingSystem, setOperatingSystem] = useState("");
//   const [browser, setBrowser] = useState("");
//   const [deviceType, setDeviceType] = useState("");
//   const [screenResolution, setScreenResolution] = useState("");
//   const [referringURL, setReferringURL] = useState("");
//   const [userAgent, setUserAgent] = useState("");
//   const [visitorIpType, setVisitorIpType] = useState("");
//   const [utm, setUtm] = useState({
//     utmSource: null,
//     utmMedium: null,
//     utmCampaign: null,
//     utmContent: null,
//     utmTerm: null,
//   });

//   useEffect(() => {
//     const getVisitorInfo = () => {
//       setOperatingSystem(navigator.platform);
//       setBrowser(navigator.userAgent);
//       setDeviceType(getDeviceType());
//       setScreenResolution(`${window.screen.width}x${window.screen.height}`);
//       setReferringURL(document.referrer);
//       setUserAgent(navigator.userAgent);

//       const { utmSource, utmMedium, utmCampaign, utmTerm, utmContent } =
//         getUTMParameters();
//       setUtm({ utmSource, utmMedium, utmCampaign, utmTerm, utmContent });
//     };

//     getVisitorInfo();
//     getUserIp();
//   }, []);

//   const getDeviceType = () => {
//     const userAgent = navigator.userAgent;
//     if (
//       userAgent.match(/Android/i) ||
//       userAgent.match(/webOS/i) ||
//       userAgent.match(/iPhone/i) ||
//       userAgent.match(/iPad/i) ||
//       userAgent.match(/iPod/i) ||
//       userAgent.match(/BlackBerry/i) ||
//       userAgent.match(/Windows Phone/i)
//     ) {
//       return "Mobile";
//     } else if (userAgent.match(/iPad/i) || userAgent.match(/Tablet/i)) {
//       return "Tablet";
//     } else {
//       return "Desktop";
//     }
//   };

//   const getUTMParameters = () => {
//     const queryString = window.location.search;
//     const urlParams = new URLSearchParams(queryString);

//     return {
//       utmSource: urlParams.get("utm_source"),
//       utmMedium: urlParams.get("utm_medium"),
//       utmCampaign: urlParams.get("utm_campaign"),
//       utmTerm: urlParams.get("utm_term"),
//       utmContent: urlParams.get("utm_content"),
//     };
//   };

//   const getUserIp = async () => {
//     try {
//       const response = await fetch(`${serverIP}/visitor/getIP`);
//       const data = await response.json();
//       setIpAddress(data.ipAddress);
//       setVisitorIpType(data.ipType);
//     } catch (error) {
//       console.error("Error fetching user IP:", error);
//     }
//   };

//   useEffect(() => {
//     // Check if the data has already been submitted for this user
//     const visitorId = localStorage.getItem("visitorId");
//     if (visitorId) {
//       // Data has already been submitted, no need to submit again
//       // console.log("Data has already been submitted for this visitor.");
//       return;
//     }
//     const handleVisitors = async () => {
//       const visitorData = {
//         visitorIp: ipAddress,
//         visitorIpType: visitorIpType,
//         userAgent: userAgent,
//         referringURL: referringURL,
//         screenResolution: screenResolution,
//         deviceType: deviceType,
//         operatingSystem: operatingSystem,
//         browser: browser,
//         utm_source: utm.utmSource,
//         utm_medium: utm.utmMedium,
//         utm_campaign: utm.utmCampaign,
//         utm_term: utm.utmTerm,
//         utm_content: utm.utmContent,
//       };

//       const config = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(visitorData),
//       };

//       try {
//         const response = await fetch(`${serverIP}/visitor/newvisitor`, config);
//         const data = await response.json();
//         console.log("Data:", data);
//         localStorage.setItem("visitorId", data.visitor.visitorId);
//       } catch (error) {
//         console.error("Error submitting data:", error);
//         // Handle error here, maybe show a message to the user
//       }
//     };

//     const timeoutId = setTimeout(() => {
//       handleVisitors();
//     }, 2000);

//     return () => clearTimeout(timeoutId);
//   }, [
//     browser,
//     deviceType,
//     ipAddress,
//     operatingSystem,
//     referringURL,
//     screenResolution,
//     userAgent,
//     utm,
//     visitorIpType,
//   ]);

//   const history = useNavigate();

//   const containerBlogStyle = {
//     backgroundImage: `url(${Blog1})`,
//     backgroundSize: "cover",
//     loading: "lazy",
//     color: "white",
//     width: "80%",
//     height: "100%",
//     borderRadius: "10px",
//   };

//   const blogData = [
//     {
//       heading: "Delhi's Property Registration shake-up :",
//       subheading:
//         '"Anywhere Registration" aims for Convenience and Transparency',
//       img: Blog1,
//       link: "/delhi-property-registration",
//       width: "100%",
//     },
//     {
//       heading: "What is better for a home loan or LAP – ",
//       subheading: "A bank or an HFC? All you need to know.",
//       img: Blog2,
//       link: "/homeloan-lap-whatsbetter",
//     },
//     {
//       heading: "Navigating Charges ",
//       subheading:
//         " Associated with Home Loans and Loan Against Property – Part 1",
//       img: Blog3,
//       link: "/charges-accociated-hl&lap1",
//     },
//     {
//       heading: "Navigating Charges ",
//       subheading:
//         " Associated with Home Loans and Loan Against Property – Part 2",
//       img: Blog4,
//       link: "/charges-accociated-hl&lap2",
//     },
//     {
//       heading: "Considering a CGTMSE loan? ",
//       subheading: " Here’s all that you need to know!",
//       img: Blog5,
//       link: "/about-CGTMSE",
//     },
//     {
//       heading: "Case for Public Sector Banks: ",
//       subheading:
//         "Where do they do well or fall short, and what does their lending portfolio suggest?",
//       img: Blog6,
//       link: "/casefor-publicsector-bank",
//     },
//     {
//       heading: "Unlocking Savings: ",
//       subheading:
//         " Navigating the Expected Home Loan Interest Rate Drop in 2024",
//       img: Blog7,
//       link: "/unlocking-savings-ratedrop",
//     },
//   ];
//   useEffect(() => {
//     localStorage.removeItem("accessToken");
//     localStorage.removeItem("userId");

//     // Generate a random index for the current blog on component mount
//     const randomIndex = Math.floor(Math.random() * blogData.length);
//     setCurrentBlogIndex(randomIndex);
//   }, [blogData.length]);

//   const currentBlog = blogData[currentBlogIndex];
//   const ImageSubhead = [
//     "Create your profile & check your eligibility.",
//     "Get personalized offers based on bank assessments.",
//     <>
//       Sit back & relax!
//       <br />
//       Stay updated as Creditnama navigates processing for you.
//     </>,
//   ];

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       // Change the current image index every 3 seconds
//       setCurrentImageIndex((prevIndex) =>
//         prevIndex === images.length - 1 ? 0 : prevIndex + 1
//       );
//       setCurrentImageSubheadIndex((prevIndex) =>
//         prevIndex === ImageSubhead.length - 1 ? 0 : prevIndex + 1
//       );
//     }, 3000);

//     // Clear the interval on component unmount to avoid memory leaks
//     return () => clearInterval(intervalId);
//   }, [images.length, ImageSubhead.length]);

//   useEffect(() => {
//     // Move the initialization of 'textToType' inside the useEffect callback
//     const textToType = [
//       "Offers",
//       "Eligibility",
//       "Results",
//       "Financing",
//       "Decisions",
//       "Sanctions",
//     ];

//     const timeoutId = setTimeout(() => {
//       if (isTyping) {
//         // Typing simulation
//         setCurrentText((prevText) =>
//           textToType[currentWordIndex].slice(0, prevText.length + 1)
//         );

//         // If fully typed, start backspacing after a delay
//         if (currentText === textToType[currentWordIndex]) {
//           setTimeout(() => {
//             setIsTyping(false);
//           }, 2000); // Wait for 2 seconds before starting backspacing
//         }
//       } else {
//         // Backspacing simulation
//         setCurrentText((prevText) => prevText.slice(0, -1));

//         // If fully backspaced, switch to typing the next word
//         if (currentText === "") {
//           setIsTyping(true);
//           setCurrentWordIndex(
//             (prevIndex) => (prevIndex + 1) % textToType.length
//           );
//         }
//       }
//     }, 30);

//     return () => {
//       clearTimeout(timeoutId);
//     };
//   }, [currentText, isTyping, currentWordIndex]); // Include only necessary dependencies

//   const handleDotClick = (index) => {
//     // Handle click on a dot to change the current image
//     setCurrentImageIndex(index);
//     setCurrentImageSubheadIndex(index);
//   };
//   const handleTouchStart = (e) => {
//     setTouchStartX(e.touches[0].clientX);
//   };

//   const handleTouchMove = (e) => {
//     if (touchStartX !== null) {
//       const touchEndX = e.touches[0].clientX;
//       const deltaX = touchEndX - touchStartX;

//       if (deltaX > 50) {
//         // Swipe right
//         setCurrentImageIndex(
//           (prevSlide) => (prevSlide - 1 + images.length) % images.length
//         );
//         setCurrentImageSubheadIndex(
//           (prevSlide) =>
//             (prevSlide - 1 + ImageSubhead.length) % ImageSubhead.length
//         );
//         setTouchStartX(null);
//       } else if (deltaX < -50) {
//         // Swipe left
//         setCurrentImageIndex((prevSlide) => (prevSlide + 1) % images.length);
//         setCurrentImageSubheadIndex(
//           (prevSlide) => (prevSlide + 1) % ImageSubhead.length
//         );

//         setTouchStartX(null);
//       }
//     }
//   };
//   const handleBlog1 = () => {
//     history(currentBlog.link);
//   };
//   const handleBlog2 = () => {
//     history("/charges-accociated-hl&lap1");
//   };
//   const handleBlog3 = () => {
//     history("/about-CGTMSE");
//   };
//   const handleBlog4 = () => {
//     history("/homeloan-lap-whatsbetter");
//   };

//   //   ########################################################################
//   const handlePhoneNumberSubmit = async (e) => {
//     e.preventDefault();

//     if (phoneNumber.length !== 10 || !/[6-9]/.test(phoneNumber[0])) {
//       setError("Enter Correct Mobile Number");
//       return;
//     }
//     setShowAnimation(true);
//     try {
//       const response = await axios.post(`${serverIP}/user/generate-otp`, {
//         phoneNumber: phoneNumber,
//         whatsappOptIn: isWhatsappOptedIn,
//       });
//       if (response.status === 200 || response.status < 300) {
//         startTimer();
//         console.log("OTP Send");
//         setShowOTPInput(true);
//         setShowAnimation(false);
//         localStorage.setItem("sessionId", response.data.sessionId);
//         handleSendSessionID();
//       } else {
//         setError("Error to Send OTP");
//         setShowAnimation(false);
//       }
//     } catch (error) {
//       console.log("Error sending mobile number:", error);
//     } finally {
//       setShowAnimation(false);
//     }
//   };
//   //   ########################################################################
//   const startTimer = () => {
//     setIsResendDisabled(true);
//     let countdown = 45;

//     const interval = setInterval(() => {
//       countdown -= 1;
//       setTimer(countdown);

//       if (countdown === 0) {
//         clearInterval(interval);
//         setIsResendDisabled(false);
//       }
//     }, 1000);
//   };

//   useEffect(() => {
//     if (showOTPInput) {
//       startTimer();
//     }
//   }, [showOTPInput]);

//   const formatTime = (seconds) => {
//     const minutes = Math.floor(seconds / 45);
//     const remainingSeconds = seconds % 45;
//     const formattedMinutes = String(minutes).padStart(2, "0");
//     const formattedSeconds = String(remainingSeconds).padStart(2, "0");
//     return `${formattedMinutes}:${formattedSeconds}`;
//   };
//   // ############################################################################
//   const handleOTPSubmit = async (e) => {
//     e.preventDefault();
//     setShowAnimation(true);
//     try {
//       const response = await axios.post(`${serverIP}/auth/verify-otp`, {
//         phoneNumber: phoneNumber,
//         enteredOTP: otp,
//       });
//       if (response.data.isOTPValid === true) {
//         localStorage.setItem("userId", response.data.userId);
//         localStorage.setItem("accessToken", response.data.token);
//         history("/profile1");
//       } else {
//         setError("Incorrect OTP");
//         setShowAnimation(false);
//       }
//     } catch (error) {
//       console.log("Error while sending OTP:", error);
//     } finally {
//       setShowAnimation(false);
//     }
//   };

//   //   #######################################################################
//   const handlePhoneInput = (e) => {
//     e.preventDefault();
//     setPhoneNumber(e.target.value);
//     setError("");
//   };

//   const handleOtpInput = (e) => {
//     e.preventDefault();
//     setOTP(e.target.value);
//     setError("");
//   };

//   const handleCheckboxChange = (e) => {
//     setIsWhatsappOptedIn(e.target.checked);
//     // console.log(!e.target.checked);
//   };

//   const handleSendSessionID = async () => {
//     try {
//       const response = await axios.post(
//         `${serverIP}/visitor/${localStorage.getItem("visitorId")}`,
//         {
//           sessionId: localStorage.getItem("sessionId"),
//         }
//       );

//       if (response.status === 200 || response.status < 300) {
//         console.log("Session ID sent");
//       } else {
//         console.log("Error sending session ID");
//       }
//     } catch (error) {
//       console.log("Error sending sessionId:", error);
//     }
//   };

//   return (
//     <div className="CN2-Creditnama-App">
//       <Header />
//       <div className="CN2-Main1">
//         <div>
//           <h1 className="CN2-h1-Line">Real Estate</h1>
//           <h1 className="CN2-h1-MainLine" style={{ marginTop: "-10px" }}>
//             Real-Time {currentText}.
//           </h1>
//           {/* <h2>Have a good quality property for collateral?</h2> */}
//           <div className="CN2-Subheads">
//             <h3>
//               With Creditnama, compare through 40+ banks and NBFCs to find the
//               exact property loan that fits your credit needs in real-time.
//             </h3>
//             <h3 className="CN2-Subhead1" style={{ marginTop: "10px" }}>
//               Our financing experts and advanced technology ensure transparent
//               loan terms and secured processing with your preferred bank.
//             </h3>
//           </div>
//           <h3>
//             <b>India's future of seamless property loans!</b>
//           </h3>
//           <div className="CN2-ApplyNow-btn">
//             <button className="CN2-NA-btn">
//               <a href="https://wa.me/+918750072800?text=Hi">Need Help?</a>
//             </button>
//             <button>
//               <a href="/login-signup">Apply Now</a>
//             </button>
//           </div>
//         </div>

//         <div className="CN2-Main1-Signin-Div">
//           <h2>Find Your Best Eligibility Today</h2>

//           {showAnimation ? (
//             <>
//               <Animation1 />
//             </>
//           ) : (
//             <>
//               {showOTPInput ? (
//                 <>
//                   <form onSubmit={handleOTPSubmit}>
//                     <img
//                       src={HeadImg}
//                       alt="Head Img"
//                       className="CN2-Head-Img"
//                       onContextMenu={(e) => e.preventDefault()}
//                       loading="lazy"
//                     />
//                     {error && <div className="CN2-Error-Handling">{error}</div>}

//                     <input
//                       placeholder="Enter OTP"
//                       type="tel"
//                       className="CN2-Signin-Input"
//                       id="otp-input"
//                       value={otp}
//                       maxLength={6}
//                       onChange={handleOtpInput}
//                       required
//                     />
//                     <button className="CN2-Signin-Button">Submit OTP</button>
//                     {isResendDisabled ? (
//                       <p className="CN2-ResendOtp">
//                         Resend OTP {formatTime(timer)}
//                       </p>
//                     ) : (
//                       <p className="CN2-ResendOtp" style={{ color: "blue" }}>
//                         Resend OTP
//                       </p>
//                     )}
//                   </form>
//                 </>
//               ) : (
//                 <>
//                   <form onSubmit={handlePhoneNumberSubmit}>
//                     <img
//                       src={HeadImg}
//                       alt="Head Img"
//                       className="CN2-Head-Img"
//                       onContextMenu={(e) => e.preventDefault()}
//                       loading="lazy"
//                     />
//                     {error && <div className="CN2-Error-Handling">{error}</div>}

//                     <div className="CN2-Signin-Container">
//                       <img
//                         src={ImgFlag}
//                         alt="Head Img"
//                         style={{ width: "40px" }}
//                         onContextMenu={(e) => e.preventDefault()}
//                         loading="lazy"
//                       />
//                       +91{" "}
//                       <input
//                         placeholder="Enter Phone Number"
//                         type="tel"
//                         className="CN2-Signin-Input"
//                         required
//                         id="phone-input"
//                         value={phoneNumber}
//                         maxLength={10}
//                         onChange={handlePhoneInput}
//                       />
//                     </div>
//                     <div className="CN2-Signin-checkbox">
//                       <div>
//                         <input
//                           type="checkbox"
//                           className="CN2-Checkbox-Style mx-2"
//                           required
//                         />
//                         I accept{" "}
//                         <Link className="mx-1" to="/terms">
//                           terms of service & applicable policies
//                         </Link>
//                       </div>
//                       <div>
//                         {/* <input
//                           type="checkbox"
//                           className="CN2-Checkbox-Style mx-2"
//                           checked={isWhatsappOptedIn}
//                           onChange={handleCheckboxChange}
//                         /> */}
//                         <input
//                           type="checkbox"
//                           className="CN2-Checkbox-Style mx-2"
//                           checked={isWhatsappOptedIn}
//                           onChange={handleCheckboxChange}
//                         />
//                         Opt in to receive important updates on WhatsApp
//                       </div>
//                     </div>

//                     <button className="CN2-Signin-Button">Send OTP</button>
//                   </form>
//                 </>
//               )}
//             </>
//           )}
//         </div>
//       </div>
//       <div className="CN2-Main2">
//         <h2>Creditnama Offers Real-Time Results</h2>
//         <div
//           className="CN2-Main2-Container"
//           onTouchStart={handleTouchStart}
//           onTouchMove={handleTouchMove}
//         >
//           <div className="CN2-Main2-TextImg-Container">
//             <div className="CN2-Main2-Container-Left">
//               {ImageSubhead[currentImageSubheadIndex]}
//             </div>
//             <div>
//               <img
//                 src={images[currentImageIndex]}
//                 alt={`Slide ${currentImageIndex + 1}`}
//                 className="CN2-Phone-Img"
//                 loading="lazy"
//                 onContextMenu={(e) => e.preventDefault()}
//               />
//             </div>
//           </div>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               marginTop: "20px",
//             }}
//           >
//             {images.map((_, index) => (
//               <div
//                 key={index}
//                 className={`dot ${index === currentImageIndex ? "active" : ""}`}
//                 onClick={() => handleDotClick(index)}
//               ></div>
//             ))}
//           </div>
//         </div>
//       </div>
//       <div id="Partners" className="CN2-Main3">
//         <div>
//           <h2>Across 40+ Financial Institutions To Meet Your Credit Needs</h2>
//         </div>
//         <div className="CN2-Main3-Container">
//           <img
//             src={PartnerBanks}
//             alt="Partner-Banks"
//             className="CN2-Partner-Banks"
//             onContextMenu={(e) => e.preventDefault()}
//             loading="lazy"
//           />
//           <img
//             src={PartnerBanks}
//             alt="Partner-Banks"
//             className="CN2-Partner-Banks"
//             onContextMenu={(e) => e.preventDefault()}
//             loading="lazy"
//           />
//           <img
//             src={PartnerBanks}
//             alt="Partner-Banks"
//             className="CN2-Partner-Banks"
//             onContextMenu={(e) => e.preventDefault()}
//             loading="lazy"
//           />
//         </div>
//       </div>
//       <div id="Products" className="CN2-Main4">
//         <div>
//           <h2>Products Offered</h2>
//         </div>
//         <div className="CN2-Offered-Product-Container">
//           <Link
//             to={"/hl-blogs"}
//             style={{ textDecoration: "none", color: "inherit" }}
//           >
//             <div className="CN2-Products-Div">
//               <h2>
//                 <b>Home Loan</b>
//               </h2>
//               <img
//                 src={Home}
//                 alt="PP"
//                 className="CN2-Offered-Product-Icon"
//                 onContextMenu={(e) => e.preventDefault()}
//                 loading="lazy"
//               />
//               <p className="CN2-About-Offers">
//                 Borrow money to buy your dream home. Repay in monthly
//                 installments spanning 5-30 years.
//               </p>
//             </div>
//           </Link>
//           <Link
//             to={"/lap-blogs"}
//             style={{ textDecoration: "none", color: "inherit" }}
//           >
//             <div className="CN2-Products-Div">
//               <h2>
//                 <b>Loan Against Property</b>
//               </h2>
//               <img
//                 src={LAP}
//                 alt="PP"
//                 className="CN2-Offered-Product-Icon"
//                 onContextMenu={(e) => e.preventDefault()}
//                 loading="lazy"
//               />
//               <p className="CN2-About-Offers">
//                 Borrow money at half the interest rate of business loan by
//                 offering property as collateral. <br />
//                 Flexible terms on funds use. Repayable through monthly
//                 installments.
//               </p>
//             </div>
//           </Link>
//           <Link
//             to={"/cp-blogs"}
//             style={{ textDecoration: "none", color: "inherit" }}
//           >
//             <div className="CN2-Products-Div">
//               <h2>
//                 <b>Commercial Property Purchase</b>
//               </h2>
//               <img
//                 src={CPP}
//                 alt="PP"
//                 className="CN2-Offered-Product-Icon"
//                 onContextMenu={(e) => e.preventDefault()}
//                 loading="lazy"
//               />
//               <p className="CN2-About-Offers">
//                 Borrow money to purchase your dream office, store, factory, or
//                 industrial facility. Investment to support and expand your
//                 business. Repay in monthly installments spanning 5-15 years.
//               </p>
//             </div>
//           </Link>
//         </div>
//       </div>
//       <div className="CN2-Main5">
//         <h2>Comfort of Digital Offers Without Hassle of Scams or Telecalls</h2>
//         <div className="CN2-Ceriticates-Container">
//           <div className="CN2-Trust-Div">
//             <img
//               src={Startupindia}
//               alt="Startupindia"
//               className="CN2-Ceriticates-Img"
//               onContextMenu={(e) => e.preventDefault()}
//               loading="lazy"
//             />
//           </div>
//           <div className="CN2-Trust-Div">
//             <img
//               src={Digilocker}
//               alt="Digilocker"
//               className="CN2-Ceriticates-Img"
//               onContextMenu={(e) => e.preventDefault()}
//               loading="lazy"
//               style={{ padding: "10px" }}
//             />
//           </div>
//           <div className="CN2-Trust-Div">
//             <img
//               src={AWS}
//               alt="AWS"
//               className="CN2-Ceriticates1-Img"
//               onContextMenu={(e) => e.preventDefault()}
//               loading="lazy"
//             />
//           </div>
//           <div className="CN2-Trust-Div">
//             <img
//               src={ISO}
//               alt="ISO"
//               className="CN2-Ceriticates1-Img"
//               onContextMenu={(e) => e.preventDefault()}
//               loading="lazy"
//             />
//           </div>
//           <div className="CN2-Trust-Div">
//             <img
//               src={TLS}
//               alt="TLS"
//               className="CN2-Ceriticates1-Img"
//               onContextMenu={(e) => e.preventDefault()}
//               loading="lazy"
//             />
//           </div>
//         </div>

//         <div className="CN2-Latest-Blog">
//           <h2>Latest Thinking</h2>
//         </div>
//         <div className="CN2-Blogs-Container">
//           <div className="CN2-Main-Blog" onClick={handleBlog1}>
//             <div
//               style={{
//                 ...containerBlogStyle,
//                 backgroundImage: `url(${currentBlog.img})`,
//               }}
//             />
//             <div>
//               <h2>{currentBlog.heading}</h2>
//               <h3
//                 style={{ fontFamily: "var(--font-playfair)" }}
//                 className="CN2-Main-Blog-Heading"
//               >
//                 {currentBlog.subheading}
//               </h3>
//             </div>
//           </div>

//           <div className="CN2-Sub-Blog">
//             <div onClick={handleBlog2}>
//               <a href="/charges-accociated-hl&lap1">Navigating Charges </a>
//               Associated with Home Loans and Loan Against Property
//             </div>
//             <div onClick={handleBlog3}>
//               <a href="/about-CGTMSE"> Considering a CGTMSE loan?</a> Here’s all
//               that you need to know!
//             </div>
//             <div onClick={handleBlog4}>
//               <a href="/homeloan-lap-whatsbetter">
//                 What is better for a HL or LAP
//               </a>
//               – A bank or an HFC? All you need to know.
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="CN2-Footer">
//         <Footer />
//       </div>
//     </div>
//   );
// }

// export default SigninPage;
