"use client";
import React, { useState, useEffect } from "react";
import "./sales.css";
import Navbar from "../User-Components/Front-Page-Components/Header/Header";
import serverIP from "../config/config";

const VisitSummary = () => {
  const [Discussion, setDiscussion] = useState("");
  const [channelName, setChannelName] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const date = new Date().toDateString();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (navigator.geolocation) {
      console.log("Requesting location permission...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("Location fetched successfully.");
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error fetching location: ", error);
          if (error.code === error.PERMISSION_DENIED) {
            alert("Permission to access location was denied.");
          }
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${serverIP}/product/registersalesvisits`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          salesPerson: localStorage.getItem("channelVisitor"),
          discussion: Discussion,
          channelName: channelName,
          latitude: latitude,
          longitude: longitude,
        }),
      });
      if (response.status >= 200 || response.status < 300) {
        window.alert("Sales visit registered successfully");
        setDiscussion("");
        setChannelName("");
      } else {
        window.alert("Sales visit not registered");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (number === "8750072300" && password === "manish@12345") {
      setShow(true);
      localStorage.setItem("channelVisitor", "Manish");
    } else if (number === "8750045100" && password === "ankit@54321") {
      setShow(true);
      localStorage.setItem("channelVisitor", "Ankit");
    } else if (number === "8873733581" && password === "niraj@123") {
      setShow(true);
      localStorage.setItem("channelVisitor", "Niraj");
    } else {
      alert("Wrong number or password");
    }
  };

  useEffect(() => {
    const user = localStorage.getItem("channelVisitor");
    if (user === "Manish" || user === "Ankit" || user === "Niraj") {
      setShow(true);
    }
  }, []);

  return (
    <>
      <Navbar />
      <div className="CNN-Sales-form-Parent">
        {show ? (
          <form className="CNN-Sales-form-container" onSubmit={handleSubmit}>
            <h2 style={{ textAlign: "center" }}>
              Welcome {localStorage.getItem("channelVisitor")}
            </h2>
            <br />
            <div className="CNN-Sales-form-group">
              <label>Channel Name</label>
              <input
                required
                type="text"
                value={channelName}
                onChange={(e) => setChannelName(e.target.value)}
                className="CNN-Sales-form-group"
              />
            </div>
            <div className="CNN-Sales-form-group">
              <label>Discussion </label>
              <input
                required
                type="text"
                value={Discussion}
                onChange={(e) => setDiscussion(e.target.value)}
                className="CNN-Sales-form-group"
              />
            </div>
            <div className="CNN-Sales-form-group">
              <label>Date</label>
              <input
                required
                type="text"
                value={date}
                readOnly
                className="CNN-Sales-form-group"
              />
            </div>

            {latitude && longitude ? (
              <>
                <button
                  style={{ fontWeight: "700" }}
                  type="submit"
                  className="submit-button"
                >
                  Submit
                </button>
                <button
                  style={{ fontWeight: "700", marginTop: "10px" }}
                  onClick={() => {
                    localStorage.clear();
                    setShow(false);
                  }}
                  className="submit-button"
                >
                  Logout
                </button>
              </>
            ) : (
              <button
                disabled={true}
                style={{
                  backgroundColor: "lightgrey",
                  border: "1px solid var(--color-darkgreen)",
                  color: "black",
                  fontWeight: "700",
                }}
                type="submit"
                className="submit-button"
              >
                Submit
              </button>
            )}
          </form>
        ) : (
          <form className="CNN-Sales-form-container" onSubmit={handleLogin}>
            <div className="CNN-Sales-form-group">
              <h1>Login</h1>
              <br />
              <label>Mobile Number</label>
              <input
                required
                type="text"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                maxLength={10}
                pattern="[0-9]*"
                className="CNN-Sales-form-group"
                autoComplete="tel"
              />
            </div>
            <div className="CNN-Sales-form-group">
              <label>Password</label>
              <input
                required
                type="password"
                maxLength={20}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="CNN-Sales-form-group"
                autoComplete="current-password"
              />
            </div>
            <button
              style={{ fontWeight: "700" }}
              type="submit"
              className="submit-button"
            >
              Login
            </button>
          </form>
        )}
      </div>
    </>
  );
};
export default VisitSummary;
