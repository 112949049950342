import React from "react";
import { Helmet } from "react-helmet-async";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import BlogImg1 from "../../../../Images/Bank-Logos/hdfc.png";
import BlogImg2 from "../../../../Images/Bank-Logos/axis.png";
import BlogImg3 from "../../../../Images/Bank-Logos/idfc.jpg";
import BlogImg4 from "../../../../Images/Bank-Logos/icici.png";
import BlogImg5 from "../../../../Images/Bank-Logos/LicHousing.png";
import BlogImg6 from "../../../../Images/Bank-Logos/fedral.png";
import BlogImg7 from "../../../../Images/Bank-Logos/kotak.png";
import BlogImg8 from "../../../../Images/Bank-Logos/tata.png";
import BlogImg9 from "../../../../Images/Bank-Logos/indusind.png";
import BlogImg10 from "../../../../Images/Bank-Logos/RblBank.png";
import BlogImg11 from "../../../../Images/Bank-Logos/yesbank.png";
import BlogImg12 from "../../../../Images/Bank-Logos/shriram.png";

import { Link } from "react-router-dom";
import "../../aboutus.css";
import EMIcalculator from "../../EMI-Calculator/EMIcalculator";

function HomeLoan() {
  // document.addEventListener("contextmenu", function (e) {
  //   e.preventDefault();
  // });
  // document.addEventListener("selectstart", function (e) {
  //   e.preventDefault();
  // });
  // document.addEventListener("touchstart", function (e) {
  //   e.preventDefault();
  // });

  const blogData = [
    {
      content: `HDFC Bank offers home loan at interest rates starting from 8.75% p.a.
      for a maximum repayment tenure of 25 years.`,
      img: BlogImg1,
      link: "/hdfc-hl",
    },
    {
      content: `Axis Bank offers home loan at interest rates starting from 8.5% p.a. for a maximum repayment tenure of 20 years.`,

      img: BlogImg2,
      link: "/axis-hl",
    },
    {
      content: `IDFC First Bank offers home loan at interest rates starting from 8.75% p.a.
      for a maximum repayment tenure of 30 years.`,

      img: BlogImg3,
      link: "/idfc-hl",
    },
    {
      content: `ICICI Bank offers home loan at interest rates starting from 8.75% p.a.
      for a maximum repayment tenure of 30 years.`,

      img: BlogImg4,
      link: "/icici-hl",
    },
    {
      content: `LIC Housing Finance offers home loan at interest rates starting from 8.35%
      p.a. for a maximum repayment tenure of 30 years.`,

      img: BlogImg5,
      link: "/lic-hl",
    },
    {
      content: `Fedral Bank offers home loan at interest rates starting from 8.50% p.a.
      for a maximum repayment tenure of 30 years,`,

      img: BlogImg6,
      link: "/federal-hl",
    },
    {
      content: `Kotak Mahindra Bank offers home loan at interest rates starting from 8.60% p.a.
      for a maximum repayment tenure of 25 years.`,
      img: BlogImg7,
      link: "/kotak-hl",
    },
    {
      content: `Tata Capital offers home loan at interest rates starting from 8.60% p.a.
      for a maximum repayment tenure of 30 years.`,
      img: BlogImg8,
      link: "/tata-hl",
    },
    {
      content: `Indusind Bank offers home loan at interest rates starting from 8.40% p.a.
      for a maximum repayment tenure of 30 years.`,
      img: BlogImg9,
      link: "/indusind-hl",
    },
    {
      content: `RBL Bank offers home loan at interest rates starting from 8.90% p.a.
      for a maximum repayment tenure of 30 years.`,
      img: BlogImg10,
      link: "/rbl-hl",
    },
    {
      content: `Yes Bank offers home loan at interest rates starting from 9.50% p.a.
      for a maximum repayment tenure of 35 years.`,
      img: BlogImg11,
      link: "/yes-hl",
    },
    {
      content: `Shriram Housing Finance offers home loan at interest rates starting from 9.50% p.a.
      for a maximum repayment tenure of 20 years.`,
      img: BlogImg12,
      link: "/shriram-hl",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Home Loan</title>
        <meta
          name="description"
          content="Home loan at interest rates starting from 8.50% p.a.
      for a maximum repayment tenure of 30 Years"
        />
        <link rel="canonical" href="/hl-blogs" />
      </Helmet>
      <Header />

      <div className="Aboutus-page">
        <div className="Aboutus-content">
          <EMIcalculator isVisible={false} />
          <h1 style={{ marginTop: "30px" }}>Blogs on Home Loan</h1>

          <div className="Crow">
            {blogData.map((blog, index) => (
              <div className="Ccolumn" key={index}>
                <Link to={blog.link}>
                  <div
                    className="Ccard"
                    style={{
                      justifyContent: "space-around",
                      textAlign: "justify",
                      // wordSpacing: "-2px",
                    }}
                  >
                    <img
                      src={blog.img}
                      alt={blog.link}
                      className="Fit-blogImg"
                      loading="lazy"
                      style={{
                        objectFit: "contain",
                      }}
                    />
                    <h6>{blog.content}</h6>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default HomeLoan;
