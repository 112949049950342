const ChannelData = [
  {
    id: 1,
    name: "Monu",
    channelNumber: 9664366346,
    earnedPayout: 135000,
    websiteLive: 1,
    pendingPayout: 25000,
    referredLeads: 20,
    activeLeads: 10,
    campaignsLive: 2,
  },
  {
    id: 2,
    name: "Bharat",
    channelNumber: 9958919172,
    earnedPayout: 100000,
    websiteLive: 1,
    pendingPayout: 30000,
    referredLeads: 10,
    activeLeads: 5,
    campaignsLive: 2,
  },
];

export default ChannelData;
