import React from "react";
import ChannelSidebar from "../Auth-Components/Channel-Auth/ChannelSidebar";

const ChannelLeadDocs = () => {
  return (
    <div className="CN-Channel-Leads-Offer">
      <ChannelSidebar />
      <div className="CN-Channel-LeadOffer-Div" style={{ marginTop: "25px" }}>
        <h1>Customer documents</h1>
        <div className="CN-Channel-CreateLead-Status">
          <div>
            <div
              className="CN-Channel-Status-Circle"
              style={{
                backgroundColor: "var(--color-darkgreen)",
              }}
            ></div>
            Details
          </div>
          <div className="CN-Channel-Status-Line"></div>
          <div>
            <div
              className="CN-Channel-Status-Circle"
              style={{
                backgroundColor: "var(--color-darkgreen)",
              }}
            ></div>
            Offer
          </div>
          <div className="CN-Channel-Status-Line"></div>

          <div>
            <div
              className="CN-Channel-Status-Circle"
              style={{
                backgroundColor: "var(--color-yellow)",
              }}
            ></div>
            Docs
          </div>
        </div>
        <div className="CN-Channel-LeadOffer-Container">
          <div className="Finaloffer-confirmation-form">
            <div>
              <h2>KYC Documents</h2>
              <p>Aadhaar</p>
              <p>Pan</p>
              <p>Photo</p>

              <h2>Income Documents</h2>
              <p>Salary slip (Last 3 Months)</p>
              <p>Banking (Last 6 Months)</p>

              <h2>Property Documents</h2>
              <p>Complete chain of property papers</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChannelLeadDocs;
