import React, { useEffect } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PaymentsIcon from "@mui/icons-material/Payments";
import CampaignIcon from "@mui/icons-material/Campaign";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import "./ChannelSummary.css";
import { useNavigate } from "react-router-dom";
import ChannelData from "./ChannelData";
import ChannelHamburger from "./components/ChannelHamburger";

const ChannelSummary = () => {
  const history = useNavigate();

  const channelNumber = Number(localStorage.getItem("cNumber"));
  const newChannelData = ChannelData.filter(
    (item) => item.channelNumber === channelNumber
  );
  useEffect(() => {
    const auth = localStorage.getItem("cToken");
    if (!auth) {
      console.log("Login first");
      history("/channel");
    }
  }, [history]);

  const handleCreate = () => {
    localStorage.removeItem("path");
    history("/channel/create-lead");
  };
  return (
    <div className="CN-Channel-Summary-Page">
      <div className="CN-Channel-Summary-Head">
        <div className="CN-Channel-Summary-PageTitle">Summary</div>
        <div>
          <NotificationsIcon
            style={{ cursor: "pointer", marginRight: "10px" }}
          />
          <AccountCircleIcon fontSize="large" style={{ cursor: "pointer" }} />{" "}
          {newChannelData[0].name}
        </div>
      </div>
      <ChannelHamburger />
      <div className="CN-Channel-Summary-Container">
        <div className="CN-Summary-CreateLead">
          <h1>Welcome Back, {newChannelData[0].name}</h1>
          <button onClick={handleCreate}>Create Lead</button>
        </div>
        <div className="CN-Channel-Summary-Pointers">
          <div className="CN-Channel-Summary-Pointer1">
            <PaymentsIcon sx={{ fontSize: 50 }} />
            <div className="CN-Channel-Pointer-InnerDiv">
              <span>Rs {newChannelData[0].earnedPayout / 100000}L</span>
              <br />
              Earned payout
            </div>
          </div>
          <div className="CN-Channel-Summary-Pointer2">
            <PaymentsIcon sx={{ fontSize: 50 }} />
            <div className="CN-Channel-Pointer-InnerDiv">
              <span>Rs {newChannelData[0].pendingPayout / 100000}L</span>
              <br />
              Pending payout
            </div>
          </div>
          <div className="CN-Channel-Summary-Pointer3">
            <WebAssetIcon sx={{ fontSize: 55 }} />
            <div className="CN-Channel-Pointer-InnerDiv">
              <span>{newChannelData[0].websiteLive}</span>
              <br />
              Website live
            </div>
          </div>
        </div>
        <div className="CN-Channel-Summary-Pointers">
          <div className="CN-Channel-Summary-Pointer4">
            <DataSaverOnIcon sx={{ fontSize: 55 }} />
            <div className="CN-Channel-Pointer-InnerDiv">
              <span>{newChannelData[0].referredLeads}</span>
              <br />
              Leads referred
            </div>
          </div>
          <div className="CN-Channel-Summary-Pointer5">
            <PeopleOutlineIcon sx={{ fontSize: 60 }} />
            <div className="CN-Channel-Pointer-InnerDiv">
              <span>{newChannelData[0].activeLeads}</span>
              <br />
              Active leads
            </div>
          </div>
          <div className="CN-Channel-Summary-Pointer6">
            <CampaignIcon sx={{ fontSize: 60 }} />
            <div className="CN-Channel-Pointer-InnerDiv">
              <span>{newChannelData[0].campaignsLive}</span>
              <br />
              Campaigns live
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChannelSummary;
