import React, { useEffect } from "react";
import ChannelSidebar from "../Auth-Components/Channel-Auth/ChannelSidebar";
import "./ChannelLead.css";
import Img from "../Images/image.png";
import { useNavigate } from "react-router-dom";
import ChannelHamburger from "./components/ChannelHamburger";

const ChannelLead = () => {
  const history = useNavigate();

  useEffect(() => {
    const auth = localStorage.getItem("cToken");
    if (!auth) {
      console.log("Login first");
      history("/channel");
    }
  }, [history]);

  return (
    <div className="CN-CreateLead-Page">
      <ChannelSidebar />
      <div className="CN-ChannelLead-MainContainer">
        <div
          style={{
            width: "100%",
            marginBottom: "10px",
          }}
        >
          <ChannelHamburger />
        </div>
        <h1
          style={{
            fontWeight: "700",
            fontSize: "30px",
            marginBottom: "10px",
            width: "310px",
          }}
        >
          Leads Status
        </h1>
        <img src={Img} alt="img" style={{ width: "350px" }} />
      </div>
      ;
    </div>
  );
};

export default ChannelLead;
