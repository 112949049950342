import React, { useEffect, useState } from "react";
import "./Calculators.css";
import Tab from "./Tab";
import AffordibilityCal from "./AffordabilityCal";
import BalanceTranCal from "./BTCalc";
import EMICal from "./NextEMICal";
import EligibilityCal from "./EligibilityCal";

const Calculators = () => {
  const [affordCal, setAffordCal] = useState(true);
  const [btCal, setBtCal] = useState(false);
  const [emiCal, setEmiCal] = useState(false);
  const [eligibilityCal, setEligibilityCal] = useState(false);
  const [tabName, setTabName] = useState("");

  useEffect(() => {
    if (tabName === "affordabilityCal") {
      setAffordCal(true);
      setBtCal(false);
      setEmiCal(false);
      setEligibilityCal(false);
    } else if (tabName === "btCal") {
      setAffordCal(false);
      setBtCal(true);
      setEmiCal(false);
      setEligibilityCal(false);
    } else if (tabName === "emiCal") {
      setAffordCal(false);
      setBtCal(false);
      setEmiCal(true);
      setEligibilityCal(false);
    } else if (tabName === "eligibilityCal") {
      setAffordCal(false);
      setBtCal(false);
      setEmiCal(false);
      setEligibilityCal(true);
    }
  }, [tabName]);

  return (
    <div className="BCN-Calculator-Page">
      <div className="BCN-Calculator-Page-Tabs">
        <Tab tabName={tabName} setTabName={setTabName} />
      </div>

      {affordCal === true && (
        <>
          <AffordibilityCal />
        </>
      )}

      {btCal === true && (
        <>
          <BalanceTranCal />
        </>
      )}

      {emiCal === true && (
        <>
          <EMICal />
        </>
      )}

      {eligibilityCal === true && (
        <>
          <EligibilityCal />
        </>
      )}
    </div>
  );
};

export default Calculators;
