import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Personal1 from "./User-Components/Personal/personal1";
import Personal2 from "./User-Components/Personal/personal2";
import Ask1 from "./User-Components/Ask/ask1";
import Kyc from "./Kyc-Components/kyc";
import Aadhaar from "./Kyc-Components/aadhaar";
import PageNotFound from "./PagenotFound/pagenotfound";
import Payslip from "./Banking-Components/Payslip/payslip";
import OfferPage from "./Product-Components/Bank-Product/offerpage";
import BankingForm from "./Banking-Components/Banking/banking";
import Profile1 from "./User-Components/Profile/profile1";
import Profile2 from "./User-Components/Profile/profile2";
import SigninPage from "./Auth-Components/User-Auth/user";
import Standby from "./Standby-Components/standby";
import Employer from "./User-Components/Employment/employer";
import Confirmation from "./User-Components/Confirmation/confirmation";
import OfferStandby from "./Standby-Components/offerstandby";
import Animation1 from "./Animation/animation";
import PanVerification from "./Kyc-Components/pan";
import IncomeProofIndex from "./Banking-Components/incomeindex";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PayslipStandby from "./Standby-Components/payslipstandby";
import ApplicationStatus from "./Status-Components/applicationstatus";
import TrackApplication from "./Status-Components/trackapplication";
import FinalOfferPage from "./Eligibility-Components/finaloffer";
import SelectionDetails from "./Eligibility-Components/selectiondetails";
import PrivacyPolicyPage from "./User-Components/Front-Page-Components/privacypolicy";
import AccountUpload from "./Banking-Components/Banking/bankingupload";
import GlossaryPage from "./User-Components/Front-Page-Components/glossary";
import LoginOfferPage from "./User-Components/Front-Page-Components/loginoffer";
import PartnershipPage from "./User-Components/Front-Page-Components/partnership";
import ContactPage from "./User-Components/Front-Page-Components/contact";
import LendingTeam from "./User-Components/Front-Page-Components/lendingteam";
import AreaSalesManager from "./User-Components/Front-Page-Components/asm";
import Intern from "./User-Components/Front-Page-Components/intern";
import ScrollToTopOnMount from "./User-Components/Front-Page-Components/scrolltotop";
import Business from "./User-Components/Ask/Business";
import Asset from "./Asset-Components/Asset";
import Profession from "./User-Components/Ask/Profession";
import AxisHL from "./User-Components/Front-Page-Components/HLBlogs/BankWise/AxisHL";
import HdfcHL from "./User-Components/Front-Page-Components/HLBlogs/BankWise/HdfcHL";
import FederalHL from "./User-Components/Front-Page-Components/HLBlogs/BankWise/FederalHL";
import IciciHL from "./User-Components/Front-Page-Components/HLBlogs/BankWise/IciciHL";
import IdfcHL from "./User-Components/Front-Page-Components/HLBlogs/BankWise/IdfcHL";
import KotakHL from "./User-Components/Front-Page-Components/HLBlogs/BankWise/KotakHL";
import LicHL from "./User-Components/Front-Page-Components/HLBlogs/BankWise/LicHL";
import HomeLoan from "./User-Components/Front-Page-Components/HLBlogs/AllBanks/HomeLoan";
import LAP from "./User-Components/Front-Page-Components/LAPBlogs/AllBank/LAP";
import AxisLAP from "./User-Components/Front-Page-Components/LAPBlogs/BanksWise/AxisLAP";
import HdfcLAP from "./User-Components/Front-Page-Components/LAPBlogs/BanksWise/HdfcLAP";
import FederalLAP from "./User-Components/Front-Page-Components/LAPBlogs/BanksWise/FederalLAP";
import IciciLAP from "./User-Components/Front-Page-Components/LAPBlogs/BanksWise/IciciLAP";
import IdfcLAP from "./User-Components/Front-Page-Components/LAPBlogs/BanksWise/IdfcLAP";
import KotakLAP from "./User-Components/Front-Page-Components/LAPBlogs/BanksWise/KotakLAP";
import LicLAP from "./User-Components/Front-Page-Components/LAPBlogs/BanksWise/LicLAP";
import TataHL from "./User-Components/Front-Page-Components/HLBlogs/BankWise/TataHL";
import IndusindHL from "./User-Components/Front-Page-Components/HLBlogs/BankWise/IndusindHL";
import RblHL from "./User-Components/Front-Page-Components/HLBlogs/BankWise/RblHL";
import YesHL from "./User-Components/Front-Page-Components/HLBlogs/BankWise/YesHL";
import ShriramHL from "./User-Components/Front-Page-Components/HLBlogs/BankWise/ShriramHL";
import YesLAP from "./User-Components/Front-Page-Components/LAPBlogs/BanksWise/YesLAP";
import Aboutus from "./User-Components/Front-Page-Components/About/Aboutus";
import Team from "./User-Components/Front-Page-Components/Team/Team";
import EmiCalPage from "./User-Components/Front-Page-Components/EMI-Calculator/EmiCalPage";
import Careers from "./User-Components/Front-Page-Components/Career/Careers";
import LoginPage from "./Auth-Components/User-Auth/LoginPage";
import CheckApplication from "./Track-Application/CheckApplication";
import CPBlogs from "./User-Components/Front-Page-Components/CPBlogs/AllBanks/CPBlogs";
import AxisCP from "./User-Components/Front-Page-Components/CPBlogs/BankWise/AxisCP";
import HdfcCP from "./User-Components/Front-Page-Components/CPBlogs/BankWise/HdfcCP";
import FederalCP from "./User-Components/Front-Page-Components/CPBlogs/BankWise/FederalCP";
import IciciCP from "./User-Components/Front-Page-Components/CPBlogs/BankWise/IciciCP";
import IdfcCP from "./User-Components/Front-Page-Components/CPBlogs/BankWise/IdfcCP";
import YesCP from "./User-Components/Front-Page-Components/CPBlogs/BankWise/YesCP";
import KotakCP from "./User-Components/Front-Page-Components/CPBlogs/BankWise/KotakCP";
import LicCP from "./User-Components/Front-Page-Components/CPBlogs/BankWise/LicCP";
import Previousasks from "./User-Components/Previous-Ask/Previousasks";
// import Test2 from "./Test/Test2";
import SessionBreak from "./PagenotFound/SessionBreak";
import BankingStandby from "./Banking-Components/Banking/bankingstandby";
// import Test3 from "./Test/Test3";
import ChannelTest from "./Auth-Components/Channel-Auth/ChannelTest";
import ChannelDashboardTest from "./Auth-Components/Channel-Auth/ChannelDashboardTest";
// import ChannelSummary from "./Channel-Components/ChannelSummary";
import InternalTracker from "./Tracker-Components/InternalTracker";
import TrackerSummary from "./Tracker-Components/TrackerSummary";
import TrackerMaster from "./Tracker-Components/TrackerMaster";
import TrackAskDetail from "./Tracker-Components/TrackAskDetail";
import UpdateTracking from "./Tracker-Components/UpdateTracking";
import Faq from "./User-Components/Front-Page-Components/Faq/Faq";
import CreateLead from "./Channel-Components/CreateLead";
import CreateStepOne from "./Channel-Components/CreateStepOne";
import CompareOffer from "./User-Components/Front-Page-Components/Comparison/CompareOffers";
import ChannelLead from "./Channel-Components/ChannelLead";
import ChannelEMICalc from "./Channel-Components/Channel-Tools/ChannelEMICalc";
import ChannelAuthStatus from "./Auth-Components/Channel-Auth/ChannelAuthStatus";
import ChannelLeadOffers from "./Channel-Components/ChannelLeadOffers";
import Test from "./Test/test";
import AffordabilityCalc from "./Channel-Components/Channel-Tools/AffordabilityCalc";
import CircleRates from "./Channel-Components/Channel-Tools/CircleRates";
import RateList from "./Channel-Components/Channel-Tools/RateList";
import ChannelLeadDocs from "./Channel-Components/ChannelLeadDocs";
import PlTest from "./pl-Test/PlTest";
import RedirectionPage from "./redirectionPage/RedirectionPage";
import VisitSummary from "./Visit-Components/VisitSummary";
import PdfPreview from "./AIDocsReader/AIDocsReader";
import Paras from "./Projects/Paras/Paras";

// import TelePrivacy from "./User-Components/Front-Page-Components/TelePrivacy";

// import { Helmet } from "react-helmet";

function ProtectedRoutes() {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = !!localStorage.getItem("accessToken");
    const currentPath = window.location.pathname;

    if (
      !isAuthenticated &&
      !currentPath.startsWith("/login-signup/create-otp") &&
      !currentPath.startsWith("/next_steps") &&
      currentPath !== "/" &&
      currentPath !== "/terms" &&
      // currentPath !== "/privacy-policy" &&
      currentPath !== "/partneroffers" &&
      currentPath !== "/glossary" &&
      currentPath !== "/blogs" &&
      currentPath !== "/partnership" &&
      currentPath !== "/careers" &&
      currentPath !== "/about" &&
      currentPath !== "/contact-us" &&
      currentPath !== "/career/lendingteam" &&
      currentPath !== "/career/asm" &&
      currentPath !== "/career/intern" &&
      currentPath !== "/delhi-property-registration" &&
      currentPath !== "/homeloan-lap-whatsbetter" &&
      currentPath !== "/charges-accociated-hl&lap1" &&
      currentPath !== "/charges-accociated-hl&lap2" &&
      currentPath !== "/about-CGTMSE" &&
      currentPath !== "/casefor-publicsector-bank" &&
      currentPath !== "/unlocking-savings-ratedrop" &&
      currentPath !== "/hl-blogs" &&
      currentPath !== "/axis-hl" &&
      currentPath !== "/hdfc-hl" &&
      currentPath !== "/icici-hl" &&
      currentPath !== "/idfc-hl" &&
      currentPath !== "/lic-hl" &&
      currentPath !== "/federal-hl" &&
      currentPath !== "/kotak-hl" &&
      currentPath !== "/tata-hl" &&
      currentPath !== "/indusind-hl" &&
      currentPath !== "/rbl-hl" &&
      currentPath !== "/yes-hl" &&
      currentPath !== "/shriram-hl" &&
      currentPath !== "/lap-blogs" &&
      currentPath !== "/axis-lap" &&
      currentPath !== "/hdfc-lap" &&
      currentPath !== "/icici-lap" &&
      currentPath !== "/idfc-lap" &&
      currentPath !== "/yes-lap" &&
      currentPath !== "/federal-lap" &&
      currentPath !== "/kotak-lap" &&
      currentPath !== "/cp-blogs" &&
      currentPath !== "/axis-cp" &&
      currentPath !== "/hdfc-cp" &&
      currentPath !== "/icici-cp" &&
      currentPath !== "/idfc-cp" &&
      currentPath !== "/yes-cp" &&
      currentPath !== "/federal-cp" &&
      currentPath !== "/kotak-cp" &&
      currentPath !== "/test" &&
      currentPath !== "/signup" &&
      currentPath !== "/login-signup" &&
      currentPath !== "/track-application" &&
      currentPath !== "/team" &&
      currentPath !== "/emi-calculator" &&
      currentPath !== "/faqs" &&
      currentPath !== "/compare" &&
      // ############################(Internal Use)##################################

      currentPath !== "/thekedaar/login" &&
      currentPath !== "/thekedaar/summary" &&
      currentPath !== "/thekedaar/master" &&
      currentPath !== "/thekedaar/details" &&
      currentPath !== "/thekedaar/status-update" &&
      currentPath !== "/visit/summary" &&
      // ############################(Channel Use)##################################
      currentPath !== "/channel" &&
      currentPath !== "/channel/summary" &&
      currentPath !== "/channel/leads" &&
      currentPath !== "/channel/leadoffers" &&
      currentPath !== "/channel/lead-documents" &&
      currentPath !== "/channel/calculators" &&
      currentPath !== "/channel/create-lead" &&
      currentPath !== "/channel/auth-status" &&
      currentPath !== "/channel/emi-calculator" &&
      currentPath !== "/channel/circle-rates" &&
      currentPath !== "/channel/rate-of-interest" &&
      currentPath !== "/channel/create-lead-s1" &&
      currentPath !== "/personal-loan" &&
      currentPath !== "/home-loan" &&
      currentPath !== "/propnama" &&
      currentPath !== "/paras-sector59"
    ) {
      navigate("/404");
    }
  }, [navigate]);

  return null;
}

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* ########################### User Part ###################################### */}
          <Route path="/" element={<SigninPage />} />
          <Route path="/propnama" element={<PdfPreview />} />
          <Route path="/paras-sector59" element={<Paras />} />
          <Route path="/login-signup" element={<LoginPage />} />
          <Route path="/login-signup/create-otp" element={<LoginPage />} />
          <Route path="/next_steps" element={<RedirectionPage />} />
          <Route path="/signup" element={<LoginPage />} />
          <Route path="/profile1" element={<Profile1 />} />
          <Route path="/profile2" element={<Profile2 />} />
          <Route path="/standby" element={<Standby />} />
          <Route path="/personal1" element={<Personal1 />} />
          <Route path="/personal2" element={<Personal2 />} />
          <Route path="/ask1" element={<Ask1 />} />
          <Route path="/previousasks" element={<Previousasks />} />
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/employer" element={<Employer />} />
          <Route path="/business" element={<Business />} />
          <Route path="/profession" element={<Profession />} />
          <Route path="/offerstandby" element={<OfferStandby />} />
          <Route path="/offer" element={<OfferPage />} />
          <Route path="/asset" element={<Asset />} />
          <Route path="/kyc" element={<Kyc />} />
          <Route path="/pan" element={<PanVerification />} />
          <Route path="/aadhaar" element={<Aadhaar />} />
          {/* <Route path="/cibil" element={<Cibil />} />
          <Route path="/creditstatus" element={<CibilRejection />} /> */}
          {/* <Route path="/success" element={<CibilSuccess />} /> */}
          <Route path="/banking" element={<BankingForm />} />
          <Route path="/uploadbanking" element={<AccountUpload />} />
          <Route path="/bankingstandby" element={<BankingStandby />} />
          <Route path="/payslip" element={<Payslip />} />
          <Route path="/payslipstandby" element={<PayslipStandby />} />
          <Route path="/finaloffer" element={<FinalOfferPage />} />
          <Route path="/selectiondetails" element={<SelectionDetails />} />
          <Route path="/applicationstatus" element={<ApplicationStatus />} />
          <Route path="/trackapplication" element={<TrackApplication />} />

          {/* ########################### First Page Components ###################################### */}

          <Route path="/terms" element={<PrivacyPolicyPage />} />
          {/* <Route path="/privacy-policy" element={<TelePrivacy />} /> */}

          <Route path="/about" element={<Aboutus />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/glossary" element={<GlossaryPage />} />
          <Route path="/partneroffers" element={<LoginOfferPage />} />
          <Route path="/partnership" element={<PartnershipPage />} />
          <Route path="/contact-us" element={<ContactPage />} />
          <Route path="/career/lendingteam" element={<LendingTeam />} />
          <Route path="/career/asm" element={<AreaSalesManager />} />
          <Route path="/career/intern" element={<Intern />} />
          <Route path="/animation" element={<Animation1 />} />
          <Route path="/income" element={<IncomeProofIndex />} />
          <Route path="/emi-calculator" element={<EmiCalPage />} />
          <Route path="/team" element={<Team />} />
          <Route path="/faqs" element={<Faq />} />
          <Route path="/track-application" element={<CheckApplication />} />
          <Route path="/compare" element={<CompareOffer />} />

          {/* ########################### Blogs Components ###################################### */}

          {/* <Route path="/blogs" element={<BlogPage />} /> */}
          <Route path="/hL-blogs" element={<HomeLoan />} />
          <Route path="/lap-blogs" element={<LAP />} />
          <Route path="/cp-blogs" element={<CPBlogs />} />

          {/* ########################### Home Loan Blogs Components ############################### */}

          <Route path="/axis-hl" element={<AxisHL />} />
          <Route path="/hdfc-hl" element={<HdfcHL />} />
          <Route path="/federal-hl" element={<FederalHL />} />
          <Route path="/icici-hl" element={<IciciHL />} />
          <Route path="/idfc-hl" element={<IdfcHL />} />
          <Route path="/kotak-hl" element={<KotakHL />} />
          <Route path="/lic-hl" element={<LicHL />} />
          <Route path="/tata-hl" element={<TataHL />} />
          <Route path="/indusind-hl" element={<IndusindHL />} />
          <Route path="/rbl-hl" element={<RblHL />} />
          <Route path="/yes-hl" element={<YesHL />} />
          <Route path="/shriram-hl" element={<ShriramHL />} />

          {/* ########################### LAP Blogs Components ############################### */}

          <Route path="/axis-lap" element={<AxisLAP />} />
          <Route path="/hdfc-lap" element={<HdfcLAP />} />
          <Route path="/federal-lap" element={<FederalLAP />} />
          <Route path="/icici-lap" element={<IciciLAP />} />
          <Route path="/idfc-lap" element={<IdfcLAP />} />
          <Route path="/yes-lap" element={<YesLAP />} />
          <Route path="/kotak-lap" element={<KotakLAP />} />
          <Route path="/lic-lap" element={<LicLAP />} />

          {/* ################# Commercial Property Blogs Components ################### */}

          <Route path="/axis-cp" element={<AxisCP />} />
          <Route path="/hdfc-cp" element={<HdfcCP />} />
          <Route path="/federal-cp" element={<FederalCP />} />
          <Route path="/icici-cp" element={<IciciCP />} />
          <Route path="/idfc-cp" element={<IdfcCP />} />
          <Route path="/yes-cp" element={<YesCP />} />
          <Route path="/kotak-cp" element={<KotakCP />} />
          <Route path="/lic-cp" element={<LicCP />} />

          {/* ########################### Channel Part ###################################### */}

          {/* <Route path="/channel/signup" element={<ChannelSignup />} /> */}
          {/* <Route path="/channel/login" element={<ChannelLogin />} /> */}
          {/* <Route path="/channel/verify" element={<ChannelVerify />} /> */}
          {/* <Route path="/channel/self-details" element={<Channel />} /> */}
          {/* <Route path="/channel/dashboard" element={<Dashboard />} /> */}
          {/* <Route path="/channel/case" element={<ChannelCustomer />} /> */}
          {/* <Route path="/channel/createlead" element={<ChannelCreateCase />} /> */}

          <Route path="/channel" element={<ChannelTest />} />
          <Route path="/channel/auth-status" element={<ChannelAuthStatus />} />
          <Route path="/channel/summary" element={<ChannelDashboardTest />} />
          <Route path="/channel/leads" element={<ChannelLead />} />
          <Route path="/channel/create-lead" element={<CreateLead />} />

          <Route path="/channel/emi-calculator" element={<ChannelEMICalc />} />
          <Route path="/channel/calculators" element={<AffordabilityCalc />} />
          <Route path="/channel/circle-rates" element={<CircleRates />} />
          <Route path="/channel/rate-of-interest" element={<RateList />} />
          <Route path="/channel/create-lead-s1" element={<CreateStepOne />} />
          <Route path="/channel/leadoffers" element={<ChannelLeadOffers />} />
          <Route path="/channel/lead-documents" element={<ChannelLeadDocs />} />

          {/* ########################### Internal Part ###################################### */}

          <Route path="/thekedaar/login" element={<InternalTracker />} />
          <Route path="/thekedaar/summary" element={<TrackerSummary />} />
          <Route path="/thekedaar/master" element={<TrackerMaster />} />

          <Route path="/thekedaar/details" element={<TrackAskDetail />} />
          <Route path="/thekedaar/status-update" element={<UpdateTracking />} />
          <Route path="/visit/summary" element={<VisitSummary />} />

          {/* ########################### Other ############################################# */}

          <Route path="/home-loan-test" element={<Test />} />
          <Route path="/personal-loan" element={<PlTest />} />

          {/* <Route path="/test3" element={<Test3 />} /> */}

          <Route path="/*" element={<PageNotFound />} />
          <Route path="/session-break" element={<SessionBreak />} />

          {/* <Route path="/ssb" element={<CNApplication />} /> */}
        </Routes>
        <ProtectedRoutes />
        <ScrollToTopOnMount />
      </Router>
    </div>
  );
}

export default App;
