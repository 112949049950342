import React from "react";
import Header from "../../User-Components/Front-Page-Components/Header/Header";
import StepsFooter from "../../User-Components/Front-Page-Components/Footer/StepsFooter";
import WA from "../../Images/Assets/WA.png";
import { useNavigate } from "react-router-dom";

const ChannelAuthStatus = () => {
  const history = useNavigate();

  const handleWAClick = () => {
    window.open(
      "https://wa.me/+918750072800?text=Hi, I'm interested in partnering with Creditnama."
    );
    history("/channel");
  };
  return (
    <>
      <Header />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "var(--font-poppins)",
          height: "500px",
          flexDirection: "column",
          color: "var(--color-darkgreen)",
        }}
      >
        <h1 style={{ fontSize: "20px", textAlign: "center" }}>
          Thank you for signing up to partner with Creditnama.
          <br />
          We will connect with you shortly to complete the onboarding process.
        </h1>
        <button
          style={{
            width: "160px",
            height: "45px",
            backgroundColor: "rgb(8, 180, 8)",
            color: "white",
            border: "1px solid lightgrey",
            borderRadius: "5px",
            marginTop: "20px",
          }}
          onClick={handleWAClick}
        >
          <img src={WA} alt="whatsapp us" style={{ width: "25px" }} /> Whatsapp
          Us
        </button>
      </div>
      <StepsFooter />
    </>
  );
};

export default ChannelAuthStatus;
