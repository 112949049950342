import React, { useEffect, useState } from "react";
import StepsFooter from "../User-Components/Front-Page-Components/Footer/StepsFooter";
import StatusBar from "../Status-Bar/StatusBar";
import Header from "../User-Components/Front-Page-Components/Header/Header";
import OfferImg from "../Images/Page-Img/Finaloffer.png";
import axisLogo from "../Images/Bank-Logos/axis.png";
import hdfcLogo from "../Images/Bank-Logos/hdfc.png";
import kotakLogo from "../Images/Bank-Logos/kotak.png";
import abflLogo from "../Images/Bank-Logos/abfl.jpg";
import axisfinanceLogo from "../Images/Bank-Logos/axisfinance.png";
import bajajLogo from "../Images/Bank-Logos/bajaj.png";
import capriLogo from "../Images/Bank-Logos/capri.png";
import cholaLogo from "../Images/Bank-Logos/chola.png";
import citibankLogo from "../Images/Bank-Logos/citibank.png";
import dbsLogo from "../Images/Bank-Logos/dbs.png";
import deutcheLogo from "../Images/Bank-Logos/deutche.png";
import edelwisLogo from "../Images/Bank-Logos/edelwis.jpg";
import fullertonLogo from "../Images/Bank-Logos/fullerton.png";
import hdbLogo from "../Images/Bank-Logos/hdb.png";
import homecreditLogo from "../Images/Bank-Logos/homecredit.png";
import hsbcLogo from "../Images/Bank-Logos/hsbc.png";
import iciciLogo from "../Images/Bank-Logos/icici.png";
import idfcLogo from "../Images/Bank-Logos/idfc.jpg";
import iiflLogo from "../Images/Bank-Logos/iifl.png";
import incredLogo from "../Images/Bank-Logos/incred.png";
import indusindLogo from "../Images/Bank-Logos/indusind.png";
import karurLogo from "../Images/Bank-Logos/karur.jpg";
import myshubhlifeLogo from "../Images/Bank-Logos/myshubhlife.png";
import paysenseLogo from "../Images/Bank-Logos/paysense.png";
import protiumLogo from "../Images/Bank-Logos/protium.png";
import scbLogo from "../Images/Bank-Logos/scb.png";
import shriramLogo from "../Images/Bank-Logos/shriram.png";
import smcLogo from "../Images/Bank-Logos/smc.png";
import tataLogo from "../Images/Bank-Logos/tata.png";
import ujjivanLogo from "../Images/Bank-Logos/ujjivan.png";
import utkershLogo from "../Images/Bank-Logos/utkersh.png";
import yesbankLogo from "../Images/Bank-Logos/yesbank.png";
import indiabullshomeLogo from "../Images/Bank-Logos/Indiabulls.png";
import indiahomeLogo from "../Images/Bank-Logos/indiashelter.png";
import shubhamhomeLogo from "../Images/Bank-Logos/shubham.png";
import godrejLogo from "../Images/Bank-Logos/godrej.png";
import adityalimitedLogo from "../Images/Bank-Logos/abfl.jpg";
import easyhomefinanceLogo from "../Images/Bank-Logos/easyhome.png";
import piramalhousingLogo from "../Images/Bank-Logos/piramal.png";
import federalLogo from "../Images/Bank-Logos/fedral.png";
import shriramhousingLogo from "../Images/Bank-Logos/shriram.png";
import bajajhousingLogo from "../Images/Bank-Logos/bajaj.png";
import anandLogo from "../Images/Bank-Logos/Anandrathi.png";
import dmiLogo from "../Images/Bank-Logos/DmiHousing.png";
import rblLogo from "../Images/Bank-Logos/RblBank.png";
import aadharLogo from "../Images/Bank-Logos/AadharHousing.png";
import satinLogo from "../Images/Bank-Logos/SatinHousing.png";
import licLogo from "../Images/Bank-Logos/LicHousing.png";
import poonawallaLogo from "../Images/Bank-Logos/poonawalla.png";
import sbmLogo from "../Images/Bank-Logos/sbm.png";
import "./CNFinalOffer.css";
import serverIP from "../config/config";
import { useNavigate } from "react-router-dom";
import LoaderAnimation from "../Animation/LoaderAnimation";

function FinalOfferPage() {
  const [selectedUser, setSelectedUser] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [visibleUsers, setVisibleUsers] = useState([]);
  const [showAll] = useState(false);
  const [offerData, setOfferData] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useNavigate();

  const bankLogos = {
    axisLogo: axisLogo,
    hdfcLogo: hdfcLogo,
    kotakLogo: kotakLogo,
    abflLogo: abflLogo,
    axisfinanceLogo: axisfinanceLogo,
    bajajLogo: bajajLogo,
    capriLogo: capriLogo,
    cholaLogo: cholaLogo,
    citibankLogo: citibankLogo,
    dbsLogo: dbsLogo,
    deutcheLogo: deutcheLogo,
    edelwisLogo: edelwisLogo,
    fullertonLogo: fullertonLogo,
    hdbLogo: hdbLogo,
    homecreditLogo: homecreditLogo,
    hsbcLogo: hsbcLogo,
    iciciLogo: iciciLogo,
    idfcLogo: idfcLogo,
    iiflLogo: iiflLogo,
    incredLogo: incredLogo,
    indusindLogo: indusindLogo,
    karurLogo: karurLogo,
    myshubhlifeLogo: myshubhlifeLogo,
    paysenseLogo: paysenseLogo,
    protiumLogo: protiumLogo,
    scbLogo: scbLogo,
    shriramLogo: shriramLogo,
    smcLogo: smcLogo,
    tataLogo: tataLogo,
    ujjivanLogo: ujjivanLogo,
    utkershLogo: utkershLogo,
    yesbankLogo: yesbankLogo,
    indiahomeLogo: indiahomeLogo,
    shubhamhomeLogo: shubhamhomeLogo,
    godrejLogo: godrejLogo,
    adityalimitedLogo: adityalimitedLogo,
    easyhomefinanceLogo: easyhomefinanceLogo,
    indiabullshomeLogo: indiabullshomeLogo,
    piramalhousingLogo: piramalhousingLogo,
    federalLogo: federalLogo,
    shriramhousingLogo: shriramhousingLogo,
    bajajhousingLogo: bajajhousingLogo,
    anandLogo: anandLogo,
    dmiLogo: dmiLogo,
    rblLogo: rblLogo,
    aadharLogo: aadharLogo,
    satinLogo: satinLogo,
    licLogo: licLogo,
    poonawallaLogo: poonawallaLogo,
    sbmLogo: sbmLogo,
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const ask = localStorage.getItem("initial_ask_purpose");
  //       console.log(ask);
  //       const response = await fetch(
  //         `${serverIP}/product/get-bank-product-details`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             product_category: ask,
  //           }),
  //         }
  //       );
  //       const data = await response.json();
  //       if (response.ok) {
  //         console.log(data);
  //         // console.log(ask);

  //         setOfferData(data);
  //       } else {
  //         console.log(response);
  //       }
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };
  //   fetchData();
  // }, []);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const askId = localStorage.getItem("askId");
        const token = localStorage.getItem("accessToken");

        const response = await fetch(
          `${serverIP}/eligibility/return-eligibility-tables/${askId}`,

          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          // console.log(data);

          const filteredData = data.filter((item) => item.priority === 1);
          // console.log("Filtered data with priority 1:", filteredData);
          setOfferData(filteredData);

          if (filteredData.length === 0) {
            console.log("No eligibility");
            history("/session-break");
          }
        } else {
          console.log(response);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [history]);

  useEffect(() => {
    setSelectedUser(null);
    setVisibleUsers(offerData.slice(0, showAll ? offerData.length : 5));
  }, [showAll, offerData]);

  const toggleShowAll = () => {
    const currentlyVisibleCount = visibleUsers.length;
    const newVisibleCount = currentlyVisibleCount + 3;
    setVisibleUsers(offerData.slice(0, newVisibleCount));
  };

  const handleSubmit = () => {
    if (selectedUser) {
      const offerSelection = {
        interestRate: selectedUser.eligible_rate,
        bankName: selectedUser.bank_logo,
        maxLoan: selectedUser.eligible_loan_max,
        minLoan: selectedUser.eligible_loan_min,
        maxTenure: selectedUser.eligible_tenure_max,
        minTenure: selectedUser.eligible_tenure_min,
        tat: selectedUser.tat,
        appCharge: selectedUser.application_fees,
      };

      localStorage.setItem(
        "finalOfferSelection",
        JSON.stringify([offerSelection])
      );

      console.log(selectedUser.bank_logo);
      history("/selectiondetails");
    } else {
      console.log("Please select a bank");
    }
  };

  return (
    <>
      <Header />

      <div className="CN2-FinalOfferPage">
        <div className="CN2-FinalOfferPage-Container">
          <div className="CN2-FinalOfferPage-Main-Div">
            <div className="CN2-FinalOfferPage-Main-Div-Left">
              <img
                src={OfferImg}
                alt="Profile with pan and aadhaar"
                className="CN2-FinalOfferPage-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-success)"
                KycColor="var(--color-success)"
                IncomeColor="var(--color-yellow)"
                OffersColor="var(--color-success)"
              />
            </div>
            <div className="CN2-FinalOfferPage-Main-Div-Right">
              <div className="Finaloffer-confirmation-form">
                <div>
                  <h1>Eligible offers for you</h1>
                </div>
                {loading ? (
                  <>
                    <LoaderAnimation />
                    <LoaderAnimation />
                    <LoaderAnimation />
                  </>
                ) : (
                  <>
                    <div className="accordion">
                      {visibleUsers.map((user) => (
                        <div
                          className="Finalaccordion-item my-1"
                          key={user.bankId}
                        >
                          <button
                            className={`accordion-button FinalPage-accordion-button ${
                              selectedUser === user ? "" : "collapsed"
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#panelsStayOpen-collapse-${user.bankId}`}
                            aria-expanded={
                              selectedUser === user ? "true" : "false"
                            }
                            onClick={() => setSelectedUser(user)}
                            style={{ transform: "none" }}
                          >
                            <div className="Test1">
                              <div>
                                <img
                                  src={bankLogos[user.bank_logo]}
                                  alt={user.bankName}
                                  className="FinalPageBankofferLogo"
                                  style={{
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                              <div className="Div2 ">
                                <div>
                                  {/* <div style={{ fontSize: "12px" }}>Upto</div> */}
                                  <div>
                                    <b style={{ fontSize: "15px" }}>₹</b>
                                    <b style={{ fontSize: "20px" }}>
                                      {user.eligible_loan_max <= "99" ? (
                                        <>
                                          {(user.eligible_loan_max / 1).toFixed(
                                            0
                                          )}
                                          L
                                        </>
                                      ) : (
                                        <>
                                          {(
                                            user.eligible_loan_max / 100
                                          ).toFixed(2)}
                                          Cr
                                        </>
                                      )}
                                    </b>
                                  </div>
                                </div>
                              </div>
                              <div className="Div3">
                                <div style={{ fontSize: "12px" }}>
                                  {/* Starting */}
                                  <div>
                                    <b style={{ fontSize: "20px" }}>
                                      {(user.eligible_rate * 1).toFixed(2)}
                                    </b>
                                    <b style={{ fontSize: "10px" }}>%</b>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <input
                              // className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id={`flexRadioDefault-${user.bankId}`}
                              style={{
                                position: "absolute",
                                right: "20px",
                                width: "20px",
                                height: "20px",
                                border: "1px solid black",
                              }}
                              checked={selectedUser === user}
                              onChange={() => setIsChecked(!isChecked)}
                            />
                          </button>

                          <div
                            id={`panelsStayOpen-collapse-${user.bankId}`}
                            className={`accordion-collapse collapse ${
                              selectedUser === user ? "show" : ""
                            }`}
                          >
                            <div className="FinalPageInnerContainer my-1">
                              <div>
                                <div className="FinalPageInnerContent">
                                  <div>
                                    <div>
                                      Tenure: {user.eligible_tenure_max || "NA"}{" "}
                                      Years
                                    </div>
                                    <div>
                                      Application Charges:{" "}
                                      {user.application_fees || "NA"}
                                    </div>
                                  </div>
                                  <div>
                                    <div>
                                      EMI:{" "}
                                      {(user.eligible_emi || "NA").toFixed(1)}
                                    </div>
                                    <div>Time: {user.tat || "NA"} Days</div>
                                  </div>
                                </div>

                                <div className="ProceedContainer">
                                  <div className="mx-1">
                                    <b>Click proceed to grab deal</b>
                                  </div>
                                  <div>
                                    <button
                                      className="proceed-button"
                                      onClick={handleSubmit}
                                    >
                                      Customize
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {!showAll && offerData.length > visibleUsers.length && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "10px",
                          }}
                        >
                          <div>
                            <button
                              onClick={toggleShowAll}
                              className="view-more-button"
                              style={{
                                backgroundColor: "var(--color-lightyellow)",
                                color: "var(--color-darkgreen)",
                              }}
                            >
                              <b> View More</b>
                            </button>
                          </div>

                          <div>
                            <button
                              onClick={handleSubmit}
                              className="view-more-button mx-2"
                              style={
                                !selectedUser
                                  ? {
                                      backgroundColor: "lightgray",
                                      color: "gray",
                                    }
                                  : {
                                      backgroundColor: "var(--color-darkgreen)",
                                      color: "white",
                                    }
                              }
                            >
                              <b>Proceed</b>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default FinalOfferPage;
