import React from "react";
import "./StepsFooter.css";
import Aws from "../../../Images/Steps-Footer/Aws.png";
import Iso from "../../../Images/Steps-Footer/Iso.png";
import TLS from "../../../Images/Steps-Footer/TLS.png";
import DigiLocker from "../../../Images/Steps-Footer/DigiLocker.png";
import Footer from "./Footer";

function StepsFooter() {
  return (
    <>
      <div className="CN-StepsFooter">
        We ensure highest standards of data privacy and safety
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <img
            src={Iso}
            alt="ISO"
            className="CN-StepsFooter-ISO"
            onContextMenu={(e) => e.preventDefault()}
          />
          <img
            src={Aws}
            alt="AWS"
            className="CN-StepsFooter-AWS"
            onContextMenu={(e) => e.preventDefault()}
          />
          <img
            src={TLS}
            alt="TLS"
            className="CN-StepsFooter-TLS"
            onContextMenu={(e) => e.preventDefault()}
          />
          <img
            src={DigiLocker}
            alt="Digilocker"
            className="CN-StepsFooter-DigiLocker"
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
        <div className="CN-Copyright-Line">
          Copyright 2024 © creditnama.com. All rights reserved.
        </div>
      </div>
      <div className="CN-StepsFooter-DFooter">
        <Footer />
      </div>
    </>
  );
}

export default StepsFooter;
