import React, { useState } from "react";
import "./CNPayslip.css";
import PayslipImg from "../../Images/Assets/payslipdoclogo@2x.png";
import DocumentsImg from "../../Images/Page-Img/Pay Slip.png";
import Uploadimg from "../../Images/Assets/uploadicon@2x.png";
import Handriseicon from "../../Images/Assets/handrise@2x.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import serverIP from "../../config/config";
import Animation1 from "../../Animation/animation";
import Header from "../../User-Components/Front-Page-Components/Header/Header";
import StatusBar from "../../Status-Bar/StatusBar";
import StepsFooter from "../../User-Components/Front-Page-Components/Footer/StepsFooter";

function Payslip() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [multiSelect, setMultiSelect] = useState(false);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [error, setError] = useState("");
  const [isloading, setIsloading] = useState(false);

  const isMultiFilesSelected = selectedFile1 && selectedFile2 && selectedFile3;

  useEffect(() => {
    // Update the current date every second
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  // Calculate the current month and the previous two months
  const currentMonth = currentDate.getMonth(); // Get the current month (0-11)
  const currentYear = currentDate.getFullYear(); // Get the current year

  // Create date objects for the current and previous two months
  const currentMonthDate = new Date(currentYear, currentMonth - 1, 1);
  const previousMonth1 = new Date(currentYear, currentMonth - 2, 1);
  const previousMonth2 = new Date(currentYear, currentMonth - 3, 1);

  // Format the dates as needed
  const formattedCurrentMonth = currentMonthDate.toLocaleDateString("en-US", {
    month: "short",
    year: "2-digit",
  });
  const formattedPreviousMonth1 = previousMonth1.toLocaleDateString("en-US", {
    month: "short",
    year: "2-digit",
  });
  const formattedPreviousMonth2 = previousMonth2.toLocaleDateString("en-US", {
    month: "short",
    year: "2-digit",
  });

  const history = useNavigate();

  const handleUploadSingle = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");

    const formData = new FormData();

    if (selectedFile) {
      formData.append("payslip", selectedFile);
      formData.append("payslipType", "3month");
    }

    setIsloading(true);

    try {
      const response = await fetch(
        `${serverIP}/banking/uploadpayslip/${userId}`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 || response.status <= 300) {
        console.log("Files uploaded successfully!");
        console.log(response);
        history("/applicationstatus"); // Redirect to the Payslip page
      } else {
        console.error("Error uploading files.");
        // console.log(formData);
        setIsloading(false);
        history("/session-break");

        // history("/payslipstandby"); // Redirect to the Payslip page
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsloading(false);
      history("/session-break");
    } finally {
      setIsloading(false);
    }
  };

  const handleUploadMultiple = async () => {
    const token = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");

    setIsloading(true);

    try {
      const uploadRequests = [];
      let success = false;

      if (selectedFile1) {
        const formData1 = new FormData();
        formData1.append("payslip", selectedFile1);
        formData1.append("payslipType", formattedCurrentMonth);
        const request1 = fetch(`${serverIP}/banking/uploadpayslip/${userId}`, {
          method: "POST",
          body: formData1,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        uploadRequests.push(request1);
      }

      if (selectedFile2) {
        const formData2 = new FormData();
        formData2.append("payslip", selectedFile2);
        formData2.append("payslipType", formattedPreviousMonth1);
        const request2 = fetch(`${serverIP}/banking/uploadpayslip/${userId}`, {
          method: "POST",
          body: formData2,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        uploadRequests.push(request2);
      }

      if (selectedFile3) {
        const formData3 = new FormData();
        formData3.append("payslip", selectedFile3);
        formData3.append("payslipType", formattedPreviousMonth2);
        const request3 = fetch(`${serverIP}/banking/uploadpayslip/${userId}`, {
          method: "POST",
          body: formData3,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        uploadRequests.push(request3);
      }

      // Wait for all upload requests to complete
      const responses = await Promise.all(uploadRequests);

      // Check if any response is successful (status 200)
      responses.forEach((response) => {
        if (response.status === 200 || response.status <= 300) {
          success = true;
        }
      });

      if (success) {
        console.log("files uploaded successfully!");
        setIsloading(false);
        history("/applicationstatus");
      } else {
        console.error("None of the files were uploaded successfully.");
        setIsloading(false);
        history("/session-break");
        // You can handle the failure scenario here
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsloading(false);
      history("/session-break");
    } finally {
      setIsloading(false);
    }
  };

  const onUploadMoreClick = () => {
    setSelectedFile(null);
    console.log("clicked");
    setMultiSelect(true);
    setIsFileSelected(false);
    setError("");
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setError("");
    setIsFileSelected(!!file); // Set isFileSelected to true if a file is selected
  };

  const handleFile1Change = (event) => {
    const file = event.target.files[0];
    setSelectedFile1(file);
    setError("");
    setIsFileSelected(!!file);
  };

  const handleFile2Change = (event) => {
    const file = event.target.files[0];
    setSelectedFile2(file);
    setError("");
    setIsFileSelected(!!file);
  };

  const handleFile3Change = (event) => {
    const file = event.target.files[0];
    setSelectedFile3(file);
    setError("");
    setIsFileSelected(!!file);
  };
  return (
    <>
      <Header />

      <div className="CN2-Payslip">
        <div className="CN2-Payslip-Container">
          <div className="CN2-Payslip-Main-Div">
            <div className="CN2-Payslip-Main-Div-Left">
              <img
                src={DocumentsImg}
                alt="Upload Payslip"
                className="CN2-Payslip-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-success)"
                KycColor="var(--color-success)"
                IncomeColor="var(--color-yellow)"
                OffersColor="var(--color-success)"
              />
            </div>
            <div className="CN2-Payslip-Main-Div-Right">
              <h1>Upload pay slips</h1>
              Upload latest 3 month Payslip
              {error && <div className="CN2-Payslip-Error">{error}</div>}
              {isloading ? (
                <Animation1 />
              ) : (
                <>
                  {multiSelect ? (
                    <>
                      <div style={{ marginTop: "10px" }}>
                        {formattedCurrentMonth}

                        <label
                          htmlFor="fileInput1"
                          className="CN2-Payslip-Bank-Div"
                        >
                          <input
                            type="file"
                            accept=".pdf"
                            onChange={handleFile1Change}
                            style={{ display: "none" }}
                            id="fileInput1"
                            required={true}
                          />
                          <img
                            className="CN2-Payslip1-Bank-Icon"
                            alt=""
                            src={PayslipImg}
                          />
                          Click to upload
                          <img
                            className="CN2-Payslip-Bank-Icon"
                            alt=""
                            src={Uploadimg}
                          />
                        </label>
                      </div>
                      {selectedFile1 && (
                        <div style={{ textAlign: "center", width: "100%" }}>
                          Selected File: {selectedFile1.name}
                        </div>
                      )}
                      <div style={{ marginTop: "10px" }}>
                        {formattedPreviousMonth1}

                        <label
                          htmlFor="fileInput2"
                          className="CN2-Payslip-Bank-Div"
                        >
                          <input
                            type="file"
                            accept=".pdf"
                            onChange={handleFile2Change}
                            style={{ display: "none" }}
                            id="fileInput2"
                            required={true}
                          />
                          <img
                            className="CN2-Payslip1-Bank-Icon"
                            alt=""
                            src={PayslipImg}
                          />
                          Click to upload
                          <img
                            className="CN2-Payslip-Bank-Icon"
                            alt=""
                            src={Uploadimg}
                          />
                        </label>
                      </div>
                      {selectedFile2 && (
                        <div style={{ textAlign: "center", width: "100%" }}>
                          Selected File: {selectedFile2.name}
                        </div>
                      )}
                      <div style={{ marginTop: "10px" }}>
                        {formattedPreviousMonth2}

                        <label
                          htmlFor="fileInput3"
                          className="CN2-Payslip-Bank-Div"
                        >
                          <input
                            type="file"
                            accept=".pdf"
                            onChange={handleFile3Change}
                            style={{ display: "none" }}
                            id="fileInput3"
                            required={true}
                          />
                          <img
                            className="CN2-Payslip1-Bank-Icon"
                            alt=""
                            src={PayslipImg}
                          />
                          Click to upload
                          <img
                            className="CN2-Payslip-Bank-Icon"
                            alt=""
                            src={Uploadimg}
                          />
                        </label>
                      </div>
                      {selectedFile3 && (
                        <div style={{ textAlign: "center", width: "100%" }}>
                          Selected File: {selectedFile3.name}
                        </div>
                      )}

                      <button
                        className="CN2-Payslip-Button"
                        disabled={!isMultiFilesSelected}
                        onClick={handleUploadMultiple}
                        style={
                          !isMultiFilesSelected
                            ? {
                                backgroundColor: "lightgray",
                                color: "gray",
                              }
                            : {
                                backgroundColor: "var(--color-darkgreen)",
                                color: "white",
                              }
                        }
                      >
                        Proceed
                      </button>
                    </>
                  ) : (
                    <>
                      <label
                        htmlFor="fileInput"
                        className="CN2-Payslip-Bank-Div"
                      >
                        {/* <div className="statement-div"> */}
                        <input
                          type="file"
                          accept=".pdf"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                          id="fileInput"
                        />
                        {/* <Date /> */}
                        <img
                          className="CN2-Payslip1-Bank-Icon "
                          alt="Payslip"
                          src={PayslipImg}
                        />
                        Click to upload
                        <img
                          className="CN2-Payslip-Bank-Icon "
                          alt="Upload Payslip"
                          src={Uploadimg}
                        />
                      </label>
                      {/* <p>Scan and upload 3 month payslip in single pdf</p> */}
                      {selectedFile && (
                        <div style={{ textAlign: "center", width: "100%" }}>
                          Selected File: {selectedFile.name}
                        </div>
                      )}
                      <div
                        onClick={onUploadMoreClick}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          marginTop: "10px",
                        }}
                      >
                        <img
                          className="CN2-Payslip1-Bank-Icon"
                          alt=""
                          src={Handriseicon}
                        />
                        I want to upload month wise payslip
                      </div>
                      <button
                        className="CN2-Payslip-Button"
                        onClick={handleUploadSingle}
                        style={
                          !isFileSelected
                            ? { backgroundColor: "lightgray", color: "gray" }
                            : {
                                backgroundColor: "var(--color-darkgreen)",
                                color: "white",
                              }
                        }
                        disabled={!isFileSelected}
                      >
                        Proceed
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default Payslip;
