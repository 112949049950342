import React, { useEffect, useState } from "react";
import Navbar from "./Components/Navbar";
import ImageSlider from "./Components/Slider";
import Details from "./Components/Details";
import Footer from "./Components/footer";
import CTA from "./Components/CTA";
import WhatsappIcon from "./Components/WhatsappIcon";
import "./Components/details.css";
import Text from "./Text";
import ThankYou from "./Components/ThankYou";
import Gallery from "./Components/Gallery";
import PopUp from "./Components/PopUp";
import serverIP from "../../config/config";

function Paras() {
  const [showForm, setShowForm] = useState(true);
  const [utm, setUtm] = useState({
    utmSource: null,
    utmMedium: null,
    utmCampaign: null,
    utmContent: null,
    utmTerm: null,
  });

  const getUTMParameters = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    return {
      utmSource: urlParams.get("utm_source"),
      utmMedium: urlParams.get("utm_medium"),
      utmCampaign: urlParams.get("utm_campaign"),
      utmTerm: urlParams.get("utm_term"),
      utmContent: urlParams.get("utm_content"),
    };
  };

  useEffect(() => {
    const { utmSource, utmMedium, utmCampaign, utmTerm, utmContent } =
      getUTMParameters();
    setUtm({ utmSource, utmMedium, utmCampaign, utmTerm, utmContent });
  }, []);

  useEffect(() => {
    // Check if the data has already been submitted for this user
    const visitorId = localStorage.getItem("visitorId");
    if (visitorId) {
      // Data has already been submitted, no need to submit again
      // console.log("Data has already been submitted for this visitor.");
      return;
    }

    const handleVisitors = async () => {
      const visitorData = {
        utm_source: utm.utmSource,
        utm_medium: utm.utmMedium,
        utm_campaign: utm.utmCampaign,
        utm_term: utm.utmTerm,
        utm_content: utm.utmContent,
      };

      const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(visitorData),
      };

      try {
        const response = await fetch(`${serverIP}/visitor/newvisitor`, config);
        const data = await response.json();
        console.log("Data:", data);
        localStorage.setItem("visitorId", data.visitorId);
      } catch (error) {
        console.error("Error submitting data:", error);
        // Handle error here, maybe show a message to the user
      }
    };

    const timeoutId = setTimeout(() => {
      handleVisitors();
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [utm]);

  return (
    <div
      className="CN-Paras-sec59"
      style={{ width: "100%", overflow: "hidden" }}
    >
      {showForm ? (
        <>
          <Navbar />
          <WhatsappIcon />
          <ImageSlider />
          <CTA setShowForm={setShowForm} />
          <Text />
          <Gallery />
          <Details />
          <Footer />
          <PopUp />
        </>
      ) : (
        <>
          <ThankYou setShowForm={setShowForm} />
        </>
      )}
    </div>
  );
}

export default Paras;
