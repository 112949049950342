import React, { useEffect, useState } from "react";
import "./UpdateTracking.css";
import Creditnama from "../Images/CNLogo.png";
import serverIP from "../config/config";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const UpdateTracking = () => {
  const [status, setStatus] = useState("");
  const [subStatus, setSubStatus] = useState("");
  const [step, setStep] = useState("");
  const [response, setResponse] = useState("");
  const [remarks, setRemarks] = useState("");
  const askId = localStorage.getItem("askId");
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const history = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("iToken");
    const askId = localStorage.getItem("askId");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (!token) {
      console.log("Token not found. Relogin");
      history("/thekedaar/login");
      return;
    }

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${serverIP}/ask/summary/${askId}`,
          //   `${serverIP}/ask/thekedaar/details/${askId}`,
          config
        );

        if (response.status >= 200 && response.status < 300) {
          const data = await response.json();
          console.log(data);
          setData(data);
        } else {
          console.log("Data not found");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [history]);

  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setStatus(selectedStatus);
    setSubStatus("");
    setStep("");
    setResponse("");
    setError("");
  };

  const handleSubStatusChange = (event) => {
    const selectedSubStatus = event.target.value;
    setSubStatus(selectedSubStatus);
    setStep("");
    setResponse("");
    setError("");
  };

  const handleStepChange = (event) => {
    const selectedStep = event.target.value;
    setStep(selectedStep);
    setResponse("");
    setError("");
  };

  const handleResponse = (event) => {
    const selectedResponse = event.target.value;
    setResponse(selectedResponse);
    setError("");
  };

  const handleSubmit = async (e) => {
    if (response === "") {
      console.log("error");
      setError("Fill all the required fields");
      return;
    }

    e.preventDefault();

    const askId = localStorage.getItem("askId");
    const token = localStorage.getItem("accessToken");

    const config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stage: status,
        subStage: subStatus,
        step: step,
        response: response,
        remarks: remarks,
      }),
    };

    try {
      const response = await fetch(
        `${serverIP}/ask/thekedaar/status-update/${askId}`,
        config
      );

      if (response.ok) {
        const data = await response.json(); // Need to await response.json()
        console.log(data);
        history("/thekedaar/details");
      } else {
        console.log("data not sent");
      }
    } catch (error) {
      console.error(error);
      history("/thekedaar/login");
    }
  };

  const formatAmount = (amount) => {
    const formattedAmount = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: "0",
    }).format(amount);

    return formattedAmount;
  };

  const handleBack = () => {
    history("/thekedaar/details");
  };

  return (
    <div className="CN-UpdateTracking-Page">
      <div className="CN-UpdateTracking-Logo-Div">
        <img
          src={Creditnama}
          alt="Creditnama"
          className="CN-UpdateTracking-Img"
        />
        <button className="CN-UpdateTracking-Back-Btn" onClick={handleBack}>
          <ArrowBackIcon />
          Back
        </button>
      </div>

      <div className="CN-UpdateTracking-Pointer">
        <div>
          <div>
            <b>Ask id:</b> {askId}
          </div>
          <div>
            <b>Channel id:</b>
          </div>
          <div>
            <b>Requested value:</b> {formatAmount(data.initial_ask_value)} (
            {data.initial_ask_value / 10000000} Cr)
          </div>
          <div>
            <b>Requested purpose:</b> {data.initial_ask_purpose}
          </div>
          <div>
            <b>Requested tenure:</b> {data.initial_ask_tenure} Years
          </div>
          <div>
            <b>Created on:</b>
          </div>
        </div>
        <div>
          <div>
            <b>User id:</b>
          </div>
          <div>
            <b>Name:</b> {data.fullnamePan}
          </div>
          <div>
            <b>Phone:</b>
          </div>
          <div>
            <b>Address:</b> {data.fullAddress}
          </div>
        </div>
      </div>
      {error && <div className="CN-UpdateTracking-Error">{error}</div>}
      <div className="CN-UpdateTracking-SelectDiv">
        <div className="CN-UpdateTracking-Select-Pointers">
          <label>Status</label>
          <select onChange={handleStatusChange} value={status}>
            <option>Choose option....</option>
            <option value="pre application submission">
              Pre application submission
            </option>
            <option value="post application submission">
              Post application submission
            </option>
            <option value="post approval">Post approval</option>
          </select>
        </div>
        <div className="CN-UpdateTracking-Select-Pointers">
          <label>Sub status</label>
          <select
            disabled={status === "" ? true : false}
            onChange={handleSubStatusChange}
            value={subStatus}
          >
            <option>Choose option....</option>

            {status === "pre application submission" && (
              <>
                <option value="profile">Profile</option>
                <option value="loan">Loan</option>
                <option value="kyc">KYC</option>
                <option value="asset">Asset</option>
                <option value="income">Income</option>
                <option value="offers">Offers</option>
              </>
            )}

            {status === "post application submission" && (
              <>
                <option value="loggedin">LoggedIn</option>
                <option value="evaluation">Evaluation</option>
                <option value="pendency">Pendency</option>
              </>
            )}

            {status === "post approval" && (
              <>
                <option value="agreement">Agreement</option>
              </>
            )}
          </select>
        </div>
        <div className="CN-UpdateTracking-Select-Pointers">
          <label>Step</label>
          <select
            disabled={subStatus === "" ? true : false}
            onChange={handleStepChange}
            value={step}
          >
            <option>Choose option....</option>
            {subStatus === "profile" && (
              <>
                <option value="name available">Name available</option>
                <option value="phone available">Phone available</option>
              </>
            )}
            {subStatus === "loan" && (
              <>
                <option value="loan requirements availabe">
                  Loan requirements availabe
                </option>
                <option value="credit score available">
                  Credit score available
                </option>
              </>
            )}

            {subStatus === "kyc" && (
              <>
                <option value="pan verified">Pan verified</option>
                <option value="aadhaar verified">Aadhaar verified</option>
              </>
            )}

            {subStatus === "asset" && (
              <>
                <option value="property details availabe">
                  Property details availabe
                </option>
                <option value="title chain availabe">
                  Title chain availabe
                </option>
              </>
            )}

            {subStatus === "income" && (
              <>
                <option value="income available">Income available</option>
                <option value="banking available">Banking available</option>
              </>
            )}
            {subStatus === "offers" && (
              <>
                <option value="customer provided with options">
                  Customer provided with options
                </option>
                <option value="customer has approved an option">
                  Customer has approved an option
                </option>
              </>
            )}

            {subStatus === "loggedin" && (
              <>
                <option value="customer submits the application with creditnama">
                  Customer submits the application with creditnama
                </option>
                <option value="creditnama logged customer application with lender">
                  Creditnama logged customer application with lender
                </option>
                <option value="customer has given application fees">
                  Customer has given application fees
                </option>
              </>
            )}

            {subStatus === "evaluation" && (
              <>
                <option value="lender completed legal evaluation">
                  Lender completed legal evaluation
                </option>
                <option value="lender completed technical evaluation">
                  Lender completed technical evaluation
                </option>
                <option value="lender completed personal discussion">
                  Lender completed personal discussion
                </option>
              </>
            )}

            {subStatus === "pendency" && (
              <>
                <option value="lender provided pendencies">
                  Lender provided pendencies
                </option>
                <option value="customer addressed pendencies">
                  Customer addressed pendencies
                </option>
              </>
            )}

            {subStatus === "agreement" && (
              <>
                <option value="lender gave in principal approval">
                  Lender gave in principal approval
                </option>
                <option value="customer agreed to the terms">
                  Customer agreed to the terms
                </option>
                <option value="lender gave sanction letter">
                  Lender gave sanction letter
                </option>
                <option value="customer signs sanction letter">
                  Customer signs sanction letter
                </option>
                <option value="loan is disbursed">Loan is disbursed</option>
              </>
            )}
          </select>
        </div>
      </div>
      <div className="CN-UpdateTracking-SelectDiv">
        <div className="CN-UpdateTracking-Select-Pointers">
          <label>Response</label>
          <select
            disabled={step === "" ? true : false}
            onChange={handleResponse}
            value={response}
          >
            <option>Choose option....</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
            <option value="na">NA</option>
          </select>
        </div>
        <div className="CN-UpdateTracking-Select-Pointers">
          <label>Remarks (optional)</label>
          <input
            type="text"
            onChange={(e) => {
              setRemarks(e.target.value);
            }}
          />
        </div>
      </div>
      <button onClick={handleSubmit}>Submit update</button>
    </div>
  );
};

export default UpdateTracking;
