import "./CNProfile2.css";
import Header from "../Front-Page-Components/Header/Header";
import Profile from "../../Images/Page-Img/Create Profile.png";
import StatusBar from "../../Status-Bar/StatusBar";
import AadhaarIcon from "../../Images/Assets/aadhaaricon@2x.png";
import PanIcon from "../../Images/Assets/panicon@2x.png";
import { Link, useLocation } from "react-router-dom";
import Animation1 from "../../Animation/animation";
import serverIP from "../../config/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import StepsFooter from "../Front-Page-Components/Footer/StepsFooter";
import OtpImg from "../../Images/Assets/smsicon@2x.png";
import { useEffect, useState } from "react";
import FAQ from "../FAQs/FAQ";

function Profile2() {
  const [Error, setError] = useState("");
  const [DivError, setDivError] = useState("");
  const [resendTimer, setResendTimer] = useState(0);
  const [showResendButton, setShowResendButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const history = useNavigate();
  const location = useLocation();

  const { pan, aadhaar } = location.state || {};

  const formattedResendTimer =
    resendTimer < 10 ? `0${resendTimer}` : resendTimer;

  useEffect(() => {
    let timer;

    if (resendTimer > 0 && !showResendButton) {
      timer = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (resendTimer === 0 && !showResendButton) {
      setShowResendButton(true);
    }

    return () => {
      clearInterval(timer);
    };
  }, [resendTimer, showResendButton]);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Get the access token from localStorage
      const token = localStorage.getItem("accessToken");
      const userId = localStorage.getItem("userId");

      // Set the Authorization header with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Send a POST request to the server with the selected loan type, amount, and tenure
      const response = await axios.post(
        `${serverIP}/user/scoreme/eaadhaar/file/${userId}`,
        {
          otp: otp,
          aadhaar: aadhaar,
        },
        config
      );

      // Handle different response codes
      if (response.data.responseCode === "SRC001") {
        console.log("Verified");
        history("/standby");
      } else if (response.data.responseCode === "EIP018") {
        console.error("Invalid OTP");
        setError("Invalid OTP");
      } else if (response.data.responseCode === "EOE794") {
        setError("OTP Expired");
      } else if (response.data.responseCode === "ENL517") {
        console.log(
          "Aadhaar not linked with mobile. Go back to home & enter details manually"
        );
        setDivError(
          "Aadhaar not linked with mobile. Go back to home & enter details manually"
        );
      } else {
        setDivError(
          "Error fetching Aadhaar. Go back to home & enter details manually"
        );
      }
    } catch (error) {
      console.error("Error inserting data:", error);
    } finally {
      setIsLoading(false); // Stop loading animation
    }
  };

  // Function to handle OTP resend
  const handleResend = async (e) => {
    e.preventDefault();
    if (showResendButton) {
      setIsLoading(true);
      setShowResendButton(false);
      setResendTimer(60);

      try {
        const token = localStorage.getItem("accessToken");
        const userId = localStorage.getItem("userId");

        // Make sure the token exists before proceeding
        if (!token || !userId) {
          console.error("Access token or UserId not found.");
          return;
        }

        // Set the Authorization header with the token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        // Send the POST request to trigger Aadhaar OTP Request
        const resendResponse = await axios.post(
          `${serverIP}/kyc/scoreme/eaadhaar/otp/${userId}`,
          {
            aadhaar: aadhaar,
          },
          config
        );
        if (resendResponse.data.responseCode === "SOS174") {
          console.log("Resend Successfully");
          setIsLoading(false);
        } else {
          setError("Error Resending OTP");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setResendTimer(60);
    setShowResendButton(false);
  }, []);

  // Function to handle error dismissal
  const handleReadError = () => {
    setDivError("");
    history("/profile1");
  };

  return (
    <>
      <Header />

      <div className="CN2-Profile2">
        <div className="CN2-Profile2-Container">
          <div className="CN2-Profile2-Main-Div">
            <div className="CN2-Profile2-Main-Div-Left">
              <img
                src={Profile}
                alt="Profile with pan and aadhaar"
                className="CN2-Profile2-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-yellow)"
                LoanColor="white"
                KycColor="white"
                IncomeColor="white"
                OffersColor="white"
              />
            </div>
            <div className="CN2-Profile2-Main-Div-Right">
              <h1>Create Profile</h1>
              <div className="CN2-Profile2-Faq-Div">
                <FAQ PageName="profile2" />
              </div>
              {DivError ? (
                <div className="CN2-Profile2-DivError">
                  {DivError}

                  <button
                    className="CN2-Profile2-Button"
                    onClick={handleReadError}
                  >
                    Got it
                  </button>
                </div>
              ) : (
                <>
                  {isLoading ? (
                    <>
                      <Animation1 />
                    </>
                  ) : (
                    <>
                      <form onSubmit={handleSubmit}>
                        <div className="CN2-Profile2-Edit-Btn">
                          <Link to="/profile1">Edit</Link>
                        </div>
                        {Error && (
                          <div className="CN2-Profile2-Error">{Error}</div>
                        )}
                        <div>
                          <img
                            src={PanIcon}
                            alt="Pan"
                            className="CN2-Profile2-Pan-Icon"
                          />
                          <input
                            type="text"
                            placeholder="Pan Number"
                            className="CN2-Profile2-Input"
                            value={pan}
                            disabled
                          />
                        </div>
                        <div>
                          <img
                            src={AadhaarIcon}
                            alt="Aadhaar"
                            className="CN2-Profile2-Aadhaar-Icon"
                          />
                          <input
                            placeholder="Aadhaar Number"
                            className="CN2-Profile2-Input"
                            type="tel"
                            value={aadhaar}
                            disabled
                          />
                        </div>
                        <div>
                          <img
                            src={OtpImg}
                            alt="Otp"
                            className="CN2-Profile2-Otp-Icon"
                          />
                          <input
                            placeholder="Enter OTP"
                            className="CN2-Profile2-Input"
                            type="tel"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            maxLength={6}
                            required
                          />
                        </div>
                        <button className="CN2-Profile2-Button">
                          Fetch Info
                        </button>
                        <div style={{ marginTop: "15px", fontSize: "14px" }}>
                          {showResendButton ? (
                            <>
                              Didn’t receive OTP?{" "}
                              <Link onClick={handleResend} className="mx-1">
                                Resend OTP
                              </Link>
                              {resendTimer > 0 && <span>00:{resendTimer}</span>}
                            </>
                          ) : (
                            `Resend OTP in 00:${formattedResendTimer} seconds`
                          )}
                        </div>
                      </form>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default Profile2;
