import React, { useEffect, useState } from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import SubjectIcon from "@mui/icons-material/Subject";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../../../Images/CNLogo.png";
import WA from "../../../Images/Assets/WA.png";

function Navbar({ isvisible = true }) {
  const [logOut, setLogOut] = useState(false);
  const [loading, setLoading] = useState(true); // New state to manage loading state
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileMenuOpenAbout, setIsMobileMenuOpenAbout] = useState(false);
  const [isMobileMenuOpenInsight, setIsMobileMenuOpenInsight] = useState(false);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const handleMobileMenuToggleAbout = () => {
    setIsMobileMenuOpenAbout(!isMobileMenuOpenAbout);
  };
  const handleMobileMenuToggleInsight = () => {
    setIsMobileMenuOpenInsight(!isMobileMenuOpenInsight);
  };

  const router = useNavigate();

  useEffect(() => {
    const response = localStorage.getItem("accessToken");
    if (response == null) {
      setLogOut(true);
    } else {
      setLogOut(false);
    }
    setLoading(false); // Update loading state when token check is completed
  }, []);

  const [nav, setNav] = useState(false);
  //   const history = useNavigate();

  const handleNav = () => {
    setNav(!nav);
  };

  const handleHome = () => {
    localStorage.removeItem("accessToken");
    router.push("https://creditnama.com/");
  };

  // const handleProducts = () => {
  //   router.push("https://creditnama.com/#Products");
  //   setNav(!nav);
  //   setTimeout(() => {
  //     const partnersSection = document.getElementById("Products");
  //     if (partnersSection) {
  //       partnersSection.scrollIntoView({ behavior: "smooth" });
  //     }
  //   }, 500);
  // };
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("askId");
    localStorage.removeItem("visitorId");
    localStorage.removeItem("sessionId");
    router.push("/");
  };

  if (loading) {
    return null;
  }

  const handleWAClick = () => {
    window.open(
      "https://wa.me/+918750072800?text=Hey, help me choose the best loan offers"
    );
  };
  // -------------------------------------------------------------------------------------------------------

  return (
    <>
      {isvisible && (
        <>
          <div className="CN2-Header">
            <div className="CN2-Name" onClick={handleHome}>
              <img
                src={Logo}
                alt="Logo"
                className="CN-Logo"
                loading="lazy"
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
            <div className="CN2-Header-Menu">
              <div className="CN2-Header-Dropdown">
                <a
                  href="#about"
                  className="CN2-Header-dropbtn  dropdown-toggle"
                >
                  About
                </a>
                <div className="CN2-Header-dropdown-content">
                  <div className="CN2-Header-sub-dropdown CN2-Header-mtop CN2-Header-mbottom">
                    <a href="https://creditnama.com/about">About Us</a>
                    <a href="https://creditnama.com/team">Team</a>
                    <a href="https://creditnama.com/contact-us">Contact</a>
                  </div>
                </div>
              </div>
              <div className="CN2-Header-Dropdown">
                <a
                  href="#products"
                  className="CN2-Header-dropbtn  dropdown-toggle"
                >
                  Products
                </a>
                <div className="CN2-Header-dropdown-content">
                  <div className="CN2-Header-sub-dropdown CN2-Header-mtop">
                    <a
                      className="dropdown-toggle"
                      href="https://creditnama.com/home-loan-blogs"
                    >
                      Home Loan from Banks
                    </a>
                    <div className="CN2-Header-sub-dropdown-content">
                      <a href="https://creditnama.com/sbi-bank/home-loan">
                        SBI Bank Home Loan
                      </a>
                      <a href="https://creditnama.com/hdfc-bank/home-loan">
                        HDFC Bank Home Loan
                      </a>
                      <a href="https://creditnama.com/punjab-national-bank/home-loan">
                        Punjab National Bank
                      </a>
                      <a href="https://creditnama.com/icici-bank/home-loan">
                        ICICI Bank Home Loan
                      </a>
                      <a href="https://creditnama.com/axis-bank/home-loan">
                        Axis Bank Home Loan
                      </a>
                      <a href="https://creditnama.com/bank-of-baroda/home-loan">
                        Bank of Baroda Home Loan
                      </a>
                      <a href="https://creditnama.com/bank-of-india/home-loan">
                        Bank of India Home Loan
                      </a>
                      <a href="https://creditnama.com/yes-bank/home-loan">
                        Yes Bank Home Loan
                      </a>
                      <a
                        style={{ textAlign: "right", color: "blue" }}
                        href="https://creditnama.com/home-loan-blogs"
                      >
                        View All
                      </a>
                    </div>
                  </div>
                  <div className="CN2-Header-sub-dropdown">
                    <a
                      className="  dropdown-toggle"
                      href="https://creditnama.com/home-loan-blogs"
                    >
                      Home Loan from NBFCs
                    </a>
                    <div className="CN2-Header-sub-dropdown-content">
                      <a href="https://creditnama.com/lic-housing-finance/home-loan">
                        LIC Housing Finance
                      </a>
                      <a href="https://creditnama.com/aditya-birla-housing-finance/home-loan">
                        Aditya Birla Housing Finance
                      </a>
                      <a href="https://creditnama.com/bajaj-housing-finance/home-loan">
                        Bajaj Housing Finance
                      </a>
                      <a href="https://creditnama.com/pnb-housing-finance/home-loan">
                        PNB Housing Finance
                      </a>
                      <a href="https://creditnama.com/tata-capital/home-loan">
                        TATA Capital
                      </a>
                      <a
                        style={{ textAlign: "right", color: "blue" }}
                        href="https://creditnama.com/home-loan-blogs"
                      >
                        View All
                      </a>
                    </div>
                  </div>
                  <div className="CN2-Header-sub-dropdown">
                    <a
                      className="  dropdown-toggle"
                      href="https://creditnama.com/home-loan-blogs"
                    >
                      Home Loan in City
                    </a>
                    <div className="CN2-Header-sub-dropdown-content">
                      <a href="https://creditnama.com/home-loan/delhi">
                        Home Loan in Delhi
                      </a>
                      <a href="https://creditnama.com/home-loan/gurgaon">
                        Home Loan in Gurgaon
                      </a>
                      <a href="https://creditnama.com/home-loan/greater-noida">
                        Home Loan in Greater Noida
                      </a>
                      <a href="https://creditnama.com/home-loan/noida">
                        Home Loan in Noida
                      </a>
                      <a href="https://creditnama.com/home-loan/mumbai">
                        Home Loan in Mumbai
                      </a>
                    </div>
                  </div>
                  <div className="CN2-Header-sub-dropdown CN2-Header-mbottom">
                    <a
                      className="  dropdown-toggle"
                      href="#homeloanbalancetransfer"
                    >
                      Home Loan Balance Transfer
                    </a>
                    <div className="CN2-Header-sub-dropdown-content">
                      <a href="https://creditnama.com/sbi-bank/home-loan/balance-transfer-calculator">
                        SBI Bank Home Loan
                      </a>
                      <a href="https://creditnama.com/hdfc-bank/home-loan/balance-transfer-calculator">
                        HDFC Bank Home Loan
                      </a>
                      <a href="https://creditnama.com/punjab-national-bank/home-loan/balance-transfer-calculator">
                        Punjab National Bank
                      </a>
                      <a href="https://creditnama.com/icici-bank/home-loan/balance-transfer-calculator">
                        ICICI Bank Home Loan
                      </a>
                      <a href="https://creditnama.com/axis-bank/home-loan/balance-transfer-calculator">
                        Axis Bank Home Loan
                      </a>
                      <a
                        style={{ textAlign: "right", color: "blue" }}
                        href="https://creditnama.com/home-loan-blogs"
                      >
                        View All
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="CN2-Header-Dropdown">
                <a
                  href="#insight"
                  className="CN2-Header-dropbtn  dropdown-toggle"
                >
                  Insight
                </a>
                <div className="CN2-Header-dropdown-content">
                  <div className="CN2-Header-sub-dropdown CN2-Header-mtop">
                    <a href="https://creditnama.com/blogs">Blogs</a>
                  </div>
                  <div className="CN2-Header-sub-dropdown">
                    <a
                      className="  dropdown-toggle"
                      href="https://creditnama.com/calculators"
                    >
                      Calculators
                    </a>
                    <div className="CN2-Header-sub-dropdown-content">
                      <a href="https://creditnama.com/home-loan/affordability-calculator">
                        Affordability Calculator
                      </a>
                      <a href="https://creditnama.com/home-loan/balance-transfer-calculator">
                        Balance Transfer Calculator
                      </a>
                      <a href="https://creditnama.com/home-loan/emi-calculator">
                        EMI Calculator
                      </a>
                      <a href="https://creditnama.com/home-loan/eligibility-calculator">
                        Eligibility Calculator
                      </a>
                    </div>
                  </div>
                  <div className="CN2-Header-sub-dropdown CN2-Header-mbottom">
                    <a href="https://creditnama.com/compare">Compare</a>
                  </div>
                </div>
              </div>
              {/* <a href="/contact-us">Contact</a> */}

              <a href="https://creditnama.com/track-application">
                Track Application
              </a>
              {logOut ? (
                <a href="https://creditnama.com/login-signup">Login/Signup</a>
              ) : (
                <button className="CN2-Header-Logout" onClick={handleLogout}>
                  Logout
                </button>
              )}
              <button
                className="CN2-Header-WhatsappButton"
                onClick={handleWAClick}
              >
                <img
                  src={WA}
                  alt="Say hi"
                  className="CN2-Header-Whatsappus-Icon"
                  width={25}
                />
                Get advice
              </button>
            </div>
            {!nav ? (
              <div className="CN2-Test3-1" onClick={handleNav}>
                <SubjectIcon />
              </div>
            ) : (
              <>
                <div className="CN2-Test3">
                  <div className={`CN2-Mobile-Menu ${nav ? "open" : ""}`}>
                    <div onClick={handleNav} className="CN2-Mobile-Menu-Close">
                      <CloseIcon />
                    </div>
                    <a href="https://creditnama.com/" onClick={handleHome}>
                      Home
                    </a>

                    <div
                      className="dropdown-toggle"
                      style={{ padding: "5px", cursor: "pointer" }}
                      onClick={handleMobileMenuToggleAbout}
                    >
                      About
                    </div>
                    <div
                      className={`CNN-menu ${
                        isMobileMenuOpenAbout ? "active" : ""
                      }`}
                    >
                      <ul style={{ marginLeft: "0px", textAlign: "left" }}>
                        <li
                          style={{ marginLeft: "0px", textAlign: "left" }}
                          className="CCN-dropdown"
                        >
                          <a
                            style={{ marginLeft: "0px", textAlign: "left" }}
                            href="https://creditnama.com/about"
                            className="dropbtn"
                          >
                            About Us
                          </a>
                        </li>
                        <li
                          style={{ marginLeft: "0px", textAlign: "left" }}
                          className="CCN-dropdown"
                        >
                          <a
                            style={{ marginLeft: "0px", textAlign: "left" }}
                            href="https://creditnama.com/team"
                            className="dropbtn"
                          >
                            Team
                          </a>
                        </li>
                        <li
                          style={{ marginLeft: "0px", textAlign: "left" }}
                          className="CCN-dropdown"
                        >
                          <a
                            style={{ marginLeft: "0px", textAlign: "left" }}
                            href="https://creditnama.com/contact-us"
                            className="dropbtn"
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* --------------------------------------------- */}
                    <div
                      className="dropdown-toggle"
                      style={{ padding: "5px", cursor: "pointer" }}
                      onClick={handleMobileMenuToggle}
                    >
                      Products
                    </div>
                    <div
                      className={`CNN-menu ${isMobileMenuOpen ? "active" : ""}`}
                    >
                      <ul style={{ marginLeft: "0px", textAlign: "left" }}>
                        <li
                          style={{ marginLeft: "0px", textAlign: "left" }}
                          className="CCN-dropdown"
                        >
                          <a
                            style={{ marginLeft: "0px", textAlign: "left" }}
                            href="#homeloanfrombanks"
                            className="dropbtn dropdown-toggle"
                          >
                            Home Loan from Banks
                          </a>
                          <div className="CCN-dropdown-content">
                            <div className="CCN-sub-dropdown">
                              <a href="https://creditnama.com/sbi-bank/home-loan">
                                SBI Bank Home Loan
                              </a>
                              <a href="https://creditnama.com/hdfc-bank/home-loan">
                                HDFC Bank Home Loan
                              </a>
                              <a href="https://creditnama.com/punjab-national-bank/home-loan">
                                Punjab National Bank
                              </a>
                              <a href="https://creditnama.com/icici-bank/home-loan">
                                ICICI Bank Home Loan
                              </a>
                              <a href="https://creditnama.com/axis-bank/home-loan">
                                Axis Bank Home Loan
                              </a>
                              <a href="https://creditnama.com/bank-of-baroda/home-loan">
                                Bank of Baroda Home Loan
                              </a>
                              <a href="https://creditnama.com/bank-of-india/home-loan">
                                Bank of India Home Loan
                              </a>
                              <a href="https://creditnama.com/yes-bank/home-loan">
                                Yes Bank Home Loan
                              </a>
                              <a
                                style={{ textAlign: "right", color: "blue" }}
                                href="https://creditnama.com/home-loan-blogs"
                              >
                                View All
                              </a>
                            </div>
                          </div>
                        </li>
                        <li
                          style={{ marginLeft: "0px", textAlign: "left" }}
                          className="CCN-dropdown"
                        >
                          <a
                            style={{ marginLeft: "0px", textAlign: "left" }}
                            href="#homeloaninnbfc"
                            className="dropbtn dropdown-toggle"
                          >
                            Home Loan from NBFCs
                          </a>
                          <div className="CCN-dropdown-content">
                            <div className="CCN-sub-dropdown">
                              <a href="https://creditnama.com/lic-housing-finance/home-loan">
                                LIC Housing Finance
                              </a>
                              <a href="https://creditnama.com/aditya-birla-housing-finance/home-loan">
                                Aditya Birla Housing Finance
                              </a>
                              <a href="https://creditnama.com/bajaj-housing-finance/home-loan">
                                Bajaj Housing Finance
                              </a>
                              <a href="https://creditnama.com/pnb-housing-finance/home-loan">
                                PNB Housing Finance
                              </a>
                              <a href="https://creditnama.com/tata-capital/home-loan">
                                TATA Capital
                              </a>
                              <a
                                style={{ textAlign: "right", color: "blue" }}
                                href="https://creditnama.com/home-loan-blogs"
                              >
                                View All
                              </a>
                            </div>
                          </div>
                        </li>
                        <li
                          style={{ marginLeft: "0px", textAlign: "left" }}
                          className="CCN-dropdown"
                        >
                          <a
                            style={{ marginLeft: "0px", textAlign: "left" }}
                            className="dropbtn dropdown-toggle"
                            href="#homeloanincity"
                          >
                            Home Loan in City
                          </a>
                          <div className="CCN-dropdown-content">
                            <div className="CCN-sub-dropdown">
                              <a href="https://creditnama.com/home-loan/delhi">
                                Home Loan in Delhi
                              </a>
                              <a href="https://creditnama.com/home-loan/gurgaon">
                                Home Loan in Gurgaon
                              </a>
                              <a href="https://creditnama.com/home-loan/greater-noida">
                                Home Loan in Greater Noida
                              </a>
                              <a href="https://creditnama.com/home-loan/noida">
                                Home Loan in Noida
                              </a>
                              <a href="https://creditnama.com/home-loan/mumbai">
                                Home Loan in Mumbai
                              </a>
                            </div>
                          </div>
                        </li>
                        <li
                          style={{ marginLeft: "0px", textAlign: "left" }}
                          className="CCN-dropdown"
                        >
                          <a
                            style={{ marginLeft: "0px", textAlign: "left" }}
                            className="dropbtn dropdown-toggle"
                            href="#homeloanbt"
                          >
                            Home Loan Balance Transfer
                          </a>
                          <div className="CCN-dropdown-content">
                            <div className="CCN-sub-dropdown">
                              <a href="https://creditnama.com/sbi-bank/home-loan/balance-transfer-calculator">
                                SBI Bank Home Loan
                              </a>
                              <a href="https://creditnama.com/hdfc-bank/home-loan/balance-transfer-calculator">
                                HDFC Bank Home Loan
                              </a>
                              <a href="https://creditnama.com/punjab-national-bank/home-loan/balance-transfer-calculator">
                                Punjab National Bank
                              </a>
                              <a href="https://creditnama.com/icici-bank/home-loan/balance-transfer-calculator">
                                ICICI Bank Home Loan
                              </a>
                              <a href="https://creditnama.com/axis-bank/home-loan/balance-transfer-calculator">
                                Axis Bank Home Loan
                              </a>
                              <a
                                style={{ textAlign: "right", color: "blue" }}
                                href="https://creditnama.com/home-loan-blogs"
                              >
                                View All
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>

                    {/* --------------------------------------------- */}
                    <div
                      className="dropdown-toggle"
                      style={{ padding: "5px", cursor: "pointer" }}
                      onClick={handleMobileMenuToggleInsight}
                    >
                      Insights
                    </div>
                    <div
                      className={`CNN-menu ${
                        isMobileMenuOpenInsight ? "active" : ""
                      }`}
                    >
                      <ul style={{ marginLeft: "0px", textAlign: "left" }}>
                        <li
                          style={{ marginLeft: "0px", textAlign: "left" }}
                          className="CCN-dropdown"
                        >
                          <a
                            style={{ marginLeft: "0px", textAlign: "left" }}
                            href="https://creditnama.com/blogs"
                          >
                            Blogs
                          </a>
                        </li>
                        <li
                          style={{ marginLeft: "0px", textAlign: "left" }}
                          className="CCN-dropdown"
                        >
                          <a
                            style={{ marginLeft: "0px", textAlign: "left" }}
                            className="dropdown-toggle"
                            href="#calculators"
                          >
                            {" "}
                            Calculators
                          </a>
                          <div className="CCN-dropdown-content">
                            <div className="CCN-sub-dropdown">
                              <a href="https://creditnama.com/home-loan/affordability-calculator">
                                Home Loan Affordability Calculator
                              </a>
                              <a href="https://creditnama.com/home-loan/balance-transfer-calculator">
                                Home Loan Balance Transfer Calculator
                              </a>
                              <a href="https://creditnama.com/home-loan/emi-calculator">
                                Home Loan EMI Calculator
                              </a>
                              <a href="https://creditnama.com/home-loan/eligibility-calculator">
                                Home Loan Eligibility Calculator
                              </a>
                            </div>
                          </div>
                        </li>
                        <li
                          style={{ marginLeft: "0px", textAlign: "left" }}
                          className="CCN-dropdown"
                        >
                          <a
                            style={{ marginLeft: "0px", textAlign: "left" }}
                            href="https://creditnama.com/compare"
                          >
                            Compare
                          </a>
                        </li>
                      </ul>
                    </div>

                    {/* <a href="/contact-us">Contact</a> */}
                    <a href="https://creditnama.com/track-application">
                      Track Application
                    </a>
                    {logOut ? (
                      <a href="https://creditnama.com/login-signup">
                        Login/Signup
                      </a>
                    ) : (
                      <button
                        className="CN2-Header-Logout"
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    )}
                    <button
                      className="CN2-Header-WhatsappButton"
                      onClick={handleWAClick}
                      style={{ marginTop: "10px" }}
                    >
                      <img
                        src={WA}
                        alt="Say hi"
                        className="CN2-Header-Whatsappus-Icon"
                        width={25}
                      />
                      Get advice
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Navbar;
