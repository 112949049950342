import React, { useState } from "react";
import "./AiAuth.css";

function AiAuth() {
  const [email, setEmail] = useState("");
  const [Password, setPassword] = useState("");

  const setAuth = () => {
    document.cookie = `AiAuth=true; path=/; expires=${new Date(
      new Date().getTime() + 7 * 24 * 60 * 60 * 1000
    ).toUTCString()}`;
    window.location.reload();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === "hello@creditnama.com" && Password === "hello@creditnama") {
      setAuth();
    } else {
      alert("Invalid credentials");
    }
  };
  return (
    <>
      <div className="Ai-Chatbot-Page">
        <form onSubmit={handleSubmit}>
          <h1>Login</h1>
          <label>Username</label>
          <input
            type="email"
            value={email}
            maxLength={20}
            onChange={(e)=>setEmail(e.target.value)}
          />
          <label>Password</label>
          <input
            type="password"
            maxLength={20}
            value={Password}
            onChange={(e)=>setPassword(e.target.value)}
          />

          <button type="submit">Submit</button>
        </form>
      </div>
    </>
  );
}

export default AiAuth;


export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
}