import React, { useEffect, useState } from "react";
import "./CNPersonal1.css";
import Header from "../Front-Page-Components/Header/Header";
import StatusBar from "../../Status-Bar/StatusBar";
import Personal from "../../Images/Page-Img/Personal Info.png";
import Animation1 from "../../Animation/animation";
import serverIP from "../../config/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import StepsFooter from "../Front-Page-Components/Footer/StepsFooter";

function Personal1() {
  const [Error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [pincode, setPincode] = useState("");
  const [sex, setSex] = useState("");
  const isOnlyLetters = (input) => /^[A-Za-z\s]+$/.test(input);
  const [dataForCRM, setDataForCRM] = useState([]);

  useEffect(() => {
    const storedData =
      JSON.parse(localStorage.getItem("Personal1FormData")) || {};
    const userId = localStorage.getItem("userId");
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    // Helper function to format date
    const formatDob = (dateString) => {
      // Parse the date string to a Date object
      const date = new Date(dateString);

      // Extract day, month, and year components
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();

      // Format the date as dd-mm-yyyy
      return `${day}-${month}-${year}`;
    };

    const fetchFnLNameData = async () => {
      try {
        const response = await axios.get(
          `${serverIP}/user/fetchNameifExists/${userId}`,
          config
        );

        if (response.status >= 200 && response.status < 300) {
          // console.log(response);
          setFirstName(response.data.fname || storedData.firstName || "");
          setLastName(response.data.lname || storedData.lastName || "");
        } else {
          console.error(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setFirstName(storedData.firstName || "");
        setLastName(storedData.lastName || "");
      }
    };

    const fetchGendernDob = async () => {
      try {
        const response = await axios.get(
          `${serverIP}/user/fetchSexDobifExists/${userId}`,
          config
        );

        if (response.status >= 200 && response.status < 300) {
          // console.log(response);
          const responseSex = response.data.sex.toLowerCase();
          setSex(responseSex || storedData.sex || "");
          setDob(formatDob(response.data.dob || storedData.dob || ""));
        } else {
          console.error(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);

        setSex(storedData.sex || "");
        setDob(storedData.dob || "");
      }
    };
    const fetchPincode = async () => {
      try {
        const response = await axios.get(
          `${serverIP}/user/fetchPincodeifExists/${userId}`,
          config
        );

        if (response.status >= 200 && response.status < 300) {
          // console.log(response);
          setPincode(response.data.pincode || storedData.pincode || "");
        } else {
          console.error(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setPincode(storedData.pincode || "");
      }
    };

    fetchFnLNameData();
    fetchGendernDob();
    fetchPincode();
  }, []);

  const history = useNavigate();

  const formatDob = (value) => {
    // Remove all non-numeric characters
    const numericValue = value.replace(/\D/g, "");

    // Check if the numericValue is empty or not
    if (!numericValue) {
      return "";
    }

    // Use regular expressions to format as DD/MM/YYYY
    if (numericValue.length <= 2) {
      return numericValue;
    } else if (numericValue.length <= 4) {
      return `${numericValue.slice(0, 2)}-${numericValue.slice(2)}`;
    } else {
      return `${numericValue.slice(0, 2)}-${numericValue.slice(
        2,
        4
      )}-${numericValue.slice(4, 8)}`;
    }
  };

  const handleChange = (e) => {
    const formattedValue = formatDob(e.target.value);
    setDob(formattedValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Set Details in Local Head
    localStorage.setItem(
      "Personal1FormData",
      JSON.stringify({
        firstName,
        lastName,
        sex,
        dob,
        pincode,
      })
    );
    // Check First name condition (min 3 letters required)

    if (firstName.length < 3) {
      setError("Need 3+ letters in first name");
      return;
    }

    // Check if either "Male" or "Female" is selected
    if (sex !== "male" && sex !== "female") {
      setError("Please select your sex");
      return;
    }

    try {
      // Format the joining date as "yyyy-mm-dd"
      const formatteddob = dob.split("-").reverse().join("-");

      const [yyyy, mm, dd] = formatteddob.split("-");
      const isValidDate =
        parseInt(dd, 10) <= 31 &&
        parseInt(mm, 10) <= 12 &&
        parseInt(yyyy, 10) <= 2002 &&
        parseInt(yyyy, 10) >= 1947;

      if (!isValidDate) {
        console.error("Invalid date entered.");
        setError("Age should between 21 to 70");
        return;
      }
      if (pincode.length < 6) {
        setError("Enter valid pincode");
        return;
      }
      const token = localStorage.getItem("accessToken");
      const userId = localStorage.getItem("userId");

      if (!token) {
        console.error("Access token not found.");
        return;
      }
      if (!userId) {
        console.error("UserId not found.");
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setIsLoading(true);

      // Send a PATCH request to the server with the updated user's personal details
      const response = await axios.patch(
        `${serverIP}/user/personal1/${userId}`,
        {
          fname: firstName,
          lname: lastName,
          sex: sex,
          dob: formatteddob,
          pincode: pincode,
        },
        config
      );

      if (response.status >= 200 && response.status < 300) {
        console.log("Personal1 data submitted successfully.");
        handleTeleCRMUpadte();
        history("/personal2");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const config = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        };

        const response = await fetch(
          `${serverIP}/user/getNameNumber/${userId}`,
          config
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setDataForCRM(data);
        // console.log(data.name);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTeleCRMUpadte = async () => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer 2c438757-dc99-4297-b188-b06bba3341651714828905449:e51d6e0b-9961-45b9-bcf0-bae006ffa758",
      },
      body: JSON.stringify({
        fields: {
          name: dataForCRM.name || `${firstName} ${lastName}` || "",
          phone: dataForCRM.phone || "",
          pincode: dataForCRM.pincode || pincode || "",
        },
        actions: [
          {
            type: "SYSTEM_NOTE",
            text: "Profile created",
          },
        ],
      }),
    };

    try {
      const response = await fetch(
        `https://api.telecrm.in/enterprise/66361419545ce40e83b1154b/autoupdatelead`,
        config
      );
      if (response.status >= 200 && response.status < 300) {
        console.log("Lead updated successfully");
      } else {
        console.error("Lead update failed. Error", response.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header />

      <div className="CN2-Personal1">
        <div className="CN2-Personal1-Container">
          <div className="CN2-Personal1-Main-Div">
            <div className="CN2-Personal1-Main-Div-Left">
              <img
                src={Personal}
                alt="Profile with pan and aadhaar"
                className="CN2-Personal1-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-yellow)"
                LoanColor="white"
                KycColor="white"
                IncomeColor="white"
                OffersColor="white"
              />
            </div>
            <div className="CN2-Personal1-Main-Div-Right">
              <h1>Personal Info</h1>
              {isLoading ? (
                <>
                  <Animation1 />
                </>
              ) : (
                <>
                  <form onSubmit={handleSubmit}>
                    {Error && (
                      <div className="CN2-Personal1-Error">{Error}</div>
                    )}
                    <div>
                      <input
                        type="text"
                        placeholder="First Name"
                        className="CN2-Personal1-Input"
                        value={firstName}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (isOnlyLetters(value) || value === "") {
                            setFirstName(value);
                          }
                        }}
                        required
                      />
                    </div>
                    <div>
                      <input
                        placeholder="Last Name"
                        className="CN2-Personal1-Input"
                        type="text"
                        value={lastName}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (isOnlyLetters(value) || value === "") {
                            setLastName(value);
                          }
                        }}
                      />
                    </div>
                    <div>
                      <div className="CN2-Personal1-Gender-Div">
                        <div>
                          <input
                            type="checkbox"
                            onChange={(e) => setSex(e.target.value)}
                            value="male"
                            checked={sex.toLowerCase() === "male"}
                            className="CN2-Personal1-Gender-Input"
                          />
                          Male
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            onChange={(e) => setSex(e.target.value)}
                            value="female"
                            checked={sex.toLowerCase() === "female"}
                            className="CN2-Personal1-Gender-Input"
                          />
                          Female
                        </div>
                      </div>
                    </div>
                    <div>
                      <input
                        placeholder="Date of Birth"
                        className="CN2-Personal1-Input"
                        type="tel"
                        value={dob}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <input
                        placeholder="Pincode"
                        className="CN2-Personal1-Input"
                        type="text"
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                        required
                        maxLength={6}
                      />
                    </div>
                    <button className="CN2-Personal1-Button">Submit</button>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default Personal1;
