import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import "../CNSelection.css";

const options = {
  indexAxis: "y",
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      title: {
        display: true,
        text: "Payment",
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
      title: {
        text: "Tenure (in Year)",
        display: true,
      },
    },
  },
};

function BarChart({ chartData }) {
  return (
    <div className="CN-BarGraph-Size">
      <Bar data={chartData} options={options} />
    </div>
  );
}

export default BarChart;
