import React, { useEffect, useState } from "react";
import "./TrackAskDetail.css";
import Creditnama from "../Images/CNLogo.png";
import serverIP from "../config/config";
import { useNavigate } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const TrackAskDetail = () => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const askId = localStorage.getItem("askId");

  const history = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("iToken");
    const askId = localStorage.getItem("askId");

    if (!token) {
      history("/thekedaar/login");
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${serverIP}/ask/summary/${askId}`,
          //   `${serverIP}/ask/thekedaar/details/${askId}`,
          config
        );

        if (response.status >= 200 && response.status < 300) {
          const data = await response.json();
          console.log(data);
          setData(data);
        } else {
          console.log("Data not found");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [history]);

  useEffect(() => {
    const token = localStorage.getItem("iToken");
    const askId = localStorage.getItem("askId");

    if (!token) {
      history("/thekedaar/login");
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${serverIP}/ask/thekedaar/details/${askId}`,
          config
        );

        if (response.status >= 200 && response.status < 300) {
          const data = await response.json();
          console.log(data);
          setData1(data);
        } else {
          console.log("Data not found");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [history]);

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  };

  const formatAmount = (amount) => {
    const formattedAmount = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: "0",
    }).format(amount);

    return formattedAmount;
  };

  const handleBack = () => {
    history("/thekedaar/master");
  };

  return (
    <div className="CN-TrackDetails-Page">
      <div className="CN-TrackDetails-Logo-Div">
        <img
          src={Creditnama}
          alt="Creditnama"
          className="CN-TrackDetails-Img"
        />
        <button className="CN-TrackDetails-Back-Btn" onClick={handleBack}>
          <ArrowBackIcon /> Back
        </button>
      </div>
      <div className="CN-TrackDetails-Pointer">
        <div>
          <div>
            <b>Ask id:</b> {askId}
          </div>
          <div>
            <b>Channel id:</b>
          </div>
          <div>
            <b>Requested value:</b> {formatAmount(data.initial_ask_value)} (
            {data.initial_ask_value / 10000000} Cr)
          </div>
          <div>
            <b>Requested purpose:</b> {data.initial_ask_purpose}
          </div>
          <div>
            <b>Requested tenure:</b> {data.initial_ask_tenure} Years
          </div>
          <div>
            <b>Created on: </b>
          </div>
        </div>
        <div>
          <div>
            <b>User id:</b>
          </div>
          <div>
            <b>Name:</b> {data.fullnamePan}
          </div>
          <div>
            <b>Phone:</b>
          </div>
          <div>
            <b>Address:</b> {data.fullAddress}
          </div>
          <div>
            <button onClick={() => history("/thekedaar/status-update")}>
              Update Status
            </button>
          </div>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Tracker ID</th>
            <th>Stage</th>
            <th>Sub stage</th>
            <th>Step</th>
            <th>Updated date</th>
            <th>Owner</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data1.map((value) => (
            <tr key={value.id}>
              <td>{value.id}</td>
              <td>{value.stage}</td>
              <td>{value.subStage}</td>
              <td>{value.step}</td>
              <td>{formatDate(value.created_at)}</td>
              <td>{value.owner}</td>
              <td>
                <BorderColorIcon style={{ cursor: "pointer" }} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TrackAskDetail;
