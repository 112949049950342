import React from "react";
import Floret from "../../../Images/Paras/paras-floret.png";
import MasterPlan from "../../../Images/Paras/master-plan.png";
import EntryGate from "../../../Images/Paras/entryGate.png";
import LocationPlan from "../../../Images/Paras/map.png";
import RoadView from "../../../Images/Paras/roadView.png";
import partyLawn from "../../../Images/Paras/partyLawn.png";
import partyLawnMain from "../../../Images/Paras/partyLawnMain.png";
import gym from "../../../Images/Paras/gym.png";
import innerGym from "../../../Images/Paras/innerGym.png";
import villaView from "../../../Images/Paras/villaView.png";
import club from "../../../Images/Paras/club.png";
import banquetView from "../../../Images/Paras/banquetView.png";
import bookself from "../../../Images/Paras/booself.png";
import gamingZone from "../../../Images/Paras/gamingZone.png";
import LiftLandingArea from "../../../Images/Paras/LiftLandingArea.png";
import Audiovideo from "../../../Images/Paras/AudioVideoRoom.png";
import YogaRoom from "../../../Images/Paras/YogaRoom.png";
import TreatmentRoom from "../../../Images/Paras/TreatmentRoom.png";
import steamRoom from "../../../Images/Paras/steamRoom.png";

function Gallery() {
  const images = [
    Floret,
    villaView,
    MasterPlan,
    LocationPlan,
    EntryGate,
    RoadView,
    partyLawn,
    partyLawnMain,
    gym,
    innerGym,
    club,
    banquetView,
    bookself,
    gamingZone,
    LiftLandingArea,
    Audiovideo,
    YogaRoom,
    TreatmentRoom,
    steamRoom,
  ];
  return (
    <div style={{ height: "auto" }} className="paras-slide">
      {images.map((image, index) => (
        <img
          key={index}
          style={{ width: "100%", height: "auto" }}
          className="paras-slide"
          src={image}
          alt="paras florett"
        />
      ))}
    </div>
  );
}

export default Gallery;
