import StepsFooter from "../User-Components/Front-Page-Components/Footer/StepsFooter";
import Header from "../User-Components/Front-Page-Components/Header/Header";
import serverIP from "../config/config";
import "./trackapplication.css";
import { useEffect, useState } from "react";

const TrackApplication = () => {
  // const [data, setData] = useState();
  const [pendingUser, setpendingUser] = useState();
  const [pendingCreditnama, setpendingCreditnama] = useState();
  const [pendingBank, setpendingBank] = useState();
  const [applicationSubmitted, setapplicationSubmitted] = useState();
  const [applicationLogged, setapplicationLogged] = useState();
  const [bank1Evaluation, setbank1Evaluation] = useState();
  const [bank2Evaluation, setbank2Evaluation] = useState();
  const [loanApproved, setloanApproved] = useState();
  const [bankQueries, setbankQueries] = useState();

  useEffect(() => {
    const askID = localStorage.getItem("askId");
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const url = `${serverIP}/ask/ask-status/${askID}`;

    fetch(url, config)
      .then((response) => {
        // console.log(response);
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // Log the response data to the console
        console.log(data);
        // setData(data);
        setpendingUser(data.pendingUser);
        setpendingCreditnama(data.pendingCreditnama);
        setpendingBank(data.pendingBank);
        setapplicationLogged(data.status_applicationLogged);
        setapplicationSubmitted(data.status_applicationSubmitted);
        setbank1Evaluation(data.status_bank1Evaluation);
        setbank2Evaluation(data.status_bank2Evaluation);
        setloanApproved(data.status_loanApproved);
        setbankQueries(data.status_bankQueries);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  return (
    <>
      <Header />

      <div className="CN2-Track-Status">
        <div className="track-application">
          <div className="track-application-header">
            Here’s what happens next
          </div>

          <div
            className="tracking-status1"
            style={
              !applicationSubmitted
                ? { color: "gray" }
                : { color: "var(--color-darkgreen)" }
            }
          >
            Application submitted
          </div>
          <div
            className="tracking-status2"
            style={
              !applicationLogged
                ? { color: "gray" }
                : { color: "var(--color-darkgreen)" }
            }
          >
            Creditnama logs your application with the bank
          </div>
          <div
            className="tracking-status3"
            style={
              !bank1Evaluation
                ? { color: "gray" }
                : { color: "var(--color-darkgreen)" }
            }
          >
            Bank evaluates your application
          </div>
          <div
            className="tracking-status4"
            style={
              !bankQueries
                ? { color: "gray" }
                : { color: "var(--color-darkgreen)" }
            }
          >
            Bank may come back with queries (usual)
          </div>
          <div
            className="tracking-status5"
            style={
              !bank2Evaluation
                ? { color: "gray" }
                : { color: "var(--color-darkgreen)" }
            }
          >
            Bank does final evaluation
          </div>
          <div
            className="tracking-status6"
            style={
              !loanApproved
                ? { color: "gray" }
                : { color: "var(--color-darkgreen)" }
            }
          >
            Loan is approved
          </div>

          <div className="tracking-status-bar">
            <div
              className="tracking-ellipse"
              style={!applicationSubmitted ? { backgroundColor: "orange" } : {}}
            />
            <div
              className="tracking-ellipse1"
              style={!applicationLogged ? { backgroundColor: "orange" } : {}}
            />
            <div
              className="tracking-ellipse2"
              style={!bank1Evaluation ? { backgroundColor: "orange" } : {}}
            />
            <div
              className="tracking-ellipse3"
              style={!bankQueries ? { backgroundColor: "orange" } : {}}
            />
            <div
              className="tracking-ellipse4"
              style={!bank2Evaluation ? { backgroundColor: "orange" } : {}}
            />
            <div
              className="tracking-ellipse5"
              style={!loanApproved ? { backgroundColor: "orange" } : {}}
            />
            <div className="tracking-line1-icon" />
            <div className="tracking-line2-icon" />
            <div className="tracking-line3-icon" />
            <div className="tracking-line4-icon" />
            <div className="tracking-line5-icon" />

            {/* <pre style={{ fontSize: "15px", position: "absolute", top: "300px" }}>
          {JSON.stringify(data, null, 2)}
        </pre> */}

            {/* <h1 style={{ position: "absolute", top: "400px" }}>Status : {data1}</h1> */}
          </div>
          <div className="tracking-status-buttons">
            <p> Currently pending with :</p>
            <div className="Button-location">
              <button
                className="track-button"
                style={
                  !pendingCreditnama
                    ? { backgroundColor: "grey", color: "white" }
                    : { backgroundColor: "darkred", color: "white" }
                }
              >
                Creditnama
              </button>
              <button
                className="track-button"
                style={
                  !pendingBank
                    ? { backgroundColor: "grey", color: "white" }
                    : { backgroundColor: "darkred", color: "white" }
                }
              >
                Bank/NBFC
              </button>
              <button
                className="track-button"
                style={
                  !pendingUser
                    ? { backgroundColor: "grey", color: "white" }
                    : { backgroundColor: "darkred", color: "white" }
                }
              >
                Applicant
              </button>
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
};

export default TrackApplication;
