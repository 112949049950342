import React, { useEffect } from "react";
import "./ChannelEMICalc.css";
import ChannelSidebar from "../../Auth-Components/Channel-Auth/ChannelSidebar";
import EmiCalulatorForLandingPage from "../../User-Components/Front-Page-Components/EMI-Calculator/EmiCalulatorForLandingPage";
import { useNavigate } from "react-router-dom";

const ChannelEMICalc = () => {
  const history = useNavigate();

  useEffect(() => {
    const auth = localStorage.getItem("cToken");
    if (!auth) {
      console.log("Login first");
      history("/channel");
    }
  }, [history]);
  return (
    <div className="CN-ChannelEmiCalc-Page">
      <ChannelSidebar />
      <div className="CN-ChannelEmiCalc-MainContainer">
        <div style={{ marginTop: "50px" }}>
          <EmiCalulatorForLandingPage Heading="EMI Calculator" />
        </div>
      </div>
      ;
    </div>
  );
};

export default ChannelEMICalc;
