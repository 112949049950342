import React from "react";
import "./LoaderAnimation.css";

const LoaderAnimation = () => {
  return (
    <div className="CN-Animation-Loader">
      <div className="CN-Animation-Wrapper">
        <div className="circle"></div>
        <div className="line-1"></div>
        <div className="line-2"></div>
        <div className="line-3"></div>
        <div className="line-4"></div>
      </div>
    </div>
  );
};

export default LoaderAnimation;
