import React, { useEffect, useState } from "react";
import "./RateList.css";
import ChannelSidebar from "../../Auth-Components/Channel-Auth/ChannelSidebar";
import serverIP from "../../config/config";
import axisLogo from "../../Images/Bank-Logos/axis.png";
import hdfcLogo from "../../Images/Bank-Logos/hdfc.png";
import kotakLogo from "../../Images/Bank-Logos/kotak.png";
import abflLogo from "../../Images/Bank-Logos/abfl.jpg";
import axisfinanceLogo from "../../Images/Bank-Logos/axisfinance.png";
import bajajLogo from "../../Images/Bank-Logos/bajaj.png";
import capriLogo from "../../Images/Bank-Logos/capri.png";
import cholaLogo from "../../Images/Bank-Logos/chola.png";
import citibankLogo from "../../Images/Bank-Logos/citibank.png";
import dbsLogo from "../../Images/Bank-Logos/dbs.png";
import deutcheLogo from "../../Images/Bank-Logos/deutche.png";
import edelwisLogo from "../../Images/Bank-Logos/edelwis.jpg";
import fullertonLogo from "../../Images/Bank-Logos/fullerton.png";
import hdbLogo from "../../Images/Bank-Logos/hdb.png";
import homecreditLogo from "../../Images/Bank-Logos/homecredit.png";
import hsbcLogo from "../../Images/Bank-Logos/hsbc.png";
import iciciLogo from "../../Images/Bank-Logos/icici.png";
import idfcLogo from "../../Images/Bank-Logos/idfc.jpg";
import iiflLogo from "../../Images/Bank-Logos/iifl.png";
import incredLogo from "../../Images/Bank-Logos/incred.png";
import indusindLogo from "../../Images/Bank-Logos/indusind.png";
import karurLogo from "../../Images/Bank-Logos/karur.jpg";
import myshubhlifeLogo from "../../Images/Bank-Logos/myshubhlife.png";
import paysenseLogo from "../../Images/Bank-Logos/paysense.png";
import protiumLogo from "../../Images/Bank-Logos/protium.png";
import scbLogo from "../../Images/Bank-Logos/scb.png";
import shriramLogo from "../../Images/Bank-Logos/shriram.png";
import smcLogo from "../../Images/Bank-Logos/smc.png";
import tataLogo from "../../Images/Bank-Logos/tata.png";
import ujjivanLogo from "../../Images/Bank-Logos/ujjivan.png";
import utkershLogo from "../../Images/Bank-Logos/utkersh.png";
import yesbankLogo from "../../Images/Bank-Logos/yesbank.png";
import indiabullshomeLogo from "../../Images/Bank-Logos/Indiabulls.png";
import indiahomeLogo from "../../Images/Bank-Logos/indiashelter.png";
import shubhamhomeLogo from "../../Images/Bank-Logos/shubham.png";
import godrejLogo from "../../Images/Bank-Logos/godrej.png";
import adityalimitedLogo from "../../Images/Bank-Logos/abfl.jpg";
import easyhomefinanceLogo from "../../Images/Bank-Logos/easyhome.png";
import piramalhousingLogo from "../../Images/Bank-Logos/piramal.png";
import federalLogo from "../../Images/Bank-Logos/fedral.png";
import shriramhousingLogo from "../../Images/Bank-Logos/shriram.png";
import bajajhousingLogo from "../../Images/Bank-Logos/bajaj.png";
import anandLogo from "../../Images/Bank-Logos/Anandrathi.png";
import dmiLogo from "../../Images/Bank-Logos/DmiHousing.png";
import rblLogo from "../../Images/Bank-Logos/RblBank.png";
import aadharLogo from "../../Images/Bank-Logos/AadharHousing.png";
import satinLogo from "../../Images/Bank-Logos/SatinHousing.png";
import licLogo from "../../Images/Bank-Logos/LicHousing.png";
import poonawallaLogo from "../../Images/Bank-Logos/poonawalla.png";
import sbmLogo from "../../Images/Bank-Logos/sbm.png";
import ChannelHamburger from "../components/ChannelHamburger";

const RateList = () => {
  const [selected, setSelected] = useState("property purchase");
  const [BankData, setBankData] = useState([]);

  const bankLogos = {
    axisLogo: axisLogo,
    hdfcLogo: hdfcLogo,
    kotakLogo: kotakLogo,
    abflLogo: abflLogo,
    axisfinanceLogo: axisfinanceLogo,
    bajajLogo: bajajLogo,
    capriLogo: capriLogo,
    cholaLogo: cholaLogo,
    citibankLogo: citibankLogo,
    dbsLogo: dbsLogo,
    deutcheLogo: deutcheLogo,
    edelwisLogo: edelwisLogo,
    fullertonLogo: fullertonLogo,
    hdbLogo: hdbLogo,
    homecreditLogo: homecreditLogo,
    hsbcLogo: hsbcLogo,
    iciciLogo: iciciLogo,
    idfcLogo: idfcLogo,
    iiflLogo: iiflLogo,
    incredLogo: incredLogo,
    indusindLogo: indusindLogo,
    karurLogo: karurLogo,
    myshubhlifeLogo: myshubhlifeLogo,
    paysenseLogo: paysenseLogo,
    protiumLogo: protiumLogo,
    scbLogo: scbLogo,
    shriramLogo: shriramLogo,
    smcLogo: smcLogo,
    tataLogo: tataLogo,
    ujjivanLogo: ujjivanLogo,
    utkershLogo: utkershLogo,
    yesbankLogo: yesbankLogo,
    indiahomeLogo: indiahomeLogo,
    shubhamhomeLogo: shubhamhomeLogo,
    godrejLogo: godrejLogo,
    adityalimitedLogo: adityalimitedLogo,
    easyhomefinanceLogo: easyhomefinanceLogo,
    indiabullshomeLogo: indiabullshomeLogo,
    piramalhousingLogo: piramalhousingLogo,
    federalLogo: federalLogo,
    shriramhousingLogo: shriramhousingLogo,
    bajajhousingLogo: bajajhousingLogo,
    anandLogo: anandLogo,
    dmiLogo: dmiLogo,
    rblLogo: rblLogo,
    aadharLogo: aadharLogo,
    satinLogo: satinLogo,
    licLogo: licLogo,
    poonawallaLogo: poonawallaLogo,
    sbmLogo: sbmLogo,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${serverIP}/product/get-bank-product-details`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              product_category: selected,
            }),
          }
        );
        const data = await response.json();
        if (response.ok) {
          const sortedData = data.sort((a, b) => a.minRate - b.minRate);
          console.log(sortedData);
          setBankData(sortedData);
        } else {
          console.log(response);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [selected]);

  return (
    <div className="CN-RateList-Page">
      <ChannelSidebar />
      <div className="CN-RateList-MainContainer">
        <div
          style={{
            marginTop: "10px",
            marginBottom: "15px",
          }}
        >
          <ChannelHamburger />
        </div>
        <h1>Current offers & lenders</h1>

        <div className="CN-RateList-InterestRates">
          <div
            onClick={() => setSelected("property purchase")}
            className={
              selected === "property purchase" ? "CN-Selected-RateList" : ""
            }
          >
            Home Loan
          </div>
          <div
            onClick={() => setSelected("loan against property")}
            className={
              selected === "loan against property" ? "CN-Selected-RateList" : ""
            }
          >
            Loan against property
          </div>
          <div
            onClick={() => setSelected("personal loan")}
            className={
              selected === "personal loan" ? "CN-Selected-RateList" : ""
            }
          >
            Personal Loan
          </div>
        </div>
        {selected === "property purchase" && (
          <div className="CN-RateList-MainContainer-RatesDiv">
            <table>
              <thead>
                <tr>
                  <th>Lender</th>
                  <th>Loan upto</th>
                  <th>Min rate</th>
                </tr>
                <tbody>
                  {BankData.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          src={bankLogos[item.bankLogo]}
                          alt={item.bankName}
                          className="CN-RateList-BankLogo"
                        />
                      </td>
                      <td>
                        ₹
                        {new Intl.NumberFormat("en-IN").format(
                          item.maxLoanMax / 100
                        )}{" "}
                        cr
                      </td>
                      <td>{(item.minRate / 1).toFixed(2)}%</td>
                    </tr>
                  ))}
                </tbody>
              </thead>
            </table>
          </div>
        )}

        {selected === "loan against property" && (
          <div className="CN-RateList-MainContainer-RatesDiv">
            <table>
              <thead>
                <tr>
                  <th>Lender</th>
                  <th>Loan upto</th>
                  <th>Min rate</th>
                </tr>
                <tbody>
                  {BankData.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          src={bankLogos[item.bankLogo]}
                          alt={item.bankName}
                          className="CN-RateList-BankLogo"
                        />
                      </td>
                      <td>
                        ₹
                        {new Intl.NumberFormat("en-IN").format(
                          item.maxLoanMax / 100
                        )}{" "}
                        cr
                      </td>
                      <td>{(item.minRate / 1).toFixed(2)}%</td>
                    </tr>
                  ))}
                </tbody>
              </thead>
            </table>
          </div>
        )}

        {selected === "personal loan" && (
          <div className="CN-RateList-MainContainer-RatesDiv">
            <table>
              <thead>
                <tr>
                  <th>Lender</th>
                  <th>Loan upto</th>
                  <th>Min rate</th>
                </tr>
                <tbody>
                  {BankData.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          src={bankLogos[item.bankLogo]}
                          alt={item.bankName}
                          className="CN-RateList-BankLogo"
                        />
                      </td>
                      <td>
                        ₹
                        {new Intl.NumberFormat("en-IN").format(item.maxLoanMax)}{" "}
                        L
                      </td>
                      <td>{(item.minRate / 1).toFixed(2)}%</td>
                    </tr>
                  ))}
                </tbody>
              </thead>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default RateList;
