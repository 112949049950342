import React, { useState } from "react";
import "./AiSideBar.css";
import LogoutIcon from "@mui/icons-material/Logout";

const caseData = [
  {
    year: "2023",
    months: [
      {
        month: "July",
        cases: ["Monu Singh", "Rahul Kumar"],
      },
      {
        month: "August",
        cases: ["Bharat Malhotra", "Neha Sharma"],
      },
    ],
  },
  {
    year: "2024",
    months: [
      {
        month: "July",
        cases: ["Amit Singh", "Pooja Verma"],
      },
      {
        month: "August",
        cases: ["Vikram Malhotra", "Anjali Mehra"],
      },
    ],
  },
];

function deleteCookie(name) {
  document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
}

const AiSideBar = () => {
  const [openYear, setOpenYear] = useState(null);
  const [openMonth, setOpenMonth] = useState(null);

  
  const handleLogout = () => {
    deleteCookie("AiAuth");
    window.location.reload();
};

  const toggleYear = (year) => {
    setOpenYear(openYear === year ? null : year);
    setOpenMonth(null); // Close months when a different year is opened
  };

  const toggleMonth = (month) => {
    setOpenMonth(openMonth === month ? null : month);
  };

  return (
    <div className="Ai-sidebar-parent">
      <div className="Ai-sidebar">
        <b style={{ fontSize: "18px" }}>Cases</b>
        {caseData.map((yearData, index) => (
          <div key={index} className="Ai-sidebar-year">
            <div
              onClick={() => toggleYear(yearData.year)}
              className="mx-2 Ai-sidebar-caseyears"
              style={openYear === yearData.year ? { fontWeight: "700" } : {}}
            >
              {yearData.year}
            </div>
            {openYear === yearData.year &&
              yearData.months.map((monthData, index) => (
                <div key={index} className="mx-4 Ai-sidebar-casemonth">
                  <div
                    onClick={() => toggleMonth(monthData.month)}
                    style={
                      openMonth === monthData.month ? { fontWeight: "700" } : {}
                    }
                  >
                    {monthData.month}
                  </div>
                  {openMonth === monthData.month &&
                    monthData.cases.map((caseName, index) => (
                      <div key={index} className="mx-2 Ai-sidebar-casename">
                        {caseName}
                      </div>
                    ))}
                </div>
              ))}
          </div>
        ))}
      </div>
      <div onClick={handleLogout} className="Ai-sidebar-logout">
        {" "}
        <LogoutIcon />
        Logout
      </div>
    </div>
  );
};

export default AiSideBar;
