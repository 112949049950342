import React, { useEffect, useState } from "react";
import "./SuccessStandby.css";
import SuccessStanby from "../Images/Page-Img/Create Profile.png";
import StatusBar from "../Status-Bar/StatusBar";
import Header from "../User-Components/Front-Page-Components/Header/Header";
import StepsFooter from "../User-Components/Front-Page-Components/Footer/StepsFooter";
import serverIP from "../config/config";
import ApiStatus from "../Images/Assets/applicationcheck.gif";
import { useNavigate } from "react-router-dom";

function Standy() {
  const [response, setResponse] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dataForCRM, setDataForCRM] = useState([]);

  const history = useNavigate();

  const handleFail = () => {
    history("/personal1");
  };

  const handleSuccess = () => {
    handleTeleCRMUpadte();
    history("/ask1");
  };

  useEffect(() => {
    // Get the access token from localStorage
    const token = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    // Set the Authorization header with the token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    fetch(`${serverIP}/user/profileStatus/${userId}`, config)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setResponse(data);
        setDataLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const config = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        };

        const response = await fetch(
          `${serverIP}/user/getNameNumber/${userId}`,
          config
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setDataForCRM(data);
        console.log(data.name);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchData();
  }, []);

  const handleTeleCRMUpadte = async () => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer 2c438757-dc99-4297-b188-b06bba3341651714828905449:e51d6e0b-9961-45b9-bcf0-bae006ffa758",
      },
      body: JSON.stringify({
        fields: {
          name: dataForCRM.name || "",
          phone: dataForCRM.phone || "",
          pincode: dataForCRM.pincode || "",
        },
        actions: [
          {
            type: "SYSTEM_NOTE",
            text: "Profile created",
          },
        ],
      }),
    };

    try {
      const response = await fetch(
        `https://api.telecrm.in/enterprise/66361419545ce40e83b1154b/autoupdatelead`,
        config
      );
      if (response.status >= 200 && response.status < 300) {
        console.log("Lead updated successfully");
      } else {
        console.error("Lead update failed. Error", response.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Check if both retrievedPan and retrievedAadhaar are true
  const isBothTrue =
    response && response.retrievedPan && response.retrievedAadhaar;

  return (
    <>
      <Header />

      <div className="CN2-SuccessStanby">
        <div className="CN2-SuccessStanby-Container">
          <div className="CN2-SuccessStanby-Main-Div">
            <div className="CN2-SuccessStanby-Main-Div-Left">
              <img
                src={SuccessStanby}
                alt="Profile with pan and aadhaar"
                className="CN2-SuccessStanby-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-yellow)"
                LoanColor="white"
                KycColor="white"
                IncomeColor="white"
                OffersColor="white"
              />
            </div>
            <div className="CN2-SuccessStanby-Main-Div-Right">
              <h1>Please wait until we pull out your info</h1>
              <img
                src={ApiStatus}
                alt="Api Status"
                className="CN2-SuccessStandby-ApiStatus"
              />
              {dataLoaded && response === false && dataLoaded && isBothTrue && (
                <div className="CN2-SuccessStanby-button-div">
                  Complete your profile
                  <button
                    onClick={handleFail}
                    className="CN2-SuccessStanby-Button"
                    style={{
                      backgroundColor: "var(--color-yellow)",
                      color: "var(--color-darkgreen)",
                    }}
                  >
                    Proceed
                  </button>
                </div>
              )}
              {dataLoaded && isBothTrue && (
                <div className="CN2-SuccessStanby-button-div">
                  All set
                  <button
                    onClick={handleSuccess}
                    className="CN2-SuccessStanby-Button"
                  >
                    Proceed
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}
export default Standy;
