import React from "react";
import { useNavigate } from "react-router-dom";
import "./header.css";

function Header() {
  const history = useNavigate();

  const handleHome = () => {
    history("/");
    console.log("Home");
  };
  return (
    <div>
      <div className="topview">
        <div className="logo-background" />
        <div className="creditnama" onClick={handleHome}>
          Creditnama
        </div>
      </div>
    </div>
  );
}

export default Header;
