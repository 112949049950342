import React, { useState } from "react";
import "./CTA.css";

function CTA({ setShowForm }) {
  const [FullName, setFullName] = useState("");
  const [Number, setNumber] = useState("");
  const [FlatType, setFlatType] = useState("2 BHK");

  const handlSubmit = (event) => {
    event.preventDefault();

    if (Number.length !== 10 || !/[6-9]/.test(Number[0])) {
      alert("Enter Correct Mobile Number");
      return;
    }
    handleTeleCRMUpadte();
    setShowForm(false);
  };

  const handleTeleCRMUpadte = async () => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer 2c438757-dc99-4297-b188-b06bba3341651714828905449:e51d6e0b-9961-45b9-bcf0-bae006ffa758",
      },
      body: JSON.stringify({
        fields: {
          name: FullName || "",
          phone: `+91${Number}` || "",
        },
        actions: [
          {
            type: "SYSTEM_NOTE",
            text: "Lead Source: Paras Landing Page",
          },
          {
            type: "SYSTEM_NOTE",
            text: `Flat Type ${FlatType}`,
          },
        ],
      }),
    };

    try {
      const response = await fetch(
        `https://api.telecrm.in/enterprise/66361419545ce40e83b1154b/autoupdatelead`,
        config
      );
      if (response.status >= 200 && response.status < 300) {
        console.log("Lead updated successfully");
      } else {
        console.error("Lead update failed. Error", response.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="paras-CTA-form-parentDesktop">
        <form onSubmit={handlSubmit} className="form">
          <>
            <div className="paras-CTA-form-input">
              <label>
                <input
                  className="input"
                  type="text"
                  onChange={(e) => setFullName(e.target.value)}
                  placeholder="Full Name"
                  required
                />
              </label>
              <label>
                <input
                  className="input"
                  type="tel"
                  maxLength={10}
                  minLength={10}
                  onChange={(e) => setNumber(e.target.value)}
                  placeholder="Mobile No."
                  required
                />
              </label>
              <label>
                <select onChange={(e) => setFlatType(e.target.value)} required>
                  <option style={{ textAlign: "center" }} value="2bhk">
                    2 BHK
                  </option>
                  <option style={{ textAlign: "center" }} value="3bhk">
                    3 BHK
                  </option>
                </select>
              </label>
            </div>
            <button type="submit" className="paras-CTA-submit">
              Submit
            </button>
          </>
        </form>
      </div>

      <div className="paras-CTA-form-parent paras-CTA-form-parentMobile">
        <form onSubmit={handlSubmit} className="form">
          <div className="paras-CTA-form-input">
            <h1>Enquire Now</h1>
            <label>
              <input
                className="input"
                type="text"
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Full Name"
                required
              />
            </label>
            <label>
              <input
                className="input"
                type="tel"
                maxLength={10}
                minLength={10}
                onChange={(e) => setNumber(e.target.value)}
                placeholder="Mobile No."
                required
              />
            </label>
            <label>
              <select onChange={(e) => setFlatType(e.target.value)} required>
                <option style={{ textAlign: "center" }} value="2bhk">
                  2 BHK
                </option>
                <option style={{ textAlign: "center" }} value="3bhk">
                  3 BHK
                </option>
              </select>
            </label>
            <button type="submit" className="paras-CTA-submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default CTA;
