import React, { useEffect, useState } from "react";
import "./TrackerMaster.css";
import { useNavigate } from "react-router-dom";
import Creditnama from "../Images/CNLogo.png";
import serverIP from "../config/config";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Pagination from "./Pagination";

// import { visitors, usersData, session } from "./mock";

function TrackerMaster() {
  const [TableControler, setTableControler] = useState("Visitor");
  const [visitors, setVisitors] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [session, setSession] = useState([]);
  const [askData, setAskData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1000;
  const history = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("iToken");

    if (!token) {
      history("/thekedaar/login");
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${serverIP}/ask/thekedaar/visitor`,
          config
        );

        if (response.status >= 200 && response.status < 300) {
          const data = await response.json();
          console.log(data);
          setVisitors(data);
        } else {
          console.log("Data not found");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [history]);
  useEffect(() => {
    const token = localStorage.getItem("iToken");

    if (!token) {
      history("/thekedaar/login");
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${serverIP}/ask/thekedaar/session`,
          config
        );

        if (response.status >= 200 && response.status < 300) {
          const data = await response.json();
          console.log(data);
          setSession(data);
        } else {
          console.log("Data not found");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [history]);

  useEffect(() => {
    const token = localStorage.getItem("iToken");

    if (!token) {
      history("/thekedaar/login");
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const fetchData = async () => {
      try {
        const response = await fetch(`${serverIP}/ask/thekedaar/user`, config);

        if (response.status >= 200 && response.status < 300) {
          const data = await response.json();
          console.log(data);
          setUsersData(data);
        } else {
          console.log("Data not found");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [history]);

  useEffect(() => {
    const token = localStorage.getItem("iToken");

    if (!token) {
      history("/thekedaar/login");
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${serverIP}/ask/thekedaar/summary`,
          config
        );

        if (response.status >= 200 && response.status < 300) {
          const data = await response.json();
          console.log(data);
          setAskData(data);
        } else {
          console.log("Data not found");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [history]);

  const handleGo = (askId) => {
    localStorage.setItem("askId", askId);
    history("/thekedaar/details");
  };

  // const formatDate = (isoDate) => {
  //   const date = new Date(isoDate);
  //   const day = date.getDate();
  //   const month = date.getMonth() + 1;
  //   const year = date.getFullYear();

  //   const formattedDay = day < 10 ? `0${day}` : day;
  //   const formattedMonth = month < 10 ? `0${month}` : month;

  //   return `${formattedDay}/${formattedMonth}/${year}`;
  // };
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12; // convert 24-hour format to 12-hour format
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
  };

  const handleLogOut = () => {
    localStorage.removeItem("iToken");
    history("/thekedaar/login");
  };

  // ------------------------------------- Pagination -------------------------------------
  // const [users, setUsers] = useState([]);

  const totalPages = Math.ceil(visitors?.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedUsers = visitors.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="BCN-TrackerMaster-Page">
      <div className="CN-TrackDetails-Logo-Div">
        <img
          src={Creditnama}
          alt="Creditnama"
          className="CN-TrackDetails-Img"
        />
        <button
          className="CN-TrackDetails-Back-Btn"
          style={{ border: "1px solid grey", fontWeight: "bolder" }}
          onClick={handleLogOut}
        >
          <ArrowBackIcon /> Logout
        </button>
      </div>

      <div>
        <div className="BCN-Calculator-Page-Tabs">
          <div className="tab-container BCN-Tab-Container-D">
            <input
              type="radio"
              name="tab"
              id="tab1"
              className="tab tab--1"
              defaultValue="affordabilityCal"
            />
            <label
              onClick={() => {
                setTableControler("Visitor");
              }}
              className="tab_label"
              htmlFor="tab1"
            >
              Visitor Table
            </label>
            <input
              type="radio"
              name="tab"
              id="tab2"
              className="tab tab--2"
              defaultValue="btCal"
            />
            <label
              onClick={() => {
                setTableControler("Session");
              }}
              className="tab_label"
              htmlFor="tab2"
            >
              Session Table
            </label>
            <input
              type="radio"
              name="tab"
              id="tab3"
              className="tab tab--3"
              defaultValue="emiCal"
            />
            <label
              onClick={() => {
                setTableControler("User");
              }}
              className="tab_label"
              htmlFor="tab3"
            >
              User Table
            </label>
            <input
              type="radio"
              name="tab"
              id="tab4"
              className="tab tab--4"
              defaultValue="emiCal"
            />
            <label
              onClick={() => {
                setTableControler("Ask");
              }}
              className="tab_label"
              htmlFor="tab4"
              id="tab4"
            >
              Ask Table
            </label>

            <div className="indicator" />
          </div>
          <div className="dropdown BCN-Tab-Container-M">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Session Table
            </button>
          </div>
        </div>
      </div>
      <div className="BCN-TrackerMaster-Table-Div">
        {TableControler === "Visitor" ? (
          <table>
            <thead>
              <tr>
                <th>Visitor ID</th>
                <th>Session Id</th>
                <th>utm_source</th>
                <th>utm_medium</th>
                <th>utm_campaign</th>
                <th>utm_content</th>
                <th>utm_term</th>
                <th>Visit At</th>
              </tr>
            </thead>
            <tbody>
              {paginatedUsers.map((user) => (
                <tr key={user.visitorId}>
                  <td>{user.visitorId}</td>
                  <td>{user.sessionId}</td>
                  <td>{user.utm_source}</td>
                  <td>{user.utm_medium}</td>
                  <td>{user.utm_campaign}</td>
                  <td>{user.utm_content}</td>
                  <td>{user.utm_term}</td>
                  <td>{formatDate(user.visitDateTime)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {TableControler === "User" ? (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Phone No.</th>
                <th>Pin Code</th>
                <th>Profession</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {usersData.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.fname}</td>
                  <td>{user.lname}</td>
                  <td>{user.phone}</td>
                  <td>{user.pincode}</td>
                  <td>{user.profession}</td>
                  <td>{formatDate(user.created_at)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {TableControler === "Session" ? (
          <table>
            <thead>
              <tr>
                <th>Session Id</th>
                <th>Phone</th>
                <th>Whatsapp Opt in</th>
                <th>Otp Date Time</th>
                <th>Reapeat</th>
              </tr>
            </thead>
            <tbody>
              {session.map((user) => (
                <tr key={user.sessionId}>
                  <td>{user.sessionId}</td>
                  <td>{user.phone}</td>
                  <td>{user.whatsappOptIn ? "Yes" : "No"}</td>
                  <td>{formatDate(user.otpDateTime)}</td>
                  <td>{user.repeat ? "Yes" : "No"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {TableControler === "Ask" ? (
          <table>
            <thead>
              <tr>
                <th>Ask ID</th>
                <th>Product</th>
                <th>Last stage</th>
                <th>Last substage</th>
                <th>Last step</th>
                <th>Last updated</th>
                <th>Owner</th>
                <th>Add an update</th>
              </tr>
            </thead>
            <tbody>
              {askData.map((value) => (
                <tr key={value.askId}>
                  <td>{value.askId}</td>
                  <td>{value.product}</td>
                  <td>{value.lastStatus}</td>
                  <td>{value.lastSubStatus}</td>
                  <td>{value.lastStep}</td>
                  <td>{formatDate(value.lastUpdated)}</td>
                  <td>{value.owner}</td>
                  <td>
                    <PlayArrowIcon
                      onClick={() => handleGo(value.askId)}
                      fontSize="large"
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default TrackerMaster;
