import React, { useState } from "react";
import "./Calculators.css";

const BalanceTranCal = () => {
  const [existingOs, setExistingOs] = useState(6000000);
  const [currentRoi, setCurrentRoi] = useState(8.45);
  const [tenureLeft, setTenureLeft] = useState(25);
  const [proposedRoi, setProposedRoi] = useState(8.4);

  const calculateEMI = (principal, rate, tenure) => {
    rate = rate / 12 / 100;
    const n = tenure * 12;
    return (
      (principal * rate * Math.pow(1 + rate, n)) /
      (Math.pow(1 + rate, n) - 1)
    ).toFixed(2);
  };

  const existingEMI = calculateEMI(existingOs, currentRoi, tenureLeft);
  const proposedEMI = calculateEMI(existingOs, proposedRoi, tenureLeft);

  const totalAmountExisting = (
    parseFloat(existingEMI) *
    tenureLeft *
    12
  ).toFixed(2);
  const totalAmountProposed = (
    parseFloat(proposedEMI) *
    tenureLeft *
    12
  ).toFixed(2);

  const totalSavings = (
    parseFloat(totalAmountExisting) - parseFloat(totalAmountProposed)
  ).toFixed(2);

  return (
    <div className="BCN-Calculator-Page">
      <h2>Balance Transfer Calculator</h2>
      <p>Find out the savings in EMI</p>
      <div className="BCN-Calculator-Container">
        <div className="BCN-Calculator-Container-Part1">
          <label>
            Existing Loan
            <span className="BCN-Calculator-Input-Symbol2">₹</span>
            <input
              type="tel"
              value={existingOs}
              onChange={(e) =>
                setExistingOs(
                  isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
                )
              }
              maxLength={10}
              pattern="\d*"
            />
          </label>
          <input
            type="range"
            className="BCN-Calculator-Range"
            min={100000}
            max={10000000}
            step={100000}
            value={existingOs}
            onChange={(e) =>
              setExistingOs(
                isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
              )
            }
          />

          <label>
            Existing Interest Rate (% P.A.)
            <input
              type="tel"
              value={currentRoi}
              onChange={(e) => setCurrentRoi(e.target.value)}
              maxLength={5}
              style={{ paddingLeft: "10px" }}
            />
          </label>
          <input
            type="range"
            className="BCN-Calculator-Range"
            min={1}
            max={20}
            step={0.25}
            value={currentRoi}
            onChange={(e) =>
              setCurrentRoi(
                isNaN(parseFloat(e.target.value))
                  ? 0
                  : parseFloat(e.target.value)
              )
            }
          />

          <label>
            Tenure (Years)
            <input
              type="tel"
              value={tenureLeft}
              onChange={(e) =>
                setTenureLeft(
                  isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
                )
              }
              maxLength={2}
              style={{ paddingLeft: "10px" }}
              pattern="\d*"
            />
          </label>
          <input
            type="range"
            className="BCN-Calculator-Range"
            min={1}
            max={30}
            step={1}
            value={tenureLeft}
            onChange={(e) =>
              setTenureLeft(
                isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
              )
            }
          />

          <label>
            Proposed Interest Rate (% P.A.)
            <input
              type="tel"
              value={proposedRoi}
              onChange={(e) => setProposedRoi(e.target.value)}
              maxLength={5}
              style={{ paddingLeft: "10px" }}
            />
          </label>
          <input
            type="range"
            className="BCN-Calculator-Range"
            min={1}
            max={20}
            step={0.25}
            value={proposedRoi}
            onChange={(e) =>
              setProposedRoi(
                isNaN(parseFloat(e.target.value))
                  ? 0
                  : parseFloat(e.target.value)
              )
            }
          />
        </div>
        <div className="BCN-Calculator-Container-Part2">
          <p>
            <b>Total Saving in Cash Outflow</b>
            <br />₹{" "}
            <span className="BCN-Calculator-Result-Amt-Imp">
              {parseFloat(totalSavings) <= 0
                ? 0
                : parseFloat(totalSavings).toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  }) === "NaN"
                ? 0
                : parseFloat(totalSavings).toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  })}
            </span>
          </p>
          <p>
            <b>Existing EMI</b> <br />₹{" "}
            <span className="BCN-Calculator-Result-Amt">
              {tenureLeft <= 0 ? (
                "0"
              ) : (
                <>
                  {parseFloat(existingEMI).toLocaleString("en-IN", {
                    maximumFractionDigits: 0,
                  }) === "NaN"
                    ? 0
                    : parseFloat(existingEMI).toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                      })}
                </>
              )}
            </span>
          </p>
          <p>
            <b>Proposed EMI</b> <br />₹{" "}
            <span className="BCN-Calculator-Result-Amt">
              {tenureLeft <= 0 ? (
                "0"
              ) : (
                <>
                  {" "}
                  {parseFloat(proposedEMI).toLocaleString("en-IN", {
                    maximumFractionDigits: 0,
                  }) === "NaN"
                    ? 0
                    : parseFloat(proposedEMI).toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                      })}
                </>
              )}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BalanceTranCal;
