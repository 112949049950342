import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Team.css";
import TeamCreditnama from "../../../Images/Team-Photo/Team.png";
import Manik from "../../../Images/Team-Photo/Manik.png";
import Dhawal from "../../../Images/Team-Photo/Dhawal.png";
import Bhart from "../../../Images/Team-Photo/Bhart.png";
import Linkdin from "../../../Images/Assets/Linkdin.png";
import { Helmet } from "react-helmet-async";
import ishaan from "../../../Images/Team-Photo/unkown.png";
import shruti from "../../../Images/Team-Photo/shruti.png";
import rohit from "../../../Images/Team-Photo/unkown.png";
import kapil from "../../../Images/Team-Photo/kapil.png";
import nishant from "../../../Images/Team-Photo/nishant.png";
import ronak from "../../../Images/Team-Photo/ronak.png";
import nihit from "../../../Images/Team-Photo/nihit.png";
import aishwarya from "../../../Images/Team-Photo/aishwarya.png";
import siddharth from "../../../Images/Team-Photo/siddharth.png";
// import culture from "../../../Images/Team-Photo/culture.png";

const Team = () => {
  const handleBhart = () => {
    window.location.href =
      "https://www.linkedin.com/in/bhart-malhotra-5aa4021b6";
  };
  const handleDhawal = () => {
    window.location.href = "https://www.linkedin.com/in/dhawalmehra/";
  };
  const handleManik = () => {
    window.location.href = "https://www.linkedin.com/in/mpasricha/";
  };
  return (
    <>
      <Helmet>
        <title>Team Creditnama</title>
        <meta name="description" content="Craditnama Team" />
        <link rel="canonical" href="/team" />
      </Helmet>
      <Header />

      <div className="CN-Team-Page">
        <div className="CN-Team-Page-Main">
          <div className="CN-Team-Page-Main-Heading">
            <h1>Our Team</h1>
          </div>

          <div className="CN-Team-Page-Main-Container">
            <img
              src={TeamCreditnama}
              alt="Team Creditnama"
              className="CN-Team-Photo"
              onContextMenu={(e) => e.preventDefault()}
              loading="lazy"
            />
            <div>
              <b>‘Will India grow rich before it grows old?’</b> is the
              provocative question Manik probed in his MBA at one of the world’s
              best universities when reflecting on his innate passion.
            </div>
            <div>
              Six years later, driven by his undying love to make Bharat and
              Bharatwaasi prosperous and richer, Manik moved back to pursue his
              lofty goal – meaningfully contribute to a developed India, a
              viksit Bharat, grounded in reality and realty.
            </div>
          </div>
          <div className="CN-Team-Page-Profile-Div">
            <div className="CN-Team-Page-Img-Container">
              <img
                src={Manik}
                alt="Manik"
                className="CN-Team-page-Profile-Img"
              />

              <div className="CN-Team-Page-Namme-Div">
                Manik Pasricha
                <img
                  src={Linkdin}
                  alt="Linkdin"
                  className="CN-Team-Page-Linkdin-Img"
                  loading="lazy"
                  onClick={handleManik}
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
              <div className="CN-Job-Title">
                Executive & Tech
                <br />
                B.Tech. (IIT Delhi), CFA L2, <br />
                MBA (U. Chicago Booth School)
              </div>
            </div>
            <div>
              <b>Manik Pasricha</b> leads Executive & Tech at Creditnama.
              Combining his passion for real estate and financial prudence,
              Creditnama was born to help our Bhartwaasi get access to credit
              that can unlock the potential of their real estate and wealth
              creation dreams. <br />
              Bharat and Dhawal joined Manik in his mission in the same year, to
              make the founding team of Creditnama.
            </div>
          </div>
          <div className="CN-Team-Page-Profile-Div2">
            <div className="CN-Team-Page-Img-Container">
              <img
                src={Dhawal}
                alt="Dhawal"
                className="CN-Team-page-Profile-Img"
                loading="lazy"
                onContextMenu={(e) => e.preventDefault()}
              />
              <div className="CN-Team-Page-Namme-Div">
                Dhawal Mehra{" "}
                <img
                  src={Linkdin}
                  alt="Linkdin"
                  className="CN-Team-Page-Linkdin-Img"
                  onClick={handleDhawal}
                />
              </div>
              <div className="CN-Job-Title">
                Operations & Alliance
                <br />
                MBA (I.E Business School, Spain),
                <br /> Ex-Senior Manager, Bank of India
              </div>
            </div>
            <div>
              <b>Dhawal Mehra</b> spearheads lending operations, fostering
              strategic alliances with financial institutions and guiding
              clients to optimal credit solutions to match their credit
              requirements.
              <br /> His journey epitomizes a commitment to bridging financial
              awareness gaps and facilitating informed lending decisions.
            </div>
          </div>
          <div className="CN-Team-Page-Profile-Div">
            <div className="CN-Team-Page-Img-Container">
              <img
                src={Bhart}
                alt="Bhart Malhotra"
                className="CN-Team-page-Profile-Img"
                loading="lazy"
                onContextMenu={(e) => e.preventDefault()}
              />
              <div className="CN-Team-Page-Namme-Div">
                Bhart Malhotra{" "}
                <img
                  src={Linkdin}
                  alt="Linkdin"
                  className="CN-Team-Page-Linkdin-Img"
                  onClick={handleBhart}
                />
              </div>
              <div className="CN-Job-Title">
                Product
                <br />
                4+ years of loan channel experience
                <br />
                BBA (MDU, Rohtak)
              </div>
            </div>
            <div>
              <b>Bhart Malhotra</b> is a key leader of the product team who
              leans on his curiosity and rapid iterations to deliver exceptional
              customer experience. <br />
              Enamored by solving this problem, Bhart became a self-taught
              programmer and quickly learned critical aspects of software
              engineering, UI/UX, and prototyping.
            </div>
          </div>
        </div>
      </div>
      <div className="CN2-Team-Advisory">
        <h1>Advisory team: Credit to Product</h1>
        <div className="CN2-Team-Advisory-Container">
          <div>
            <img
              src={siddharth}
              alt="Siddharth Attrishi"
              className="CN2-Team-Advisory-Img"
            />
            <p>
              <span>Siddharth Attrishi</span>
              <br />
              Marketing Manager (Freelance)
              <br />
              8+ years in building brands – <br />
              myWorld, Hi Life, Booming Bull
            </p>
          </div>
          <div>
            <img
              src={ishaan}
              alt="Ishaan Kataria"
              className="CN2-Team-Advisory-Img"
            />
            <p>
              <span>Ishaan Kataria</span>
              <br />
              Zonal sales manager, Tata Capital <br />
              16+ year in lending, Ex-Yes Bank, ICICI Bank
            </p>
          </div>
        </div>
        <div className="CN2-Team-Advisory-Container">
          <div>
            <div>
              <img
                src={shruti}
                alt="Shruti Lamba"
                className="CN2-Team-Advisory-Img"
              />
              <p>
                <span>Shruti Lamba</span>
                <br />
                Staff Engg Manager, Disney Hotstar <br />
                CS (IP University)
              </p>
            </div>
          </div>
          <div>
            <img
              src={rohit}
              alt="Rohit Sharma"
              className="CN2-Team-Advisory-Img"
            />
            <p>
              <span>Rohit Sharma</span>
              <br />
              Performance Marketing Leader, Bajaj Finserv <br />
              11+ year in digital lead gen & marketing
            </p>
          </div>
        </div>
        <div className="CN2-Team-Advisory-Container">
          <div>
            <div>
              <img
                src={kapil}
                alt="Kapil Gadhire"
                className="CN2-Team-Advisory-Img"
              />
              <p>
                <span>Kapil Gadhire</span>
                <br />
                Head of Marketing, Magnifi AI <br />
                10+ years in marketing + AI
              </p>
            </div>
          </div>
          <div>
            <img
              src={nishant}
              alt="Nitant Chaturvedi"
              className="CN2-Team-Advisory-Img"
            />
            <p>
              <span>Nitant Chaturvedi</span>
              <br />
              Area Sales Manager, Yes Bank
              <br />
              12+ year in lending. Ex-HDFC Bank, Kotak
            </p>
          </div>
        </div>
        <div className="CN2-Team-Advisory-Container">
          <div>
            <div>
              <img
                src={ronak}
                alt="Ronak Patel"
                className="CN2-Team-Advisory-Img"
              />
              <p>
                <span>Ronak Patel</span>
                <br />
                SDE, Google, ex-Snap
                <br />
                BS Comp Sc.
              </p>
            </div>
          </div>
          <div>
            <img
              src={nihit}
              alt="Nihit Kumar"
              className="CN2-Team-Advisory-Img"
            />
            <p>
              <span>Nihit Kumar</span>
              <br />
              Ex-ICICI Bank, 10+ years in corporate banking
              <br />
              B.Tech. (IIT Delhi), MBA (XLRI)
            </p>
          </div>
        </div>
        <div className="CN2-Team-Advisory-Container">
          <div>
            <div>
              <img
                src={aishwarya}
                alt="Aishwarya Sharma"
                className="CN2-Team-Advisory-Img"
              />
              <p>
                <span>Aishwarya Sharma</span>
                <br />
                Senior PM, Amazon US
                <br />
                Instructor, Product School
              </p>
            </div>
          </div>
          <div className="CN2-Team-Nouse"></div>
        </div>
      </div>

      {/* <div className="CN2-Team-Culture">
        <h1>Culture @ Creditnama</h1>
        <div>
          <img src={culture} alt="Culture" className="CN2-Team-Culture-Img" />
        </div>
      </div> */}

      <div className="CN2-Team-SubFooter">
        <div
          className="CN2-Team-Page-Redirection-div"
          style={{ backgroundColor: "var(--color-white)" }}
        >
          <div>Curious to know more? Learn more about us here.</div>
          <div>
            <button>
              <a href="/about">About</a>
            </button>
          </div>
        </div>
        <div className="CN2-Team-Page-Redirection-div">
          <div>
            Does the thought of collaborating with smart folks to shape the
            future of digital lending excite you? Share your profile with us and
            join our growing team, your career’s best move awaits you!
          </div>
          <div>
            <button>
              <a href="/careers">Join us</a>
            </button>
          </div>
        </div>
        <div
          className="CN2-Team-Page-Redirection-div"
          style={{ backgroundColor: "var(--color-white)" }}
        >
          <div>
            We appreciate your feedback and would love to hear from you. Please
            don’t hesitate to leave a comment, question or thought or just to
            say hi.
          </div>
          <div>
            <button>
              <a href="/contact-us">Let's Talk</a>
            </button>
          </div>
        </div>
        <div className="CN2-Team-Page-Redirection-div">
          <div>Learn more about Creditnama product offerings.</div>
          <div>
            <button>Products</button>
          </div>
        </div>
        <div
          className="CN2-Team-Page-Redirection-div"
          style={{
            borderBottom: "1px solid #00000040",
            backgroundColor: "var(--color-white)",
          }}
        >
          <div>
            Check our latest thinking as we follow macro trends and micro
            markets.
          </div>
          <div>
            <button>
              <a href="/blogs">Blogs</a>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Team;
