import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./offerstandby.css";
import Header from "../User-Components/Front-Page-Components/Header/Header";
import StatusBar from "../Status-Bar/StatusBar";
import StepsFooter from "../User-Components/Front-Page-Components/Footer/StepsFooter";
import OfferImg from "../Images/Page-Img/Offers.png";

function OfferStandby() {
  const history = useNavigate(); // Initialize the useHistory hook

  useEffect(() => {
    // Use the useEffect hook to trigger the redirect after 5 seconds
    const redirectTimeout = setTimeout(() => {
      history("/offer"); // Redirect to "/offer" after 5 seconds
    }, 5000);

    // Clean up the timeout if the component unmounts
    return () => clearTimeout(redirectTimeout);
  }, [history]); // Include history as a dependency to ensure it's in the scope

  return (
    <>
      <Header />

      <div className="CN2-OfferStandby">
        <div className="CN2-OfferStandby-Container">
          <div className="CN2-OfferStandby-Main-Div">
            <div className="CN2-OfferStandby-Main-Div-Left">
              <img
                src={OfferImg}
                alt="Profile with pan and aadhaar"
                className="CN2-OfferStandby-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-yellow)"
                KycColor="white"
                IncomeColor="white"
                OffersColor="white"
              />
            </div>
            <div className="CN2-OfferStandby-Main-Div-Right">
              <h1>Creating offers for you!</h1>
              <div className="spinnerContainer">
                <div className="spinner"></div>
                <div className="loader">
                  <b>Loading</b>

                  <div className="words">
                    <span className="word">banks</span>
                    <span className="word">amount</span>
                    <span className="word">rates</span>

                    <span className="word">offers</span>
                    <span className="word">deals</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default OfferStandby;
