import React from "react";
import Header from "../User-Components/Front-Page-Components/Header/Header";
import "./SessionBreak.css";
import SessionBreakImg from "../Images/Page-Img/SessionBreak.png";
import WA from "../Images/Assets/WA.png";
import { useNavigate } from "react-router-dom";
import StepsFooter from "../User-Components/Front-Page-Components/Footer/StepsFooter";

const SessionBreak = () => {
  const redirectToSocialMedia = (socialMedia) => {
    // Define the URLs for each social media platform
    const socialMediaUrls = {
      Whatsapp: "https://wa.me/+918750072800?text=Hi",
    };

    // Redirect to the selected social media page
    window.location.href = socialMediaUrls[socialMedia];
  };

  const history = useNavigate();
  return (
    <>
      <Header />

      <div className="CN-SessionBreak-Page">
        <div className="CN-SessionBreak-Main-Container">
          <img
            src={SessionBreakImg}
            alt="Session Break"
            className="CN-SessionBreak-Image"
            loading="lazy"
            onContextMenu={(e) => e.preventDefault()}
          />
          <h1>Oh Snap!</h1>
          <p>Seems like your property loan is one more step away!</p>
          <div className="CN-SessionBreak-Buttons-Div">
            <button
              className="CN-SessionBreack-Retrybtn"
              onClick={() => history("/")}
            >
              Retry
            </button>
            <button
              className="CN-SessionBreack-Connectbtn"
              onClick={() => redirectToSocialMedia(`Whatsapp`)}
            >
              <img
                src={WA}
                alt="whats app icon"
                className="CN-SessionBreak-WA-Icon"
                loading="lazy"
                onContextMenu={(e) => e.preventDefault()}
              />
              Connect with us
            </button>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
};

export default SessionBreak;
