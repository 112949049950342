import React, { useEffect, useState } from "react";
import "./InternalTracker.css";
import Creditnama from "../Images/CNLogo.png";
import serverIP from "../config/config";
import { useNavigate } from "react-router-dom";

const InternalTracker = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const history = useNavigate();
  // const [otp, setOtp] = useState("");

  // const handleOtp = (e) => {
  //   e.preventDefault();
  //   console.log(userName, password);
  // };

  useEffect(() => {
    localStorage.removeItem("iToken");
  });

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
    setError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${serverIP}/auth/thekedaar/verify-channel-text-otp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            p1: userName,
            p2: password,
          }),
        }
      );

      if (response.status >= 200 || response.status < 300) {
        const data = await response.json();
        console.log(data);

        if (data.isAuthValid === true) {
          localStorage.setItem("iToken", data.token);
          history("/thekedaar/master");
        } else setError("Invalid Credentials");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="CN-InternalTracker-Page">
      <div className="CN-InternalTracker-Logo-Div">
        <img
          src={Creditnama}
          alt="Creditnama"
          className="CN-InterTracker-Img"
        />
      </div>
      <form onSubmit={handleSubmit}>
        <h1>Login</h1>
        {error && <p className="CN-InternalTrackerError">{error}</p>}
        <label>Username</label>
        <input type="password" maxLength={20} onChange={handleUserNameChange} />
        <label>Password</label>
        <input type="password" maxLength={20} onChange={handlePasswordChange} />

        <button>Submit</button>
      </form>
    </div>
  );
};

export default InternalTracker;
