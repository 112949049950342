import React from "react";
import "./CNPan.css";
import KycImg from "../Images/Page-Img/Verifyidentity.png";
import PanIcon from "../Images/Assets/panicon@2x.png";
import DigiIcon from "../Images/Assets/digicn@2x.png";
import UploadIcon from "../Images/Assets/uploadicon@2x.png";
import { useState } from "react";
import serverIP from "../config/config";
import Animation1 from "../Animation/animation";
import { useNavigate } from "react-router-dom";
import Header from "../User-Components/Front-Page-Components/Header/Header";
import StepsFooter from "../User-Components/Front-Page-Components/Footer/StepsFooter";
import StatusBar from "../Status-Bar/StatusBar";

function Pan() {
  const [selectedFileName, setSelectedFileName] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const history = useNavigate();

  function handleClick() {
    const fileInput = document.getElementById("fileInput");
    fileInput.click(); // Trigger the file input's click event
  }

  function handleFileUpload(event) {
    setError("");
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setSelectedFileName(selectedFile.name); // Update the selected file name
      setIsSubmitDisabled(false); // Enable the Submit button
      // Handle the selected file here (e.g., send it to a server)
      // console.log("Selected file:", selectedFile.name);
    } else {
      setSelectedFileName(""); // Clear the selected file name
      setIsSubmitDisabled(true); // Disable the Submit button
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("accessToken");
      const userId = localStorage.getItem("userId");

      const fileInput = document.getElementById("fileInput");
      const selectedFile = fileInput.files[0];
      const formData = new FormData();
      formData.append("pan", selectedFile);
      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      };
      setIsLoading(true);

      const response = await fetch(
        `${serverIP}/kyc/uploadpan/${userId}`,
        config
      );

      if (response.status === 200 || response.status <= 300) {
        console.log("File uploaded successfully");
        console.log(response);
        setIsLoading(false);
        history("/kyc");
      } else {
        console.error("Failed to upload file");
        setError("Failed to upload file");
        setIsLoading(false);
        history("/session-break");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsLoading(false);
      history("/session-break");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header />

      <div className="CN2-KYC">
        <div className="CN2-PAN-Container">
          <div className="CN2-PAN-Main-Div">
            <div className="CN2-PAN-Main-Div-Left">
              <img
                src={KycImg}
                alt="Profile with pan and aadhaar"
                className="CN2-PAN-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-success)"
                KycColor="var(--color-yellow)"
                IncomeColor="white"
                OffersColor="white"
              />
            </div>
            <div className="CN2-PAN-Main-Div-Right">
              <h1>Verify Your PAN</h1>
              {isLoading ? (
                <>
                  <Animation1 />
                </>
              ) : (
                <>
                  {error && <div className="CN2-PAN-Error">{error}</div>}
                  <div className="CN2-PAN-Pan-Div" onClick={handleClick}>
                    <div>
                      <img
                        src={PanIcon}
                        alt="Verify Pan"
                        className="CN2-PAN-Pan-Icon"
                      />
                    </div>
                    <div>Click to upload</div>
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileUpload}
                    />
                    <div>
                      <img
                        src={UploadIcon}
                        alt="Verify Pan"
                        className="CN2-PAN-Upload-Icon"
                      />
                    </div>
                  </div>
                  {selectedFileName ? (
                    <>Selected File : {selectedFileName}</>
                  ) : (
                    <>
                      or
                      <img
                        src={DigiIcon}
                        alt="digilocker"
                        style={{
                          width: "200px",
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                      />
                    </>
                  )}

                  {selectedFileName ? (
                    <button
                      className="CN2-PAN-Button my-2"
                      onClick={handleSubmit}
                      disabled={isSubmitDisabled}
                      style={{ backgroundColor: "var(--color-darkgreen)" }}
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      className="CN2-PAN-Button my-2"
                      style={{
                        color: "gray",
                        backgroundColor: "lightgray",
                        cursor: "inherit",
                      }}
                      disabled={isSubmitDisabled}
                    >
                      Submit
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default Pan;
