import React, { useEffect, useState } from "react";
import "./NextEMICalculators.css";
import PieChart from "../../Eligibility-Components/Charts-Components/PieChart";

const EMICal = () => {
  const [loanAmount, setLoanAmount] = useState(6000000);
  const [currentRoi, setCurrentRoi] = useState(8.45);
  const [tenureLeft, setTenureLeft] = useState(25);

  // Pie chart data
  const [pieChartData, setPieChartData] = useState({
    datasets: [
      {
        data: [loanAmount, 0],
        backgroundColor: ["#217E3C", "#d4df33"],
        borderColor: "black",
        borderWidth: 0.5,
      },
    ],
  });

  const calculateEMI = (principal, rate, tenure) => {
    rate = rate / 12 / 100;
    const n = tenure * 12;
    const emi =
      (principal * rate * Math.pow(1 + rate, n)) / (Math.pow(1 + rate, n) - 1);
    const totalPayment = emi * n;
    const interestAmount = totalPayment - principal;

    return {
      emi: emi.toFixed(2),
      principal: principal.toFixed(2),
      interest: interestAmount.toFixed(2),
      total: totalPayment.toFixed(2),
    };
  };

  useEffect(() => {
    const totalInterestPaid =
      Number(calculateEMI(loanAmount, currentRoi, tenureLeft).total) -
      loanAmount;
    setPieChartData((prevChartData) => ({
      ...prevChartData,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: [loanAmount, totalInterestPaid],
        },
      ],
    }));
  }, [loanAmount, currentRoi, tenureLeft]);

  const { emi, principal, interest, total } = calculateEMI(
    loanAmount,
    currentRoi,
    tenureLeft
  );

  return (
    <div className="BCN-EMICalculator-Page">
      <h2>EMI Calculator</h2>
      <p>Find out the EMI</p>
      <div className="BCN-EMICalculator-Container">
        <div className="BCN-EMICalculator-Container-Part1">
          <label>
            Loan Amount
            <span className="BCN-EMICalculator-Input-Symbol">₹</span>
            <input
              type="tel"
              value={loanAmount}
              onChange={(e) =>
                setLoanAmount(
                  isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
                )
              }
              maxLength={10}
              pattern="\d*"
            />
          </label>
          <input
            type="range"
            className="BCN-EMICalculator-Range"
            min={100000}
            max={10000000}
            step={100000}
            value={loanAmount}
            onChange={(e) =>
              setLoanAmount(
                isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
              )
            }
          />

          <label>
            Interest Rate (% P.A.)
            <input
              type="tel"
              value={currentRoi}
              onChange={(e) => setCurrentRoi(e.target.value)}
              maxLength={5}
              style={{ paddingLeft: "10px" }}
            />
          </label>
          <input
            type="range"
            className="BCN-EMICalculator-Range"
            min={1}
            max={20}
            step={0.25}
            value={currentRoi}
            onChange={(e) =>
              setCurrentRoi(
                isNaN(parseFloat(e.target.value))
                  ? 0
                  : parseFloat(e.target.value)
              )
            }
          />

          <label>
            Tenure (Years)
            <input
              type="tel"
              value={tenureLeft}
              onChange={(e) =>
                setTenureLeft(
                  isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
                )
              }
              maxLength={2}
              style={{ paddingLeft: "10px" }}
              pattern="\d*"
            />
          </label>
          <input
            type="range"
            className="BCN-EMICalculator-Range"
            min={1}
            max={30}
            step={1}
            value={tenureLeft}
            onChange={(e) =>
              setTenureLeft(
                isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
              )
            }
          />
        </div>
        <div className="BCN-EMICalculator-Container-Part2">
          <p>
            <b>Monthly EMI Amount</b> <br />₹{" "}
            <span className="BCN-EMICalculator-Result-Amt-Imp">
              {tenureLeft <= 0 ? (
                "0"
              ) : (
                <>
                  {parseFloat(emi).toLocaleString("en-IN", {
                    maximumFractionDigits: 0,
                  }) === "NaN"
                    ? 0
                    : parseFloat(emi).toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                      })}
                </>
              )}
            </span>
          </p>

          <p>
            <b>Principal Amount</b>
            <br />₹{" "}
            <span className="BCN-EMICalculator-Result-Amt">
              {parseFloat(principal).toLocaleString("en-IN", {
                maximumFractionDigits: 0,
              })}
            </span>
          </p>

          <p>
            <b>Interest Amount</b>
            <br />₹{" "}
            <span className="BCN-EMICalculator-Result-Amt">
              {tenureLeft <= 0 ? (
                "0"
              ) : (
                <>
                  {" "}
                  {parseFloat(interest).toLocaleString("en-IN", {
                    maximumFractionDigits: 0,
                  })}
                </>
              )}
            </span>
          </p>

          <p>
            <b>Total Amount Paid</b>
            <br />₹{" "}
            <span className="BCN-EMICalculator-Result-Amt">
              {tenureLeft <= 0 ? (
                "0"
              ) : (
                <>
                  {parseFloat(total).toLocaleString("en-IN", {
                    maximumFractionDigits: 0,
                  })}
                </>
              )}
            </span>
          </p>
        </div>
        <div className="BCN-EMICalculator-Container-Part3">
          <PieChart
            chartData={pieChartData}
            className="BCN-EMICalculator-PieChart"
          />
          <div style={{ marginTop: "10px" }}>
            <div>
              <div
                style={{
                  width: "10px",
                  height: "10px ",
                  backgroundColor: "#217E3C",
                  display: "inline-block",
                  marginRight: "5px",
                }}
              />
              Principal: ₹
              {loanAmount.toLocaleString("en-IN", { maximumFractionDigits: 0 })}
            </div>
            <div>
              <div
                style={{
                  width: "10px",
                  height: "10px ",
                  backgroundColor: "#d4df33",
                  display: "inline-block",
                  marginRight: "5px",
                }}
              />
              Interest: ₹
              {Number(interest).toLocaleString("en-IN", {
                maximumFractionDigits: 0,
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EMICal;
