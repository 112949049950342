import React from "react";
import waIcon from "../../../Images/Assets/WA.png";
import "./CTA.css";
function WhatsappIcon() {
  return (
    <div
      onClick={() => {
        window.open(
          "https://wa.me/+918750064900?text=Hey, I would like to know more about Paras sec 59"
        );
      }}
      className="paras-whatsapp-icon"
    >
      <img className="paras-whatsapp-icon-image" src={waIcon} alt="whatsapp" />
    </div>
  );
}

export default WhatsappIcon;
