import React, { useEffect, useState } from "react";
import "./Calculators.css";
// import Tab from "@/components/ui/Tab";

const AffordibilityCal = () => {
  const [dp, setDp] = useState("1000000");
  const [emi, setEmi] = useState("0");
  const [salary, setSalary] = useState("100000");
  const [roi, setRoi] = useState("8.75");
  const [tenure, setTenure] = useState("30");
  const [loanEligibility, setLoanEligibility] = useState(0);
  const [propertyCost, setPropertyCost] = useState(0);
  const [messageDiv, setMessageDiv] = useState(false);

  const Foir = 0.6;
  const propertyMargin = 0.2;

  const PV = (rate, nper, pmt, fv = 0, type = 0) => {
    if (rate === 0) return -pmt * nper - fv;
    const pv =
      pmt * ((1 - Math.pow(1 + rate, -nper)) / rate) * (1 + rate * type) -
      fv / Math.pow(1 + rate, nper);
    return pv;
  };

  const dpNum = parseFloat(dp);
  const salaryNum = parseFloat(salary);
  const emiNum = parseFloat(emi);
  const roiNum = parseFloat(roi) / 100;
  const tenureNum = parseFloat(tenure);

  const PropEmi = salaryNum * Foir - emiNum;
  const PrincipalAmount = PV(roiNum / 12, tenureNum * 12, PropEmi);
  // const MaxHomeLoan = dpNum / propertyMargin - dpNum;
  const MaxHomeLoan =
    dpNum >= propertyMargin * (PrincipalAmount + dpNum)
      ? PrincipalAmount
      : dpNum / propertyMargin;

  const PropertyCost = Math.min(PrincipalAmount + dpNum, MaxHomeLoan + dpNum);

  const handleDpChange = (e) => {
    setDp(e.target.value);
    setSalary("100000");
  };

  useEffect(() => {
    setLoanEligibility(MaxHomeLoan);
    setPropertyCost(PropertyCost);

    if (PropertyCost < 0) {
      setMessageDiv(true);
    } else {
      setMessageDiv(false);
    }
  }, [dpNum, salaryNum, emiNum, roiNum, tenureNum, MaxHomeLoan, PropertyCost]);

  const handleDpRangeChange = (e) => {
    setDp(e.target.value);
  };

  const handleGotIt = () => {
    setMessageDiv(false);
    setSalary("100000");
    setEmi("0");
  };

  return (
    <div className="BCN-Calculator-Page">
      {messageDiv ? (
        <>
          <div className="BCN-Calculator-Message-Heading">
            <p>
              We are unable to show you any offers currently as your current
              EMIs amount is very high. You can go back and modify your inputs
              if you wish to recalculate your eligibility.
            </p>
            <button onClick={handleGotIt}>Got it!</button>
          </div>
        </>
      ) : (
        <>
          {/* <div className="BCN-Calculator-Page-Tabs">
            <Tab />
          </div> */}
          <h2>Affordability Calculator</h2>
          <p>
            Get the peace of mind by knowing all the details about your loan
          </p>
          <div className="BCN-Calculator-Container">
            <div className="BCN-Calculator-Container-Part1">
              <label>
                Down Payment
                <span className="BCN-Calculator-Input-Symbol">₹</span>
                <input
                  type="tel"
                  value={dp}
                  onChange={handleDpChange}
                  maxLength={10}
                  pattern="\d*"
                />
              </label>
              <input
                type="range"
                className="BCN-Calculator-Range"
                min="100000"
                max="10000000"
                step="100000"
                value={dp}
                onChange={handleDpRangeChange}
              />

              <label>
                Monthly Salary
                <span className="BCN-Calculator-Input-Symbol">₹</span>
                <input
                  type="tel"
                  value={salary}
                  onChange={(e) => setSalary(e.target.value)}
                  maxLength={10}
                  pattern="\d*"
                />
              </label>
              <input
                type="range"
                className="BCN-Calculator-Range"
                min="10000"
                max="1000000"
                step="10000"
                value={salary}
                onChange={(e) => setSalary(e.target.value)}
              />

              <label>
                Interest Rate (% P.A.)
                <input
                  type="tel"
                  placeholder="Interest rate"
                  value={roi}
                  onChange={(e) => setRoi(e.target.value)}
                  maxLength={5}
                  style={{ paddingLeft: "10px" }}
                />
              </label>
              <input
                type="range"
                className="BCN-Calculator-Range"
                min="1"
                max="20"
                step="0.25"
                value={roi}
                onChange={(e) => setRoi(e.target.value)}
              />

              <label>
                Tenure (Years)
                <input
                  type="tel"
                  value={tenure}
                  onChange={(e) => setTenure(e.target.value)}
                  maxLength={2}
                  style={{ paddingLeft: "10px" }}
                  pattern="\d*"
                />
              </label>
              <input
                type="range"
                className="BCN-Calculator-Range"
                min="1"
                max="30"
                step="1"
                value={tenure}
                onChange={(e) => setTenure(e.target.value)}
              />

              <label>
                Existing EMIs
                <span className="BCN-Calculator-Input-Symbol2">₹</span>
                <input
                  type="tel"
                  value={emi}
                  onChange={(e) => setEmi(e.target.value)}
                  maxLength={10}
                  pattern="\d*"
                />
              </label>
              <input
                type="range"
                className="BCN-Calculator-Range"
                min="0"
                max="500000"
                step="1000"
                value={emi}
                onChange={(e) => setEmi(e.target.value)}
              />
            </div>
            <div className="BCN-Calculator-Container-Part2">
              <p>
                <b>You are Eligible for a Loan Amount up to</b>
                <br />₹{" "}
                <span className="BCN-Calculator-Result-Amt-Imp">
                  {loanEligibility.toLocaleString("en-IN", {
                    currency: "INR",
                    maximumFractionDigits: 0,
                  }) === "NaN"
                    ? 0
                    : loanEligibility.toLocaleString("en-IN", {
                        currency: "INR",
                        maximumFractionDigits: 0,
                      })}
                </span>
              </p>
              <p>
                <b>Property Cost</b> <br />₹{" "}
                <span className="BCN-Calculator-Result-Amt">
                  {propertyCost.toLocaleString("en-IN", {
                    currency: "INR",
                    maximumFractionDigits: 0,
                  }) === "NaN"
                    ? 0
                    : propertyCost.toLocaleString("en-IN", {
                        currency: "INR",
                        maximumFractionDigits: 0,
                      })}
                </span>
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AffordibilityCal;
