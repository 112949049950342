import React, { useEffect, useState } from "react";
import "./CNKyc.css";
import Header from "../User-Components/Front-Page-Components/Header/Header";
import StatusBar from "../Status-Bar/StatusBar";
import KycImg from "../Images/Page-Img/Verifyidentity.png";
import serverIP from "../config/config";
import AadhaatIcon from "../Images/Assets/aadhaaricon@2x.png";
import PanIcon from "../Images/Assets/panicon@2x.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import StepsFooter from "../User-Components/Front-Page-Components/Footer/StepsFooter";

function Kyc() {
  const [panStatus, setPanStatus] = useState(null);
  const [aadhaarStatus, setAadhaarStatus] = useState(null);
  const [isProceedEnabled, setIsProceedEnabled] = useState(false);

  const history = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const userId = localStorage.getItem("userId");

        if (!token || !userId) {
          console.error("Access token or UserId not found.");
          return;
        }

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        // First GET request
        const response1 = await axios.get(
          `${serverIP}/user/profileStatus/${userId}`,
          config
        );

        const { retrievedPan, retrievedAadhaar } = response1.data;
        console.log("Pan Status: " + retrievedPan);
        console.log("Aadhaar Status: " + retrievedAadhaar);

        setPanStatus(retrievedPan);
        setAadhaarStatus(retrievedAadhaar);
        // Second GET request
        const response2 = await axios.get(
          `${serverIP}/kyc/status-upload-aadhaar/${userId}`,
          config
        );

        // const { uploadaadhaarstatus } = response2.data;
        console.log("Aadhaar Upload Status: " + response2.data);

        // Third GET request
        const response3 = await axios.get(
          `${serverIP}/kyc/status-upload-pan/${userId}`,
          config
        );
        console.log("Pan Upload Status: " + response3.data);

        // Enable the "Proceed" button and display "Verified Successfully" text
        if (
          (retrievedPan === true && retrievedAadhaar === true) ||
          (response2.data === true && response3.data === true)
        ) {
          setIsProceedEnabled(true);
        } else {
          setIsProceedEnabled(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const getkycircleClass = (status) => {
    if (status === true) {
      return "kycircle-green"; // Apply green color class for true
    } else if (status === null) {
      return "kycircle-red"; // Apply red color class for null
    }
    return "kycircle"; // Default class
  };
  const getStatusText = (status) => {
    if (status === true) {
      return "Verified Successfully"; // Display this text for true
    } else if (status === null) {
      return "Click to Verify"; // Display this text for null
    }
    return ""; // Empty text for other cases
  };

  const handleClickPan = () => {
    // if (isProceedEnabled) {
    //   return;
    // }
    history("/pan");
  };
  const handleClickAadhaar = () => {
    // if (isProceedEnabled) {
    //   return;
    // }
    history("/aadhaar");
  };
  const handleProceed = () => {
    // Handle the "Proceed" button click event
    history("/payslipstandby");
  };
  const buttonStyle = {
    color: isProceedEnabled ? "white" : "gray",
    backgroundColor: isProceedEnabled ? "var(--color-darkgreen)" : "lightgray",
  };

  return (
    <>
      <Header />

      <div className="CN2-KYC">
        <div className="CN2-KYC-Container">
          <div className="CN2-KYC-Main-Div">
            <div className="CN2-KYC-Main-Div-Left">
              <img
                src={KycImg}
                alt="Verify Kyc"
                className="CN2-KYC-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-success)"
                KycColor={
                  isProceedEnabled
                    ? "var(--color-success)"
                    : "var(--color-yellow)"
                }
                IncomeColor="white"
                OffersColor="white"
              />
            </div>
            <div className="CN2-KYC-Main-Div-Right">
              <h1>Verify Your Identity!</h1>
              <div className="CN2-Kyc-Pan-Div" onClick={handleClickPan}>
                <div>
                  <img
                    src={PanIcon}
                    alt="Verify Pan"
                    className="CN2-KYC-Pan-Icon"
                  />
                </div>
                <div>{getStatusText(panStatus)}</div>
                <div
                  className={`CNverify-pan-kycircle ${getkycircleClass(
                    panStatus
                  )}`}
                />
              </div>
              <div className="CN2-Kyc-Aadhaar-Div" onClick={handleClickAadhaar}>
                <div>
                  <img
                    src={AadhaatIcon}
                    className="CN2-KYC-Aadhaar-Icon"
                    alt="Verify Aadhaar"
                  />
                </div>
                <div>{getStatusText(aadhaarStatus)}</div>
                <div
                  className={`CNverify-pan-kycircle ${getkycircleClass(
                    panStatus
                  )}`}
                />
              </div>

              <button
                className="CN2-KYC-Button"
                style={buttonStyle}
                onClick={handleProceed}
                disabled={!isProceedEnabled}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default Kyc;
