import React, { useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "./AiDocPreview.css";
import Delete from "@mui/icons-material/Delete";

function AiDocsPreview({ handleFileUpload, setAiChatH2 }) {
  const [pdfFile, setPdfFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      handleFileUpload(file);
      setPdfFile(URL.createObjectURL(file));
    } else {
      alert("Please select a valid PDF file");
    }
  };

  return (
    <div className="Ai-doc-preview">
      <div>
        {pdfFile ? (
          <>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setPdfFile(null);
                setAiChatH2(true);
              }}
            >
              <Delete
                style={{
                  color: "red",
                  marginBottom: "10px",
                }}
              />
              Remove
            </div>
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
            >
              <Viewer fileUrl={pdfFile} />
            </Worker>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <label htmlFor="file" className="Ai-labelFile">
              <span>
                <svg
                  viewBox="0 0 640 512"
                  style={{ width: "60px", height: "60px" }}
                >
                  <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                </svg>
              </span>
              <p>click to select a file!</p>
            </label>
            <input
              className="input"
              name="text"
              id="file"
              type="file"
              onChange={handleFileChange}
              accept="application/pdf"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AiDocsPreview;
