import React, { useState, useEffect, useCallback } from "react";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
// import { useNavigate } from "react-router-dom";
import "./EMIcalculator.css";
import ArticleIcon from "@mui/icons-material/Article";
import EmiCalulatorForLandingPage from "./EmiCalulatorForLandingPage";

const PlEmiCalc = () => {
  const [loanAmount, setLoanAmount] = useState(100000);
  const [interestRate, setInterestRate] = useState(10.5);
  const [tenure, setTenure] = useState(3);
  const [result, setResult] = useState(null);
  const [LoanTable, setLoanTable] = useState(false);
  const [amortizationTable, setAmortizationTable] = useState([]);

  //   const history = useNavigate();

  const calculateLoan = useCallback(() => {
    const monthlyInterestRate = interestRate / 12 / 100;
    const totalMonths = tenure * 12;

    const emi =
      (loanAmount *
        monthlyInterestRate *
        (1 + monthlyInterestRate) ** totalMonths) /
      ((1 + monthlyInterestRate) ** totalMonths - 1);

    let remainingBalance = loanAmount;
    const tableData = [];

    for (let month = 1; month <= totalMonths; month++) {
      const interestPayment = remainingBalance * monthlyInterestRate;
      const principalPayment = emi - interestPayment;
      remainingBalance -= principalPayment;

      tableData.push({
        month,
        monthlyPayment: emi.toFixed(0),
        principalPayment: principalPayment.toFixed(0),
        interestPayment: interestPayment.toFixed(0),
        remainingBalance: remainingBalance.toFixed(0),
      });
    }

    setAmortizationTable(tableData);

    setResult({
      emi: emi.toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 0,
      }),
      totalPrincipalPaid: (emi * totalMonths).toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 0,
      }),
      totalInterestPaid: (emi * totalMonths - loanAmount).toLocaleString(
        "en-IN",
        {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }
      ),
    });
  }, [loanAmount, interestRate, tenure]);

  useEffect(() => {
    calculateLoan();
  }, [loanAmount, interestRate, tenure, calculateLoan]);
  const handleShowTable = (e) => {
    e.preventDefault();
    if (LoanTable === true) {
      setLoanTable(false);
    } else if (LoanTable === false) {
      setLoanTable(true);
    }
  };
  //   const handleApply = () => {
  //     history("/signup");
  //   };

  return (
    <>
      <div className="CN-For-EMICalcForCamp">
        <div className="CN-EMI-Cal-Div">
          <form
            onSubmit={handleShowTable}
            style={{ marginTop: "50px", width: "100%" }}
          >
            <h2
              style={{
                fontSize: "20px",
                fontWeight: "700",
                fontFamily: "var(--font-Josefin)",
                marginBottom: "25px",
              }}
            >
              Persoanl Loan EMI Calculator
            </h2>
            <div
              className="PB-range-slider-div"
              style={{
                marginTop: "20px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="CN-EMI-InnerLabel-Div">
                <label
                  style={{
                    width: "100%",
                    textAlign: "left",
                    fontWeight: "normal",
                  }}
                >
                  Loan Amount:{" "}
                  {loanAmount.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                    maximumFractionDigits: 0,
                  })}
                  <input
                    type="range"
                    min="500000"
                    max="100000000"
                    step="50000"
                    value={loanAmount}
                    className="PB-range-slider"
                    id="loanAmountRange"
                    onChange={(e) =>
                      setLoanAmount(
                        parseFloat(e.target.value.replace(/[^\d."]/g, ""))
                      )
                    }
                  />
                </label>
                <label
                  style={{
                    width: "100%",
                    textAlign: "left",

                    fontWeight: "normal",
                  }}
                >
                  Rate Of Interest: {interestRate}%
                  <input
                    type="range"
                    min="1"
                    max="30"
                    step={0.25}
                    value={interestRate}
                    onChange={(e) =>
                      setInterestRate(
                        parseFloat(e.target.value.replace(/[^\d.]/g, ""))
                      )
                    }
                    className="PB-range-slider"
                    id="interestRateRange"
                  />
                </label>
                <label
                  style={{
                    width: "100%",
                    textAlign: "left",
                    fontWeight: "normal",
                  }}
                >
                  {" "}
                  Loan Tenure: {tenure * 12} Moths
                  <input
                    type="range"
                    min="1"
                    max="35"
                    value={tenure}
                    onChange={(e) => setTenure(e.target.value)}
                    className="PB-range-slider"
                    id="tenureRange"
                  />
                </label>
                {result && (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    <div>Monthly EMI: {result.emi}</div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        {" "}
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "2rem",
                            backgroundColor: "#d4df33",
                          }}
                        />
                        Interest Payment: {result.totalInterestPaid}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "15px",
                          height: "15px",
                          borderRadius: "2rem",
                          backgroundColor: "#217E3C",
                        }}
                      />
                      Principal Payment: {result.totalPrincipalPaid}
                    </div>
                  </div>
                )}
                <div style={{ width: "100%", gap: "20px" }}>
                  <div className="CN-Emi-Piechart-Div">
                    {result && amortizationTable.length > 0 && (
                      <>
                        <div>
                          <Doughnut
                            className="Cn-PieChart-Size"
                            style={{ width: "250px" }}
                            data={{
                              datasets: [
                                {
                                  data: [
                                    result.totalPrincipalPaid.replace(
                                      /[^0-9.-]+/g,
                                      ""
                                    ), // Remove non-numeric characters
                                    result.totalInterestPaid.replace(
                                      /[^0-9.-]+/g,
                                      ""
                                    ),
                                  ],
                                  backgroundColor: ["#217E3C", "#d4df33"],
                                },
                              ],
                            }}
                            options={{
                              tooltips: {
                                callbacks: {
                                  label: function (tooltipItem, data) {
                                    const label =
                                      data.labels[tooltipItem.index] || "";
                                    const value = Number(
                                      data.datasets[0].data[tooltipItem.index]
                                    ).toLocaleString("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                      maximumFractionDigits: 0,
                                    });
                                    return `${label}: ${value}`;
                                  },
                                },
                              },
                              legend: {
                                display: false,
                              },
                            }}
                          />
                        </div>
                      </>
                    )}

                    {/* <button onClick={handleApply} style={{ fontSize: "15px" }}>
                    Apply Now
                  </button> */}
                  </div>
                </div>
                Amortization
                <div
                  onClick={handleShowTable}
                  style={{
                    cursor: "pointer",
                    fontSize: "15px",
                    fontWeight: "normal",
                    marginTop: "-10px",
                  }}
                >
                  <ArticleIcon fontSize="large" />
                  {LoanTable === true ? (
                    <>
                      <b>Hide</b>
                    </>
                  ) : (
                    <>
                      <b>Show</b>
                    </>
                  )}
                </div>
                {LoanTable && (
                  <>
                    {amortizationTable.length > 0 && (
                      <div
                        style={{
                          padding: "0px",
                          fontWeight: "normal",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <table className="CN-EMI-Chart-Cont">
                          <thead>
                            <tr
                              style={{
                                backgroundColor: "var(--color-darkgreen)",
                                color: "white",
                              }}
                            >
                              <th>Month</th>
                              <th>EMI</th>
                              <th>Principal</th>
                              <th>Interest</th>
                              <th>Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                            {amortizationTable.map((row) => (
                              <tr key={row.month}>
                                <td>{row.month}</td>
                                <td>
                                  ₹
                                  {new Intl.NumberFormat("en-IN").format(
                                    row.monthlyPayment
                                  )}
                                </td>
                                <td>
                                  ₹
                                  {new Intl.NumberFormat("en-IN").format(
                                    row.principalPayment
                                  )}
                                </td>
                                <td>
                                  ₹
                                  {new Intl.NumberFormat("en-IN").format(
                                    row.interestPayment
                                  )}
                                </td>
                                <td>
                                  ₹
                                  {new Intl.NumberFormat("en-IN").format(
                                    row.remainingBalance
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        className="CN-For-EmiCalulatorForLandingPage"
        style={{
          marginTop: "50px",
          height: "fit-content",
        }}
      >
        <EmiCalulatorForLandingPage Heading="Personal Loan EMI Calculator" />
      </div>
    </>
  );
};

export default PlEmiCalc;
