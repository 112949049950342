import logo from "../Paraslogo.svg";
import "./footer.css";

function Footer() {
  return (
    <footer
      style={{ zIndex: "1100", position: "absolute" }}
      className="paras-cnn-footer_section"
    >
      <div className="paras-cnn-top_footer">
        <div className="paras-cnn-container">
          <div className="paras-cnn-row">
            <div className="paras-cnn-col-md-12 paras-cnn-foot_logos">
              <img src={logo} alt="" className="paras-cnn-site_logo" />
            </div>
            <div className="paras-cnn-col-md-12">
              <div className="paras-cnn-rera-details">
                <h2>
                  Sector 59 (Golf course extension) – Near Grand Hyatt Hotel
                </h2>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "100px" }}
              >
                <div>
                  <h3>Available Options</h3>
                  <p>2 BHK (1121 sqft & 1390 sqft)</p>
                  <p>3 BHK (1750 sqft)</p>

                  <h3>Additional Features</h3>
                  <p>AC, Modular kitchen & wardrobe</p>
                  <p>Exclusive terrace rights with 4th floor</p>
                  <p>Basement access to 1st, 2nd, and 3rd floor</p>
                </div>

                <div>
                  <h3>Pricing</h3>
                  <p>
                    Price starts from <strong>₹2.2 crore++</strong> onwards
                  </p>

                  <h3>Attractive Payment Plan</h3>
                  <p>40:30:20:10</p>

                  <h3>Project Details</h3>
                  <p>
                    Project Area: <strong>6.225 acres</strong>
                  </p>
                  <p>
                    Clubhouse area: <strong>25,000 to 30,000 sqf</strong>
                  </p>
                </div>
              </div>
              <br />
              <p>
                <strong>Disclaimer:</strong> All information, images and
                visuals, drawings, plans or sketches shown in this brochure are
                only an architect’s impression, for
                representational/illustrative purposes only and not to scale.
                The Promoter is not liable/ required to provide any Interior
                works, furniture, items, electronics goods, amenities,
                accessories, etc, as displayed herein as the same do not form
                part of the specifications or deliverables being offered by the
                Promoter.
              </p>
              <p>
                *Taxes and statutory charges extra as applicable and terms and
                conditions apply.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
