import React from "react";
import { Helmet } from "react-helmet-async";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import BlogBank from "../../../../Images/Bank-Logos/axis.png";
import "../../aboutus.css";

function AxisHL() {
  document.addEventListener("contextmenu", function (e) {
    e.preventDefault();
  });
  document.addEventListener("selectstart", function (e) {
    e.preventDefault();
  });
  document.addEventListener("touchstart", function (e) {
    e.preventDefault();
  });

  return (
    <>
      <Helmet>
        <title>Axis Bank Home Loan</title>
        <meta
          name="description"
          content="Axis offers loans at interest rates starting from 8.5% p.a.
          for a maximum repayment tenure of 20 Years"
        />
        <link rel="canonical" href="/axis-hl" />
      </Helmet>
      <Header />

      <div className="Aboutus-page">
        <div className="Aboutus-content">
          <h1>Axis Bank Home Loan</h1>
          <div className="CN-Bank-BlogsImgDiv">
            <img
              src={BlogBank}
              alt="Axis Bank Home Loan"
              className="CN-Bank-BlogsImg"
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <p>
              Axis Bank Home Loans are designed to cater to a wide spectrum of
              individuals, including salaried employees and self-employed
              individuals.
            </p>
            <p>
              The lender offers loans at interest rates starting from 8.5% p.a.
              for a maximum repayment tenure of 20 years.
            </p>
            <p>
              The loan can be availed for the purchase of new houses, purchase
              of plots plus construction, or construction on existing plot.
            </p>
            <p>
              The bank also offers a balance transfer facility to take over
              loans from other financial institutions.
            </p>
            <p>
              The bank charges a processing fee of up to 1% of the loan amount
              plus applicable taxes.
            </p>
          </div>
          <p>
            <b>Documents for Salaried Customers</b>
          </p>
          <ul className="CN-ul">
            <li>
              Identity and residence proof (KYC), such as Aadhaar, PAN card,
              Voter ID card.
            </li>
            <li>
              Income proof, such as last 3 months salary slip, latest Form 16,
              and six-month bank statement.
            </li>

            <li> A Cheque For The Processing Fee For The Loan Application</li>
            <li>
              Property Documents Such As Sale Deed, NOC From The Builder Or
              Housing Society, Advance Payment Receipt To The Builder, And
              Approved Construction Plan
            </li>
          </ul>
          <p>
            <b>Documents for Self-Employed Customers</b>
          </p>
          <ul className="CN-ul">
            <li>
              Identity and residence proof (KYC), such as Aadhaar, PAN card,
              Voter ID card.
            </li>
            <li>
              ITR With Computation of Income for Previous 3 Years as Proof of
              Income
            </li>
            <li>
              latest two years P&L accounts and B/S (with schedules), six-month
              bank statement, etc
            </li>
            <li> A Cheque For The Processing Fee For The Loan Application</li>
            <li>
              Property Documents Such As Sale Deed, NOC From The Builder Or
              Housing Society, Advance Payment Receipt To The Builder, And
              Approved Construction Plan
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AxisHL;
