import React from "react";
// import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import DocsReader from "./DocsReader";

function PdfPreview() {
  return <DocsReader />;
}

export default PdfPreview;
