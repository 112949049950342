// import React from "react";
import "./BlogsPart.css";
import Blog1 from "../Images/BlogsImages/Blog1Img.png";
import Blog2 from "../Images/BlogsImages/Blog2-1Img.png";
import Blog3 from "../Images/BlogsImages/Blog3-ThumImg.png";
import Blog4 from "../Images/BlogsImages/Blog4-1Img.jpg";
import Blog5 from "../Images/BlogsImages/Blog5-1Img.jpg";
import Blog6 from "../Images/BlogsImages/Blog6-1Img.png";
import Blog7 from "../Images/BlogsImages/UnlockSavings.jpg";
import Blog8 from "../Images/BlogsImages/SEBI.jpg";
import Blog9 from "../Images/BlogsImages/LoanSavings.png";
import Blog10 from "../Images/BlogsImages/MumbaiRealEstate.jpg";
import Blog11 from "../Images/BlogsImages/stamppaper.jpg";
import Blog12 from "../Images/BlogsImages/educationloan.jpg";
import Blog13 from "../Images/BlogsImages/CibilImg.jpg";
import Blog14 from "../Images/BlogsImages/Typesofhomeloan.jpg";
import Blog15 from "../Images/BlogsImages/fourFloor.jpg";
import Blog16 from "../Images/BlogsImages/yeidaCity.jpg";
import Blog17 from "../Images/BlogsImages/emiorinvest1.jpg";
import Blog18 from "../Images/BlogsImages/interestImpact.jpg";
import Blog19 from "../Images/BlogsImages/budget2024.jpg";
import Blog20 from "../Images/BlogsImages/CreditScore.jpg";
import Blog21 from "../Images/BlogsImages/WealthCreation.jpg";
import Blog22 from "../Images/BlogsImages/ruinedCreditScore.jpg";
import Blog23 from "../Images/BlogsImages/smartInvestment.jpg";
import Blog24 from "../Images/BlogsImages/MillennialsvsGenZ.jpg";
import Blog25 from "../Images/BlogsImages/keyofsucess.jpg";
import Blog26 from "../Images/BlogsImages/newbudget2024.jpg";
import Blog27 from "../Images/BlogsImages/fixed-or-reducing-balance-interest-rate.jpg";
import Blog28 from "../Images/BlogsImages/spamCalls.jpg";
import Blog29 from "../Images/BlogsImages/propertyPurchase.jpg";
import Blog30 from "../Images/BlogsImages/bestHomeLoan.jpg";
import Blog31 from "../Images/BlogsImages/nriBlog.jpg";
import Blog32 from "../Images/BlogsImages/july2024.jpg";
import Blogm from "../Images/BlogsImages/mistakes.jpg";
import Blog33 from "../Images/BlogsImages/benefits.jpg";
import Blog34 from "../Images/BlogsImages/charges.jpg";
import Blog35 from "../Images/BlogsImages/compareBank.jpg";
import Blog36 from "../Images/BlogsImages/improvement.jpg";
import Blog37 from "../Images/BlogsImages/approvel.png";
import Blog38 from "../Images/BlogsImages/refinance.png";

const BlogPart = () => {
  const blogs = [
    {
      id: 1,
      title: "Delhi's Property Registration shake-up :",
      content: '"Anywhere Registration" aims for Convenience and Transparency',
      image: Blog1,
      link: "https://creditnama.com/blog/delhi-property-registration",
    },
    {
      id: 2,
      title: "What is better for a home loan or LAP – ",
      content: "A bank or an HFC? All you need to know.",
      image: Blog2,
      link: "https://creditnama.com/blog/home-loan-lap-whats-better",
    },
    {
      id: 3,
      title: "Navigating Charges ",
      content: " Associated with Home Loans and Loan Against Property – Part 1",
      image: Blog3,
      link: "https://creditnama.com/blog/charges-associated-home-loan-lap-part-one",
    },
    {
      id: 4,
      title: "Navigating Charges ",
      content: " Associated with Home Loans and Loan Against Property – Part 2",
      image: Blog4,
      link: "https://creditnama.com/blog/charges-associated-home-loan-lap-part-two",
    },
    {
      id: 5,
      title: "Considering a CGTMSE loan? ",
      content: " Here’s all that you need to know about CGTMSE limit!",
      image: Blog5,
      link: "https://creditnama.com/blog/about-cgtmse",
    },
    {
      id: 6,
      title: "Case for Public Sector Banks: ",
      content: "what does their lending portfolio suggest?",
      image: Blog6,
      link: "https://creditnama.com/blog/case-for-public-sector-bank",
    },
    {
      id: 7,
      title: "Unlocking Savings: ",
      content: " Navigating the Expected Home Loan Interest Rate Drop in 2024",
      image: Blog7,
      link: "https://creditnama.com/blog/unlocking-savings-ratedrop",
    },
    {
      id: 8,
      title: "Fractional Ownership of Residential Real Estate ",
      content: "– SEBI Introduces Guidelines",
      image: Blog8,
      link: "https://creditnama.com/blog/residential-properties-fractional-ownership",
    },

    {
      id: 9,
      title: "How Can I Save ₹20 Lakhs on My Home Loan? ",
      content: "Creditnama's exclusive offerings.",
      image: Blog9,
      link: "https://creditnama.com/blog/save-twenty-lakhs-on-home-loan",
    },

    {
      id: 10,
      title: "Insights on rental yields ",
      content: "- Delhi NCR vs Mumbai vs Bengaluru",
      image: Blog10,
      link: "https://creditnama.com/blog/rental-yield-q1-2024",
    },
    {
      id: 11,
      title: "Stamp Paper vs Notary vs Gazetted Certificate ",
      content: "Certificates Protect Your Home Purchase",
      image: Blog11,
      link: "https://creditnama.com/blog/stamp-paper-vs-notary-vs-gazetted-certificate",
    },

    {
      id: 12,
      title: "A Guide to Education Loans in India ",
      content: "This blog offers an in-depth look at education loans in India",
      image: Blog12,
      link: "https://creditnama.com/blog/guide-to-education-loans-in-india",
    },
    {
      id: 13,
      title: "Raising Your Credit Score: ",
      content: "It's Easier Than You Think",
      image: Blog13,
      link: "https://creditnama.com/blog/raising-your-credit-score",
    },

    {
      id: 14,
      title: "Types of Home Loans in India ",
      content:
        "Each designed to cater to different financial needs and circumstances.",
      image: Blog14,
      link: "https://creditnama.com/blog/home-loan-types-india",
    },
    {
      id: 15,
      title: "4 Floors Rules for Gurgaon",
      content: `The Haryana government in July 2024 has finally amended its rules to`,
      image: Blog15,
      link: "https://creditnama.com/blog/gurgaon-stilt-plus-four-floors",
    },
    {
      id: 16,
      title: "Investing in YEIDA City",
      content: `Yamuna Expressway, also known as Taj Expressway, is a 165.5 km, six-lane wide access`,
      image: Blog16,
      link: "https://creditnama.com/blog/investing-in-yeida-city",
    },

    {
      id: 17,
      title: " Pay Off EMI Early or Invest?",
      content: ` In the world of personal finance, one significant question often arises`,

      image: Blog17,
      link: "https://creditnama.com/blog/pay-off-emi-early-or-invest",
    },
    {
      id: 18,
      title: "Home Loan Interest Rates Impact",
      content: `For most people, buying a home is one of the most significant...`,

      image: Blog18,
      link: "https://creditnama.com/blog/home-loan-interest-rates-impact",
    },
    {
      id: 19,
      title: "Home Expectations from Modi 3.0 First Budget",
      content: `One of the most anticipated measures is enhancing the tax rebate`,
      image: Blog19,
      link: "https://creditnama.com/blog/home-expectations-modi-2024-budget",
    },
    {
      id: 20,
      title: "5 Essential Hacks to Boost Your Credit Score Fast",
      content: `our credit score is more than just a number – it's the...`,
      image: Blog20,
      link: "https://creditnama.com/blog/5-essential-hacks-to-boost-your-credit-score-fast",
    },
    {
      id: 21,
      title: "The Einstein Method: How a Home Loan Can Build Your Wealth",
      content: `Have you ever thought about taking out a big loan to buy a house?`,
      image: Blog21,
      link: "https://creditnama.com/blog/wealth-creation",
    },
    {
      id: 22,
      title: "How One Text Message Can Ruin Your Credit Score",
      content: `In today's digital age, where our smartphones are practically...`,
      image: Blog22,
      link: "https://creditnama.com/blog/unaware-of-credit-enquiry",
    },
    {
      id: 23,
      title: "The Ultimate Investment Strategy",
      content: `Why Diversification is Key to Building Wealth?`,
      image: Blog23,
      link: "https://creditnama.com/blog/smart-investment",
    },
    {
      id: 24,
      title: "The Great Housing Divide: Millennials vs. Gen Z",
      content: `In the ever-evolving landscape of personal finance and lifestyle choices...`,
      image: Blog24,
      link: "https://creditnama.com/blog/millennials-vs-genz",
    },
    {
      id: 25,
      title: "Mastering Your Credit Score: The Key to Financial Success",
      content: `Remember that pivotal moment on Shark Tank when...`,
      image: Blog25,
      link: "https://creditnama.com/blog/mastering-your-credit-score",
    },
    {
      id: 26,
      title: "How Union Budget 2024 Reshapes Property Sales and Taxes",
      content: `The Union Budget 2024 has ushered in a new era for property...`,
      image: Blog26,
      link: "https://creditnama.com/blog/union-budget-2024",
    },
    {
      id: 27,
      title:
        "Fixed or Reducing Balance Interest Rate: Which Option Suits You Best?",
      content: `Choosing the right interest rate for your loan can feel like solving a complex...`,
      image: Blog27,
      link: "https://creditnama.com/blog/fixed-vs-reducing-interest-rate",
    },
    {
      id: 28,
      title: "The Rise of Spam Calls!",
      content: `These days, in the digital age, spam calls are a common annoyance. They...`,
      image: Blog28,
      link: "https://creditnama.com/blog/spam-calls",
    },
    {
      id: 29,
      title: "Unlock the Festive Season: A Guide to Smart Property Purchases",
      content: `The festive season is a time of joy, celebration, and, interestingly enough...`,
      image: Blog29,
      link: "https://creditnama.com/blog/property-purchase-during-festive-season",
    },
    {
      id: 30,
      title: "How to Secure the Best Home Loan Percent for Your Dream House",
      content: `Buying your dream house is one of the most exciting milestones in life.`,
      image: Blog30,
      link: "https://creditnama.com/blog/secure-the-best-home-loan",
    },
    {
      id: 31,
      title: "Best Home Loans for NRIs",
      content: `For Non-Resident Indians (NRIs), buying property in India can be both...`,
      image: Blog31,
      link: "https://creditnama.com/blog/best-home-loans-for-nri",
    },
    {
      id: 32,
      title: "Home Loan Interest Rates in July",
      content: `When it comes to securing a home loan, one of the most crucial factors to consider....`,
      image: Blog32,
      link: "https://creditnama.com/blog/july-2024",
    },
    {
      id: 33,
      title: "Common Home Loan Mistakes and How to Avoid Them?",
      content: `Securing a home loan is crucial for homeownership, but the process can be tricky.`,
      image: Blogm,
      link: "https://creditnama.com/blog/common-home-loan-mistakes",
    },
    {
      id: 34,
      title: "Tax Benefits for Homeowners",
      content: `Owning a home in India comes with several tax benefits that can significantly...`,
      image: Blog33,
      link: "https://creditnama.com/blog/tax-benefits-for-homeowners",
    },
    {
      id: 35,
      title: "Hidden Charges in Home Loans: What to Watch Out For?",
      content: `Securing a home loan is a significant financial decision that...`,
      image: Blog34,
      link: "https://creditnama.com/blog/hidden-charges-in-home-loans",
    },
    {
      id: 36,
      title: "Top 10 Benefits of Getting a Home Loan Through Multiple Bank?",
      content: `Explore the top 10 benefits of securing a home loan...`,
      image: Blog35,
      link: "https://creditnama.com/blog/benefits-of-comparing-home-loans",
    },
    {
      id: 37,
      title: "How to Improve Your Eligibility for a Home Loan",
      content: `Securing a home loan can be a significant step towards...`,
      image: Blog36,
      link: "https://creditnama.com/blog/how-to-improve-your-eligibility-for-a-home-loan",
    },
    {
      id: 38,
      title: "Home Loan Pre-Approval: Benefits and How to Get It in India",
      content: `Securing a home loan can be a significant step towards...`,
      image: Blog37,
      link: "https://creditnama.com/blog/home-loan-pre-approval",
    },
    {
      id: 39,
      title: "Refinancing Your Home Loan: When and Why You Should Consider It",
      content: `Refinancing a home loan can be a strategic move...`,
      image: Blog38,
      link: "https://creditnama.com/blog/refinancing-your-home-loan",
    },
  ];
  // console.log("Length = ", blogs.length);
  const repeatedBlogs = [];
  for (let i = 0; i < 5; i++) {
    repeatedBlogs.push(
      ...blogs.map((blog) => ({ ...blog, id: blog.id + i * blogs.length }))
    );
  }

  return (
    <>
      <div>
        <div className="CN-BlogTest-Container">
          {repeatedBlogs.map((blog) => {
            return (
              <div
                key={blog.id}
                className="CN-BlogTest-Card"
                style={{ backgroundImage: `url(${blog.image})` }}
              >
                <div className="CN-BlogTest-Card-Footer">
                  <div>
                    <h3>
                      {blog.title}{" "}
                      <span className="CN-BlogTest-Para">{blog.content}</span>
                    </h3>
                  </div>
                  <div className="CN-BlogTest-Card-FooterLink">
                    <a href={blog.link}>Learn more</a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BlogPart;
