import React, { useEffect, useState } from "react";
import "./Calculators.css";

const EligibilityCal = () => {
  const [salary, setSalary] = useState(100000);
  const [emi, setEmi] = useState(0);
  const [roi, setRoi] = useState(9);
  const [tenure, setTenure] = useState(20);
  const [loanEligibility, setLoanEligibility] = useState(0);
  const [messageDiv, setMessageDiv] = useState(false);

  useEffect(() => {
    // FOIR is set at 60%
    const foir = 0.55;
    // Calculate the maximum EMI that can be proposed
    const maxEmi = salary * foir - emi;

    // Interest rate per month
    const monthlyInterestRate = roi / 12 / 100;

    // Total number of monthly payments
    const numberOfPayments = tenure * 12;

    // Calculate the present value (loan amount) using the formula for PV of an annuity
    if (monthlyInterestRate > 0) {
      const maxLoanAmount =
        maxEmi *
        ((1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments)) /
          monthlyInterestRate);
      setLoanEligibility(maxLoanAmount);
    } else {
      setLoanEligibility(maxEmi * numberOfPayments);
    }
  }, [salary, emi, roi, tenure]);

  useEffect(() => {
    if (loanEligibility < 0) {
      setMessageDiv(true);
    } else {
      setMessageDiv(false);
    }
  }, [loanEligibility]);

  const handleGotIt = () => {
    setMessageDiv(false);
    setSalary(100000);
    setEmi(0);
  };

  return (
    <div className="BCN-Calculator-Page">
      {messageDiv ? (
        <>
          <div className="BCN-Calculator-Message-Heading">
            <p>
              We are unable to show you any offers currently as your current
              EMIs amount is very high. You can go back and modify your inputs
              if you wish to recalculate your eligibility.
            </p>
            <button onClick={handleGotIt}>Got it!</button>
          </div>
        </>
      ) : (
        <>
          <h2>Eligibility Calculator</h2>
          <p>
            Get the peace of mind by knowing all the details about your loan
            using Creditnama Loan Eligibility Calculator
          </p>
          <div className="BCN-Calculator-Container">
            <div className="BCN-Calculator-Container-Part1">
              <label>
                Monthly Salary
                <span className="BCN-Calculator-Input-Symbol">₹</span>
                <input
                  type="tel"
                  value={salary}
                  onChange={(e) => setSalary(Number(e.target.value))}
                  maxLength={10}
                  pattern="\d*"
                />
              </label>
              <input
                type="range"
                className="BCN-Calculator-Range"
                min="10000"
                max="1000000"
                step="10000"
                value={salary}
                onChange={(e) => setSalary(Number(e.target.value))}
              />

              <label>
                Interest Rate (% P.A.)
                <input
                  type="tel"
                  placeholder="Interest rate"
                  value={roi}
                  onChange={(e) => setRoi(e.target.value)}
                  maxLength={5}
                  style={{ paddingLeft: "10px" }}
                />
              </label>
              <input
                type="range"
                className="BCN-Calculator-Range"
                min="1"
                max="20"
                step="0.25"
                value={roi}
                onChange={(e) => setRoi(Number(e.target.value))}
              />

              <label>
                Tenure (Years)
                <input
                  type="tel"
                  value={tenure}
                  onChange={(e) => setTenure(Number(e.target.value))}
                  maxLength={2}
                  style={{ paddingLeft: "10px" }}
                  pattern="\d*"
                />
              </label>
              <input
                type="range"
                className="BCN-Calculator-Range"
                min="1"
                max="30"
                step="1"
                value={tenure}
                onChange={(e) => setTenure(Number(e.target.value))}
              />

              <label>
                Existing EMIs
                <span className="BCN-Calculator-Input-Symbol2">₹</span>
                <input
                  type="tel"
                  value={emi}
                  onChange={(e) => setEmi(Number(e.target.value))}
                  maxLength={10}
                  pattern="\d*"
                />
              </label>
              <input
                type="range"
                className="BCN-Calculator-Range"
                min="0"
                max="500000"
                step="1000"
                value={emi}
                onChange={(e) => setEmi(Number(e.target.value))}
              />
            </div>
            <div className="BCN-Calculator-Container-Part2">
              <p>
                <b>You are Eligible for a Loan Amount up to</b>
                <br />₹{" "}
                <span className="BCN-Calculator-Result-Amt-Imp">
                  {loanEligibility.toLocaleString("en-IN", {
                    currency: "INR",
                    maximumFractionDigits: 0,
                  }) === "NaN"
                    ? 0
                    : loanEligibility.toLocaleString("en-IN", {
                        currency: "INR",
                        maximumFractionDigits: 0,
                      })}
                </span>
              </p>
              <p>
                <b>Your Loan EMI will be</b> <br />₹{" "}
                <span className="BCN-Calculator-Result-Amt">
                  {(
                    (loanEligibility * (roi / 12 / 100)) /
                    (1 - Math.pow(1 + roi / 12 / 100, -tenure * 12))
                  ).toLocaleString("en-IN", {
                    currency: "INR",
                    maximumFractionDigits: 0,
                  }) === "NaN"
                    ? 0
                    : (
                        (loanEligibility * (roi / 12 / 100)) /
                        (1 - Math.pow(1 + roi / 12 / 100, -tenure * 12))
                      ).toLocaleString("en-IN", {
                        currency: "INR",
                        maximumFractionDigits: 0,
                      })}
                </span>
                /month
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EligibilityCal;
