import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./payslipstandby.css";
import Header from "../User-Components/Front-Page-Components/Header/Header";
import StatusBar from "../Status-Bar/StatusBar";
import StepsFooter from "../User-Components/Front-Page-Components/Footer/StepsFooter";
import OfferImg from "../Images/Page-Img/Pay Slip.png";

function PayslipStandby() {
  const history = useNavigate(); // Initialize the useHistory hook

  useEffect(() => {
    // Use the useEffect hook to trigger the redirect after 5 seconds
    const redirectTimeout = setTimeout(() => {
      history("/finaloffer"); // Redirect to "/offer" after 5 seconds
    }, 5000);

    // Clean up the timeout if the component unmounts
    return () => clearTimeout(redirectTimeout);
  }, [history]); // Include history as a dependency to ensure it's in the scope

  return (
    <>
      <Header />

      <div className="CN2-PayslipStandby">
        <div className="CN2-PayslipStandby-Container">
          <div className="CN2-PayslipStandby-Main-Div">
            <div className="CN2-PayslipStandby-Main-Div-Left">
              <img
                src={OfferImg}
                alt="Profile with pan and aadhaar"
                className="CN2-PayslipStandby-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-success)"
                KycColor="var(--color-success)"
                IncomeColor="white"
                OffersColor="var(--color-yellow)"
              />
            </div>
            <div className="CN2-PayslipStandby-Main-Div-Right">
              <h1>
                We’re extracting your information,
                <br /> Please stand by
              </h1>
              <div>
                <div className="CN2-Payslip-Loader">
                  <div className="book-wrapper">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="white"
                      viewBox="0 0 126 75"
                      className="book"
                    >
                      <rect
                        strokeWidth="5"
                        stroke="var(--color-darkgreen)"
                        rx="7.5"
                        height="70"
                        width="121"
                        y="2.5"
                        x="2.5"
                      ></rect>
                      <line
                        strokeWidth="5"
                        stroke="var(--color-darkgreen)"
                        y2="75"
                        x2="63.5"
                        x1="63.5"
                      ></line>
                      <path
                        strokeLinecap="round"
                        strokeWidth="4"
                        stroke="var(--color-darkgreen)"
                        d="M25 20H50"
                      ></path>
                      <path
                        strokeLinecap="round"
                        strokeWidth="4"
                        stroke="var(--color-darkgreen)"
                        d="M101 20H76"
                      ></path>
                      <path
                        strokeLinecap="round"
                        strokeWidth="4"
                        stroke="var(--color-darkgreen)"
                        d="M16 30L50 30"
                      ></path>
                      <path
                        strokeLinecap="round"
                        strokeWidth="4"
                        stroke="var(--color-darkgreen)"
                        d="M110 30L76 30"
                      ></path>
                    </svg>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#ffffff74"
                      viewBox="0 0 65 75"
                      className="book-page"
                    >
                      <path
                        strokeLinecap="round"
                        strokeWidth="4"
                        stroke="var(--color-darkgreen)"
                        d="M40 20H15"
                      ></path>
                      <path
                        strokeLinecap="round"
                        strokeWidth="4"
                        stroke="var(--color-darkgreen)"
                        d="M49 30L15 30"
                      ></path>
                      <path
                        strokeWidth="5"
                        stroke="var(--color-darkgreen)"
                        d="M2.5 2.5H55C59.1421 2.5 62.5 5.85786 62.5 10V65C62.5 69.1421 59.1421 72.5 55 72.5H2.5V2.5Z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default PayslipStandby;
