import React, { useEffect, useState } from "react";
import Header from "../Front-Page-Components/Header/Header";
import StepsFooter from "../Front-Page-Components/Footer/StepsFooter";
import Animation1 from "../../Animation/animation";
import StatusBar from "../../Status-Bar/StatusBar";
import Ask from "../../Images/Page-Img/Loan Requirements.png";
import Checktest from "../../User-Components/Ask/Checktest";
import SalariedImage from "../../Images/Assets/Salaried.png";
import SelfEmployedImage from "../../Images/Assets/Self-Employed.png";
import ProfessionalImage from "../../Images/Assets/otherdegree.png";
import ExistingFile from "../../Images/Assets/ExistingFile.png";
import NewFile from "../../Images/Assets/NewFile.png";
import HLImage from "../../Images/Assets/HL.png";
import LAPImage from "../../Images/Assets/LAP.png";
import UnsecuredImage from "../../Images/Assets/UnsecuredLoan.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import serverIP from "../../config/config";
import "./CNAsk.css";
import FAQ from "../FAQs/FAQ";
// import Animation1 from "../../Animation/animation";

function Ask1() {
  const [isLoading, setIsLoading] = useState(false);
  const [loan, setLoan] = useState("");
  const [amt, setAmt] = useState(0);
  const [tenure, setTenure] = useState("");
  const [loantype, setLoanType] = useState("");
  const [prof, setProf] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [err, setErr] = useState("");
  const [dataForCRM, setDataForCRM] = useState([]);

  const handleProfChange = (e) => {
    setProf(e.target.value);
    setErr("");
    setLoan("");
  };

  const handleLoanChange = (e) => {
    if (prof === "") {
      setErr("Please select your profession first");
    } else {
      setLoanType(e.target.value);
    }
  };

  const handlePropertyType = (e) => {
    setPropertyType(e.target.value);
  };
  const handleLoanTypeChange = (e) => {
    setLoan(e.target.value);
    setTenure("");
    setAmt("");
    setPropertyType("");
    console.log(loan);
  };

  const range1Styles = {
    WebkitAppearance: "none",
  };
  const range2Styles = {
    WebkitAppearance: "none",
  };

  const handlePrincipalChange = (e) => {
    setErr("");
    if (loan === "") {
      setErr("First Please select a loan type");
      return;
    }
    const value =
      e.target.type === "range"
        ? parseFloat(e.target.value)
        : parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
    setAmt(value);
  };

  const handleTenureChange = (event) => {
    setErr("");
    if (loan === "") {
      setErr("First Please select a loan type");
      return;
    }
    setTenure(parseInt(event.target.value));
  };
  // Load data from localStorage on component mount
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("AskFormData")) || {};
    setProf(storedData.prof || "");
    setLoanType(storedData.loantype || "");
    setLoan(storedData.loan || "");

    setAmt(storedData.amt || "");
    setTenure(storedData.tenure || "");
    setPropertyType(storedData.propertyType || "");
  }, []);

  const history = useNavigate();

  // Define the handleSubmit function that handles form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Save form data to localStorage on successful submission
    localStorage.setItem(
      "AskFormData",
      JSON.stringify({
        prof,
        loantype,
        loan,
        amt,
        tenure,
        propertyType,
      })
    );
    if (prof === "") {
      setErr("Please select Profession");
      return;
    }

    if (loantype === "") {
      setErr("Please select loan");
      return;
    }

    if (loan === "") {
      setErr("Please select loan type");
      return;
    }
    if (loan === "property purchase" && propertyType === "") {
      setErr("Please select property type");
      return;
    }
    if (loan === "loan against property" && propertyType === "") {
      setErr("Please select property type");
      return;
    }
    if (amt === "") {
      setErr("Please select amount");
      return;
    }
    if (amt > "500000000") {
      setErr("Amount must be between given range ");
      return;
    }
    if (amt === "0") {
      setErr("Amount must be between given range ");
      return;
    }
    if (loan === "personal loan") {
      const minAmount = 100000;
      const maxAmount = 10000000;

      if (amt < minAmount || amt > maxAmount) {
        setErr(`Amount must be between given range `);
        return;
      }
    }
    if (loan === "property purchase") {
      const minAmount = 2000000;
      const maxAmount = 500000000;

      if (amt < minAmount || amt > maxAmount) {
        setErr(`Amount must be between given range `);
        return;
      }
    }
    if (loan === "loan against property") {
      const minAmount = 2000000;
      const maxAmount = 500000000;

      if (amt < minAmount || amt > maxAmount) {
        setErr(`Amount must be between given range `);
        return;
      }
    }
    if (loan === "business loan") {
      const minAmount = 500000;
      const maxAmount = 50000000;

      if (amt < minAmount || amt > maxAmount) {
        setErr(`Amount must be between given range `);
        return;
      }
    }
    if (loan === "professional loan") {
      const minAmount = 500000;
      const maxAmount = 50000000;

      if (amt < minAmount || amt > maxAmount) {
        setErr(`Amount must be between given range `);
        return;
      }
    }
    if (tenure === "") {
      setErr("Please select tenure");
      return;
    }

    if (loan === "personal loan") {
      const minTenure = 1;
      const maxTenure = 7;

      if (tenure < minTenure || tenure > maxTenure) {
        setErr(`Tenure must be between given range `);
        return;
      }
    }

    setIsLoading(true);
    try {
      // Get the access token from localStorage
      const token = localStorage.getItem("accessToken");
      const userId = localStorage.getItem("userId");

      // Make sure the token exists before proceeding
      if (!token) {
        console.error("Access token not found");
        return;
      }

      // Set the Authorization header with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Send a POST request to the server with the selected loan type, amount, and tenure
      const response = await axios.post(
        `${serverIP}/ask/ask1/${userId}`,
        {
          initial_ask_value: amt,
          initial_ask_purpose: loan,
          initial_ask_tenure: tenure,
          initial_ask_type: loantype,
          initial_ask_theme: propertyType,
          profession: prof,
        },
        config
      );

      localStorage.setItem("askId", response.data.id);
      localStorage.setItem("initial_ask_purpose", loan);

      if (response.status === 200 || response.status <= 300) {
        console.log("Ask 1 Values Updated");
        handleTeleCRMUpadte();
        history("/confirmation");
        setIsLoading(false);
      } else {
        console.log("Error in Post");
        setErr("Error in Post");
      }
    } catch (error) {
      console.error("Error inserting data:", error);
      console.log("Error in Post");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const config = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        };

        const response = await fetch(
          `${serverIP}/user/getNameNumber/${userId}`,
          config
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setDataForCRM(data);
        // console.log(data.name);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTeleCRMUpadte = async () => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer 2c438757-dc99-4297-b188-b06bba3341651714828905449:e51d6e0b-9961-45b9-bcf0-bae006ffa758",
      },
      body: JSON.stringify({
        fields: {
          phone: dataForCRM.phone || "",
          "loan product": loan || "",
          "loan amount": amt || "",
        },
        actions: [
          {
            type: "SYSTEM_NOTE",
            text: "Ask ID created",
          },
        ],
      }),
    };

    try {
      const response = await fetch(
        `https://api.telecrm.in/enterprise/66361419545ce40e83b1154b/autoupdatelead`,
        config
      );
      if (response.status >= 200 && response.status < 300) {
        console.log("Lead updated successfully");
      } else {
        console.error("Lead update failed. Error", response.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header />

      <div className="CN2-Ask">
        <div className="CN2-Ask-Container">
          <div className="CN2-Ask-Main-Div">
            <div className="CN2-Ask-Main-Div-Left">
              <img
                src={Ask}
                alt="Profile with pan and aadhaar"
                className="CN2-Ask-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-yellow)"
                KycColor="white"
                IncomeColor="white"
                OffersColor="white"
              />
            </div>
            <div className="CN2-Ask-Main-Div-Right">
              <h1>Loan Requirements</h1>
              <div className="CN2-Ask-Faq-Div">
                <FAQ PageName="ask1" />
              </div>
              <div className="CN2-Ask-Button-Div">
                <button className="CN2-Create-Ask-Button">
                  Create new ask
                </button>
                <button
                  className="CN2-Privious-Ask-Button"
                  onClick={() => history("/previousasks")}
                >
                  Explore past asks
                </button>
              </div>
              {err && <div className="CN2-Ask-Error">{err}</div>}
              <form onSubmit={handleSubmit}>
                {isLoading ? (
                  <>
                    <Animation1 />
                  </>
                ) : (
                  <>
                    <label className="CN2-Ask1-CheckTest-Heading">
                      Choose Profession
                    </label>
                    <div className="CN2-Ask1-CheckTest-Div">
                      <Checktest
                        name="Salaried"
                        src={SalariedImage}
                        width="40px"
                        onChange={handleProfChange}
                        value="salaried"
                        checked={prof === "salaried"}
                        backgroundColor={prof === "salaried" && "#dcf7e4"}
                        fontWeight={prof === "salaried" && "bolder"}
                      />
                      <Checktest
                        name="Self Employed"
                        src={SelfEmployedImage}
                        width="30px"
                        value="self employed"
                        onChange={handleProfChange}
                        checked={prof === "self employed"}
                        backgroundColor={prof === "self employed" && "#dcf7e4"}
                        fontWeight={prof === "self employed" && "bolder"}
                      />
                      <Checktest
                        name="Specialist Professional"
                        src={ProfessionalImage}
                        width="30px"
                        value="specialist professional"
                        onChange={handleProfChange}
                        checked={prof === "specialist professional"}
                        backgroundColor={
                          prof === "specialist professional" && "#dcf7e4"
                        }
                        fontWeight={
                          prof === "specialist professional" && "bolder"
                        }
                      />
                    </div>
                    <label className="CN2-Ask1-CheckTest-Heading">
                      Choose loan
                    </label>
                    <div className="CN2-Ask1-CheckTest-Div">
                      <Checktest
                        name={
                          <div>
                            New
                            <br />
                            Loan
                          </div>
                        }
                        src={NewFile}
                        width="30px"
                        value="new"
                        onChange={handleLoanChange}
                        checked={loantype === "new"}
                        backgroundColor={loantype === "new" && "#dcf7e4"}
                        fontWeight={loantype === "new" && "bolder"}
                      />
                      <Checktest
                        name="Balance Transfer"
                        src={ExistingFile}
                        width="25px"
                        value="existing"
                        onChange={handleLoanChange}
                        checked={loantype === "existing"}
                        backgroundColor={loantype === "existing" && "#dcf7e4"}
                        fontWeight={loantype === "existing" && "bolder"}
                      />
                    </div>
                    {loantype ? (
                      <>
                        <label className="CN2-Ask1-CheckTest-Heading">
                          Choose loan type
                        </label>
                        <div className="CN2-Ask1-CheckTest-Div">
                          <Checktest
                            name="Property Purchase"
                            src={HLImage}
                            width="35px"
                            value="property purchase"
                            onChange={handleLoanTypeChange}
                            checked={loan === "property purchase"}
                            backgroundColor={
                              loan === "property purchase" && "#dcf7e4"
                            }
                            fontWeight={
                              loan === "property purchase" && "bolder"
                            }
                          />
                          <Checktest
                            name="Loan Against Property"
                            src={LAPImage}
                            width="35px"
                            value="loan against property"
                            onChange={handleLoanTypeChange}
                            checked={loan === "loan against property"}
                            backgroundColor={
                              loan === "loan against property" && "#dcf7e4"
                            }
                            fontWeight={
                              loan === "loan against property" && "bolder"
                            }
                          />
                          <Checktest
                            name={
                              (prof === "salaried" && "personal Loan") ||
                              (prof === "self employed" && "business Loan") ||
                              (prof === "specialist professional" &&
                                "professional loan")
                            }
                            src={UnsecuredImage}
                            width="40px"
                            value={
                              (prof === "salaried" && "personal loan") ||
                              (prof === "self employed" && "business loan") ||
                              (prof === "specialist professional" &&
                                "professional loan")
                            }
                            checked={
                              loan === "personal loan" ||
                              loan === "business loan" ||
                              loan === "professional loan"
                            }
                            onChange={handleLoanTypeChange}
                            backgroundColor={
                              (loan === "personal loan" ||
                                loan === "business loan" ||
                                loan === "professional loan") &&
                              "#dcf7e4"
                            }
                            fontWeight={
                              (loan === "personal loan" ||
                                loan === "business loan" ||
                                loan === "professional loan") &&
                              "bolder"
                            }
                          />
                        </div>
                      </>
                    ) : (
                      <div>
                        <button
                          disabled={true}
                          className="CN2-Ask-Button"
                          style={{
                            backgroundColor: "lightgray",
                            color: "gray",
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    )}
                    {loantype ? (
                      <>
                        {!loan ? (
                          <>
                            <div>
                              <button
                                disabled={true}
                                className="CN2-Ask-Button"
                                style={{
                                  backgroundColor: "lightgray",
                                  color: "gray",
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {loan === "property purchase" ||
                    loan === "loan against property" ? (
                      <>
                        <div className="CN2-Ask1-CheckTest-Div">
                          <div className="CN2-Ask1-Checkbox-Div">
                            <input
                              type="checkbox"
                              value="residential"
                              onChange={handlePropertyType}
                              checked={propertyType === "residential"}
                              className="CN2-Ask1-CheckTest-Checkbox"
                            />
                            Residential
                          </div>
                          <div className="CN2-Ask1-Checkbox-Div">
                            <input
                              value="commercial"
                              type="checkbox"
                              onChange={handlePropertyType}
                              checked={propertyType === "commercial"}
                              className="CN2-Ask1-CheckTest-Checkbox"
                            />
                            Commercial
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {loan ? (
                      <>
                        <div className="CN2-Ask-Amount-Div">
                          <div>
                            <label>Loan Amount</label>

                            <input
                              value={
                                isNaN(amt)
                                  ? "0"
                                  : amt.toLocaleString("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                      maximumFractionDigits: "0",
                                    })
                              }
                              onChange={handlePrincipalChange}
                              id="principal"
                              className="CN2-Ask-Input"
                              type="tel"
                              min={
                                (loan === "personal loan" && 100000) ||
                                (loan === "business loan" && 500000) ||
                                (loan === "professional loan" && 500000) ||
                                (loan === "property purchase" && 2000000) ||
                                (loan === "loan against property" && 2000000)
                              }
                              max={
                                (loan === "personal loan" && 10000000) ||
                                (loan === "business loan" && 50000000) ||
                                (loan === "professional loan" && 20000000) ||
                                (loan === "property purchase" && 500000000) ||
                                (loan === "loan against property" && 500000000)
                              }
                              step={
                                (loan === "personal loan" && 100000) ||
                                (loan === "business loan" && 500000) ||
                                (loan === "professional loan" && 500000) ||
                                (loan === "property purchase" && 1000000) ||
                                (loan === "loan against property" && 1000000)
                              }
                            />

                            {amt < 10000000 && (
                              <>
                                {amt >= 100000 ? (
                                  <>₹{(amt / 100000).toFixed(1)} lacs</>
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                            {amt >= 10000000 ? (
                              <>₹{(amt / 10000000).toFixed(1)} cr</>
                            ) : (
                              ""
                            )}
                          </div>

                          <div>
                            <input
                              value={amt}
                              type="range"
                              min={
                                (loan === "personal loan" && 100000) ||
                                (loan === "business loan" && 500000) ||
                                (loan === "professional loan" && 500000) ||
                                (loan === "property purchase" && 2000000) ||
                                (loan === "loan against property" && 2000000)
                              }
                              max={
                                (loan === "personal loan" && 10000000) ||
                                (loan === "business loan" && 50000000) ||
                                (loan === "professional loan" && 20000000) ||
                                (loan === "property purchase" && 500000000) ||
                                (loan === "loan against property" && 500000000)
                              }
                              step={
                                (loan === "personal loan" && 100000) ||
                                (loan === "business loan" && 500000) ||
                                (loan === "professional loan" && 500000) ||
                                (loan === "property purchase" && 1000000) ||
                                (loan === "loan against property" && 1000000)
                              }
                              id="customRange1"
                              onChange={handlePrincipalChange}
                              className="CN2-Ask-Slider"
                              style={range1Styles}
                            />
                          </div>
                        </div>
                        <div className="CN2-Ask-Amount-Div">
                          <label>Tenure</label>
                          <div>
                            {tenure ? (
                              <>
                                {isNaN(tenure) ? (
                                  "Scroll to select"
                                ) : (
                                  <> {tenure * 12} Months</>
                                )}
                              </>
                            ) : (
                              "Scroll to select"
                            )}
                          </div>
                          <div>
                            <input
                              value={tenure}
                              type="range"
                              min={
                                (loan === "personal loan" && 1) ||
                                (loan === "business loan" && 1) ||
                                (loan === "professional loan" && 1) ||
                                (loan === "loan against property" && 5) ||
                                (loan === "property purchase" && 5)
                              }
                              max={
                                (loan === "personal loan" && 7) ||
                                (loan === "business loan" && 7) ||
                                (loan === "professional loan" && 7) ||
                                (loan === "loan against property" && 25) ||
                                (loan === "property purchase" && 25)
                              }
                              id="customRange2"
                              onChange={handleTenureChange}
                              style={range2Styles}
                              className="CN2-Ask-Slider"
                            />
                          </div>
                        </div>
                        <div>
                          <button className="CN2-Ask-Button">Submit</button>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default Ask1;
