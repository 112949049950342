import React from "react";
import "./aboutus.css";
import Footer from "./Footer/Footer";
import Header from "./header";

const LendingTeam = () => {
  return (
    <>
      <div className="Aboutus-page">
        <Header />
        <div className="Aboutus-content" style={{ top: "80px" }}>
          <div className="job-opening-card">
            <h1
              style={{
                color: "darkgreen",
                fontSize: "20px",
                marginTop: "30px",
              }}
            >
              <b>
                Founding Team Member – Lending Operations (Potential future COO)
              </b>{" "}
            </h1>
            <p>
              <strong>Location:</strong> Gurgaon
            </p>
            <p>
              <strong>Type:</strong> Full-time, In-person
            </p>
            <p>
              Creditnama is an early-stage digital lending company dedicated to
              real-time distribution of financial products such as loans and
              credit cards to individuals and SMEs. Our team and mentors
              comprise IITians and CA/CS/MBAs.
            </p>

            <p>
              We are seeking a highly motivated and versatile visionary founding
              team member to join our team and spearhead the development of our
              lending and loan processing capabilities, ensuring tech-driven
              operational excellence.
            </p>

            <h6>
              <b>Responsibilities:</b>
            </h6>
            <p>
              As a Founding team member – Lending Operations at Creditnama, you
              will be entrusted with a variety of responsibilities:
            </p>
            <ul>
              <li>
                <b style={{ color: "gray" }}>Building team: </b>Build and
                nurture a high-performance team to enhance our lending and loan
                processing capabilities to deliver exceptional customer
                experience.
              </li>
              <li>
                <b style={{ color: "gray" }}>Lead Management: </b>Proactively
                manage our leads, ensuring swift follow-ups and successful loan
                disbursements for qualified clients.
              </li>
              <li>
                <b style={{ color: "gray" }}>Strategic Leadership:</b> Serve as
                a key member of the initial operations team, laying the
                groundwork for processes and systems that will scale as the
                company grows.
              </li>
              <li>
                <b style={{ color: "gray" }}>Banking and NBFC Partnerships: </b>
                Build and maintain strategic relationships with banks and NBFCs.
                Understanding the macro patterns to develop innovative
                offerings.
              </li>
              <li>
                <b style={{ color: "gray" }}>Data & Reporting: </b>Leverage
                analytics tools to glean insights and compile reports, aiding in
                informed decision-making.
              </li>
              <li>
                <b style={{ color: "gray" }}>Operational Excellence:</b>{" "}
                Implement and optimize operational processes to enhance
                efficiency and productivity within the organization.
              </li>
              <li>
                <b style={{ color: "gray" }}>Collaboration:</b> Work closely
                with cross-functional teams, including marketing, data, and
                technology, to ensure seamless coordination and alignment of
                efforts.
              </li>
            </ul>

            <h6>
              <b>Qualifications:</b>
            </h6>
            <ul>
              <li>
                <b style={{ color: "gray" }}>Lending Expertise:</b> 8+ years of
                progressive experience in working in, building, and scaling
                lending operations in banks or NBFCs. Illustrative titles:
                Senior Sales managers, Area sales managers
              </li>
              <li>
                <b style={{ color: "gray" }}>Strategic Vision: </b>Ability to
                think strategically for the digital operations of a dynamic,
                growing startup.
              </li>
              <li>
                <b style={{ color: "gray" }}>Funnel Management: </b>Track record
                of successful loan disbursements and collaboration with banks,
                and proficient handling of customer applications.
              </li>
              <li>
                <b style={{ color: "gray" }}>Data Proficiency:</b> Strong
                proficiency in data analysis using tools like Excel.
              </li>
              <li>
                <b style={{ color: "gray" }}>Travel: </b>Expected domestic
                travel 10-15% time.
              </li>
            </ul>
            <div style={{ display: "flex", width: "500px" }}>
              <div>
                <h6>
                  <b>Why join us?</b>
                </h6>
              </div>
              <div
                style={{
                  fontSize: "12px",
                  marginTop: "3px",
                  marginLeft: "3px",
                }}
              >
                {" "}
                <u>Your career’s best move awaits you.</u>
              </div>
            </div>
            <p>
              At Creditnama, we believe in empowering each one of us to do our
              career’s best work here. We believe that everything around us was
              built by people no smarter than us. And that together, by being
              candid and self-aware, we can solve customer’s pain and feel proud
              of what we build.
            </p>

            <h6>
              <b>How to Apply:</b>
            </h6>
            <p>
              If you are interested in this role, please send your resume to
              manik@creditnama.com with the subject line "Lending Operations:
              Your Full Name." Additionally, include the following details in
              your email:
            </p>
            <ul>
              <li>Current Total Compensation (Fixed + Variable):</li>
              <li>Expected Total Compensation:</li>
              <li>Notice Period:</li>
              <li>Reason for Seeking a Change:</li>
              <li>
                How many years of loan processing in secured lending? (HL/LAP)
              </li>
              <li>
                How many years of loan processing in unsecured lending? (PL, BL)
              </li>
              <li>Total book of business (Rs Cr) developed from scratch?</li>
              <li>Largest team size built from scratch?</li>
            </ul>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
export default LendingTeam;
