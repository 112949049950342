import React, { useState, useEffect } from "react";
import "./CompareOffers.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import AxisBank from "../../../Images/Bank-Logos/axis.png";
import HdfcBank from "../../../Images/Bank-Logos/hdfc.png";
import IciciBank from "../../../Images/Bank-Logos/icici.png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TuneIcon from "@mui/icons-material/Tune";
import { useNavigate } from "react-router-dom";

const CompareOffer = () => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [sortByOption, setSortByOption] = useState("");
  const [selectedLenderType, setSelectedLenderType] = useState([]);
  const [selectedProfessionType, setSelectedProfessionType] = useState([]);
  const [selectedFc, setSelectedFc] = useState([]);
  const [selectedPrepayment, setSelectedPrepayment] = useState([]);
  const [selectedLockin, setSelectedLockin] = useState([]);
  const [selectedCibil, setSelectedCibil] = useState([]);

  const [mobile, setMobile] = useState("");

  const Data = [
    {
      id: "1",
      bankName: "Axis Bank",
      Img: AxisBank,
      interest: 8.0,
      amount: 10000000,
      tenure: 20,
      pf: 2.5,
      insurance: 4,
      fc: "yes",
      lockin: 24,
      prepayment: "yes",
      btcharges: 6,
      tat: 10,
      type: "bank",
      profession: "salaried",
      text: "Axis bank offer ___amount in ____ time for _____ purpose",
    },
    {
      id: "2",
      bankName: "Hdfc Bank",
      Img: HdfcBank,
      interest: 9.0,
      amount: 20000000,
      tenure: 25,
      pf: 2.5,
      insurance: 4,
      fc: "no",
      lockin: 6,
      prepayment: "no",
      btcharges: 6,
      tat: 10,
      type: "bank",
      profession: "salaried",

      text: "Hdfc bank offer ___amount in ____ time for _____ purpose",
    },
    {
      id: "3",
      bankName: "Icici Bank",
      Img: IciciBank,
      interest: 9.75,
      amount: 15000000,
      tenure: 30,
      pf: 2.5,
      insurance: 4,
      fc: "yes",
      lockin: 12,
      prepayment: "no",
      btcharges: 6,
      tat: 10,
      type: "bank",
      profession: "self employed",

      text: "Icici bank offer ___amount in ____ time for _____ purpose",
    },
    {
      id: "4",
      bankName: "Tata capital",
      Img: AxisBank,
      interest: 8.0,
      amount: 10000000,
      tenure: 20,
      pf: 2.5,
      insurance: 4,
      fc: "no",
      lockin: 24,
      prepayment: "yes",
      btcharges: 6,
      tat: 10,
      type: "nbfc",
      profession: "self employed",

      text: "Axis bank offer ___amount in ____ time for _____ purpose",
    },
    {
      id: "5",
      bankName: "Bajaj Finserv",
      Img: HdfcBank,
      interest: 9.0,
      amount: 20000000,
      tenure: 25,
      pf: 2.5,
      insurance: 4,
      fc: "yes",
      lockin: 12,
      prepayment: "no",
      btcharges: 6,
      tat: 10,
      type: "nbfc",
      profession: "professional",
      text: "Hdfc bank offer ___amount in ____ time for _____ purpose",
    },
    {
      id: "6",
      bankName: "SCB Bank",
      Img: IciciBank,
      interest: 9.75,
      amount: 15000000,
      tenure: 30,
      pf: 2.5,
      insurance: 4,
      fc: "yes",
      lockin: 6,
      prepayment: "yes",
      btcharges: 6,
      tat: 10,
      type: "bank",
      profession: "professional",
      text: "Icici bank offer ___amount in ____ time for _____ purpose",
    },
  ];

  useEffect(() => {
    sortData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortByOption]);

  const sortData = () => {
    const sortedData = [...Data];
    if (sortByOption === "interest") {
      sortedData.sort((a, b) => a.interest - b.interest);
    } else if (sortByOption === "amount") {
      sortedData.sort((a, b) => b.amount - a.amount);
    }
    setSortedData(sortedData);
  };

  const handleSortByChange = (event) => {
    setSortByOption(event.target.value);
  };

  const filteredData = sortedData.filter((item) => {
    let passFilter = true;

    if (
      selectedLenderType.length > 0 &&
      !selectedLenderType.includes(item.type)
    ) {
      passFilter = false;
    }
    if (
      selectedProfessionType.length > 0 &&
      !selectedProfessionType.includes(item.profession.toLowerCase())
    ) {
      passFilter = false;
    }
    if (selectedFc.length > 0 && !selectedFc.includes(item.fc.toLowerCase())) {
      passFilter = false;
    }
    if (
      selectedPrepayment.length > 0 &&
      !selectedPrepayment.includes(item.prepayment.toLowerCase())
    ) {
      passFilter = false;
    }
    if (
      selectedLockin.length > 0 &&
      !selectedLockin.includes(item.lockin.toString())
    ) {
      passFilter = false;
    }

    return passFilter;
  });

  // Function to handle checkbox change for lender type
  const handleLenderTypeChange = (type) => {
    if (selectedLenderType.includes(type)) {
      setSelectedLenderType(selectedLenderType.filter((t) => t !== type));
    } else {
      setSelectedLenderType([...selectedLenderType, type]);
    }
  };
  const handleProfessionTypeChange = (profession) => {
    setSelectedProfessionType(profession);
  };

  const handleFcChange = (fc) => {
    setSelectedFc(fc);
  };

  const handlePrepaymentChange = (prepayment) => {
    setSelectedPrepayment(prepayment);
  };

  const handleLockinChange = (lockin) => {
    setSelectedLockin(lockin);
  };
  const handleCardHover = () => {
    setIsFlipped(!isFlipped);
  };

  const handleCibilTypeChange = (cibil) => {
    setSelectedCibil(cibil);
  };

  const history = useNavigate();

  const handleMobileEnter = (e) => {
    setMobile(e.target.value);
  };

  const handleMobileSubmit = () => {
    if (mobile) {
      localStorage.setItem("compareMobile", mobile);
      history("/signup");
    }
  };
  return (
    <>
      <Header />
      <div className="CN-CompareOffers-page">
        <h1>Compare</h1>

        <div className="CN-CompareOffers-Container">
          <div className="CN-CompareOffers-FilterBar">
            <div>
              <b>Filters</b> <TuneIcon />
            </div>

            <div>
              <select value={sortByOption} onChange={handleSortByChange}>
                <option value="">Sort By</option>
                <option value="interest">Lower rate</option>
                <option value="amount">Maximum amount</option>
              </select>
            </div>
          </div>
          <div className="CN-CompareOffers-FilterOptions-mobile">
            <button
              style={{ border: "none", backgroundColor: "transparent" }}
              data-bs-toggle="offcanvas"
              href="#offcanvasExample"
              aria-controls="offcanvasExample"
            >
              Filter <TuneIcon />
            </button>

            <div
              className="offcanvas offcanvas-start"
              style={{ width: "80%" }}
              tabindex="-1"
              id="offcanvasExample"
              aria-labelledby="offcanvasExampleLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                  Filter <TuneIcon />
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body CN-CompareOffer-FilterRightPart-offcanvas">
                <div className="CN-CompareOffer-FilterLeftPart">
                  <label>
                    <b>Lender type</b>
                  </label>
                  <div>
                    <input
                      type="checkbox"
                      checked={selectedLenderType.includes("bank")}
                      onChange={() => handleLenderTypeChange("bank")}
                    />{" "}
                    Bank
                    <br />
                    <input
                      type="checkbox"
                      checked={selectedLenderType.includes("nbfc")}
                      onChange={() => handleLenderTypeChange("nbfc")}
                    />{" "}
                    NBFC
                  </div>
                  <label>
                    <b>Profession</b>
                  </label>
                  <div>
                    <input
                      type="checkbox"
                      checked={selectedProfessionType.includes("salaried")}
                      onChange={() => handleProfessionTypeChange("salaried")}
                    />{" "}
                    Salaried <br />
                    <input
                      type="checkbox"
                      checked={selectedProfessionType.includes("self employed")}
                      onChange={() =>
                        handleProfessionTypeChange("self employed")
                      }
                    />{" "}
                    Self employed <br />
                    <input
                      type="checkbox"
                      checked={selectedProfessionType.includes("professional")}
                      onChange={() =>
                        handleProfessionTypeChange("professional")
                      }
                    />{" "}
                    Professional
                  </div>

                  <label>
                    <b>Minimum lockin period</b>
                  </label>

                  <div>
                    <input
                      type="checkbox"
                      checked={selectedLockin.includes("6")}
                      onChange={() => handleLockinChange("6")}
                    />{" "}
                    6 Months <br />
                    <input
                      type="checkbox"
                      checked={selectedLockin.includes("12")}
                      onChange={() => handleLockinChange("12")}
                    />{" "}
                    12 Months <br />
                    <input
                      type="checkbox"
                      checked={selectedLockin.includes("24")}
                      onChange={() => handleLockinChange("24")}
                    />{" "}
                    24 Months <br />
                  </div>

                  <label>
                    <b>Foreclosure</b>
                  </label>
                  <div>
                    <input
                      type="checkbox"
                      checked={selectedFc.includes("yes")}
                      onChange={() => handleFcChange("yes")}
                    />{" "}
                    Yes <br />
                    <input
                      type="checkbox"
                      checked={selectedFc.includes("no")}
                      onChange={() => handleFcChange("no")}
                    />{" "}
                    No
                  </div>

                  <label>
                    <b>Prepayment allowed</b>
                  </label>
                  <div>
                    <input
                      type="checkbox"
                      checked={selectedPrepayment.includes("yes")}
                      onChange={() => handlePrepaymentChange("yes")}
                    />{" "}
                    Yes <br />
                    <input
                      type="checkbox"
                      checked={selectedPrepayment.includes("no")}
                      onChange={() => handlePrepaymentChange("no")}
                    />{" "}
                    No
                  </div>

                  <label>
                    <b>Min Cibil</b>
                  </label>
                  <div>
                    <input type="checkbox" /> 650 - 700
                    <br />
                    <input type="checkbox" /> 700 - 750 <br />
                    <input type="checkbox" /> 750+
                  </div>
                </div>
                <button
                  type="button"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  style={{
                    width: "120px",
                    height: "45px",
                    border: "1px solid lightgrey",
                    borderRadius: "10px",
                    marginTop: "10px",
                    backgroundColor: "var(--color-darkgreen)",
                    color: "white",
                    fontWeight: "700",
                    fontSize: "15px",
                  }}
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </div>
          <div className="CN-CompareOffers-MainDiv">
            <div className="CN-CompareOffer-FilterLeft">
              <label>
                <b>Lender type</b>
              </label>
              <div>
                <input
                  type="checkbox"
                  checked={selectedLenderType.includes("bank")}
                  onChange={() => handleLenderTypeChange("bank")}
                />{" "}
                Bank
                <br />
                <input
                  type="checkbox"
                  checked={selectedLenderType.includes("nbfc")}
                  onChange={() => handleLenderTypeChange("nbfc")}
                />{" "}
                NBFC
              </div>
              {/* <label>
                <b>Rate of interest</b> <br />
                <input type="checkbox" /> Min <br />
                <input type="checkbox" /> Max
              </label>

              <label>
                <b>Loan amount</b> <br />
                <input type="checkbox" /> Min <br />
                <input type="checkbox" /> Max
              </label> */}
              <div>
                <label>
                  <b>Profession</b>
                </label>
                <div>
                  <label>
                    <input
                      type="radio"
                      value="salaried"
                      checked={selectedProfessionType === "salaried"}
                      onChange={() => handleProfessionTypeChange("salaried")}
                    />{" "}
                    Salaried
                  </label>
                  <br />
                  <label>
                    <input
                      type="radio"
                      value="self employed"
                      checked={selectedProfessionType === "self employed"}
                      onChange={() =>
                        handleProfessionTypeChange("self employed")
                      }
                    />{" "}
                    Self employed
                  </label>
                  <br />
                  <label>
                    <input
                      type="radio"
                      value="professional"
                      checked={selectedProfessionType === "professional"}
                      onChange={() =>
                        handleProfessionTypeChange("professional")
                      }
                    />{" "}
                    Professional
                  </label>
                </div>
              </div>
              <div>
                <label>
                  <b>Minimum lockin period</b>
                </label>
                <div>
                  <label>
                    <input
                      type="radio"
                      value="6"
                      checked={selectedLockin === "6"}
                      onChange={() => handleLockinChange("6")}
                    />{" "}
                    6 Months
                  </label>
                  <br />
                  <label>
                    <input
                      type="radio"
                      value="12"
                      checked={selectedLockin === "12"}
                      onChange={() => handleLockinChange("12")}
                    />{" "}
                    12 Months
                  </label>
                  <br />
                  <label>
                    <input
                      type="radio"
                      value="24"
                      checked={selectedLockin === "24"}
                      onChange={() => handleLockinChange("24")}
                    />{" "}
                    24 Months
                  </label>
                </div>
              </div>
              <div>
                <label>
                  <b>Foreclosure</b>
                </label>
                <div>
                  <label>
                    <input
                      type="radio"
                      value="yes"
                      checked={selectedFc === "yes"}
                      onChange={() => handleFcChange("yes")}
                    />{" "}
                    Yes
                  </label>
                  <br />
                  <label>
                    <input
                      type="radio"
                      value="no"
                      checked={selectedFc === "no"}
                      onChange={() => handleFcChange("no")}
                    />{" "}
                    No
                  </label>
                </div>
              </div>
              <div>
                <label>
                  <b>Prepayment allowed</b>
                </label>
                <div>
                  <label>
                    <input
                      type="radio"
                      value="yes"
                      checked={selectedPrepayment === "yes"}
                      onChange={() => handlePrepaymentChange("yes")}
                    />{" "}
                    Yes
                  </label>
                  <br />
                  <label>
                    <input
                      type="radio"
                      value="no"
                      checked={selectedPrepayment === "no"}
                      onChange={() => handlePrepaymentChange("no")}
                    />{" "}
                    No
                  </label>
                  <br />
                  <label>
                    <b>Min Cibil</b>
                  </label>
                  <div>
                    <input
                      type="radio"
                      value="650 - 700"
                      checked={selectedCibil === "650 - 700"}
                      onChange={() => handleCibilTypeChange("650 - 700")}
                    />{" "}
                    650 - 700
                    <br />
                    <input
                      type="radio"
                      value="700 - 750"
                      checked={selectedCibil === "700 - 750"}
                      onChange={() => handleCibilTypeChange("700 - 750")}
                    />{" "}
                    700 - 750 <br />
                    <input
                      type="radio"
                      value="750+"
                      checked={selectedCibil === "750+"}
                      onChange={() => handleCibilTypeChange("750+")}
                    />{" "}
                    750+
                  </div>
                </div>
              </div>
            </div>
            <div className="CN-CompareOffers-BankCard-Container">
              {filteredData.map((value) => {
                return (
                  <div
                    style={{
                      border: "1px solid lightgrey",
                      borderRadius: "10px",
                      padding: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      flexDirection: "column",
                    }}
                    key={value.id}
                  >
                    <div
                      className="flip-card"
                      onMouseEnter={handleCardHover}
                      onMouseLeave={handleCardHover}
                    >
                      <div
                        className={`CN-CompareOffers-flip-card-inner ${
                          isFlipped ? "flipped" : ""
                        }`}
                      >
                        <div className="CN-CompareOffers-flip-card-front">
                          <div className="CN-CompareOffers-title">
                            <img src={value.Img} alt={value.Img} />
                          </div>
                          <div className="CN-CompareOffers-valuesDiv">
                            <div>
                              <span>Upto</span>
                              {value.amount / 10000000} cr
                            </div>
                            <div>
                              <span>Starting</span>
                              {value.interest.toFixed(2)}%
                            </div>
                          </div>
                        </div>
                        <div
                          className="CN-CompareOffers-flip-card-back"
                          onClick={handleCardHover}
                          style={{ gap: "5px" }}
                        >
                          <b>Features: </b>
                          <div>TAT: Upto {value.tat} Days</div>
                          <div>Tenure: Upto {value.tenure} Years</div>
                          <div>Processing Fees: Upto {value.pf}%</div>
                          <div>Min Lockin Period: {value.lockin} Months</div>
                          <div>
                            Foreclosure Allowed:{" "}
                            {value.fc === "yes" ? <>Yes</> : <>No</>}
                          </div>
                          <div>
                            Prepayment Allowed:{" "}
                            {value.prepayment === "yes" ? <>Yes</> : <>No</>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="CN-CompareOffers-NumberDiv">
                      <input
                        type="tel"
                        placeholder="Mobile number"
                        onChange={handleMobileEnter}
                        maxLength={10}
                      />
                      <button onClick={handleMobileSubmit}>
                        <NavigateNextIcon />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CompareOffer;
