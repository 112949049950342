import React, { useEffect, useState, useCallback } from "react";
import "./LoginPage.css";
import Header from "../../User-Components/Front-Page-Components/Header/Header";
import LoginImg from "../../Images/Page-Img/Login-Page-Img.png";
import axios from "axios";
import serverIP from "../../config/config";
import { Link, useNavigate } from "react-router-dom";
import ImgFlag from "../../Images/Assets/indiaflagcn@2x.png";
import Animation1 from "../../Animation/animation";
import StepsFooter from "../../User-Components/Front-Page-Components/Footer/StepsFooter";

function LoginPage() {
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [error, setError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [otp, setOTP] = useState("");
  const [timer, setTimer] = useState(45);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [isWhatsappOptedIn, setIsWhatsappOptedIn] = useState(true);
  const [terms, setTerms] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const [operatingSystem, setOperatingSystem] = useState("");
  const [browser, setBrowser] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [screenResolution, setScreenResolution] = useState("");
  const [referringURL, setReferringURL] = useState("");
  const [userAgent, setUserAgent] = useState("");
  const [visitorIpType, setVisitorIpType] = useState("");
  const [utm, setUtm] = useState({
    utmSource: null,
    utmMedium: null,
    utmCampaign: null,
    utmContent: null,
    utmTerm: null,
  });

  const history = useNavigate();

  useEffect(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");

    const getVisitorInfo = () => {
      setOperatingSystem(navigator.platform);
      setBrowser(navigator.userAgent);
      setDeviceType(getDeviceType());
      setScreenResolution(`${window.screen.width}x${window.screen.height}`);
      setReferringURL(document.referrer);
      setUserAgent(navigator.userAgent);

      const { utmSource, utmMedium, utmCampaign, utmTerm, utmContent } =
        getUTMParameters() || {};
      setUtm({ utmSource, utmMedium, utmCampaign, utmTerm, utmContent });
    };

    getVisitorInfo();
    getUserIp();
    handleExtractAndTriggerOtp();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDeviceType = () => {
    const userAgent = navigator.userAgent;
    if (
      userAgent.match(/Android/i) ||
      userAgent.match(/webOS/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPod/i) ||
      userAgent.match(/BlackBerry/i) ||
      userAgent.match(/Windows Phone/i)
    ) {
      return "Mobile";
    } else if (userAgent.match(/iPad/i) || userAgent.match(/Tablet/i)) {
      return "Tablet";
    } else {
      return "Desktop";
    }
  };

  const getUTMParameters = () => {
    const utmData = localStorage.getItem("Nextutm");
    if (utmData) {
      const utmDataObj = JSON.parse(utmData);

      return {
        utmSource: utmDataObj.utmSource || null,
        utmMedium: utmDataObj.utmMedium || null,
        utmCampaign: utmDataObj.utmCampaign || null,
        utmContent: utmDataObj.utmContent || null,
        utmTerm: utmDataObj.utmTerm || null,
      };
    }
  };

  const getUserIp = async () => {
    try {
      const response = await fetch(`${serverIP}/visitor/getIP`);
      const data = await response.json();
      setIpAddress(data.ipAddress);
      setVisitorIpType(data.ipType);
    } catch (error) {
      console.error("Error fetching user IP:", error);
    }
  };

  useEffect(() => {
    // Check if the data has already been submitted for this user
    const visitorId = localStorage.getItem("visitorId");
    if (visitorId) {
      // Data has already been submitted, no need to submit again
      // console.log("Data has already been submitted for this visitor.");
      return;
    }
    const handleVisitors = async () => {
      const visitorData = {
        visitorIp: ipAddress,
        visitorIpType: visitorIpType,
        userAgent: userAgent,
        referringURL: referringURL,
        screenResolution: screenResolution,
        deviceType: deviceType,
        operatingSystem: operatingSystem,
        browser: browser,
        utm_source: utm.utmSource,
        utm_medium: utm.utmMedium,
        utm_campaign: utm.utmCampaign,
        utm_term: utm.utmTerm,
        utm_content: utm.utmContent,
      };

      const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(visitorData),
      };

      try {
        const response = await fetch(`${serverIP}/visitor/newvisitor`, config);
        const data = await response.json();
        console.log("Data:", data);
        localStorage.setItem("visitorId", data.visitorId);
      } catch (error) {
        console.error("Error submitting data:", error);
        // Handle error here, maybe show a message to the user
      }
    };

    const timeoutId = setTimeout(() => {
      handleVisitors();
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [
    browser,
    deviceType,
    ipAddress,
    operatingSystem,
    referringURL,
    screenResolution,
    userAgent,
    utm,
    visitorIpType,
  ]);

  const handleSendSessionID = async () => {
    try {
      const response = await axios.post(
        `${serverIP}/visitor/${localStorage.getItem("visitorId")}`,
        {
          sessionId: localStorage.getItem("sessionId"),
        }
      );

      if (response.status === 200 || response.status < 300) {
        console.log("Session ID sent");
      } else {
        console.log("Error sending session ID");
      }
    } catch (error) {
      console.log("Error sending sessionId:", error);
    }
  };

  //  ####################### For TeleCRM ############################################

  const handleTeleCRM = useCallback(async () => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer 2c438757-dc99-4297-b188-b06bba3341651714828905449:e51d6e0b-9961-45b9-bcf0-bae006ffa758",
      },
      body: JSON.stringify({
        fields: {
          phone: `${phoneNumber}`,
        },
        actions: [
          {
            type: "SYSTEM_NOTE",
            text: "Lead Source: Website Home-Loan",
          },
          {
            type: "SYSTEM_NOTE",
            text: "Session ID created",
          },
        ],
      }),
    };

    try {
      const response = await fetch(
        `https://api.telecrm.in/enterprise/66361419545ce40e83b1154b/autoupdatelead`,
        config
      );
      if (response.status >= 200 && response.status < 300) {
        console.log("Lead updated successfully");
      } else {
        console.error("Lead update failed. Error", response.status);
      }
    } catch (error) {
      console.log(error);
    }
  }, [phoneNumber]);

  const handlePhoneNumberSubmit = useCallback(async () => {
    if (phoneNumber.length !== 10 || !/[6-9]/.test(phoneNumber[0])) {
      setError("Enter Correct Mobile Number");
      return;
    }
    setShowAnimation(true);
    try {
      const response = await axios.post(`${serverIP}/user/generate-otp`, {
        phoneNumber: phoneNumber,
        whatsappOptIn: isWhatsappOptedIn,
      });
      if (response.status === 200 || response.status < 300) {
        startTimer();
        console.log("OTP Sent");
        setError("");
        setShowOTPInput(true);
        setShowAnimation(false);
        localStorage.setItem("sessionId", response.data.sessionId);
        localStorage.removeItem("home-loan-mobile");
        handleTeleCRM();
        handleSendSessionID();
      } else {
        setError("Error to Send OTP");
        setShowAnimation(false);
      }
    } catch (error) {
      console.log("Error sending mobile number:", error);
    } finally {
      setShowAnimation(false);
    }
  }, [phoneNumber, isWhatsappOptedIn, handleTeleCRM]);

  useEffect(() => {
    const storedPhoneNumber = localStorage.getItem("home-loan-mobile");
    if (storedPhoneNumber) {
      setPhoneNumber(storedPhoneNumber);
      setTerms(true);
      handlePhoneNumberSubmit();
    }
  }, [handlePhoneNumberSubmit]);

  const startTimer = () => {
    setIsResendDisabled(true);
    let countdown = 45;

    const interval = setInterval(() => {
      countdown -= 1;
      setTimer(countdown);

      if (countdown === 0) {
        clearInterval(interval);
        setIsResendDisabled(false);
      }
    }, 1000);
  };

  useEffect(() => {
    if (showOTPInput) {
      startTimer();
    }
  }, [showOTPInput]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    setShowAnimation(true);
    try {
      const response = await axios.post(`${serverIP}/auth/verify-otp`, {
        phoneNumber: phoneNumber,
        enteredOTP: otp,
      });
      if (response.data.isOTPValid === true) {
        localStorage.setItem("userId", response.data.userId);
        localStorage.setItem("accessToken", response.data.token);
        localStorage.removeItem("Nextutm");
        handleTeleCRMUpadte();
        history("/profile1");
      } else {
        setError("Incorrect OTP");
        setShowAnimation(false);
      }
    } catch (error) {
      console.log("Error while sending OTP:", error);
    } finally {
      setShowAnimation(false);
    }
  };

  const handlePhoneInput = (e) => {
    setPhoneNumber(e.target.value);
    setError("");
  };

  const handleOtpInput = (e) => {
    setOTP(e.target.value);
    setError("");
  };

  const handleCheckboxChange = (e) => {
    setIsWhatsappOptedIn(e.target.checked);
  };

  useEffect(() => {
    const CompareMobile = localStorage.getItem("compareMobile") || "";
    setPhoneNumber(CompareMobile);
  }, []);

  const handleTermsChange = () => {
    setTerms(!terms);
  };

  const handleExtractAndTriggerOtp = () => {
    const extractedUrl = window.location.href;
    // const phoneNumberRegex = /phone=(\d{10})/;
    const phoneNumberRegex = /phone=\/(\d{10})/;
    const match = extractedUrl.match(phoneNumberRegex);
    if (match && match[1]) {
      const phoneNumberFromUrl = match[1];
      console.log("Extracted Phone Number:", phoneNumberFromUrl);
      localStorage.setItem("home-loan-mobile", phoneNumberFromUrl);
      setPhoneNumber(phoneNumberFromUrl);
      handlePhoneNumberSubmit();
    }
  };

  const handleTeleCRMUpadte = async () => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer 2c438757-dc99-4297-b188-b06bba3341651714828905449:e51d6e0b-9961-45b9-bcf0-bae006ffa758",
      },
      body: JSON.stringify({
        fields: {
          phone: `${phoneNumber}`,
        },
        actions: [
          {
            type: "SYSTEM_NOTE",
            text: "User ID created",
          },
        ],
      }),
    };

    try {
      const response = await fetch(
        `https://api.telecrm.in/enterprise/66361419545ce40e83b1154b/autoupdatelead`,
        config
      );
      if (response.status >= 200 && response.status < 300) {
        console.log("Lead updated successfully");
      } else {
        console.error("Lead update failed. Error", response.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      <div className="CN2-Login-Page">
        <div className="CN2-Login-Page-Container">
          <div className="CN2-Login-Page-Container-div1">
            <img
              src={LoginImg}
              alt="Creditnama-Login-Img"
              className="CN2-Login-Page-Img"
            />
          </div>
          <div className="CN2-Login-Page-Container-div2">
            <h1>Find Your Best Eligibility Today</h1>

            {showAnimation ? (
              <Animation1 />
            ) : (
              <>
                {showOTPInput ? (
                  <div className="CN2-Login-Page-Form">
                    <form onSubmit={handleOTPSubmit}>
                      {error && (
                        <div className="CN2-Login-Error-Handling">{error}</div>
                      )}

                      <input
                        placeholder="Enter OTP"
                        type="tel"
                        className="CN2-Login-Signin-Input"
                        id="otp-input"
                        value={otp}
                        maxLength={6}
                        onChange={handleOtpInput}
                        required
                      />
                      <button className="CN2-Login-Signin-Button">
                        Submit OTP
                      </button>
                      {isResendDisabled ? (
                        <p className="CN2-Login-ResendOtp">
                          Resend OTP {formatTime(timer)}
                        </p>
                      ) : (
                        <p
                          className="CN2-Login-ResendOtp"
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={handlePhoneNumberSubmit}
                        >
                          Resend OTP
                        </p>
                      )}
                    </form>
                  </div>
                ) : (
                  <div className="CN2-Login-Page-Form">
                    <form onSubmit={handlePhoneNumberSubmit}>
                      {error && (
                        <div className="CN2-Login-Error-Handling">{error}</div>
                      )}

                      <div className="CN2-Login-Signin-Container">
                        <img
                          src={ImgFlag}
                          alt="Head Img"
                          style={{ width: "50px" }}
                          loading="lazy"
                        />
                        +91{" "}
                        <input
                          placeholder="Enter Phone Number"
                          type="tel"
                          className="CN2-Login-Signin-Input"
                          required
                          id="phone-input"
                          value={phoneNumber}
                          maxLength={10}
                          onChange={handlePhoneInput}
                        />
                      </div>
                      <div className="CN2-Login-Signin-checkbox">
                        <div>
                          <input
                            type="checkbox"
                            className="CN2-Login-Checkbox-Style mx-2"
                            checked={terms}
                            onChange={handleTermsChange}
                            required
                          />
                          I accept{" "}
                          <Link className="mx-1" to="/terms">
                            terms of service & applicable policies
                          </Link>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            className="CN2-Login-Checkbox-Style mx-2"
                            checked={isWhatsappOptedIn}
                            onChange={handleCheckboxChange}
                          />
                          Opt in to receive important updates on WhatsApp
                        </div>
                      </div>

                      <button className="CN2-Login-Signin-Button">
                        Send OTP
                      </button>
                    </form>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default LoginPage;
