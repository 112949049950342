import "./animation.css";
// import Animated from "../Images/Assets/Animated.gif";

const Animation1 = () => {
  return (
    <div
      className="Animation"
      style={{
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div id="page">
        <div id="container">
          <div id="ring"></div>
          <div id="ring"></div>
          <div id="ring"></div>
          <div id="ring"></div>
          <div id="h3">
            <b>Loading</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Animation1;
