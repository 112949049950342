import React, { useState, useEffect, useCallback } from "react";
import "chart.js/auto";
import ArticleIcon from "@mui/icons-material/Article";
import { Doughnut } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import "./EMIcalculator.css";
// import Header from "../Header/Header";

function EMIcalculator({ isVisible }) {
  const [loanAmount, setLoanAmount] = useState(100000);
  const [interestRate, setInterestRate] = useState(8);
  const [tenure, setTenure] = useState(3);
  const [result, setResult] = useState(null);
  const [LoanTable, setLoanTable] = useState(false);
  const [amortizationTable, setAmortizationTable] = useState([]);
  //   const [isFooter, setIsFooter] = useState(true);
  //   const [isHeader, setIsHeader] = useState(true);

  const history = useNavigate();

  const calculateLoan = useCallback(() => {
    const monthlyInterestRate = interestRate / 12 / 100;
    const totalMonths = tenure * 12;

    const emi =
      (loanAmount *
        monthlyInterestRate *
        (1 + monthlyInterestRate) ** totalMonths) /
      ((1 + monthlyInterestRate) ** totalMonths - 1);

    let remainingBalance = loanAmount;
    const tableData = [];

    for (let month = 1; month <= totalMonths; month++) {
      const interestPayment = remainingBalance * monthlyInterestRate;
      const principalPayment = emi - interestPayment;
      remainingBalance -= principalPayment;

      tableData.push({
        month,
        monthlyPayment: emi.toFixed(0),
        principalPayment: principalPayment.toFixed(0),
        interestPayment: interestPayment.toFixed(0),
        remainingBalance: remainingBalance.toFixed(0),
      });
    }

    setAmortizationTable(tableData);

    setResult({
      emi: emi.toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 0,
      }),
      totalPrincipalPaid: (emi * totalMonths).toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 0,
      }),
      totalInterestPaid: (emi * totalMonths - loanAmount).toLocaleString(
        "en-IN",
        {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }
      ),
    });
  }, [loanAmount, interestRate, tenure]);

  useEffect(() => {
    calculateLoan();
  }, [loanAmount, interestRate, tenure, calculateLoan]);
  const handleShowTable = (e) => {
    e.preventDefault();
    if (LoanTable === true) {
      setLoanTable(false);
    } else if (LoanTable === false) {
      setLoanTable(true);
    }
  };
  const handleApply = () => {
    history("/");
  };

  return (
    <>
      <div className="CN-EMI-Cal-Div">
        <form onSubmit={handleShowTable}>
          <h3>EMI Calculator</h3>
          <div className="CN-EMI-Inner-Content">
            <div className="PB-range-slider-div">
              <div
                style={{
                  width: "100%",
                  gap: "20px",
                  display: "flex",
                  flexDirection: "column",
                  // fontSize: "12px",
                }}
              >
                <div className="CN-EMICal-Container">
                  <label>Loan amount:</label>
                  <div>
                    <input
                      value={Number(loanAmount).toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR",
                        maximumFractionDigits: 0,
                      })}
                      style={{
                        textDecoration: "none",
                        border: "none",
                        backgroundColor: "transparent",
                        width: "130px",
                        textAlign: "end",
                      }}
                      onChange={(e) =>
                        setLoanAmount(
                          parseFloat(e.target.value.replace(/[^\d.]/g, ""))
                        )
                      }
                      maxLength={15}
                    />
                  </div>
                </div>
                <input
                  type="range"
                  min="0"
                  max="1000000000"
                  step={1000000}
                  value={loanAmount}
                  className="PB-range-slider"
                  id="loanAmountRange"
                  onChange={(e) =>
                    setLoanAmount(
                      parseFloat(e.target.value.replace(/[^\d."]/g, ""))
                    )
                  }
                />

                <div className="CN-EMICal-Container">
                  <label>Rate of interest (p.a)</label>
                  <div>
                    <input
                      value={interestRate}
                      style={{
                        textDecoration: "none",
                        border: "none",
                        backgroundColor: "transparent",
                        width: "50px",
                        textAlign: "center",
                      }}
                      onChange={(e) => setInterestRate(e.target.value)}
                      maxLength={5}
                    />
                    %
                  </div>
                </div>
                <input
                  type="range"
                  min="0"
                  max="30"
                  step={0.1}
                  value={interestRate}
                  onChange={(e) => setInterestRate(e.target.value)}
                  className="PB-range-slider"
                  id="interestRateRange"
                />
                <div className="CN-EMICal-Container">
                  <label>Loan Tenure</label>
                  <div>{tenure} years</div>
                </div>
                <input
                  type="range"
                  min="1"
                  max="35"
                  value={tenure}
                  onChange={(e) => setTenure(e.target.value)}
                  className="PB-range-slider"
                  id="tenureRange"
                />
              </div>
              <div style={{ width: "100%", gap: "20px" }}>
                <div className="CN-Emi-Piechart-Div">
                  {result && amortizationTable.length > 0 && (
                    <>
                      <div>
                        <Doughnut
                          className="Cn-PieChart-Size"
                          data={{
                            datasets: [
                              {
                                data: [
                                  result.totalPrincipalPaid.replace(
                                    /[^0-9.-]+/g,
                                    ""
                                  ), // Remove non-numeric characters
                                  result.totalInterestPaid.replace(
                                    /[^0-9.-]+/g,
                                    ""
                                  ),
                                ],
                                backgroundColor: ["#36A2EB", "#FFCE56"],
                              },
                            ],
                          }}
                          options={{
                            tooltips: {
                              callbacks: {
                                label: function (tooltipItem, data) {
                                  const label =
                                    data.labels[tooltipItem.index] || "";
                                  const value = Number(
                                    data.datasets[0].data[tooltipItem.index]
                                  ).toLocaleString("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                    maximumFractionDigits: 0,
                                  });
                                  return `${label}: ${value}`;
                                },
                              },
                            },
                            legend: {
                              display: false,
                            },
                          }}
                        />
                      </div>
                    </>
                  )}

                  {result && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        fontSize: "16px",
                      }}
                    >
                      <div className="CN-EMI-Details">
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            background: "#FFCE56",
                          }}
                        />
                        <div>
                          Total Interest Paid: {result.totalInterestPaid}
                        </div>
                      </div>
                      <div className="CN-EMI-Details">
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            background: "#36a2eb",
                          }}
                        />
                        Total Amount Paid: {result.totalPrincipalPaid}
                      </div>
                      <div className="my-2 mx-3">Monthly EMI: {result.emi}</div>
                    </div>
                  )}
                  <button
                    style={{
                      width: "120px",
                      height: "35px",
                      borderRadius: "1rem",
                      border: "none",
                      backgroundColor: "var(--color-darkgreen)",
                      color: "white",
                      fontFamily: "var(--font-lalezar)",
                    }}
                    onClick={handleApply}
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>

            <b style={{ marginTop: "20px" }}>Amortization Details</b>
            <div>
              <ArticleIcon
                fontSize="large"
                onClick={handleShowTable}
                style={{ cursor: "pointer" }}
              />
              {LoanTable === true ? (
                <>
                  <b>Hide</b>
                </>
              ) : (
                <>
                  <b>Show</b>
                </>
              )}
            </div>

            {LoanTable && (
              <>
                {amortizationTable.length > 0 && (
                  <div style={{ padding: "10px" }}>
                    <table className="CN-EMI-Chart-Cont">
                      <thead>
                        <tr>
                          <th>Month</th>
                          <th>EMI</th>
                          <th>Principal</th>
                          <th>Interest</th>
                          <th>Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {amortizationTable.map((row) => (
                          <tr key={row.month}>
                            <td>{row.month}</td>
                            <td>{row.monthlyPayment}</td>
                            <td>{row.principalPayment}</td>
                            <td>{row.interestPayment}</td>
                            <td>{row.remainingBalance}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </div>
        </form>
      </div>
      <div className="CN-Eligible-Table">
        <h6>Eligible criteria</h6>
        <table>
          <tr>
            <th>Parameters</th>
            <th>Salaried</th>
            <th>Self-Employed</th>
          </tr>
          <tr>
            <td>Citizenship</td>
            <td>Indian/NRI</td>
            <td>Indian/NRI</td>
          </tr>
          <tr>
            <td>Professional Experience</td>
            <td>Minimum 2 years</td>
            <td>Minimum 2 years of business stability</td>
          </tr>
          <tr>
            <td>Age</td>
            <td>
              Min: 21 years
              <br />
              Max: 75 years
            </td>
            <td>
              Min: 21 years
              <br />
              Max: 75 years
            </td>
          </tr>
          <tr>
            <td>Monthly Income</td>
            <td>Minimum Rs. 10,000 per month</td>
            <td>Minimum Rs. 1,80,000 per annum</td>
          </tr>
          <tr>
            <td>CIBIL Score</td>
            <td>700+</td>
            <td>700+</td>
          </tr>
        </table>
      </div>
      <div className="CN-LOD-Container">
        <h6>List of documents</h6>

        <p>
          <b>Documents for Salaried Customers</b>
        </p>
        <ul>
          <li>
            Identity and residence proof (KYC), such as Aadhaar, PAN card, Voter
            ID card.
          </li>
          <li>
            Income proof, such as last 3 months salary slip, latest Form 16, and
            six-month bank statement.
          </li>

          <li> A Cheque For The Processing Fee For The Loan Application</li>
          <li>
            Property Documents Such As Sale Deed, NOC From The Builder Or
            Housing Society, Advance Payment Receipt To The Builder, And
            Approved Construction Plan
          </li>
        </ul>
        <p>
          <b>Documents for Self-Employed Customers</b>
        </p>
        <ul>
          <li>
            Identity and residence proof (KYC), such as Aadhaar, PAN card, Voter
            ID card.
          </li>
          <li>
            ITR With Computation of Income for Previous 3 Years as Proof of
            Income
          </li>
          <li>
            latest two years P&L accounts and B/S (with schedules), six-month
            bank statement, etc
          </li>
          <li> A Cheque For The Processing Fee For The Loan Application</li>
          <li>
            Property Documents Such As Sale Deed, NOC From The Builder Or
            Housing Society, Advance Payment Receipt To The Builder, And
            Approved Construction Plan
          </li>
        </ul>
      </div>
    </>
  );
}

export default EMIcalculator;
