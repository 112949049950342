import { useRef, useState, useEffect } from "react";
import "./CNAadhaar.css";
import KycImg from "../Images/Page-Img/Verifyidentity.png";
import AadhaarIcon from "../Images/Assets/aadhaar@2x.png";
import DigiIcon from "../Images/Assets/digiicon@2x.png";
import UploadIcon from "../Images/Assets/uploadicon@2x.png";
import AadhaarImg from "../Images/Assets/aadhaaricon@2x.png";
import Axios from "axios";
import serverIP from "../config/config";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Animation1 from "../Animation/animation";
import Header from "../User-Components/Front-Page-Components/Header/Header";
import StatusBar from "../Status-Bar/StatusBar";
import StepsFooter from "../User-Components/Front-Page-Components/Footer/StepsFooter";

function Aadhaar() {
  const [selectedFileName, setSelectedFileName] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showResendButton, setShowResendButton] = useState(true);
  const [aadhaar, setAadhaar] = useState("");
  const [otp, setOtp] = useState("");
  const [otpDiv, setOtpDiv] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const [resendInterval, setResendInterval] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [otpError, setOTPError] = useState();
  const [error, setError] = useState("");
  const [errorDiv, setErrorDiv] = useState("");

  const history = useNavigate();

  const fileInputRef = useRef(null);

  const formattedResendTimer =
    resendTimer < 10 ? `0${resendTimer}` : resendTimer;

  // Function to start the resend timer
  const startResendTimer = () => {
    setResendTimer(60);
    const interval = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    setResendInterval(interval);
  };

  function handleClick() {
    fileInputRef.current.click(); // Trigger the file input's click event
  }

  // Function to handle Aadhaar input change and validate the input
  const handleAadhaarChange = (e) => {
    const aadhaarValue = e.target.value;
    setAadhaar(aadhaarValue);
    setError("");
  };

  function handleFileUpload(event) {
    const fileInput = document.getElementById("fileInput");
    const selectedFile = fileInput.files[0];

    // const selectedFile = event.target.files[0];
    if (selectedFile) {
      setSelectedFileName(selectedFile.name); // Update the selected file name
      setIsSubmitDisabled(false); // Enable the Submit button
      setError("");
      // Handle the selected file here (e.g., send it to a server)
      // console.log("Selected file:", selectedFile.name);
    } else {
      setSelectedFileName(""); // Clear the selected file name
      setIsSubmitDisabled(true); // Disable the Submit button
    }
  }

  // Define the handleSubmit function that handles form submission

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowResendButton(false);

    try {
      const token = localStorage.getItem("accessToken");
      const userId = localStorage.getItem("userId");

      // Make sure the token and userId exist before proceeding
      if (!token) {
        console.error("Access token not found.");
        return;
      }
      if (!userId) {
        console.error("UserId not found.");
        return;
      }

      const aadhaarValue = aadhaar;

      if (!/^\d{12}$/.test(aadhaarValue)) {
        setError("Invalid aadhaar number");
      } else {
        setError(""); // Clear the error message

        // Set the Authorization header with the token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        setIsLoading(true);

        // Trigger Aadhaar OTP Request
        const response = await Axios.post(
          `${serverIP}/kyc/scoreme/eaadhaar/otp/${userId}`,
          {
            aadhaar: aadhaar,
          },
          config
        );
        console.log(response);

        if (response.data.responseCode === "SOS174") {
          // Handle the response from the Aadhaar OTP request if needed
          console.log("OTP Sent Successfully");
          startResendTimer();
          setOtpDiv(true);
        } else if (response.data.responseCode === "EAE168") {
          setError("Aadhaar does not exist");
          setIsLoading(false);
        } else {
          setErrorDiv("Server error. Please upload aadhaar");
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsLoading(false);
      history("/session-break");
      // Handle form submission error
    } finally {
      setIsLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // Get the access token from localStorage
      const token = localStorage.getItem("accessToken");
      const userId = localStorage.getItem("userId");

      // Set the Authorization header with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setIsLoading(true);

      const response = await Axios.post(
        `${serverIP}/kyc/scoreme/eaadhaar/file/${userId}`,
        {
          otp: otp,
          aadhaar: aadhaar,
        },
        config
      );
      console.log(response);

      if (response.data.responseCode === "SRC001") {
        console.log("Verified");
        history("/Aadhaar");
      } else if (
        response.data.responseCode === "EIP018" ||
        response.data.responseCode === "ETP011"
      ) {
        // Set otperror message for invalid OTP
        console.error("Invalid OTP");
        setOTPError("Invalid OTP");
        setIsLoading(false);
      } else if (response.data.responseCode === "EOE794") {
        setOTPError("OTP Expired");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setErrorDiv(
          "Error fetching Aadhaar. Please upload aadhar photo using Upload button"
        );
      }
    } catch (error) {
      console.error("Error inserting data:", error);
      setIsLoading(false);
      history("/session-break");

      // Handle form submission error
    } finally {
      setIsLoading(false);
    }
  };

  // Effect to clear the resend timer interval when it reaches 0
  useEffect(() => {
    if (resendTimer === 0) {
      clearInterval(resendInterval);
      setShowResendButton(true);
    }
  }, [resendTimer, resendInterval]);

  const handleFileUploadSubmit = () => {
    setIsLoading(true);
    // Get the access token from localStorage
    const token = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");

    const fileInput = document.getElementById("fileInput");
    const selectedFile = fileInput.files[0];

    // Create the FormData object and append the selected file name
    const formData = new FormData();
    formData.append("aadhaar", selectedFile);

    // Set the Authorization header with the token
    const config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    fetch(`${serverIP}/kyc/uploadaadhaar/${userId}`, config)
      .then((response) => {
        if (response.status === 200 || response.status <= 300) {
          console.log("File uploaded successfully");
          console.log(response);
          history("/kyc");
        } else {
          console.log("Failed to upload :", response);
          setIsLoading(false);
          history("/session-break");
        }
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        history("/session-break");
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleReadError = () => {
    setErrorDiv(false);
    setOtpDiv(false);
  };
  return (
    <>
      <Header />

      <div className="CN2-Aadhaar">
        <div className="CN2-Aadhaar-Container">
          <div className="CN2-Aadhaar-Main-Div">
            <div className="CN2-Aadhaar-Main-Div-Left">
              <img
                src={KycImg}
                alt="Profile with Aadhaar and aadhaar"
                className="CN2-Aadhaar-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-success)"
                KycColor="var(--color-yellow)"
                IncomeColor="white"
                OffersColor="white"
              />
            </div>
            <div className="CN2-Aadhaar-Main-Div-Right">
              <h1>Verify Your Aadhaar</h1>

              {otpDiv ? (
                <>
                  {isLoading ? (
                    <>
                      <Animation1 />
                    </>
                  ) : (
                    <div>
                      {!otpDiv ? (
                        <div className="CN2-Aadhaar-Main-Div-Right">
                          {otpError && (
                            <div className="CN2-Aadhaar-Error">{otpError}</div>
                          )}
                          {errorDiv ? (
                            <>
                              <div className="CN2-Aadhaar-Error">
                                {errorDiv}
                              </div>
                              <button
                                className="CN2-Aadhaar-Button"
                                style={{
                                  backgroundColor: "var(--color-darkgreen)",
                                }}
                                onClick={handleReadError}
                              >
                                Got it
                              </button>
                            </>
                          ) : (
                            <>
                              <input
                                className="CN2-Aadhaar-Input"
                                name="OtpInput"
                                placeholder="Enter OTP"
                                type="tel"
                                value={otp}
                                maxLength={6}
                                minLength={6}
                                onChange={(e) => setOtp(e.target.value)}
                              />
                              <button
                                className="CN2-Aadhaar-Button"
                                onClick={handleOtpSubmit}
                                style={{
                                  color: "white",
                                  backgroundColor: "var(--color-darkgreen)",
                                }}
                              >
                                Submit OTP
                              </button>

                              <div>
                                {showResendButton ? (
                                  <div>
                                    Didn’t receive OTP?{" "}
                                    <Link
                                      onClick={handleSubmit}
                                      className="mx-1"
                                    >
                                      Resend OTP
                                    </Link>
                                    {resendTimer > 0 && (
                                      <span>00:{resendTimer}</span>
                                    )}
                                  </div>
                                ) : (
                                  <div>{`Resend OTP in 00:${formattedResendTimer} seconds`}</div>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {isLoading ? (
                    <>
                      <Animation1 />
                    </>
                  ) : (
                    <>
                      {error && (
                        <div className="CN2-Aadhaar-Error">{error}</div>
                      )}
                      <input
                        className="CN2-Aadhaar-Input"
                        name="AadhaarInput"
                        placeholder="12-Digit Aadhaar"
                        type="tel"
                        maxLength={12}
                        onChange={handleAadhaarChange}
                        required
                      />
                      <div
                        onClick={handleSubmit}
                        className="CN-Aadhaar-Otp-Btn"
                      >
                        <img
                          className="CN2-Aadhaar-Aadhaar-Icon"
                          alt="Aadhaar otp btn"
                          src={AadhaarIcon}
                        />
                        Send OTP
                      </div>
                      or
                      <img
                        src={DigiIcon}
                        alt="digilocker"
                        style={{
                          width: "180px",
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                      />
                      or
                      <div
                        className="CN2-Aadhaar-Aadhaar-Div"
                        onClick={handleClick}
                      >
                        <div>
                          <img
                            src={AadhaarImg}
                            alt="Verify Aadhaar"
                            className="CN2-Aadhaar-Icon"
                          />
                        </div>
                        <div>Click to upload</div>
                        <input
                          type="file"
                          id="fileInput"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleFileUpload}
                        />
                        <div>
                          <img
                            src={UploadIcon}
                            alt="Verify Aadhaar"
                            className="CN2-Aadhaar-Upload-Icon"
                          />
                        </div>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {selectedFileName
                          ? `Selected File : ${selectedFileName}`
                          : ""}
                      </div>
                      {selectedFileName ? (
                        <>
                          <button
                            className="CN2-Aadhaar-Button"
                            style={{
                              color: "White",
                              backgroundColor: "var(--color-darkgreen)",
                            }}
                            onClick={handleFileUploadSubmit}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            style={{
                              backgroundColor: "lightgray",
                              color: "grey",
                            }}
                            className="CN2-Aadhaar-Button"
                            disabled={isSubmitDisabled}
                          >
                            Submit
                          </button>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
}

export default Aadhaar;

// import { useRef, useState, useEffect } from "react";
// import "./aadhaar.css";
// import KycIcon from "../Images/Page-Img/Verifyidentity.png";
// import AadhaarIcon from "../Images/Assets/aadhaar@2x.png";
// import DigiIcon from "../Images/Assets/digiicon@2x.png";
// import UploadIcon from "../Images/Assets/uploadicon@2x.png";
// import AadhaarImg from "../Images/Assets/aadhaaricon@2x.png";
// import Axios from "axios";
// import serverIP from "../config/config";
// import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
// import Animation1 from "../Animation/animation";
// import Header from "../User-Components/Front-Page-Components/header";

// const Aadhaar = () => {
//   const history = useNavigate();

//   const [selectedFileName, setSelectedFileName] = useState("");
//   const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
//   const [showResendButton, setShowResendButton] = useState(true);
//   const [aadhaar, setAadhaar] = useState("");
//   const [otp, setOtp] = useState("");
//   const [otpDiv, setOtpDiv] = useState(false);
//   const [resendTimer, setResendTimer] = useState(60);
//   const [resendInterval, setResendInterval] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [otpError, setOTPError] = useState();
//   const [error, setError] = useState("");
//   const [errorDiv, setErrorDiv] = useState("");

//   const fileInputRef = useRef(null);

//   const formattedResendTimer =
//     resendTimer < 10 ? `0${resendTimer}` : resendTimer;

//   // Function to start the resend timer
//   const startResendTimer = () => {
//     setResendTimer(60);
//     const interval = setInterval(() => {
//       setResendTimer((prevTimer) => prevTimer - 1);
//     }, 1000);
//     setResendInterval(interval);
//   };

//   function handleClick() {
//     fileInputRef.current.click(); // Trigger the file input's click event
//   }

//   // Function to handle Aadhaar input change and validate the input
//   const handleAadhaarChange = (e) => {
//     const aadhaarValue = e.target.value;
//     setAadhaar(aadhaarValue);
//     setError("");
//   };

//   function handleFileUpload(event) {
//     const fileInput = document.getElementById("fileInput");
//     const selectedFile = fileInput.files[0];

//     // const selectedFile = event.target.files[0];
//     if (selectedFile) {
//       setSelectedFileName(selectedFile.name); // Update the selected file name
//       setIsSubmitDisabled(false); // Enable the Submit button
//       setError("");
//       // Handle the selected file here (e.g., send it to a server)
//       // console.log("Selected file:", selectedFile.name);
//     } else {
//       setSelectedFileName(""); // Clear the selected file name
//       setIsSubmitDisabled(true); // Disable the Submit button
//     }
//   }

//   // Define the handleSubmit function that handles form submission

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setShowResendButton(false);

//     try {
//       const token = localStorage.getItem("accessToken");
//       const userId = localStorage.getItem("userId");

//       // Make sure the token and userId exist before proceeding
//       if (!token) {
//         console.error("Access token not found.");
//         return;
//       }
//       if (!userId) {
//         console.error("UserId not found.");
//         return;
//       }

//       const aadhaarValue = aadhaar;

//       if (!/^\d{12}$/.test(aadhaarValue)) {
//         setError("Invalid Aadhaar number");
//       } else {
//         setError(""); // Clear the error message

//         // Set the Authorization header with the token
//         const config = {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         };

//         setIsLoading(true);
//         startResendTimer();

//         // Trigger Aadhaar OTP Request
//         const response = await Axios.post(
//           `${serverIP}/kyc/scoreme/eaadhaar/otp/${userId}`,
//           {
//             aadhaar: aadhaar,
//           },
//           config
//         );
//         console.log(response);

//         if (response.data.responseCode === "SOS174") {
//           // Handle the response from the Aadhaar OTP request if needed
//           console.log("OTP Sent Successfully");
//           setOtpDiv(true);
//         } else if (response.data.responseCode === "EAE168") {
//           setError("Aadhaar does not exist");
//           setIsLoading(false);
//         } else {
//           setErrorDiv("Server error. Please Upload");
//           setIsLoading(false);
//         }
//       }
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       setIsLoading(false);

//       // Handle form submission error
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleOtpSubmit = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);
//     try {
//       // Get the access token from localStorage
//       const token = localStorage.getItem("accessToken");
//       const userId = localStorage.getItem("userId");

//       // Set the Authorization header with the token
//       const config = {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       };
//       setIsLoading(true);

//       const response = await Axios.post(
//         `${serverIP}/kyc/scoreme/eaadhaar/file/${userId}`,
//         {
//           otp: otp,
//           aadhaar: aadhaar,
//         },
//         config
//       );
//       console.log(response);

//       if (response.data.responseCode === "SRC001") {
//         console.log("Verified");
//         history("/kyc");
//       } else if (
//         response.data.responseCode === "EIP018" ||
//         response.data.responseCode === "ETP011"
//       ) {
//         // Set otperror message for invalid OTP
//         console.error("Invalid OTP");
//         setOTPError("Invalid OTP");
//         setIsLoading(false);
//       } else if (response.data.responseCode === "EOE794") {
//         setOTPError("OTP Expired");
//         setIsLoading(false);
//       } else {
//         setIsLoading(false);
//         setErrorDiv(
//           "Error fetching Aadhaar. Please upload aadhar photo using Upload button"
//         );
//       }
//     } catch (error) {
//       console.error("Error inserting data:", error);
//       setOTPError("Invalid OTP");
//       setIsLoading(false);

//       // Handle form submission error
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   // Effect to clear the resend timer interval when it reaches 0
//   useEffect(() => {
//     if (resendTimer === 0) {
//       clearInterval(resendInterval);
//       setShowResendButton(true);
//     }
//   }, [resendTimer, resendInterval]);

//   const handleFileUploadSubmit = () => {
//     setIsLoading(true);
//     // Get the access token from localStorage
//     const token = localStorage.getItem("accessToken");
//     const userId = localStorage.getItem("userId");

//     const fileInput = document.getElementById("fileInput");
//     const selectedFile = fileInput.files[0];

//     // Create the FormData object and append the selected file name
//     const formData = new FormData();
//     formData.append("aadhaar", selectedFile); // Use selectedFileName, not selectedFile

//     // Set the Authorization header with the token
//     const config = {
//       method: "PATCH",
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       body: formData,
//     };

//     fetch(`${serverIP}/kyc/upload-aadhaar/${userId}`, config)
//       .then((response) => {
//         if (response.ok === true) {
//           console.log("File uploaded successfully");
//           // Redirect to "/profile1" after successful file upload
//           history("/kyc");
//         } else {
//           console.log("Failed to upload :", response);
//           // console.log(selectedFileName);
//           setError("Failed to upload file");
//           setIsLoading(false);
//         }
//       })
//       .catch((error) => {
//         console.error("Error uploading file:", error);
//         setError("Error uploading file");
//         setIsLoading(false);
//       })
//       .finally(() => {
//         setIsLoading(false);
//       });
//   };

//   const handleReadError = () => {
//     setErrorDiv(false);
//     setOtpDiv(false);
//   };

//   return (
//     <div className="aadhaar">
//       <Header />
//       <div className="CN-Aadhar-Prototype-container">
//         <img
//           alt=""
//           src={KycIcon}
//           style={{ width: "100%", objectFit: "contain" }}
//         />
//       </div>
//       <div className="aadhaar-verification-form">
//         <div className="verify-status-div">
//           <div className="verify-status-container">
//             <div className="kycstep5">
//               <div className="offers-text">Offers</div>
//               <div className="kycircle5" />
//             </div>
//             <div className="kycstep4">
//               <div className="kycline" />
//               <div className="income-text">{`Income `}</div>
//               <div className="kycircle4" />
//             </div>
//             <div className="kycstep3">
//               <div className="kycline1" />
//               <div className="kyc-text">KYC</div>
//               <div className="kycircle3" />
//             </div>
//             <div className="kycstep2">
//               <div className="kycline2" />
//               <div className="loan-text">Loan</div>
//               <div className="kycircle2" />
//             </div>
//             <div className="kycstep1">
//               <div className="kycline3" />
//               <div className="profile-text">Profile</div>
//               <div className="kycircle1" />
//             </div>
//           </div>
//         </div>
//         <div className="aadhaar-main-div">
//           <div className="aadhaar-verification-header">Verify Your Aadhaar</div>
//           {isLoading ? (
//             <>{/* <Animation1 /> */}</>
//           ) : (
//             <>
//               {error && (
//                 <p
//                   style={{
//                     color: "red",
//                     top: "195px",
//                     position: "absolute",
//                     left: "calc(50% - 100px)",
//                     width: "200px",
//                   }}
//                 >
//                   {error}
//                 </p>
//               )}
//               <input
//                 className="aadhaar-no-input"
//                 name="AadhaarInput"
//                 placeholder="12-Digit Aadhaar"
//                 type="tel"
//                 maxLength={12}
//                 onChange={handleAadhaarChange}
//                 required
//               />
//               <button
//                 className="aadhaar-otp-send-container"
//                 style={{ backgroundColor: "transparent", border: "none" }}
//                 onClick={handleSubmit}
//                 // disabled={isOtpSubmitDisabled}
//               >
//                 <div className="send-aadhaar-otp-div-icon" />
//                 <img
//                   className="aadhaar-verification-icon"
//                   alt=""
//                   src={AadhaarIcon}
//                 />
//                 <div className="send-otp">Send OTP</div>
//               </button>

//               <div className="or1">or</div>
//               <button
//                 className="aadhaar-digi-button"
//                 style={{ backgroundColor: "transparent" }}
//               >
//                 <img
//                   className="digilocker-small-1-icon"
//                   alt=""
//                   src={DigiIcon}
//                 />
//               </button>

//               <div className="or2">or</div>
//               <div
//                 className="aadhaar-verificaion-container"
//                 onClick={handleClick}
//               >
//                 <div className="verify-aadhaar-div">
//                   <div className="verify-aadhaar-innertext">
//                     Click to upload
//                   </div>

//                   <img
//                     className="verify-aadhaar-verification-icon"
//                     alt=""
//                     src={AadhaarImg}
//                   />
//                   <div className="verify-aadhaar-text">Aadhaar</div>
//                   <input
//                     type="file"
//                     id="fileInput"
//                     ref={fileInputRef} // Assign the ref to the file input element
//                     style={{ display: "none" }}
//                     onChange={handleFileUpload}
//                   />
//                   <img className="upload-to-cloud" alt="" src={UploadIcon} />
//                 </div>
//               </div>
//               <div className="Aadhaar-Selected-File">
//                 <b>
//                   {selectedFileName
//                     ? `Selected File : ${selectedFileName}`
//                     : ""}
//                 </b>
//               </div>
//               {selectedFileName ? (
//                 <>
//                   <button
//                     className="aadhaar-verification-button"
//                     onClick={handleFileUploadSubmit}
//                   >
//                     <div className="aadhaar-verification-text">Submit</div>
//                   </button>
//                 </>
//               ) : (
//                 <>
//                   <button
//                     style={{
//                       backgroundColor: "lightgray",
//                     }}
//                     className="aadhaar-verification-button"
//                     // onClick={onAadhaarVerificationButtonClick}
//                     disabled={isSubmitDisabled}
//                   >
//                     <div
//                       className="aadhaar-verification-text"
//                       style={{ color: "var(--black)" }}
//                     >
//                       Submit
//                     </div>
//                   </button>
//                 </>
//               )}
//             </>
//           )}
//         </div>
//         {isLoading ? (
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <Animation1 />
//           </div>
//         ) : (
//           <div>
//             {otpDiv ? (
//               <div className="Otp-Aadhaar-Verification">
//                 {otpError && (
//                   <p
//                     style={{
//                       position: "absolute",
//                       color: "red",
//                       top: "0",
//                       marginBottom: "10px",
//                       width: "100px",
//                       // backgroundColor: "red",
//                       left: "100px",
//                     }}
//                   >
//                     {otpError}
//                   </p>
//                 )}
//                 <div className="Otp-Container">
//                   <input
//                     className="aadhaar-no-input"
//                     name="OtpInput"
//                     placeholder="Enter OTP"
//                     type="tel"
//                     value={otp}
//                     maxLength={6}
//                     minLength={6}
//                     onChange={(e) => setOtp(e.target.value)}
//                   />
//                   <button
//                     className="aadhaar-otp-send-container"
//                     style={{
//                       backgroundColor: "var(--color-darkgreen)",
//                       border: "none",
//                       borderRadius: "1rem",
//                       height: "35px",
//                       fontSize: "12px",
//                       width: "100px",
//                       marginLeft: "30px",
//                     }}
//                     onClick={handleOtpSubmit}
//                   >
//                     <div className="Otp-Submit-Button">
//                       <b>Submit OTP</b>
//                     </div>
//                   </button>
//                 </div>
//                 <div>
//                   {showResendButton ? (
//                     <div style={{ color: "black", marginBottom: "200px" }}>
//                       Didn’t receive OTP?{" "}
//                       <Link onClick={handleSubmit} className="mx-1">
//                         Resend OTP
//                       </Link>
//                       {resendTimer > 0 && <span>00:{resendTimer}</span>}
//                     </div>
//                   ) : (
//                     <div
//                       style={{ color: "lightgray", marginBottom: "200px" }}
//                     >{`Resend OTP in 00:${formattedResendTimer} seconds`}</div>
//                   )}
//                 </div>
//               </div>
//             ) : (
//               ""
//             )}
//           </div>
//         )}
//         {errorDiv && (
//           <div className="kyc-aadhaar-error">
//             <p>{errorDiv}</p>
//             <div>
//               <button
//                 onClick={handleReadError}
//                 className="kycError-Button-Style"
//               >
//                 Got it
//               </button>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Aadhaar;
