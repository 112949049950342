import React from "react";
import "./AffordabilityCalc.css";
import ChannelSidebar from "../../Auth-Components/Channel-Auth/ChannelSidebar";
import Calculators from "../../User-Components/Calculators/Calculator";
import ChannelHamburger from "../components/ChannelHamburger";

const AffordabilityCalc = () => {
  return (
    <>
      <div className="CN-AF-Calc-Page">
        <ChannelSidebar />
        <div className="CN-AF-Calc-MainContainer">
          <div
            style={{
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <ChannelHamburger />
          </div>
          <Calculators />
        </div>
      </div>
    </>
  );
};

export default AffordabilityCalc;
