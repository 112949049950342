import "./CNConfirmation.css";
import AddressIcon from "../../Images/Assets/addressicon@2x.png";
import AskIcon from "../../Images/Assets/askconfirmationicon@2x.png";
import DobIcon from "../../Images/Assets/dobicon@2x.png";
import ProfessionIcon from "../../Images/Assets/professionicon@2x.png";
import ConfirmationPrototype from "../../Images/Page-Img/ApplicationForm.png";
import UsertIcon from "../../Images/Assets/usericon@2x.png";
import { useEffect, useState } from "react";
import serverIp from "../../config/config";
import { useNavigate } from "react-router-dom";
import Header from "../Front-Page-Components/Header/Header";
import StatusBar from "../../Status-Bar/StatusBar";
import StepsFooter from "../Front-Page-Components/Footer/StepsFooter";

const Confirmation = () => {
  const [userData, setUserData] = useState({
    fname: null,
    dob: null,
    address: null,
    fetchprofession: null,
    loantype: null,
    loanamount: null,
    loantenure: null,
    loantheme: null,
  });

  const history = useNavigate();

  useEffect(() => {
    const storedData =
      JSON.parse(localStorage.getItem("Personal1FormData")) || {};
    const storedAskData = JSON.parse(localStorage.getItem("AskFormData")) || {};
    const fetchData = async () => {
      try {
        // const token = localStorage.getItem("accessToken");
        const askId = localStorage.getItem("askId");
        const token = localStorage.getItem("accessToken");

        // Set the Authorization header with the token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await fetch(
          `${serverIp}/ask/summary/${askId}`,
          config
        );
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setUserData({
            fname:
              data.fullnamePan ||
              storedData.firstName + " " + storedData.lastName ||
              null,
            dob: data.dob || storedData.dob || null,
            address: data.fullAddress || "NA" || null,
            fetchprofession: data.profession || storedAskData.prof || null,
            loantype:
              (data.initial_ask_purpose === "property purchase" &&
                "Home Loan") ||
              (data.initial_ask_purpose === "loan against property" && "LAP") ||
              storedAskData.loan ||
              null,
            loanamount: data.initial_ask_value || storedAskData.amt || null,
            loantenure: data.initial_ask_tenure || storedAskData.tenure || null,
            loantheme:
              data.initial_ask_theme || storedAskData.propertyType || "",

            // landmark: data.landmark || null,
            // district: data.district || null,
            // location: data.location || null,
            // pincode: data.pincode || null,
          });
          // console.log(data);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userData.fetchprofession === "salaried") {
      // console.log(userData);
      history("/employer");
    } else if (userData.fetchprofession === "self employed") {
      history("/business");
    } else if (userData.fetchprofession === "specialist professional") {
      history("/profession");
    } else {
      history("/employer");
    }
  };

  return (
    <>
      <Header />

      <div className="CN2-Confirmation-Page">
        <div className="CN2-Confirmation-Container">
          <div className="CN2-Confirmation-Main-Div">
            <div className="CN2-Confirmation-Main-Div-Left">
              <img
                src={ConfirmationPrototype}
                alt="Profile with pan and aadhaar"
                className="CN2-Confirmation-Main-Div-Left-Img"
              />
              <StatusBar
                ProfileColor="var(--color-success)"
                LoanColor="var(--color-yellow)"
                KycColor="white"
                IncomeColor="white"
                OffersColor="white"
              />
            </div>
            <form
              className="CN2-Confirmation-Main-Div-Right"
              onSubmit={handleSubmit}
            >
              <h1>Application form</h1>
              <div>
                <img alt="" src={UsertIcon} className="CN2-Confirmation-Icon" />
                <div>{userData.fname}</div>
              </div>

              <div>
                <img alt="" src={DobIcon} className="CN2-Confirmation-Icon" />
                <div>{userData.dob}</div>
              </div>
              <div>
                <img
                  alt=""
                  src={ProfessionIcon}
                  className="CN2-Confirmation-Icon"
                />

                <div>{userData.fetchprofession}</div>
              </div>
              <div>
                <img
                  alt=""
                  src={AddressIcon}
                  className="CN2-Confirmation-Icon"
                />

                <div>{userData.address}</div>
              </div>
              <div>
                <img alt="" src={AskIcon} className="CN2-Confirmation-Icon" />
                <div>
                  {userData.loantype + " " + userData.loantheme} <br />
                  Amount :{" "}
                  {parseFloat(userData.loanamount).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                  })}
                  <br />
                  Tenure : {userData.loantenure + " Years"}
                </div>
              </div>
              <div className="CN2-Confirmation-Checkbox-Div">
                <input
                  className="CN2-Confirmation-Checkbox"
                  type="checkbox"
                  value={"Agree"}
                  required
                />
                <span> I agree with terms & conditions</span>
              </div>
              <div className="CN2-Confirmation-Buttons-Div">
                <button
                  className="CN2-Confirmation-Edit"
                  type="submit"
                  onClick={() => history("/ask1")}
                >
                  Go Back
                </button>
                <button className="CN2-Confirmation-Button" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <StepsFooter />
    </>
  );
};
export default Confirmation;
