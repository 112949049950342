import React from "react";
import "./aboutus.css";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import { Helmet } from "react-helmet-async";

const GlossaryPage = () => {
  return (
    <>
      <Helmet>
        <title>Glossary</title>
        <meta
          name="description"
          content=" Whether you're taking the loan for your first home, or exploring
          opportunities for a loan against your property, or considering a
          personal loan to get rid of excess credit card debt, loan and
          lending often introduces a slew of terms that might leave you
          seeking clarification."
        />
        <link rel="canonical" href="/glossary" />
      </Helmet>
      <Header />
      <div className="Aboutus-page">
        <div className="Aboutus-content">
          <h1>Glossary</h1>

          <h5 style={{ marginTop: "20px" }}>
            <b>Commonly used loan terms</b>
          </h5>
          <p>
            Whether you're taking the loan for your first home, or exploring
            opportunities for a loan against your property, or considering a
            personal loan to get rid of excess credit card debt, loan and
            lending often introduces a slew of terms that might leave you
            seeking clarification.
          </p>
          <p>
            We firmly believe that the complexities of financial transactions
            should be demystified, which is why we've meticulously crafted this
            informative glossary. Tailored to your specific needs, it covers an
            array of terms essential for your unique journey. Bid farewell to
            bewildering jargon and equip yourself with the knowledge you need as
            you navigate the loan application and processing. Your financial
            empowerment starts here.
          </p>
          <div className="mortgage-info-container">
            <table className="mortgage-info-table">
              <thead>
                <tr>
                  <th style={{ fontSize: "16px" }}>Concept</th>
                  <th style={{ fontSize: "16px" }}>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Types of Mortgages</td>
                  <td>
                    Mortgages in India can be used for various purposes,
                    including buying a house (home loan), against a property you
                    already own (loan against property), or for home
                    improvement/extension.
                  </td>
                </tr>
                <tr>
                  <td>Rate of interest, ROI or Annual Percentage Rate, APR</td>
                  <td>
                    APR is a critical metric in the loan industry and is used to
                    express the true cost of borrowing on an annual basis.{" "}
                    <div>
                      It includes not only the interest rate but also any
                      additional fees or costs associated with the loan, such as
                      origination fees, closing costs, and other charges.{" "}
                    </div>
                    APR provides a more comprehensive understanding of the
                    actual cost of a loan, making it easier for borrowers to
                    compare different loan offers from various lenders.
                  </td>
                </tr>
                <tr>
                  <td>Payment Schedule</td>
                  <td>
                    Refers to a detailed plan outlining the timing and amounts
                    of periodic payments that a borrower is required to make to
                    repay the loan. This schedule provides a comprehensive
                    breakdown of how the loan will be amortized over time,
                    specifying the principal and interest components of each
                    payment.
                  </td>
                </tr>
                <tr>
                  <td>Foreclosure</td>
                  <td>
                    The process by which a lender takes possession of the
                    collateral after the borrower defaults on the loan.
                  </td>
                </tr>
                <tr>
                  <td>Insurance charges</td>
                  <td>
                    An insurance charge refers to a fee or premium that a
                    borrower may be required to pay to secure insurance
                    coverage. This insurance is often associated with protecting
                    the lender's interests or the collateral securing the loan.
                  </td>
                </tr>
                <tr>
                  <td>Part payment or Prepayment</td>
                  <td>
                    Prepayment or Part-payment refers to a provision in the loan
                    agreement that outlines the terms and conditions under which
                    the borrower can repay the loan amount before the scheduled
                    repayment period ends.
                  </td>
                </tr>
                <tr>
                  <td>Login fees or Application charges</td>
                  <td>
                    "Login" or "Application" charges typically refer to fees
                    associated with the initial processing of a loan
                    application. These charges are incurred by the borrower
                    during the application stage and are meant to cover the
                    administrative costs involved in reviewing and processing
                    the loan request.
                  </td>
                </tr>
                <tr>
                  <td>Moratorium</td>
                  <td>A temporary suspension of loan repayments.</td>
                </tr>
                <tr>
                  <td>Stamp Duty</td>
                  <td>
                    A tax paid on the legal recognition of documents related to
                    the property or loan.
                  </td>
                </tr>
                <tr>
                  <td>Priority Sector Lending (PSL)</td>
                  <td>
                    A mandate by the RBI requiring banks to provide a specified
                    percentage of their loans to certain priority sectors, such
                    as agriculture, micro, small and medium enterprises (MSMEs),
                    education, and housing.
                  </td>
                </tr>
                <tr>
                  <td>Restructuring</td>
                  <td>
                    Altering the terms of a loan to provide relief to borrowers
                    facing financial difficulties, which could include extending
                    the loan tenure, reducing the interest rate, or offering a
                    payment holiday.
                  </td>
                </tr>
                <tr>
                  <td>Overdraft</td>
                  <td>
                    A credit facility that allows the borrower to withdraw more
                    money than they have in their account, up to a certain
                    limit.
                  </td>
                </tr>
                <tr>
                  <td>Term loans</td>
                  <td>
                    Any loan with terms fixed in beginning - duration, interest,
                    etc.
                  </td>
                </tr>
                <tr>
                  <td>Trade finance</td>
                  <td>
                    Financing for trade, and it concerns both domestic and
                    international trade transactions. -Letter of Credit, invoice
                    financing, Bank Guarantee, Trade Credit Insurance
                  </td>
                </tr>
                <tr>
                  <td>Invoice Financing</td>
                  <td>
                    Providing funds to businesses against their accounts
                    receivables or outstanding invoices.
                  </td>
                </tr>
                <tr>
                  <td>Invoice discounting</td>
                  <td>
                    One way of invoice financing, sell unpaid invoices to a
                    lender and they give you a cash advance that's a percentage
                    of the invoice's value
                    <div>
                      Discount Fee: The financial institution charges a discount
                      fee or interest for providing the advance.
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>CGTMSE</td>
                  <td>
                    Credit Guarantee Fund Trust for Micro and Small Enterprise
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default GlossaryPage;
